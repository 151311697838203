import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  @Input() supportQuestion: string;
  panelOpenState = true;

  constructor(private route: ActivatedRoute,
              private titleService: Title) {
    this.route.params.subscribe( params => {
      console.log('Inside support component, passed params: ' + JSON.stringify(params));
      this.supportQuestion = params ['supportQuestion'];


    });
  }

  ngOnInit() {
    this.titleService.setTitle('Help and Support | BagMask.com');
  }

}
