import { NgModule } from '@angular/core';
import {AddPostComponent} from './add-post/add-post.component';
import {AddPostSubmitDialogComponent} from './add-post/add-post-submit-dialog/add-post-submit-dialog.component';
import {AddPostIntroDialogComponent} from './add-post/add-post-intro-dialog/add-post-intro-dialog.component';
import {AddPostSalaryBenefitsComponent} from './add-post/add-post-salary-benefits/add-post-salary-benefits.component';
import { AddPostFacilityComponent } from './add-post/add-post-facility/add-post-facility.component';
import {JobPostingsTableComponent} from './PostingTable/job-postings-table/job-postings-table.component';
import {JobPostingsTableFilteringComponent} from './PostingTable/job-postings-table-filtering/job-postings-table-filtering.component';
import { SharedModule } from '../shared/shared.module';
import {AddPostResponsibilitiesRequirementsComponent} from './add-post/add-post-responsibilities-requirements/add-post-responsibilities-requirements.component';
import { AddPostPaymentSubmitComponent } from './add-post/add-post-payment-submit/add-post-payment-submit.component';
import { UserPostingTableComponent } from './PostingTable/user-posting-table/user-posting-table.component';
import {PaymentsModule} from '../payments/payments.module';
import { SinglePostComponent } from './single-post/single-post.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    AddPostComponent,
    AddPostSubmitDialogComponent,
    AddPostIntroDialogComponent,
    AddPostSalaryBenefitsComponent,
    AddPostResponsibilitiesRequirementsComponent,
    JobPostingsTableComponent,
    JobPostingsTableFilteringComponent,
    AddPostFacilityComponent,
    AddPostPaymentSubmitComponent,
    UserPostingTableComponent,
    SinglePostComponent,
  ],
  imports: [
    SharedModule,
    PaymentsModule,
    RouterModule
  ],
  exports: [
    UserPostingTableComponent,
    SinglePostComponent,
  ],
})

export class PostModule {}
