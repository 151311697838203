import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import {PostModule} from '../Post/post.module';
import {PaymentsModule} from '../payments/payments.module';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import { UserJobPostingsComponent } from './user-job-postings/user-job-postings.component';
import { PricingModule } from '../pricing/pricing.module';


@NgModule({
  declarations: [

                UserProfileComponent,
                UserJobPostingsComponent,
  ],
  imports: [
                AppRoutingModule,
                AngularFireAuthModule,
                AngularFirestoreModule,
                PaymentsModule,
                PricingModule,
                PostModule,
                PricingModule,
                SharedModule
  ],
  exports: [UserProfileComponent],
})

export class UserModule {}
