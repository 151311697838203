import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {rejects} from 'assert';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private http: HttpClient,
  ) { }

  // Check if file exists
  async fileExistsOnServer(fileName: string): Promise<any> {
    console.log('fileExistsOnServer running for fileName: ', fileName);

    const promise = new Promise<any>((resolve, reject) => {
      console.log('preparing to test for existence of file');
      this.http.get(fileName).subscribe((data) => {
        // TODO: Figure out why this doesn't work, not getting resolve, it's like the subscribe never happens
        console.log(data);
        console.log('file exists on the server, returning true');
        resolve('true');
      }, (err) => {
        // HANDLE file not found
        if (err.status === 404) {
          console.log('file does not exist on server! returning false');
          reject('false');
        }
      });

    });
    return promise;

  }

}
