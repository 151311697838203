import {BehaviorSubject, Observable, Observer, Subject, Subscription} from 'rxjs';
import {Injectable, isDevMode, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { AuthData } from '../Models/auth-data.model';

import { PostService } from './post.service';
import firebase from 'firebase/app';
import 'firebase/auth';
// import * as firebase from 'firebase/app';
import { UIService } from './ui.service';
import { User } from '../Models/user.model';

// import { auth } from 'firebase/app';
import {tap} from 'rxjs/operators';
import {CacheService} from './cache.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';


@Injectable()
export class AuthService implements OnDestroy {
  authChange = new Subject<boolean>();
  fullUserInfoSubject = new Subject<User>();
  fullUserInfoBehaviorSubject = new BehaviorSubject<User>(undefined);
  private isAuthenticated = false;
  private self = this;

  user: Observable<firebase.User>;
  userSubscription: Subscription;
  authenticatedUser: User;
  public fullUserInfo: User;

  authAfterLogin: boolean;

  // user: Observable<User>;

  constructor(private postService: PostService,
              private uiService: UIService,
              private router: Router,
              private cacheService: CacheService,
              // private fbAuth: FirebaseAuth,
              private afs: AngularFirestore,
              private afAuth: AngularFireAuth, ) {

    // this.user = this.afAuth.authState.pipe(
    //   switchMap(user => {
    //     if(user) {
    //       console.log('Inside auth service, constructor, user setup: ' + user);
    //       return this.afs.doc<User>('users/${user.uid}').valueChanges();
    //     } else {
    //       console.log('Inside auth service, constructor, no user');
    //       // User undefined return observable of null
    //       return EMPTY;
    //     }
    //   }))

  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user);
      });
  }

  // TODO: Re-write this passing object in
  private createUserData(user, userType: string, userCompanyType: string, userMailingList?: boolean, userCompanyName?: string) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    if (!userCompanyName) {
      console.log('in createUserData, no user name exists, setting to blank');
      userCompanyName = '';
    }

    console.log('In createUserData, do we have usercompanyname?: ' + userCompanyName);
    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      mailingList: userMailingList,
      // userName: userName,
      userType: userType,

      userCompanyType: userCompanyType,
      userCompanyMonthlyPlan: '',
      userCompanyName: userCompanyName,
      // userCompanyMonthlyPlanExpirationDate: '',
      // userCompanyMonthlyPlanFreePriorityPostsRemaining: 0,
      // userCompanyMonthlyPlanFreePriorityPostsTotal: 0,
      // userCompanyMonthlyPlanPriorityDiscount: 0,
      // userCompanyMonthlyPlanStartDate: '',
      createDate: new Date(),
    };

    return userRef.set(data, { merge: true });
  }


  async updateUserData(user) {
    // Sets user data to firestore on login
    console.log('updateUserData starting for user: ' + user.uid);
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
    };

    this.getUserInfo(user.uid, user.email);

    return userRef.set(data, { merge: true });
  }

  getUserInfo(uid: string, email: string) {
    console.log('inside auth service, getUserInfo');
    // this.userSubscription = this.afs.doc('users/' + this.authenticatedUser.uid).valueChanges().pipe(

    this.userSubscription = this.afs.doc('users/' + uid).valueChanges().pipe(
      tap(data => {
        try {
          console.log('Inside getUserInfo try userSubscription for data: ', data);
          this.fullUserInfo = data as User;
          this.fullUserInfo.email = email;
          this.fullUserInfo.uid = uid;

          // This returns fullUserInfo as a subject for safer grabbing of user info
          this.fullUserInfoSubject.next(data as User);
          this.fullUserInfoBehaviorSubject.next(data as User);
          // console.log('inside getUserInfo, showing data:');
          // console.log(JSON.stringify(this.fullUserInfo.userCompanyName));

        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar(
              'Encountered Issue retrieving User Info, Please contact support', null, 3000);
          } else {
            this.uiService.showSnackbar(error.message, null, 3000);
          }
        }
      })
    ).subscribe();
  }


  initAuthListener() {
    this.afAuth.authState.subscribe(user => {
      // TODO: Enable this when verification can be manual
      // if (user && user.emailVerified) {
      if (user) {
        this.isAuthenticated = true;
        // Get the User Info from the database
        console.log('User is Authenticated, getting user info');
        // this.user = this.afs.doc<User>('users/${user.uid}').valueChanges();
        console.log('User email: ' + user.email + ' uid: ' + user.uid);
        this.authenticatedUser = user;

        // Update detailed user info object we have in auth service.
        this.getUserInfo(user.uid, user.email);

        console.log('AUTH AFTER LOGIN: ' + this.authAfterLogin);
        this.authChange.next(true);
        if (this.authAfterLogin) {
          this.router.navigate(['/home']);
        }
        // if (isDevMode()) {
        //   this.router.navigate(['/home']);
        // } else {
        //   this.router.navigate(['/home']);
        // }

      } else {
        // this.user = EMPTY;
        // this.postService.cancelSubscriptions();
        console.log('User is not authenticated: ' + this.isAuthenticated);
        this.authChange.next(false);
        this.isAuthenticated = false;
      }
    });
  }


  async registerUser(authData: AuthData) {
    const self = this;
    const redirectSiteAfterVerificationLink = window.location.protocol + '//' + window.location.host + '/home';
    console.log('Register user with redirectSiteAfterVerificationLink of: ' + redirectSiteAfterVerificationLink);
    console.log('Inside auth registerUser with AuthData: ' + JSON.stringify(authData));
    const actionCodeSettings = {
      url: redirectSiteAfterVerificationLink
    };

    let signupUserSuccess: string;
    // Give default value, error by default.
    signupUserSuccess = 'error';
    this.uiService.loadingStateChanged.next(true);
    await this.afAuth
      .createUserWithEmailAndPassword(authData.email, authData.password)
      .then(result => {
        console.log('Inside registerUser, after afAuth.auth.  Preparing to send Email Verification');
        console.log(this.afAuth.currentUser);
        console.log(result.user);
        const user = this.afAuth.currentUser.then((user) => {
          console.log('returned user: ', user);

          // Start create of new user
          console.log('Creating user with data: ' + authData.userType, authData.userCompanyType);

          if (authData.userCompanyName) {
            console.log('inside auth registerUser, we have user company name: ' + authData.userCompanyName);
            this.createUserData(user,
              authData.userType,
              authData.userCompanyType,
              authData.mailingList,
              authData.userCompanyName);
          } else {
            this.createUserData(user,
              authData.userType,
              authData.userCompanyType,
              authData.mailingList);
          }
          // let newUserUid: any;
          // this.afAuth.currentUser.then((user) => {
          //   newUserUid = user.uid;
          // })
          // console.log('Checking value of authenticatedUser uid: ' + newUserUid);

          // End create of new user

          return user.sendEmailVerification().then(function() {
            console.log('Verification Email Sent');
            self.uiService.showSnackbar('Verification Email Successfully Sent!', null, 15000);
          }).catch(function(error) {
            console.log('Error occurred with email verification: ' + error);
            console.log(error);
            self.uiService.showSnackbar(error.message, null, 30000);
          });
        })

        // user.sendEmailVerification(actionCodeSettings).then(function() {
        //   console.log('Verification Email Sent');
        //   self.uiService.showSnackbar('Verification Email Successfully Sent!', null, 15000);
        // }).catch(function(error) {
        //   console.log('Error occurred with email verification: ' + error);
        //   console.log(error);
        //   self.uiService.showSnackbar(error.message, null, 30000);
        // });


        this.uiService.loadingStateChanged.next(false);
        signupUserSuccess = 'success';
      })
      .catch(error => {
        this.uiService.loadingStateChanged.next(false);

        this.uiService.showSnackbar(error.message, null, 6000);
        signupUserSuccess = 'error';
      });

    console.log('Completed registerUser, registerOutcome set to: ' + signupUserSuccess);
    return signupUserSuccess;
  }

  async login(authData: AuthData) {
    let loginOutcome: string;
    this.uiService.loadingStateChanged.next(true);
    await this.afAuth
      .signInWithEmailAndPassword(authData.email, authData.password)
      .then(result => {
        console.log('User Email Verified?: ' + result.user.emailVerified);

        // TODO: Remove this section re-enable bottom section when manual verification enabled
        this.authAfterLogin = true;
        this.updateUserData(result.user);
        console.log('Result:');
        console.log(result);
        loginOutcome = 'success';

        this.router.navigate(['/home']);
        // Add one to the user's local login count
        this.cacheService.loginCountLocal();

        // TODO: Re-enable this after manual verification
        // if (result.user.emailVerified === false) {
        //   // Log user out until verified
        //   // this.afAuth.auth.signOut();
        //   console.log('User Email Not Verified, redirect to login');
        //   this.uiService.showSnackbar('User Email not verified yet', null, 4000);
        //   this.router.navigate(['/login/']);
        //   loginOutcome = 'unverified';
        // } else {
        //   console.log('User successfully verified, logging in...');
        //   this.authAfterLogin = true;
        //   this.updateUserData(result.user);
        //   console.log('Result:');
        //   console.log(result);
        //   loginOutcome = 'success';
        // }

        // Create session cookie so we can (hopefully) communicate with the separate payment app


        this.uiService.loadingStateChanged.next(false);
        this.initAuthListener();
      })
      .catch(error => {
        console.log('Hit a login error: ' + error.message);
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar(error.message, null, 3000);
        loginOutcome = 'error';
      });
    return loginOutcome;
  }

  logout() {
    this.authChange.next(false);
    this.afAuth.signOut();
    this.authenticatedUser = null;
    // this.ngOnDestroy();

    // TODO: Look into persistent error of an existing firebase subscription causing error on logout AFTER logging in during same session
    // - Refresh seems to not cause this after logged in

  }

  isAuth() {
    return this.isAuthenticated;
  }

  // onAuthStateChanges returns an observable that completes when user is pulled in from firebase
  // onAuthStateChange(): any {
  //   console.log('onAuthStateChange started');
  //   return this.afAuth.onAuthStateChanged;
  //   // this.afAuth.onAuthStateChanged(user => {
  //   //   console.log('onAuthStateChange fired, returned user: ', user);
  //   // })
  // }

  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email)
      .then(() => {
        console.log('email sent');
        this.uiService.showSnackbar('Password Reset Email Sent.  Be sure to check your spam folder if it does not' +
          'show up quickly.  If you are still having issues, please reach out to info@bagmask.com', null, 6000);
      })
      .catch((error) => {
        console.log(error);
        this.uiService.showSnackbar(error.message, null, 3000);
      });
  }

  ngOnDestroy() {
    console.log('inside auth service ngOnDestroy');
    if (this.userSubscription) {
      console.log('auth service, userSubscription exists, destroying');
      this.userSubscription.unsubscribe();
    }
  }


}
