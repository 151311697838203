import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-job-postings',
  templateUrl: './user-job-postings.component.html',
  styleUrls: ['./user-job-postings.component.scss']
})
export class UserJobPostingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
