import {Injectable, OnDestroy} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {Payment} from '../Models/payment.model';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {ConfirmationDialogComponent} from '../ui/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UIService} from '../Services/ui.service';

@Injectable()
export class PaymentService implements OnDestroy {
  userId: string;
  private subject = new Subject<string>();
  private priceSubject = new Subject<number>();
  private prioritySelected = new Subject<number>();

  // TODO: Turn this back to FALSE WHEN FREE BETA IS OVER
  private bagmaskFreePeriod = true;

  constructor(private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              public dialog: MatDialog) {
    this.afAuth.authState.subscribe((auth) => {
      if (auth) {
        this.userId = auth.uid;
      }
    });
  }

  processPayment(token: any, amount: number) {
    console.log('processPayment started for token: ', token, ' amount: ', amount);
    const payment = { token, amount };
    console.log('payment: ', JSON.stringify(payment));
    console.log('payment: ', payment);
    // return this.afs.collection('payments').doc(this.userId).set(payment);
    return this.afs.collection('users').doc(this.userId).collection('payments').doc(token.id).set(payment);
    // return this.db.list(`/payments/${this.userId}`).push(payment)
  }

  sendMessage(message: string) {
    console.log('sendMessage: ' + message);
    this.subject.next(message) ;
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  sendPrice(price: number) {
    this.priceSubject.next(price);
  }

  getPrice(): Observable<any> {
    return this.priceSubject.asObservable();
  }

  sendPriSelected(priSelected: number) {
    this.prioritySelected.next(priSelected);
  }

  getPriSelected(): Observable<any> {
    return this.prioritySelected.asObservable();
  }

  getBagmaskFreePeriodStatus() {
    console.log('getBagmaskFreePeriodStatus returning: ' + this.bagmaskFreePeriod);
    return this.bagmaskFreePeriod;
  }

  ngOnDestroy() {
    console.log('inside payment service ngOnDestroy');

  }

}
