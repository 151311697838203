<div class="all-wrap" fxLayout="column">
  <mat-sidenav-container fxFlexFill fxFlex="100%" (backdropClick)="close('backdrop')">

    <!--<mat-sidenav #sidenav mode="over" [(opened)]="opened" (opened)="events.push('open!')"-->
    <!--(closed)="events.push('close!')">-->
    <mat-sidenav #sidenav [fixedInViewport]="true" [fixedTopGap]="0"
                 [fixedBottomGap]="0" mode="over" [(opened)]="opened">
      <mat-nav-list>

        <a mat-list-item routerLink="/home" (click)="close('Search Selected')">Search Job Postings</a>
        <!-- Hiding the link to add post from non-job posters -->
        <a mat-list-item href="https://BagMask.com/anesthesia-career-navigation"
           (click)="close('Career Navigation')">Career Navigation</a>
        <a mat-list-item href="https://BagMask.com/anesthesia-cv-template/" (click)="close('Anesthesia CV Templates')">
          Anesthesia CV Templates</a>
        <a mat-list-item href="https://BagMask.com/anesthesia-scholarship" (click)="close('Scholarship')">Scholarship</a>

        <a *ngIf="(isAuth && userPosterType === 'Job Poster')" mat-list-item routerLink="/add" (click)="close('New Posting Selected')">
          New Job Posting</a>
        <a *ngIf="(isAuth && userPosterType === 'Job Poster')" mat-list-item routerLink="/pricing" (click)="close('New Posting Selected')">
          Pricing</a>
        <a *ngIf="isAuth" mat-list-item [routerLink]="['/user', 'profile']" (click)="close('User Profile Selected')">User Profile</a>
        <a *ngIf="isAuth" mat-list-item [routerLink]="['/user', 'jobs']"
           (click)="close('User Profile Selected')">User Job Postings</a>

        <!--<a *ngIf="isAuth" mat-list-item routerLink="/company/profile"-->
        <!--   (click)="close('Company Profile Selected')">Company Profile</a>-->


        <a mat-list-item routerLink="/support" (click)="close('Help/Support')">Help/Support</a>

        <a *ngIf="!isAuth" mat-list-item routerLink="/login" (click)="close('Login Selected')">Login</a>
        <a *ngIf="!isAuth" mat-list-item routerLink="/home" (click)="onRegister('employer')">
          Signup as Employer</a>
        <a *ngIf="!isAuth" mat-list-item routerLink="/user" (click)="onRegister('seeker')">Signup as User</a>

        <div class="version-info">{{appVersion}}</div>

      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>

      <mat-toolbar id="bagmask-main-toolbar" class="mat-elevation-z5" fxLayout="row" fxLayoutAlign="space-around center">
        <mat-toolbar-row class="bagmask-main-toolbar-row">
          <div fxFlex class="bagmask-main-toolbar-nav-icons-left">
            <button
              type="button"
              aria-label="Toggle sidenav"
              mat-icon-button
              (click)="sidenav.toggle()">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <!--userInfo: {{userPosterType}}-->

            <span *ngIf="!uiService.isSmallScreen" class="header-text-links">
              <a class="white-plain-links" routerLink="/home">Search Jobs</a>

              <span class="lightblue-vertical-divider small-horizontal-space"> | </span>

              <a class="white-plain-links small-horizontal-space"
                 href="https://BagMask.com/anesthesia-career-navigation">
                Career Navigation
              </a>

              <span class="lightblue-vertical-divider small-horizontal-space"> | </span>

              <a class="white-plain-links small-horizontal-space"
                 href="https://BagMask.com/anesthesia-scholarship">Scholarship</a>

              <span class="lightblue-vertical-divider small-horizontal-space"> | </span>

              <a class="white-plain-links small-horizontal-space" href="https://BagMask.com/anesthesia-cv-template/">
                CV Templates
              </a>
            </span>
          </div>

          <div fxFlex class="bagmask-main-toolbar-title">

            <a href="https://BagMask.com">
              <img *ngIf="bagmaskServer === 'PRODUCTION'" routerLink="/home"
                 class="bagmask-logo-image" src="../../assets/bagmask-logo.png">
            </a>
            <p class="development-header-text" *ngIf="bagmaskServer === 'bagmask-dev'">{{bagmaskServer}}</p>

          </div>

          <div fxFlex class="bagmask-main-toolbar-nav-icons" fxLayoutAlign="end" fxLayout="row" fxLayoutGap="10px">

            <span *ngIf="isAuth && userPosterType==='Job Poster' && !uiService.isSmallScreen" class="header-text-links">
              <a class="white-plain-links" routerLink="/add">Post New Job</a>
            </span>

            <button *ngIf="!isAuth && !uiService.isSmallScreen"
                    fxFlex="176px"
                    mat-button mat-raised-button
                    color="warn"
                    matTooltip="Register as Employer"
                    class="bagmask-toolbar-button"
                    (click)="onRegister('employer')">
              Employers / Post a Job
            </button>
            <button *ngIf="!isAuth"
                    fxFlex="75px"
                    mat-button mat-raised-button
                    matTooltip="Login to BagMask.com"
                    class="bagmask-toolbar-button"
                    routerLink="/login">
              Login
            </button>

            <div matTooltip="Access your User Profile here" [routerLink]="['/user', 'profile']" class="toolbar-user-name" *ngIf="isAuth">
              <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">person</mat-icon>
            </div>
            <!--<a  style=" text-decoration: none;" [routerLink]="['your-path',param1,param2]" target="_blank"  >your label </a>-->
            <a class="plain-link" [routerLink]="['/support']" target="_blank">
              <div matTooltip="BagMask Support Page" class="toolbar-user-name" *ngIf="isAuth">
                <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">help</mat-icon>
              </div>
            </a>
            <button *ngIf="isAuth"
                    matTooltip="Logout"
                    mat-icon-button routerLink="/login"
                    (click)="onLogout()"
                    class="bagmask-toolbar-button"
                    routerLinkActive="activeRouterButton">
              <mat-icon class="bagmask-toolbar-icon" aria-label="label goes here">exit_to_app</mat-icon>
            </button>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>

      <!-- Snotify -->
      <ng-snotify></ng-snotify>

      <main class="content" [@fadeAnimation]="getRouterOutletState(o)">
        <router-outlet #o="outlet"></router-outlet>
      </main>

      <!--<footer>-->
        <!--<h2>Sticky Footer !!</h2>-->
        <!--<p>... with variable height</p>-->
      <!--</footer>-->

      <!--<div id="jobsContainer" [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">-->
        <!--<router-outlet #o="outlet"></router-outlet>-->
      <!--</div>-->
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-footer-distributed-contact-form fxFlexOffset="auto"></app-footer-distributed-contact-form>
</div>
