import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-post-intro-dialog',
  templateUrl: './add-post-intro-dialog.component.html',
  styleUrls: ['./add-post-intro-dialog.component.scss']
})
export class AddPostIntroDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AddPostIntroDialogComponent>) { }

  closeDialog(dialogResult: string) {
    console.log('Closed AddPostIntroDialogComponent')
    this.dialogRef.close(dialogResult);
  }
  ngOnInit() {
    console.log('In AddPostIntroDialogComponent, data: ' + this.data);
  }



}
