<form
  #addPostFormSalaryBenefits="ngForm"
  class="add-post-form"
  [formGroup]="postFormSalaryBenefits"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-sm="space-between center"
  fxLayout="row wrap">

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <!--Signing bonus-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%" hintLabel="Thousands, max 3 digits">
      <input
        class="post-right-align"
        matInput
        maxlength="3"
        #inputSalSigningBonus
        formControlName="salSigningBonus"
        placeholder="Sign-on Bonus Amount">
      <!--<span matPrefix>$</span>-->
      <span matSuffix>k</span>
      <mat-hint align="end">{{inputSalSigningBonus.value?.length || 0}}/3</mat-hint>

      <!--<mat-error *ngIf="minSalary.invalid">{{getMinSalaryErrorMessage()}}</mat-error>-->
    </mat-form-field>

    <!--Signing Bonus Commitment Time-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%" >
      <input type="text"
             placeholder="Signing Bonus Commitment Time"
             formControlName="salSigningBonusCommitmentReimbursement"
             matInput [matAutocomplete]="signingAuto">
      <mat-autocomplete #signingAuto="matAutocomplete">
        <mat-option *ngFor="let option of signingBonusCommitmentTimeAutoOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Tuition Reimbursement-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salTuitionReimbursement"
        placeholder="Tuition Reimbursement">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Interview Expenses-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Interview Expenses Covered"
             formControlName="salInterviewExpenses"
             matInput [matAutocomplete]="interviewExpensesAuto">
      <mat-autocomplete #interviewExpensesAuto="matAutocomplete">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Relocation Assistance-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Relocation Assistance"
             formControlName="salRelocationAssistance"
             matInput [matAutocomplete]="relocationAssistanceAuto">
      <mat-autocomplete #relocationAssistanceAuto="matAutocomplete">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


    <!--Weeks Paid Vacation-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Weeks Paid Vacation"
             formControlName="salWeeksPaidVacation"
             matInput [matAutocomplete]="weeksPaidVacationAuto">
      <mat-autocomplete #weeksPaidVacationAuto="matAutocomplete">
        <mat-option *ngFor="let option of weeksPaidVacationOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Paid CME Time-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Paid CME Time"
             formControlName="salPaidCMETime"
             matInput [matAutocomplete]="paidCMETimeAuto">
      <mat-autocomplete #paidCMETimeAuto="matAutocomplete">
        <mat-option *ngFor="let option of paidCMETimeOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!--CME Allowance-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="CME Allowance"
             formControlName="salCMEAllowance"
             matInput [matAutocomplete]="paidCMEAllowanceAuto">
      <mat-autocomplete #paidCMEAllowanceAuto="matAutocomplete">
        <mat-option *ngFor="let option of cmeAllowanceOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Salary Income-->
    <!--This is the AA-only version of Salary income field, similar field exists below for Anest/CRNA-->
    <mat-form-field *ngIf="currentJobType == 'AA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salSalaryIncomeBoolean"
        placeholder="Income">
        <mat-option *ngFor="let option of salaryIncomeOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Paid Overtime-->
    <!--AA, CRNA-->
    <mat-form-field *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Paid Overtime"
             formControlName="salPaidOvertime"
             matInput [matAutocomplete]="paidOvertimeAuto">
      <mat-autocomplete #paidOvertimeAuto="matAutocomplete">
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-card>

  <!--[hidden]="isLoading"-->

  <mat-card *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"
            class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <!--Salary Income-->
    <!--This is the Anest/CRNA version of Salary income field, similar field exists above for AA-->
    <mat-form-field *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salSalaryIncomeBoolean"
        placeholder="Income">
        <mat-option *ngFor="let option of salaryIncomeOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Fee-for-service Income-->
    <mat-form-field *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salIncomeFeeForService"
        placeholder="Fee-for-service Income">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Practice Partnership Potential-->
    <mat-form-field *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salEventualPartnership"
        #inputEventualPartnership
        (selectionChange)="eventualPartnershipChange(inputEventualPartnership.value)"
        placeholder="Eventual Partnership in Practice">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <!--<mat-hint>Does this job lead to partnership in the practice?</mat-hint>-->
    </mat-form-field>

    <!--Income as Full Partner-->
    <!--<mat-form-field *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"-->
    <mat-form-field *ngIf="eventualPartnershipBoolean === true"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salIncomeFullPartnerPractice"
        placeholder="Income as Full Partner">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
      <!--<mat-hint>Income for Full Partner in a Group Practice</mat-hint>-->
    </mat-form-field>


  </mat-card>

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
    <!--Medical Insurance-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salMedicalInsurance"
        placeholder="Medical Insurance">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Retirement Plan-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Retirement Plan"
             formControlName="salRetirementPlan"
             matInput [matAutocomplete]="retirementPlanAuto">
      <mat-autocomplete #retirementPlanAuto="matAutocomplete">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Malpractice Insurance-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="salMalpracticeInsurance"
        placeholder="Malpractice Insurance">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Disability Insurance-->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Disability Insurance"
             formControlName="salDisabilityInsurance"
             matInput [matAutocomplete]="disabilityInsuranceAuto">
      <mat-autocomplete #disabilityInsuranceAuto="matAutocomplete">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-card>

  <!--<div class="add-post-vertical-space" fxFlex="100%"></div>-->

  <!--<div class="debug-information"  fxFlexAlign="row" fxLayoutAlign="center center">-->
    <!--<pre>{{ addPostFormSalaryBenefits.value | json }}</pre>-->
  <!--</div>-->

</form>
