<mat-toolbar class="bagmask-sub-toolbar toolbar-smaller-font">
  <button
    mat-button mat-raised-button
    class="user-profile-section-button"
    [routerLink]="['/user', 'profile']"
    matTooltip="View your User Profile">
    User Profile
  </button>
  <button
    *ngIf="(currentUserType === 'Job Poster')"
    mat-button mat-raised-button
    class="user-profile-section-button"
    [routerLink]="['/user', 'jobs']"
    matTooltip="View all of your Job Postings">
    My Job Postings
  </button>
<!--  <button-->
<!--    mat-button mat-raised-button-->
<!--    class="user-profile-section-button"-->
<!--    [routerLink]="['/user', 'priorityInfo']"-->
<!--    matTooltip="Create Template from Post">-->
<!--    Priority Posting Info-->
<!--  </button>-->
<!--  <button-->
<!--    mat-button mat-raised-button-->
<!--    class="user-profile-section-button"-->
<!--    [routerLink]="['/user', 'advertising']"-->
<!--    matTooltip="Create Template from Post">-->
<!--    Advertising-->
<!--  </button>-->
</mat-toolbar>
<!--<mat-tab-group class="user-profile-tabs" [selectedIndex]="selectedTabIndex"  (selectedTabChange)="tabChanged($event)">-->
<!--  <mat-tab label="Profile Information">-->
<div class="user-profile-container">
  <div *ngIf="activeSection==='profile'" class="add-post-container">
    <form
      class="add-post-form"
      [formGroup]="myForm"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-sm="space-between center"
      fxLayout="row wrap">
      <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
                fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
        <mat-card-title class="center-mat-card-title" fxFlex="100%">User Info</mat-card-title>
        <mat-form-field fxFlex="48%" fxFlex.lt-sm="0 1 48%">
          <input
            matInput
            formControlName="email"
            placeholder="Email">
        </mat-form-field>

        <mat-form-field fxFlex="48%" fxFlex.lt-sm="0 1 48%">
          <input
            matInput
            formControlName="userType"
            placeholder="User Type">
        </mat-form-field>

        <!--&lt;!&ndash; Enable this to allow selecting of usertype select for Production. &ndash;&gt;-->
        <!--<mat-form-field class="add-post-focus-text-color"-->
        <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
        <!--<mat-select-->
        <!--formControlName="userType"-->
        <!--#userType-->
        <!--(selectionChange)="onJobTypeChange(userType.value)"-->
        <!--placeholder="User Type">-->
        <!--<mat-option *ngFor="let userType of userTypes" [value]="userType">{{userType}}</mat-option>-->
        <!--</mat-select>-->
        <!--<mat-hint style="color:chartreuse">DEBUG: This is only editable in debug mode, locked on release</mat-hint>-->
        <!--</mat-form-field>-->

        <!--<mat-form-field class="add-post-focus-text-color"-->
        <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
        <!--<input-->
        <!--matInput-->
        <!--required-->
        <!--formControlName="userName"-->
        <!--placeholder="User Name">-->
        <!--</mat-form-field>-->
      </mat-card>

      <mat-card *ngIf="(currentUserType === 'Job Poster')" class="bagmask-card-dark-blue"
                fxFlex="100%" fxLayoutAlign="space-between center"
                fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
        <mat-card-title class="center-mat-card-title" fxFlex="100%">Job Poster Info</mat-card-title>
        <!--<mat-card-subtitle>These will auto-populate when you are creating a new job posting</mat-card-subtitle>-->

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="48%" fxFlex.lt-sm="0 1 48%">
          <input
            matInput
            formControlName="userCompanyName"
            placeholder="Company Name">
        </mat-form-field>

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="48%" fxFlex.lt-sm="0 1 48%">
          <input
            matInput
            formControlName="userCompanyType"
            placeholder="Company Type">
        </mat-form-field>

        <!--&lt;!&ndash; Enable this to allow selecting of companyType select for Production. &ndash;&gt;-->
        <!--<mat-form-field class="add-post-focus-text-color"-->
        <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
        <!--<mat-select-->
        <!--formControlName="userCompanyType"-->
        <!--placeholder="Company Type">-->
        <!--<mat-option *ngFor="let companyType of companyTypes" [value]="companyType">{{companyType}}</mat-option>-->
        <!--</mat-select>-->
        <!--<mat-hint style="color:chartreuse">DEBUG: This is only editable in debug mode, locked on release</mat-hint>-->
        <!--</mat-form-field>-->

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="48%" fxFlex.lt-sm="0 1 48%">
          <input
            matInput
            type="url"
            formControlName="userCompanyURL"
            placeholder="Company Website URL">
        </mat-form-field>

        <!--Long Description-->
        <mat-form-field class="add-post-long-description add-post-focus-text-color"
                        fxFlex="0 1 100%" fxFlex.lt-sm="0 1 calc(100% - 15px)">
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="userCompanyInfo"
              required
              #inputLongDescription
              placeholder="Company Info">
            </textarea>
          <mat-hint>This will automatically display in any job posting from your account.</mat-hint>
        </mat-form-field>

      </mat-card>

      <mat-card *ngIf="!hasPostingPlan && currentUserType === 'Job Poster'"
                class="bagmask-card-payment-plans  vertical-space-above
                  ie-flex-basis-fix cursor-pointer"
                fxLayoutAlign="center center"
                fxLayout="row wrap" fxLayoutAlign.lt-sm="center center"
                fxFlex="100%">

        <mat-card-content>
          <p class="plan-title">No Posting Plan Active</p>
          <a class="plain-link" [routerLink]="['/pricing']" target="_blank">
            <button class="plan-button" mat-flat-button type="button">
              Click Here to select a Monthly Posting Plan
            </button>
          </a>

          <p class="plan-title">Each plan offers some great features including:</p>
          <ul>
            <li class="offset-li-left">Unlimited Free Job Postings</li>
            <li class="offset-li-left">Monthly Free Priority Upgrades</li>
            <li class="offset-li-left">Excellent Discounts on Priority / High Priority Upgrades</li>
            <!-- <li class="offset-li-left discount-green-text">
              Silver Plan Free and Gold/Platinum 50% off for a Limited Time!
            </li> -->
          </ul>
        </mat-card-content>
      </mat-card>

      <!--Only show the posting plan card if user has selected and purchased posting plan, otherwise show them "buy"-->
      <mat-card *ngIf="hasPostingPlan && currentUserType === 'Job Poster'"
                class="bagmask-card-payment-plans vertical-space-above" fxFlex="100%">
        <mat-card-header>
          <mat-card-title class="center-mat-card-title">Posting Plan: {{postingPlan}}</mat-card-title>
          <!--<mat-card-subtitle>{{option.planName}}-->
          <!--</mat-card-subtitle>-->
        </mat-card-header>
        <!--<img mat-card-image src="http://image.tmdb.org/t/p/w185/{{film.poster_path}}" alt="Photo of a Shiba Inu">-->
        <mat-card-content>
          <p *ngIf="postingPlan === '10 Standard Posts'">Free Standard Posts
            Included: {{postingPlanFreeStandardPostsTotal}}</p>
          <p *ngIf="postingPlan === '10 Standard Posts'">Free Standard Posts
            Remaining: {{postingPlanFreeStandardPostsRemaining}}</p>
          <p *ngIf="postingPlan != '10 Standard Posts'">Free Priority Posts
            Included: {{postingPlanFreePriorityPostsTotal}}</p>
          <p *ngIf="postingPlan != '10 Standard Posts'">Free Priority Posts
            Remaining: {{postingPlanFreePriorityPostsRemaining}}</p>
          <p *ngIf="postingPlan != '10 Standard Posts'">Plan Discount on all further Priority and High Priority
            Posts: {{postingPlanPriorityDiscount}}%</p>
          <p>Plan Start Date: {{postingStartDate | date: 'shortDate'}}</p>
          <p>Plan Expiration Date: {{postingStartExpirationDate | date: 'shortDate'}}</p>
        </mat-card-content>
        <!--<mat-card-actions>-->
        <!--<button mat-button>-->
        <!--<mat-icon>favorite</mat-icon>-->
        <!--</button>-->
        <!--<button mat-button>SHARE</button>-->
        <!--</mat-card-actions>-->
      </mat-card>

      <!--Only show "update plan" if user already has selected posting plan.-->
      <div *ngIf="hasPostingPlan && currentUserType === 'Job Poster'" class="user-update-form">
        <div fxLayoutAlign="space-around" fxLayout="row wrap">
          <a class="plain-link" [routerLink]="['/pricing']" target="_blank">
            <button class="user-profile-submit-button" mat-raised-button color="warn"
              type="button">
              Update Monthly Subscription Plan
            </button>
          </a>
        </div>
      </div>

      <!--<button class="user-profile-submit-button" mat-raised-button color="warn"-->
      <!--(click)="genRandInt()">-->
      <!--Gen Rand Int-->
      <!--</button>-->

      <mat-card *ngIf="(currentUserType === 'Job Seeker')" class="bagmask-card-dark-blue"
                fxFlex="100%" fxLayoutAlign="space-between center"
                fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
        <mat-card-title fxFlex="100%">Job Seeker Info</mat-card-title>
        <!--<mat-card-subtitle>These will auto-populate when you are creating a new job posting</mat-card-subtitle>-->

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="48%" fxFlex.lt-sm="0 1 24%">
          <mat-select
            formControlName="userGraduationYear"
            placeholder="Graduation Year, if Current Student">
            <mat-option *ngFor="let gradYear of graduationYearOptions" [value]="gradYear">{{gradYear}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="100%" fxFlex.lt-sm="100%">
          <input
            matInput
            formControlName="userAddress"
            placeholder="Address">
        </mat-form-field>

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="48%" fxFlex.lt-sm="0 1 48%">
          <input
            matInput

            formControlName="userCity"
            placeholder="City">
        </mat-form-field>

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="24%" fxFlex.lt-sm="0 1 24%">
          <mat-select
            formControlName="userState"
            placeholder="State">
            <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">{{state.stateName}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="add-post-focus-text-color"
                        fxFlex="24%" fxFlex.lt-sm="0 1 24%" hintLabel="5 digit zipcode">
          <input
            class="post-right-align"
            matInput
            maxlength="5"
            #inputZipCode
            formControlName="userZip"
            placeholder="Zip Code">
          <mat-hint align="end">{{inputZipCode.value?.length || 0}}/5</mat-hint>
        </mat-form-field>
      </mat-card>
    </form>

    <div class="user-update-form">
      <div fxLayoutAlign="space-around" fxLayout="row wrap">
        <button class="user-profile-submit-button" mat-raised-button color="warn"
                matTooltip="{{updateButtonTooltip}}"
                (click)="onSubmit()">Update User
        </button>
      </div>
    </div>

  </div>

  <app-user-posting-table *ngIf="activeSection==='jobs'" [userPostingType]="'All'"></app-user-posting-table>
</div>

<!--</mat-tab-group>-->

<!--<mat-tab-group [selectedIndex]="2">-->
<!--  <mat-tab label="0">0</mat-tab>-->
<!--  <mat-tab label="1">1</mat-tab>-->
<!--  <mat-tab label="2">2</mat-tab>-->
<!--  <mat-tab label="3">3</mat-tab>-->
<!--</mat-tab-group>-->

<!--<button  mat-raised-button color="warn"-->
<!--        (click)="selectTab(0)">Change Tab 0</button>-->
<!--<button  mat-raised-button color="warn"-->
<!--         (click)="selectTab(1)">Change Tab 1</button>-->



<!--<div *ngIf="success" class="notification is-success">-->
<!--Yay! We received your submission-->
<!--</div>-->

<!--<div>-->
<!--<pre>auth.authenticatedUser: {{ auth.authenticatedUser | json }}</pre>-->
<!--{{auth.authenticatedUser}}-->
<!--</div>-->

<!--<div *ngIf="auth.authenticatedUser | async; then authenticated else guest">-->
<!--&lt;!&ndash; template will replace this div &ndash;&gt;-->
<!--</div>-->

<!--&lt;!&ndash; User NOT logged in &ndash;&gt;-->
<!--<div *ngIf="!auth.authenticatedUser" #guest class="box">-->
<!--<h3>Howdy, GUEST</h3>-->
<!--<p class="card-text">Login to get started...</p>-->
<!--<button class="button" routerLink="/login">Login</button>-->
<!--</div>-->

<!--&lt;!&ndash; User logged in &ndash;&gt;-->
<!--<div *ngIf="auth.authenticatedUser" #authenticated>-->
<!--<div *ngIf="auth.authenticatedUser as user">-->
<!--<h3>Howdy, {{ user.displayName }}</h3>-->
<!--&lt;!&ndash;<img class="card-img-top" [src]="user.photoURL || 'https://api.adorable.io/avatars/109/fire.png'" width=50px>&ndash;&gt;-->
<!--<p class="text-truncate">UID: {{ user.uid }}</p>-->
<!--&lt;!&ndash;<button class="button" (click)="logout()">Logout</button>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->
