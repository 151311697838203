import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { AuthService } from './Services/auth.service';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeComponent } from './Home/home.component';
import { PostService } from './Services/post.service';
import { environment } from '../environments/environment';
import { AddPostSubmitDialogComponent } from './Post/add-post/add-post-submit-dialog/add-post-submit-dialog.component';
import { AddPostIntroDialogComponent } from './Post/add-post/add-post-intro-dialog/add-post-intro-dialog.component';
import { UIService } from './Services/ui.service';
import { AuthModule } from './auth/auth.module';
import { PostModule } from './Post/post.module';
import { NavigationModule } from './Navigation/navigation.module';
import { UserModule } from './User/user.module';
// tslint:disable-next-line
import {AgmCoreModule} from '@agm/core';
import {GeocodeService} from './Services/geocode.service';
import {ConfirmationDialogComponent} from './ui/confirmation-dialog/confirmation-dialog.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {Angulartics2Module} from 'angulartics2';
import {JobPostContentService} from './Services/jobPostContent.service';
import {SharedModule} from './shared/shared.module';
import {CacheService} from './Services/cache.service';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AnalyticsEventService} from './Services/analyticsEvent.service';
import {UserService} from './Services/user.service';
import {SeoService} from './Services/seo.service';
import {JobPosterDashboardComponent} from './Dashboards/job-poster-dashboard/job-poster-dashboard.component';
import {PricesService} from './Services/prices.service';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {CompanyModule} from './company/company.module';
import {HttpClientModule} from '@angular/common/http';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {CompaniesService} from './Services/companies.service';
import {PostUpgradeDialogComponent} from './payments/post-upgrade-dialog/post-upgrade-dialog.component';
import {PostUpgradeService} from './Services/post-upgrade.service';
import {FilesService} from './Services/files.service';
import {PostMetricsService} from './Services/postMetrics.service';
import {AngularFireFunctionsModule, REGION} from '@angular/fire/functions';
import {StripeBagmaskService} from './Services/stripeBagmask.service';
import { EnvironmentService } from './Services/environment.service';
import { PricingModule } from './pricing/pricing.module';
import { FrontEndCompanyService } from './Services/frontEndCompany.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    JobPosterDashboardComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDFy8Mw5wOAAQx-NymvrylTfQoDKcND2IY'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule,
    AuthModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    CompanyModule,
    FontAwesomeModule,
    HttpClientModule,
    LayoutModule,
    NavigationModule,
    NgxAuthFirebaseUIModule.forRoot(
      environment.firebase,
       () => 'your_app_name_factory',
      {
        enableFirestoreSync: true, // enable/disable autosync users with firestore
        toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
        authGuardFallbackURL: '/loggedout', // url for unauthenticated users - to use in combination with canActivate feature on a route
        authGuardLoggedInURL: '/loggedin', // url for authenticated users - to use in combination with canActivate feature on a route
        passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
        passwordMinLength: 4, // Password length min/max in forms independently of each componenet min/max.
        // Same as password but for the name
        nameMaxLength: 50,
        nameMinLength: 2,
        // If set, sign-in/up form is not available until email has been verified.
        // Plus protected routes are still protected even though user is connected.
        guardProtectedRoutesUntilEmailIsVerified: true,
        enableEmailVerification: true, // default: true
      }),
    PostModule,
    PricingModule,
    SharedModule,
    SnotifyModule,
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2/dist/sweetalert2.js')
    }),
    SweetAlert2Module,
    UserModule,
    YouTubePlayerModule
  ],
  entryComponents: [
    AddPostSubmitDialogComponent,
    AddPostIntroDialogComponent,
    ConfirmationDialogComponent,
    PostUpgradeDialogComponent,
  ],
  providers: [
    { provide: REGION, useValue: 'us-central1' },
    AnalyticsEventService,
    AuthService,
    CacheService,
    CompaniesService,
    EnvironmentService,
    FilesService,
    FrontEndCompanyService,
    GeocodeService,
    JobPostContentService,
    PostService,
    PostMetricsService,
    PostUpgradeService,
    PricesService,
    SeoService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
    StripeBagmaskService,
    UIService,
    UserService,
    // { provide: FunctionsRegionToken, useValue: 'us-central1' }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
