import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';

import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import {AuthRoutingModule} from './auth-routing.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';


@NgModule({
  declarations: [
                LoginComponent,
                SignupComponent,
                PasswordResetComponent,
                ],
  imports: [
                AppRoutingModule,
                AngularFireAuthModule,
                AngularFirestoreModule,
                AuthRoutingModule,
                SharedModule
  ],
  exports: [],
})

export class AuthModule {}
