import {Component, OnInit, isDevMode, OnDestroy} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BagmaskFieldValues} from '../../../Namespaces/bagmaskFieldValues.namespace';
import {USStates} from '../../../Namespaces/usStates.namespace';
import {Subscription} from 'rxjs';
import {PostService} from '../../../Services/post.service';

@Component({
  selector: 'app-add-post-responsibilities-requirements',
  templateUrl: './add-post-responsibilities-requirements.component.html',
  styleUrls: ['./add-post-responsibilities-requirements.component.scss']
})
export class AddPostResponsibilitiesRequirementsComponent implements OnInit, OnDestroy {
  devModeEnabled = false;

  postFormResponsibilitiesRequirements: FormGroup;
  usStates = USStates.getStates();
  yesNoNegotiableOptions = BagmaskFieldValues.getYesNoNegotiableOptions();
  yesNoOptions = BagmaskFieldValues.getYesNoOptions();
  yesNoSometimesOptions = BagmaskFieldValues.getYesNoSometimesOptions();
  yesNoPercentageOfTimeOptions = BagmaskFieldValues.getYesNoPercentageOfTimeOptions();
  alwaysNeverNegotiableOptions = BagmaskFieldValues.getAlwaysNeverNegotiableOptions();
  lifeSupportCardOptions = BagmaskFieldValues.getLifeSupportCardOptions();
  abaCertStatusOptions = BagmaskFieldValues.getAbaCertStatus();
  subspecialtyFellowship = BagmaskFieldValues.getSubspecialtyFellowship();
  secondCallOptions = BagmaskFieldValues.getSecondCallOptions();
  visasAccepted = BagmaskFieldValues.getVisasAccepted();
  nbcrnaCertificationStatusOptions = BagmaskFieldValues.getNBCRNACertificationStatusOptions();
  callOptions = BagmaskFieldValues.getCallOptions();
  callTypeOptions = BagmaskFieldValues.getCallTypeOptions();
  callResponseTimeOptions = BagmaskFieldValues.getCallResponseTimeOptions();
  providerModelOptions = BagmaskFieldValues.getProviderModelOptions();
  directionSupervisionOptions = BagmaskFieldValues.getDirectionSupervisionOptions();
  shiftTypeOptions = BagmaskFieldValues.getShiftTypeOptions();
  shiftDurationOptions = BagmaskFieldValues.getshiftDurationOptions();
  caseMixOptions = BagmaskFieldValues.getCaseMixOptions();

  postSubscription: Subscription;
  currentJobType: string;

  scheduledCall: string;

  callType: string;
  providerModel: string;

  constructor(private postService: PostService) {
    if (isDevMode()) {
      // Enable dev mode if isDevMode is true, showing extra form elements
      this.devModeEnabled = true;
    }
  }

  ngOnInit() {
    // Set calltype and providermodel to an initial value so we can properly set the subsequent field values if it turns correct.
    this.callType = 'N/A';
    this.providerModel = 'N/A';

    this.postSubscription = this.postService.addPostJobTypeChanged.subscribe(newJobType => {
      this.currentJobType = newJobType;
    });

    this.scheduledCall = 'No';

    this.postFormResponsibilitiesRequirements = new FormGroup({

      resShiftType: new FormControl(),
      resShiftDuration: new FormControl(),
      resScheduledCall: new FormControl(),
      resCallType: new FormControl(),
      resCallResponseTime: new FormControl(),
      resHowOftenFirstCall: new FormControl(),
      resDayOffAfterFirstCall: new FormControl(),
      resHowOftenSecondCall: new FormControl(),
      resProviderModel: new FormControl(),
      resDirectionSupervision: new FormControl(),
      resCaseMix: new FormControl(),
      resAnesthesiologistDirecting: new FormControl(),
      resAnesthesiologistPercentSolo: new FormControl(),
      resCRNAPerformLaborEpidurals: new FormControl(),
      resCRNADirectedByAnesthesiologist: new FormControl(),
      resLaborEpidurals: new FormControl(),
      resSpinalAnesthesia: new FormControl(),
      resRegionalBlocks: new FormControl(),
      resArterialCatheter: new FormControl(),
      resCentralVenousCatheter: new FormControl(),

      reqNewGrads: new FormControl(),
      reqStateLicense: new FormControl(),
      reqLifeSupportCard: new FormControl(),
      reqABACertification: new FormControl(),
      reqSubspecialtyFellowship: new FormControl(),
      reqVisaAccepted: new FormControl(),
      reqNBCRNACertification: new FormControl(),
    });

    this.scheduledCall = String(this.postFormResponsibilitiesRequirements.get('resCallType'));

  }

  onCallTypeChange(callType: string) {
    console.log('callType changed to: ' + callType);
    this.callType = callType;
  }

  providerModelChange(providerModel: string) {
    console.log('callType changed to: ' + providerModel);
    this.providerModel = providerModel;
  }

  ngOnDestroy() {
    if (this.postSubscription) {
      this.postSubscription.unsubscribe();
    }
  }

}
