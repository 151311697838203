import {Injectable, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, Query} from '@angular/fire/firestore';
import {Observable, Subject, Subscription} from 'rxjs';
import { UIService } from './ui.service';
import {GeocodeService} from './geocode.service';
import {ConfirmationDialogComponent} from '../ui/confirmation-dialog/confirmation-dialog.component';
import {JobPosting} from '../Models/post.model';
import {reject} from 'q';
import {User} from '../Models/user.model';
import {AuthService} from './auth.service';
import {first} from 'rxjs/operators';
import {StripeBagmaskService} from './stripeBagmask.service';
import {BagmaskFieldValues} from '../Namespaces/bagmaskFieldValues.namespace';

@Injectable()
export class UserService implements OnDestroy {
  private userList: AngularFirestoreCollection<any>;
  finishedUsersChanged = new Subject<User[]>();
  finishedUsersChangedCount = new Subject<number>();
  users: Observable<any[]>;

  stripePlanData = [];

  // Using fbSubs as an array of all Subscriptions so we can cancel them all at once at the end.
  // Remember to add new subscriptions in the service to this!
  private fbSubs: Subscription[] = [];
  private usersSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private stripeBagmaskService: StripeBagmaskService,
    private uiService: UIService,

  ) {
    console.log('User Service Constructor Starting');
    console.log('Current fullUserInfo: ', this.auth.fullUserInfo);

    // Check Job Poster Subscription, and if user data should update (new plan, changed plan, canceled plan?)
    this.checkAndUpdateJobPosterSubscriptionStatus()



  }

  // Function to check Job Poster Subscription status and update accordingly
  checkAndUpdateJobPosterSubscriptionStatus(  ) {
    console.log('Starting checkAndUpdateJobPosterSubscriptionStatus');
  }

  async updateUserPlanDatabaseFields(planName: string,
                                     planDiscount: number,
                                     planFreePriorityListings: number,
                                     planFreeStandardPosts: number,
                                     planStartDate: Date,
                                     planExpirationDate: Date) {
    try {
      await this.afs.doc('users/' + this.auth.authenticatedUser.uid)
        .update({userCompanyMonthlyPlan: planName,
          userCompanyMonthlyPlanPriorityDiscount: planDiscount,
          userCompanyMonthlyPlanFreePriorityPostsRemaining: planFreePriorityListings,
          userCompanyMonthlyPlanFreePriorityPostsTotal: planFreePriorityListings,
          userCompanyMonthlyPlanStandardPostsRemaining: planFreeStandardPosts,
          userCompanyMonthlyPlanStandardPostsTotal: planFreeStandardPosts,
          userCompanyMonthlyPlanStartDate: planStartDate,
          userCompanyMonthlyPlanExpirationDate: planExpirationDate
        });


      // this.success = true;
      console.log('Successful User Subscription Plan: ' + this.auth.authenticatedUser.uid);
      this.uiService.showSnackbar('Successful User Subscription Plan', null, 3000);
    } catch (error) {
      console.error(error);
      this.uiService.showSnackbar(error.message, null, 3000);
    }
  }

  // Update user's subscription plan based on plan name passed in
  async updateUserPlan(planName: string) {
    let currentPlanSelected: any;
    // TODO: 11/20/2020 convert stripePlanData to a field at the service itself that we can just reference!
    this.stripePlanData = await this.stripeBagmaskService.getBagMaskStripeSubPlans();
    console.log('Final StripePlanData: ', this.stripePlanData);

    // Check to make sure the incoming planName exists
    let planExists = false;
    for (const plan of this.stripePlanData) {
      if (planName === plan.name) {
        planExists = true;
        currentPlanSelected = plan;
      }
    }

    // Plan doesn't exist, exit function
    if (!planExists) {
      console.log('Plan Name does not exist, exiting function');
      return;
    } else {
      console.log('Plan Name exists, proceeding for currentPlanSelected: ', currentPlanSelected);
    }

    // Get the plan metadata from our BagMask namespace file
    const monthlySubscriptionOptions = BagmaskFieldValues.getMonthlySubscriptionOptions();
    console.log('monthlySubscriptionOptions: ', monthlySubscriptionOptions);

    // Add the specific metadata to the incoming plan
    for (const planMetadata of monthlySubscriptionOptions) {
      if (currentPlanSelected.name === planMetadata.planName) {
        console.log('We have a match for metadata, currentSelectedPlan before adding: ', currentPlanSelected);
        console.log('Adding with planMetadata: ', planMetadata);
        const currentPlanSelectedWithMetadata = {
          ...currentPlanSelected,
          ...planMetadata,
        }
        console.log('Added planMetadata, new currentPlanSelectedWithMetadata: ', currentPlanSelectedWithMetadata);
      }
    }

    // TODO: 11/24/2020: we have the entire plan and metadata together, now we can populate user fields with new plan metadata

    const today = new Date();
    const dateToExpire = new Date().setDate(today.getDate() + 30);

    // Update the following fields in user data:

    /*

    userCompanyMonthlyPlan
    "Unlimited Posts - Silver"
    userCompanyMonthlyPlanNextBillingDate:

    userCompanyMonthlyPlanFreePriorityPostsRemaining
    3
    userCompanyMonthlyPlanFreePriorityPostsTotal
    3
    userCompanyMonthlyPlanPriorityDiscount
    0
    userCompanyMonthlyPlanStandardPostsRemaining
    0
    userCompanyMonthlyPlanStandardPostsTotal
    0
    (number)

     */

    /* Old Incoming data:
    obj.planName,
    obj.planDiscount,
    obj.planFreePriorityListings,
    obj.planFreeStandardPosts,
    today, new Date(dateToExpire)

     */

    // TODO: 11/20/2020, finish this section to get plan name, get the details, and then populate the user info in database
    this.getPlanDetailsByName('planName');

    // try {
    //   await this.afs.doc('users/' + this.auth.authenticatedUser.uid)
    //     .update({userCompanyMonthlyPlan: planName,
    //       userCompanyMonthlyPlanPriorityDiscount: planDiscount,
    //       userCompanyMonthlyPlanFreePriorityPostsRemaining: planFreePriorityListings,
    //       userCompanyMonthlyPlanFreePriorityPostsTotal: planFreePriorityListings,
    //       userCompanyMonthlyPlanStandardPostsRemaining: planFreeStandardPosts,
    //       userCompanyMonthlyPlanStandardPostsTotal: planFreeStandardPosts,
    //       userCompanyMonthlyPlanStartDate: planStartDate,
    //       userCompanyMonthlyPlanExpirationDate: planExpirationDate
    //     });
    //
    //
    //   // this.success = true;
    //   console.log('Successful User Subscription Plan: ' + this.auth.authenticatedUser.uid);
    //   this.uiService.showSnackbar('Successful User Subscription Plan', null, 10000);
    // } catch (error) {
    //   console.error(error);
    //   this.uiService.showSnackbar(error.message, null, 3000);
    // }
  }

  // Retrieve the details and other fields for each plan based on name alone
  getPlanDetailsByName(planName: string) {

  }

  // function to return the current user's plan discount
  async getUserPlanDiscount() {
    try {
      const currentUserDoc = this.afs.doc('users/' + this.auth.authenticatedUser.uid).valueChanges();
      await currentUserDoc.pipe(first()).subscribe(data => {
        console.log('getUserPlanDiscount, full data: ', data);
        if (data['userCompanyMonthlyPlanPriorityDiscount']) {
          console.log('getUserPlanDiscount, discount field: ', data['userCompanyMonthlyPlanPriorityDiscount']);
        } else {
          console.log('Current User does not have a discount');
        }

      });



    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    this.cancelSubscriptions();
  }

  cancelSubscriptions() {
    // Step through each of the subscriptions and unsubscribe if they exist on destroy
    console.log('User Service - Cancelling Subscriptions');
    this.fbSubs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });

    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }



}

