export namespace BagmaskFieldValues {
  let yesNoOptions: string[];
  let yesNoNegotiableOptions: string[];
  let yesNoSometimesOptions: string[];
  let yesNoPercentageOfTimeOptions: string[];
  let alwaysNeverNegotiableOptions: string[];
  let userTypes: string[];
  let userTypesAll: string[];
  let jobTypes: string[];
  let companyTypes: string[];
  let employmentStatuses: string[];
  let taxStatuses: string[];
  let signingBonusCommitmentTimes: string[];
  let weeksPaidVacation: string[];
  let paidCMETime: string[];
  let cmeAllowanceOptions: string[];
  let salaryIncomeOptions: string[];
  let shiftTypeOptions: string[];
  let shiftDurationOptions: string[];
  let callOptions: string[];
  let callTypeOptions: string[];
  let callResponseTimeOptions: string[];
  let secondCallOptions: string[];
  let providerModelOptions: string[];
  let directionSupervisionOptions: string[];
  let caseMixOptions: string[];
  let lifeSupportCardOptions: string[];
  let abaCertStatusOptions: string[];
  let subspecialtyFellowshipOptions: string[];
  let visasAcceptedOptions: string[];
  let nbcrnaCertificationStatusOptions: string[];
  let averageCredentialingTime: string[];
  let otherFacilitySitesOptions: string[];
  let trainingProgramOptions: string[];
  let federalGovJobOptions: string[];
  let startDateOptions: string[];
  let bedsCensusOptions: string[];
  let surgicalCasesOBDeliveriesOptions: string[];
  // let priorityOptions: string[];
  let priorityOptions;
  let monthlySubscriptionOptions;
  let postPriorityCostOptions;

  yesNoOptions = [
    'Yes',
    'No'
  ];

  yesNoNegotiableOptions = [
    'Yes',
    'No',
    'Negotiable'
  ];

  yesNoSometimesOptions = [
    'Yes',
    'No',
    'Sometimes'
  ];

  yesNoPercentageOfTimeOptions = [
    'Yes',
    'No',
    '75% of time',
    '50% of time',
    '25% of time'
  ];

  alwaysNeverNegotiableOptions = [
    'Always',
    'No',
    'Negotiable'
  ];

  // These are the classifications of actual users of the site
  userTypes = [
    'Anesthesiologist',
    'Anesthesia Resident',
    'CRNA',
    'SRNA',
    'CAA',
    'SAA',
    'Other',
  ];

  // These are the actual user classifications with Job Poster as an option, largely for debug.
  userTypesAll = [
    'Job Poster',
    'Anesthesiologist',
    'Anesthesia Resident',
    'CRNA',
    'SRNA',
    'CAA',
    'SAA',
    'Other',
  ];

  jobTypes = [
    'Anesthesiologist',
    'AA',
    'CRNA'
  ];

  companyTypes = [
    'Academic Practice',
    'Advertising Firm',
    'Endoscopy Center',
    'Government/Military',
    'Hospital',
    'Individual: Anesthesiologist',
    'Individual: CRNA',
    'Multispeciality Practice',
    'National Private Practice',
    'Private Practice',
    'Office Based',
    'Recruitment Agency',
    'Surgery Center',
  ];

  employmentStatuses = [
    'Full Time',
    'Part Time',
    'Locums',
    'PRN'
  ];

  taxStatuses = [
    'W-2',
    '1099',
    'K1'
  ];

  signingBonusCommitmentTimes = [
    'One Year',
    'Two Years',
    'Three Years'
  ];

  weeksPaidVacation = [
    'None',
    '4 Weeks',
    '5 Weeks',
    '6 Weeks',
    '7 Weeks',
    '8 Weeks',
    'Negotiable'
  ];

  paidCMETime = [
    'None',
    '1 Week',
    'Negotiable'
  ];

  cmeAllowanceOptions = [
    '$1500',
    '$2000',
    '$2500',
    '$3000',
    '$4000',
  ];

  salaryIncomeOptions = [
    'Salary',
    'Hourly'
  ]

  shiftTypeOptions = [
    'Days',
    'Nights',
    'Weekends',
  ];

  shiftDurationOptions = [
    '8 Hours',
    '10 Hours',
    '12 Hours',
    '13 Hours',
    '16 Hours',
    '24 Hours',
  ];

  callOptions = [
    'Never',
    'Varies',
    'Second Night',
    'Third Night',
    'Fourth Night',
    'Fifth Night',
    'Sixth Night',
    'Seventh Night',
    'Eighth Night',
    'Ninth Night',
    'Tenth Night',
    'Eleventh Night',
    'Twelfth Night',
    'Thirteenth Night',
    'Fourteenth Night',
  ];

  callTypeOptions = [
    'In-house',
    'Call From Home',
  ];

  callResponseTimeOptions = [
    '30 minutes',
    '45 minutes',
    '60 minutes'
  ];

  secondCallOptions = [
    'Never',
    'First Night',
    'Second Night',
    'Third Night',
    'Varies',
    'Negotiable'
  ];

  providerModelOptions = [
    'Care Team Model',
    'All Physician Model',
    'All CRNA Model'
  ];

  directionSupervisionOptions = [
    'Medical Direction',
    'Medical Supervision',
    'Direction/Supervisor'
  ];

  caseMixOptions = [
    'Cardiovascular',
    'Dental',
    'Endo',
    'ENT',
    'General',
    'GYN',
    'Neuro',
    'Obstetric',
    'Ophthalmic',
    'Ortho',
    'Plastic',
    'Pediatric',
    'Thoracic',
    'Transplant',
    'Trauma',
    'Urology'
  ];

  lifeSupportCardOptions = [
    'ACLS',
    'PALS'
  ];

  abaCertStatusOptions = [
    'Board Certified',
    'Board Certified or Candidate in ABA exam system',
    'No ABA Certification Required',
    'Negotiable'
  ];

  subspecialtyFellowshipOptions = [
    'Ambulatory Anesthesia',
    'Cardiac',
    'Critical Care',
    'Neuroanesthesia',
    'Obstetric',
    'Pediatrics',
    'Pain',
    'Practice Management',
    'Public Policy',
    'Regional Anesthesia and Acute Pain Medicine',
    'Transplant Anesthesia',
    'Trauma Anesthesia'
  ];

  visasAcceptedOptions = [
    'J1',
    'H1'
  ];

  nbcrnaCertificationStatusOptions = [
    'NBCRNA Certified',
    'NBCRNA Certified or in NBCRNA examination process',
    'No NBCRNA Certification'
  ];

  averageCredentialingTime = [
    '30 Days',
    '60 Days',
    '90 Days',
    '120 Days'
  ];

  otherFacilitySitesOptions = [
    'Surgery Center',
    'Multiple Hospitals',
    'Multiple Surgery Centers',
    'Office Based',
  ];

  trainingProgramOptions = [
    'Resident',
    'CRNA',
    'AA',
  ];

  federalGovJobOptions = [
    'VA',
    'Military',
    'Indian Health Services (IHS)',
  ];

  startDateOptions = [
    'Immediate',
    'Select Date'
  ];

  bedsCensusOptions = [
    '0-100',
    '100-150',
    '150-200',
    '200-300',
    '300+',
  ];

  surgicalCasesOBDeliveriesOptions = [
    '0-500',
    '500-1,000',
    '1,000-2,000',
    '2,000-3,000',
    '3,000-5,000',
    '5,000-10,000',
    '10,000-15,000',
    '15,000-20,000',
    '20,000-25,000',
    '25,000+'
  ];

  priorityOptions = [
    {priorityValue : 3, priorityName: 'High Priority'},
    {priorityValue : 2, priorityName: 'Priority'},
    {priorityValue : 1, priorityName: 'Standard'},
  ];

  monthlySubscriptionOptions = [
    {planName : 'BagMask Silver Plan',
      planNonDiscountPrice: 300,
      planPriceInDollars : 149,
      planPriceInCents: 14900,
      planFreePriorityListings: 3,
      planDiscount: 0,
      planNumber: 1},
    // {planName : 'Unlimited Posts - Silver',
    //   planNonDiscountPrice: 399,
    //   planPriceInDollars : 0,
    //   planPriceInCents: 0,
    //   planFreePriorityListings: 2,
    //   planDiscount: 15,
    //   planNumber: 2},
    {planName : 'BagMask Gold Plan',
      planNonDiscountPrice: 799,
      planPriceInDollars : 399,
      planPriceInCents: 39900,
      planFreePriorityListings: 12,
      planDiscount: 25,
      planNumber: 3},
    {planName : 'BagMask Platinum Plan',
      planNonDiscountPrice: 1199,
      planPriceInDollars : 599,
      planPriceInCents: 59900,
      planFreePriorityListings: 24,
      planDiscount: 50,
      planNumber: 4},
  ];

  postPriorityCostOptions = [
    {priorityName: 'Single',
      priorityNumber: 1,
      priorityPriceInDollars: 99,
      priorityPriceInCents: 9900},
    {priorityName: 'Priority',
      priorityNumber: 2,
      priorityPriceInDollars: 199,
      priorityPriceInCents: 19900},
    {priorityName: 'High Priority',
      priorityNumber: 3,
      priorityPriceInDollars: 399,
      priorityPriceInCents: 39900},

  ];

  export function getYesNoOptions() {
    return yesNoOptions;
  }

  export function getYesNoNegotiableOptions() {
    return yesNoNegotiableOptions;
  }

  export function getYesNoSometimesOptions() {
    return yesNoSometimesOptions;
  }


  export function getYesNoPercentageOfTimeOptions() {
    return yesNoPercentageOfTimeOptions;
  }

  export function getAlwaysNeverNegotiableOptions() {
    return alwaysNeverNegotiableOptions;
  }

  export function getUserTypes() {
    return userTypes;
  }

  export function getUserTypesAll() {
    return userTypesAll;
  }

  export function getJobTypes() {
    return jobTypes;
  }

  export function getCompanyTypes() {
    return companyTypes;
  }

  export function getEmploymentStatuses() {
    return employmentStatuses;
  }

  export function getTaxStatuses() {
    return taxStatuses;
  }

  export function getSigningBonusCommitmentOptions() {
    return signingBonusCommitmentTimes;
  }

  export function getWeeksPaidVacationOptions() {
    return weeksPaidVacation;
  }

  export function getPaidCMETimeOptions() {
    return paidCMETime;
  }

  export function getCmeAllowanceOptions() {
    return cmeAllowanceOptions;
  }

  export function getSalaryIncomeOptions() {
    return salaryIncomeOptions;
  }

  export function getShiftTypeOptions() {
    return shiftTypeOptions;
  }

  export function getshiftDurationOptions() {
    return shiftDurationOptions;
  }


  export function getCallOptions() {
    return callOptions;
  }

  export function getCallTypeOptions() {
    return callTypeOptions;
  }

  export function getCallResponseTimeOptions() {
    return callResponseTimeOptions;
  }

  export function getSecondCallOptions() {
    return secondCallOptions;
  }

  export function getProviderModelOptions() {
    return providerModelOptions;
  }

  export function getDirectionSupervisionOptions() {
    return directionSupervisionOptions;
  }

  export function getCaseMixOptions() {
    return caseMixOptions;
  }

  export function getLifeSupportCardOptions() {
    return lifeSupportCardOptions;
  }

  export function getAbaCertStatus() {
    return abaCertStatusOptions;
  }

  export function getSubspecialtyFellowship() {
    return subspecialtyFellowshipOptions;
  }

  export function getVisasAccepted() {
    return visasAcceptedOptions;
  }

  export function getNBCRNACertificationStatusOptions() {
    return nbcrnaCertificationStatusOptions;
  }

  export function getAverageCredentialingTimeOptions() {
    return averageCredentialingTime;
  }

  export function getOtherFacilitySitesOptions() {
    return otherFacilitySitesOptions;
  }

  export function getTrainingProgramOptions() {
    return trainingProgramOptions;
  }

  export function getFederalGovJobOptions() {
    return federalGovJobOptions;
  }

  export function getStartDateOptions() {
    return startDateOptions;
  }

  export function getBedsCensusOptions() {
    return bedsCensusOptions;
  }

  export function getSurgicalCasesOBDeliveriesOptions() {
    return surgicalCasesOBDeliveriesOptions;
  }

  export function getPriorityOptions() {
    return priorityOptions;
  }

  export function getMonthlySubscriptionOptions() {
    return monthlySubscriptionOptions;
  }

  export function getPostPriorityCostOptions() {
    return postPriorityCostOptions;
  }


}
