<footer class="footer-distributed">
  <div class="footer-left">
    <!--<div style="text-align:center">-->
      <!--<fa-icon [icon]="faCoffee"></fa-icon>-->
    <!--</div>-->
    <!--<fa-icon [icon]="faFacebook"></fa-icon>FB Image-->
    <!--<h3>Company<span>logo</span></h3>-->
    <!--<img src="../../../assets/bagmask-logo.png" height="869" width="1898"/>-->
    <img routerLink="/home" class="bagmask-logo-image" src="../../assets/bagmask-logo.png">
    <p class="footer-links">
      <a href="/home">Job Search</a>
      ·
      <a href="https://BagMask.com/anesthesia-career-navigation">Career Navigation</a>
      ·
      <a href="https://BagMask.com/anesthesia-scholarship">Scholarship</a>
      ·
      <a href="/support">Support</a>
    </p>

    <!--<p class="footer-company-name">BagMask &copy; 2019</p>-->
    <div class="footer-icons">
      <a href="https://www.youtube.com/channel/UCFTLeAvD5Vg-MxZck8eSXYA" target="_blank">
        <fa-icon [icon]="faYoutube"></fa-icon>
      </a>
      <a href="https://www.instagram.com/bagmaskjobs/" target="_blank">
        <fa-icon [icon]="faInstagram"></fa-icon>
      </a>
      <a href="https://www.linkedin.com/company/bagmask-com" target="_blank">
        <fa-icon [icon]="faLinkedin"></fa-icon>
      </a>
      <a href="https://twitter.com/Bagmaskjobs" target="_blank">
        <fa-icon [icon]="faTwitter"></fa-icon>
      </a>
      <a href="https://www.facebook.com/Bagmaskjobs/" target="_blank">
        <fa-icon [icon]="faFacebookF"></fa-icon>
      </a>
    </div>
    <div class="home-footer mat-typography" fxLayout="row" fxLayoutAlign="center center">
      <h2 class="footer-text">Copyright 2020 BagMask.com LLC All Rights Reserved</h2>
      <a class="footer-links email-no-link" href="/userAgreement">
        <h2 class="footer-text">User Agreement & Privacy Policy</h2>
      </a>
    </div>
  </div>

  <div class="footer-right">
    <div class="footer-mailing-list mat-elevation-z8">
    <a class="email-no-link" href="http://eepurl.com/gdmPn5" target="_blank">
      <div class="mailing-list-text">
        Click Here To<br>
        Join Our Community:<br>
      </div>
        <div class="mailing-list-smaller-text">
      Stay up to date on Career Advice, Hot Jobs, and Scholarship News
      </div>
    </a>
    </div>
    <!--<div class="home-footer footer-component-right-text mat-typography" fxLayout="row" fxLayoutAlign="center center">-->
      <!--<div class="contact-us-text">-->
        <!--Join Our Community:<br>-->
      <!--</div>-->
      <!--Stay up to date on Career Advice, Hot Jobs, and Scholarship News-->
      <!--<a class="email-no-link" href="http://eepurl.com/dOStRL">-->
        <!--&lt;!&ndash;<fa-icon class="envelope-icon" [icon]="faEnvelope"></fa-icon>&ndash;&gt;-->
        <!--&lt;!&ndash;Info@Bagmask.com&ndash;&gt;-->
      <!--</a>-->
    <!--</div>-->


    <br><br>
    <div class="home-footer footer-component-right-text mat-typography" fxLayout="row" fxLayoutAlign="center center">
      <div class="contact-us-text">
        Contact Us:
        <span class="contact-us-spacing">
          <a class="email-no-link" href="mailto:Info@Bagmask.com">
            <fa-icon class="envelope-icon" [icon]="faEnvelope"></fa-icon>
            Info@Bagmask.com
          </a>
        </span>
      </div>
    </div>

    <!--<div class="home-footer footer-component-right-text mat-typography" fxLayout="row" fxLayoutAlign="center center">-->
      <!--<div class="contact-us-text">-->
        <!--<a class="footer-links email-no-link" href="/userAgreement">User Agreement & Privacy Policy</a>-->
      <!--</div>-->
    <!--</div>-->
    <!--<form action="#" method="post">-->
    <!--<input type="text" name="email" placeholder="Email" />-->
    <!--<textarea name="message" placeholder="Message"></textarea>-->
    <!--<button>Send</button>-->
    <!--</form>-->
  </div>

  <!--<div class="footer-right">-->

    <!--&lt;!&ndash;<form action="#" method="post">&ndash;&gt;-->
      <!--&lt;!&ndash;<input type="text" name="email" placeholder="Email" />&ndash;&gt;-->
      <!--&lt;!&ndash;<textarea name="message" placeholder="Message"></textarea>&ndash;&gt;-->
      <!--&lt;!&ndash;<button>Send</button>&ndash;&gt;-->
    <!--&lt;!&ndash;</form>&ndash;&gt;-->
  <!--</div>-->
</footer>
