import {Component, EventEmitter, Output, OnInit, Input} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {PostQuery} from '../../../Models/postQuery.model';
import {PostService} from '../../../Services/post.service';
import {USStates} from '../../../Namespaces/usStates.namespace';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UIService} from '../../../Services/ui.service';
import {BagmaskFieldValues} from '../../../Namespaces/bagmaskFieldValues.namespace';


@Component({
  selector: 'app-job-postings-table-filtering',
  templateUrl: './job-postings-table-filtering.component.html',
  styleUrls: ['./job-postings-table-filtering.component.scss']
})
export class JobPostingsTableFilteringComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  @Input() currentJobType: string;
  @Input() currentState: string;
  @Input() currentCompany: string;
  filterForm: FormGroup;
  panelOpenState = false;
  params: Params;
  usStates = USStates.getStates();
  employmentStatuses = BagmaskFieldValues.getEmploymentStatuses();

  constructor(private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              public uiService: UIService,
              private postService: PostService) {
  }

  ngOnInit() {
    console.log('Inside JobPostingsTableFiltering, currentJobType value: ' + this.currentJobType + ' current state ' + this.currentState);
    if (this.currentState == null) {
      console.log('State undefined, setting to All');
      this.currentState = 'All';
    }
    console.log('Inside JobPostingsTableFiltering, currentCompany?: ' + this.currentCompany);
    this.params = this.activatedRoute.params;

    // this.filterForm = new FormGroup({
    //   state: new FormControl(),
    // });

    this.filterForm = this.fb.group({
      state: new FormControl (''),
      minSalary: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      employmentStatus: new FormControl (''),
      signingBonus: new FormControl(''),
      tuitionReimbursement: new FormControl(''),
      eventualPartnership: new FormControl(''),

    })

    this.filterForm.get('state').setValue(this.currentState);

  }

  clearFilters(){
    // Want to reset the filter form fields
    this.filterForm.reset();

    // After fields reset, re-run filter with blanks (return all results for job type selected.)
    this.callNewFetchWithParams();
    this.currentState = 'All';

  }

  callNewFetchWithParams() {
    console.log('Inside JobPostingsTableFiltering, callNewFetchWithParams.  currentJobType: ' + this.currentJobType + ' currentState: '
                + this.currentState);

    // Have to update the currentState now that we're running the Query to reflect the actual query that was run!
    this.currentState = this.filterForm.get('state').value

    // Create default postQuery with constructor and 'Active' for status, since we only want to return active posts.
    const postQuery = new PostQuery('Active', this.currentJobType, this.currentState);

    // Go through the optional parameters if they exist, add to PostQuery

    const minSalaryValue = this.filterForm.get('minSalary').value;
    if (minSalaryValue) {
      console.log('minSalary has value: ' + minSalaryValue);
      postQuery.minSalary = minSalaryValue;
    } else {
      console.log('minSalary is null');
    }

    const employmentStatusValue = this.filterForm.get('employmentStatus').value;
    if (employmentStatusValue) {
      console.log('employmentStatus has value: ' + employmentStatusValue);
      postQuery.employmentStatus = employmentStatusValue;
    } else {
      console.log('employmentStatus is null');
    }

    const signingBonus = this.filterForm.controls.signingBonus.value;
    if (signingBonus === true) {
      console.log('signingBonus has value: ' + signingBonus);
      postQuery.signingBonus = signingBonus;
    } else {
      console.log('signingBonus is null');
      postQuery.signingBonus = null;
    }

    const tuitionReimbursement = this.filterForm.controls.tuitionReimbursement.value;
    if (tuitionReimbursement === true) {
      console.log('tuitionReimbursement has value: ' + tuitionReimbursement);
      postQuery.tuitionReimbursement = tuitionReimbursement;
    } else {
      console.log('tuitionReimbursement is null');
      postQuery.tuitionReimbursement = null;
    }

    const eventualPartnership = this.filterForm.controls.eventualPartnership.value;
    if (eventualPartnership === true) {
      console.log('eventualPartnership has value: ' + eventualPartnership);
      postQuery.eventualPartnership = eventualPartnership;
    } else {
      console.log('eventualPartnership is null');
      postQuery.eventualPartnership = null;
    }


    this.postService.fetchJobPostingsWithMetadataByPostQuery(postQuery);
  }

  // Check if we have a company or not, if we don't then standard display of "{type} in {area}
  getFilterHeading() {
    // console.log('getFilterHeading, currentCompany: ' + this.currentCompany);
    if (this.currentCompany) {
      return this.currentCompany + ' Job Postings';
    } else if (this.currentJobType === 'Pain') {
      let currentStateString = this.currentState === 'All' ? 'All States' : this.currentState;
      return 'Pain Management in ' + currentStateString;
    } else if (this.currentJobType === 'Leadership') {
      let currentStateString = this.currentState === 'All' ? 'All States' : this.currentState;
      return 'Leadership Positions in ' + currentStateString;
    }
    else {
      let currentStateString = this.currentState === 'All' ? 'All States' : this.currentState;
      return this.currentJobType + 's in ' + currentStateString;
    }

  }

  onTypeGroupValChange(filterType: string) {
    console.log('job-postings-table-filterg component, onTypeGroupValChange activated. Selected: ' + filterType);
    const postQuery = new PostQuery('Active', filterType, null);
    this.params.forEach((params: Params) => {
      if (params.state) {
        console.log('State has value');
        postQuery.state = params.state;
      }
      console.log('job-postings-table-filterg component, onTypeGroupValChange activated. postQuery: ' + JSON.stringify(postQuery));
      this.postService.fetchJobPostingsWithMetadataByPostQuery(postQuery);
    });
  }

  applyFilter(filterValue: string) {
    // This applies the string filter to all currently selected fields

    console.log('inside applyFilter');
    this.valueChange.emit(filterValue);
  }
}
