import {Component, OnInit, Input, isDevMode, OnDestroy, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BagmaskFieldValues} from '../../../Namespaces/bagmaskFieldValues.namespace';
import {Subscription} from 'rxjs';
import {PostService} from '../../../Services/post.service';

@Component({
  selector: 'app-add-post-salary-benefits',
  templateUrl: './add-post-salary-benefits.component.html',
  styleUrls: ['./add-post-salary-benefits.component.scss']
})
export class AddPostSalaryBenefitsComponent implements OnInit, AfterViewInit, OnDestroy {
  devModeEnabled = false;
  postFormSalaryBenefits: FormGroup;
  signingBonusCommitmentTimeAutoOptions = BagmaskFieldValues.getSigningBonusCommitmentOptions();
  weeksPaidVacationOptions = BagmaskFieldValues.getWeeksPaidVacationOptions();
  paidCMETimeOptions = BagmaskFieldValues.getPaidCMETimeOptions();
  yesNoOptions = BagmaskFieldValues.getYesNoOptions();
  yesNoNegotiableOptions = BagmaskFieldValues.getYesNoNegotiableOptions();
  cmeAllowanceOptions = BagmaskFieldValues.getCmeAllowanceOptions();
  salaryIncomeOptions = BagmaskFieldValues.getSalaryIncomeOptions();
  postSubscription: Subscription;
  currentJobType: string;
  eventualPartnershipBoolean = false;

  constructor(private postService: PostService) {
    if (isDevMode()) {
      // Enable dev mode if isDevMode is true, showing extra form elements
      this.devModeEnabled = true;
    }
  }

  ngOnInit() {
    this.postFormSalaryBenefits = new FormGroup({
      salSigningBonus: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      salSigningBonusCommitmentReimbursement: new FormControl(),
      salTuitionReimbursement: new FormControl(),
      salPaidOvertime: new FormControl(),
      salWeeksPaidVacation: new FormControl(),
      salPaidCMETime: new FormControl(),
      salCMEAllowance: new FormControl(),
      salSalaryIncomeBoolean: new FormControl(),
      salEventualPartnership: new FormControl(),
      salIncomeFullPartnerPractice: new FormControl(),
      salIncomeFeeForService: new FormControl(),
      salMedicalInsurance: new FormControl(),
      salRetirementPlan: new FormControl(),
      salMalpracticeInsurance: new FormControl(),
      salDisabilityInsurance: new FormControl(),
      salInterviewExpenses: new FormControl(),
      salRelocationAssistance: new FormControl(),

    });

  }

  ngAfterViewInit() {
    this.postSubscription = this.postService.addPostJobTypeChanged.subscribe(newJobType => {
      // console.log('Inside addPostSalaryBenefits, newJobType: ' + newJobType);
      this.currentJobType = newJobType;
    });
  }

  eventualPartnershipChange(value) {
    console.log('eventualPartnershipChange value: ' + value);
    // We only want to show the field on a value of yes.  No by default to hide from all users (only Anest/CRNA should see)
    if (this.postFormSalaryBenefits.value.salEventualPartnership === 'Yes' ||
        this.postFormSalaryBenefits.value.salEventualPartnership === 'Negotiable') {
      // Double check that job type is correct.  This should only be visible for Anest/CRNA, but to be safe:
      if (this.currentJobType === 'Anesthesiologist' || this.currentJobType === 'CRNA') {
        console.log('eventualPartnershipChange, yes value and CRNA or Anest');
        this.eventualPartnershipBoolean = true;
       } else {
        this.eventualPartnershipBoolean = false;
      }
    } else {
      this.eventualPartnershipBoolean = false;
    }
  }

  ngOnDestroy() {
    if (this.postSubscription) {
      this.postSubscription.unsubscribe();
    }

  }

}
