import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {map, tap} from 'rxjs/operators';
import {User} from '../../Models/user.model';
import {UIService} from '../../Services/ui.service';
import {Subscription} from 'rxjs';
import {BagmaskFieldValues} from '../../Namespaces/bagmaskFieldValues.namespace';
import {USStates} from '../../Namespaces/usStates.namespace';
import {MatDialog} from '@angular/material/dialog';

import { animate, state, style, transition, trigger } from '@angular/animations';
// tslint:disable-next-line:max-line-length
import {PaymentService} from '../../payments/payment.service';
import {Title} from '@angular/platform-browser';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from '../../Services/user.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        height: '100px',
        opacity: 0.5,
        backgroundColor: 'green'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.5s', style({ opacity: 0 }))
      ])
    ]),
  ]
})

export class UserProfileComponent implements OnInit, OnDestroy {
  myForm: FormGroup;
  userProfileSubscription: Subscription;
  activeSection: any;

  usStates = USStates.getStates();
  currentUserType: string;
  currentUserUID: string;
  graduationYearOptions = new Array();
  updateButtonTooltip = 'Save your updated data.';
  postDialogMaxWidth = '90%';
  postDialogWidth = '90%';


  currentUser: User;
  hasPostingPlan = false;
  postingPlan: string;
  postingPlanFreePriorityPostsTotal: number;
  postingPlanFreePriorityPostsRemaining: number;
  postingPlanFreeStandardPostsTotal: number;
  postingPlanFreeStandardPostsRemaining: number;
  postingPlanPriorityDiscount: number;
  postingStartDate: Date;
  postingStartExpirationDate: Date;

  // Form state
  loading = false;

  constructor(public auth: AuthService,
              private fb: FormBuilder,
              private titleService: Title,
              private afs: AngularFirestore,
              private uiService: UIService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private paymentService: PaymentService,
              public subscriptionDialog: MatDialog) {
    this.activeSection = this.route.snapshot.paramMap.get('section');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.titleService.setTitle('User Profile | BagMask.com');
    console.log('User Info for uid: ' + this.auth.authenticatedUser.uid);
    this.currentUserUID = this.auth.authenticatedUser.uid;
    console.log('User Profile Parameter:');

    console.log(this.activeSection);



    this.myForm = this.fb.group({
      email: new FormControl ({value: '', disabled: true}, Validators.required),
      userType: new FormControl ({value: '', disabled: true}, Validators.required),
      userGraduationYear: new FormControl (''),
      userCompanyName: new FormControl (''),
      userCompanyType: new FormControl ({value: '', disabled: true}, Validators.required),
      userCompanyURL: new FormControl (''),
      userCompanyInfo: new FormControl (''),
      userAddress: new FormControl (''),
      userCity: new FormControl (''),
      userState: new FormControl (''),
      userZip: new FormControl (''),
      // favoriteColor: ['', Validators.required]
    });

    this.preloadData();
    this.setGraduationYearOptions();

  }

  async onSubmit() {
    this.loading = true;
    const formValue = this.myForm.value;
    // const result: User = Object.assign({}, this.myForm.value);

    try {
      await this.afs.doc('users/' + this.auth.authenticatedUser.uid).update(formValue);
      // this.success = true;
      console.log('Successful User Info Update for user: ' + this.auth.authenticatedUser.uid);
      this.uiService.showSnackbar('User Information Updated!', null, 3000);
    } catch (error) {
      console.error(error);
      this.uiService.showSnackbar(error.message, null, 3000);
    }
    this.loading = false;
  }

  preloadData() {
    this.userProfileSubscription = this.afs.doc('users/' + this.auth.authenticatedUser.uid).valueChanges().pipe(
      tap(data => {
        try {
          this.myForm.patchValue(data)
          this.currentUser = data as User;
          console.log('Checking Monthlyplan: ' + this.currentUser.userCompanyMonthlyPlan);
          if (this.currentUser.userCompanyMonthlyPlan) {
            // Set hasPostingPlan to true, and show the card with posting plan, otherwise show "buy subscription plan"
            this.hasPostingPlan = true
            // console.log('preloadData finished, value: ' + JSON.stringify(data));
            // console.log('preloadData start time: ' + this.currentUser.userCompanyMonthlyPlanStartDate.toDate() +
            //   ' expiration: ' + this.currentUser.userCompanyMonthlyPlanExpirationDate.toDate());

            this.postingPlan = this.currentUser.userCompanyMonthlyPlan;
            this.postingPlanFreeStandardPostsTotal = this.currentUser.userCompanyMonthlyPlanStandardPostsTotal;
            this.postingPlanFreeStandardPostsRemaining = this.currentUser.userCompanyMonthlyPlanStandardPostsRemaining;
            this.postingPlanFreePriorityPostsTotal = this.currentUser.userCompanyMonthlyPlanFreePriorityPostsTotal;
            this.postingPlanFreePriorityPostsRemaining = this.currentUser.userCompanyMonthlyPlanFreePriorityPostsRemaining;
            this.postingPlanPriorityDiscount = this.currentUser.userCompanyMonthlyPlanPriorityDiscount;
            this.postingStartDate = this.currentUser.userCompanyMonthlyPlanStartDate.toDate();
            this.postingStartExpirationDate = this.currentUser.userCompanyMonthlyPlanExpirationDate.toDate();
            // Update current user type as job poster or job seeker to show or hide controls on user page
          }

          // console.log('Inside preloadData, checking form value for userType: ' + this.myForm.value.userType);
          // console.log('Inside preloadData, checking form control value for userType: ' + this.myForm.controls.userType.value);
          // console.log(this.myForm);
          this.updateCurrentUserType(this.myForm.controls.userType.value);
        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar('Encountered Issue with User Profile, Please contact support', null, 3000);
          } else {
            this.uiService.showSnackbar(error.message, null, 3000);
          }

        }
      })
    ).subscribe(value => {
      console.log(value);

    });

    console.log('Preloading Data, display name: ' + this.auth.authenticatedUser.displayName + ' ' + this.auth.authenticatedUser.uid);
  }

  // tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  //   console.log('tabChangeEvent => ', tabChangeEvent, ' index: ', tabChangeEvent.index);
  //   this.currentTabSelected = tabChangeEvent.index;
  // };

  setGraduationYearOptions() {
    // Set how many years we want to get for graduation options, in addition to current year
    const num = 4;
    let i: number;

    for (i = 0; i <= num; i++) {

      this.graduationYearOptions.push(new Date().getFullYear() + i);
    }
    // console.log('graduationYearOptions set to: ' + this.graduationYearOptions);
  }

  updateCurrentUserType(userType) {
    console.log('Inside updateCurrentUserType for type: ' + userType);
    if (userType === 'Job Poster') {
      this.currentUserType = 'Job Poster';
    } else {
      this.currentUserType = 'Job Seeker';
    }
    console.log('currentUserType: ' + this.currentUserType);

  }

  onJobTypeChange(userType: string) {
    this.updateCurrentUserType(userType);
  }

  genRandInt() {
    console.log('Rand Int: ' + this.uiService.randomOneBillionInt());
  }

  ngOnDestroy() {
    this.userProfileSubscription.unsubscribe();
  }

}
