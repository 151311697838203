import {Component, Input, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators, NgForm} from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../Services/auth.service';
import { UIService } from '../../Services/ui.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BagmaskFieldValues} from '../../Namespaces/bagmaskFieldValues.namespace';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  userTypes = BagmaskFieldValues.getUserTypes();
  companyTypes = BagmaskFieldValues.getCompanyTypes();
  loginForm: FormGroup;
  signupForm: FormGroup;
  signupFormSeeker: FormGroup;
  signupFormEmployer: FormGroup;
  isLoading = false;
  private loadingSubs: Subscription;
  loginState: string;
  registrationType: string;
  currentYear: number;
  mailingListChecked: boolean;

  constructor(public authService: AuthService,
              private router: Router,
              private titleService: Title,
              private activatedRoute: ActivatedRoute,
              private uiService: UIService) {}

  ngOnInit() {
    this.titleService.setTitle('Login | BagMask.com');
    this.loginState = 'login';
    // Check incoming parameters to see if we have a register type, if so, set to active registration of that type
    this.activatedRoute.params.forEach((params: Params) => {
      if (params.registerType) {
        // Parameter for register exists, send to registration page with that parameter
        this.onRegister(params.registerType);
      } else {
        // No parameter passed, so we want to stay on login page or direct to login page
        this.returnToLogin();
      }

    });

    // Get Current Year
    this.currentYear = new Date().getFullYear();


    this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
      this.isLoading = isLoading;
      }
    );

    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', { validators: [Validators.required] })
    });


    this.signupFormSeeker = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', { validators: [Validators.required] }),
      userType: new FormControl ('', Validators.required),
      // userName: new FormControl ('', Validators.required),
      mailingList: new FormControl(''),
      // agree: new FormControl('', Validators.requiredTrue),
    });

    // Set the default value for mailingList to true;
    this.signupFormSeeker.get('mailingList').setValue(true);

    this.signupFormEmployer = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', { validators: [Validators.required] }),
      // userName: new FormControl ('', Validators.required),
      userCompanyType: new FormControl ('', Validators.required),
      userCompanyName: new FormControl ('', Validators.required),
      mailingList: new FormControl(''),
      // agree: new FormControl('', Validators.requiredTrue),
    });

    // Below code used to disable login for testing other features in Dev mode only.
    // Disable/comment below code when wanting to test login and auth functionality
    // if (isDevMode()){
    //   this.authService.login({
    //     email: "dev@test.com",
    //     password: "t1"
    //   });
    // }
  }

  async onSubmitLogin() {
    // Below code of if to keep unwanted eyes out of testing
    // if (this.loginForm.value.password == "camo"){
    console.log('onSubmitLogin started');
    // let loginResponse = this.authService.login({
    //   email: this.loginForm.value.email,
    //   password: this.loginForm.value.password
    // });

    await this.authService.login({
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }).then( loginOutcome => {
        console.log('completed, loginOutcome: ' + loginOutcome);
        window.location.reload();
        // if (loginOutcome === 'success') {
        //   console.log('login successful, destroying subscriptions in login');
        //   this.ngOnDestroy();
        // }
      }
    );
  }

  async onSubmitSignup(form, type: string) {
    console.log('Start Submit Signup');
    // let currentForm: FormGroup;
    let signupSuccess: string;
    if (this.registrationType === 'seeker') {
      console.log('seeker signup form values');
      console.log('email: ' + this.signupFormSeeker.value.email);
      console.log('userType: ' + this.signupFormSeeker.value.userType);
      console.log('email: ' + this.signupFormSeeker.value.mailingList);
      signupSuccess = await this.authService.registerUser({
        email: this.signupFormSeeker.value.email,
        password: this.signupFormSeeker.value.password,
        // userName: this.signupFormSeeker.value.userName,
        userType: this.signupFormSeeker.value.userType,
        mailingList: this.signupFormSeeker.value.mailingList,
        userCompanyType: null,

      });

    } else {
      console.log('inside submit signup for employer, value of userCompanyName: ' + this.signupFormEmployer.value.userCompanyName);
      signupSuccess = await this.authService.registerUser({
        email: this.signupFormEmployer.value.email,
        password: this.signupFormEmployer.value.password,
        // userName: this.signupFormEmployer.value.userName,
        userType: 'Job Poster',
        mailingList: this.signupFormEmployer.value.mailingList,
        userCompanyType: this.signupFormEmployer.value.userCompanyType,
        userCompanyName: this.signupFormEmployer.value.userCompanyName

      });
    }

    // Now we can check and see whether success or fail on the signup, if failure don't show the success message and leave
    // on signup screen.
    console.log('Signup Submitted.  signupSuccess: ' + signupSuccess);
    this.router.navigate(['/home']).then(res => {
      console.log('Navigation result: ', res);
    });
    if (signupSuccess === 'success') {
      this.loginState = 'signupSubmitted';
    } else {
      this.loginState = 'signup';
    }

    // this.authService.registerUser({
    //   email: this.signupForm.value.email,
    //   password: this.signupForm.value.password,
    //   userName: this.signupForm.value.userName,
    //   userType: this.signupForm.value.userType,
    // });
  }

  onRegister(type: string) {
    console.log('onRegister of type: ' + type);
    this.registrationType = type;
    this.loginState = 'signup';
    this.router.navigate(['/login/', { registerType: type}]);
  }

  // onRegister(type: string) {
  //   console.log('onRegister clicked, type: ' + type);
  //   this.sidenav.close();
  //
  // }

  returnToLogin() {
    console.log('returnToLogin, setting loginState');
    this.loginState = 'login';
  }

  passwordReset() {
    console.log('password reset clicked');
    this.router.navigate(['/passwordreset']);
  }

  help() {
    this.router.navigate(['/support/registration']);
  }

  ngOnDestroy() {
    console.log('inside loginComponent, ngOnDestroy');
    if (this.loadingSubs) {
      console.log('loginComponent, loadingSubs exists, destroying');
      this.loadingSubs.unsubscribe();
    }
  }
}




