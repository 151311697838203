export class PostQuery {
  _type: string;
  _state: string;
  _companyName?: string;
  _postId?: string;
  _status?: string;
  _uid?: string;
  _minSalary?: number;
  _employmentStatus?: string;
  _signingBonus?: boolean;
  _tuitionReimbursement?: boolean;
  _eventualPartnership?: boolean;

  constructor(status: string, type: string, state: string, companyName?: string) {
    this._status = status;
    this._type = type;
    this._state = state;
    this._companyName = companyName;
  }

  get type(): string {
    return this._type;
  }

  set type(newType: string) {
    this._type = newType;
  }

  get state(): string {
    return this._state;
  }

  set state(newState: string) {
    this._state = newState;
  }

  get companyName(): string {
    return this._companyName;
  }

  set companyName(newCompanyId: string) {
    this.companyName = newCompanyId;
  }

  get postId(): string {
    return this._postId;
  }

  set postId(newPostId: string) {
    this.postId = newPostId;
  }

  get status(): string {
    return this._status;
  }

  set status(newStatus: string) {
    this._status = newStatus;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(newuid: string) {
    this._uid = newuid;
  }

  get minSalary(): number {
    return this._minSalary;
  }

  set minSalary(newMinSalary: number) {
    this._minSalary = newMinSalary;
  }

  get employmentStatus(): string {
    return this._employmentStatus;
  }

  set employmentStatus(newEmploymentStatus: string) {
    this._employmentStatus = newEmploymentStatus;
  }

  get signingBonus(): boolean {
    return this._signingBonus;
  }

  set signingBonus(newSigningBonus: boolean) {
    this._signingBonus = newSigningBonus;
  }

  get tuitionReimbursement(): boolean {
    return this._tuitionReimbursement;
  }

  set tuitionReimbursement(newTuitionReimbursement: boolean) {
    this._tuitionReimbursement = newTuitionReimbursement;
  }

  get eventualPartnership(): boolean {
    return this._eventualPartnership;
  }

  set eventualPartnership(newEventualPartnership: boolean) {
    this._eventualPartnership = newEventualPartnership;
  }

}

