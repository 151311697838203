import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-add-post-submit-dialog',
  templateUrl: './add-post-submit-dialog.component.html',
  styleUrls: ['./add-post-submit-dialog.component.scss']
})
export class AddPostSubmitDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AddPostSubmitDialogComponent>) { }

  closeDialog() {
    this.dialogRef.close('OOOh yeah dialog closed mama!');
  }
  ngOnInit() {
    console.log('In AddPostSubmitDialogComponent, data: ' + this.data);
  }

}
