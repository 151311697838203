<div class="user-agreement-info-container mat-typography" fxLayout="column" fxLayout.lt-md="column"
     fxLayoutGap.lt-md="10px" fxLayoutAlign="space-around center">
  <h2>If you have any questions about BagMask, get in touch with our friendly support team!</h2>

  <h2><a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a></h2>

  <h2 class="user-agreement-section">BagMask.com User Agreement</h2>

  <h2>I. Acceptance</h2>
  <p class="user-agreement-section">The following terms and conditions govern your use of BagMask.com LLC’s website
    (“Site”) and any services provided
    or offered on the Site (“Services”). By accessing the Site and/or utilizing the Services, you expressly
    acknowledge, agree to and accept the terms and conditions below.

  <h2>II. Copyright Notice</h2>
  <p class="user-agreement-section">Unless specifically noted otherwise, any and all information, text, articles, data,
    images, screens, webpages,
    service platforms, and/or other materials (collectively referred to hereafter as the “Materials”) appearing on this
    Site, and including but not limited to any Services or the means by which Services are offered, are the exclusive
    property of BagMask.com LLC. The Materials may not be copied, displayed, distributed, downloaded, licensed,
    modified, published, reposted, reproduced, reused, sold, transmitted, used to create a derivative work, reverse
    engineered, or otherwise used for any personal or commercial purposes, except as expressly provided in the terms
    and conditions of this User Agreement or without the express written permission of BagMask.com LLC.
    You may not use any content contained in the Materials in any manner that may give or otherwise result in the
    giving of a false or misleading impression or statement. In no event shall this User Agreement or anything on
    the Site be construed as conferring any license of any intellectual property rights, whether by estoppel,
    implication or otherwise.

  <h2>III. Trademark Notice</h2>
  <p class="user-agreement-section">BagMask.com LLC, its logo(s), and bagmask.com are trademarks, service marks and/or
    registered trademarks of BagMask.com LLC in the United States and certain other jurisdictions. All other
    trademarks and service marks that may appear on the Site are the property of their respective owners and are used
    solely to refer to those companies’ goods, services or employment opportunities. All inquiries concerning the use
    of any BagMask.com LLC trademarks, copyrights, or designs should be addressed to:

  <h2>IV. Disclaimers of Warranties</h2>
  <p class="user-agreement-section-small">Due to the unpredictability of technology and the online environment,
    BagMask.com
    LLC expressly disclaims and does not warrant that the function or operation of the Site or any Services will be
    uninterrupted or error-free, that any defects will be corrected within a certain period of time, or that this Site
    or the server that makes it available will be free of viruses or other elements harmful to technology. As a user
    of the Site and/or Services, you expressly agree to have full and exclusive responsibility for any costs associated
    with servicing or repairing equipment used in connection with your use of the Site and/or Services.

  <p class="user-agreement-section-small">BagMask.com LLC does not represent or warrant that the information accessible
    through the Site or Services is accurate, complete or current. The Site could contain typographical errors,
    technical inaccuracies or other incorrect information, and BagMask.com LLC shall not be responsible for the same.
    BagMask.com LLC expressly reserves the right to add to, change or delete any content on the Site or any Services
    offered, or any part thereof, without notice. Additionally, the Site and/or Services may contain information
    provided by third parties and BagMask.com LLC makes no representations or warranties regarding the accuracy, truth,
    quality, suitability or reliability of any such third party information. BagMask.com LLC is not responsible for any
    errors, omissions, or inaccuracies contained in any information provided by such third parties. BagMask.com LLC does
    not endorse, warrant or guarantee any products or services offered on this Site. You expressly acknowledge and agree
    that BagMask.com LLC is not a party to, and is not responsible for monitoring, any transaction between users and
    third party providers of content and/or services.

  <p class="user-agreement-section-small">While BagMask.com LLC endeavors to ensure the accuracy of the content on the
    Site and/or Services, it cannot guarantee the same and you should not treat content on the Site and/or Services as
    the basis from which to make business decisions without consulting professional advice. BagMask.com LLC emphasizes
    that trademark and related intellectual property laws vary from country to country, and between jurisdictions within
    some countries. The information included on this Site and/or in Services will not be relevant or accurate for all
    countries, states or other jurisdictions.

  <p class="user-agreement-section">THE CONTENTS OF THIS SITE AND ALL SERVICES OFFERED ON OR THROUGH THE SITE,
    INCLUDING WITHOUT LIMITATION THE MATERIALS, ARE PROVIDED “AS IS” AND BAGMASK.COM LLC MAKES NO REPRESENTATIONS OR
    WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SITE, SERVICES OR THEIR RESPECTIVE CONTENTS.
    BAGMASK.COM LLC EXPRESSLY DISCLAIMS ALL RESPRESENTATIONS AND WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION,
    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.

  <h2>V. Limitation of Liability</h2>
  <p class="user-agreement-section-small">Neither BagMask.com LLC nor any of its officers, members, directors,
    employees or other representatives shall be liable for any damages, special, consequential or otherwise, arising out
    of or in connection with the use of the Site or Services, or any information contained therein, even if advised of
    the possibility thereof. This limitation of liability is comprehensive and applies to all damages of any kind and
    nature, including without limitation, loss of data, income or profit, loss of or damage to property and claims of
    third parties. You acknowledge and agree that you have sole and exclusive responsibility for adequate storage,
    protection and backup of data and/or equipment used in connection with the Site and/or Services, and that you will
    not make any claim against BagMask.com LLC for lost data, re-run time, inaccurate output, work delays or lost
    profits
    resulting from the use of the Site and/or Services or any Materials related thereto.

  <p class="user-agreement-section">YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE AND/OR
    SERVICES AND ALL MATERIALS THEREIN ARE AT YOUR OWN RISK.

  <h2>VI. Submissions</h2>
  <p class="user-agreement-section">BagMask.com LLC shall not in any way be obligated to respond to or accept any
    information, documents, correspondence, or other materials (“Submissions”) submitted through the Site or in
    conjunction with any Services, nor have any confidentiality obligations with respect to such Submissions.
    In no event shall BagMask.com be under any obligation to pay compensation of any kind for user Submissions.
    You acknowledge that BagMask.com shall have the nonexclusive rights to use, reproduce and provide to third-parties
    any and all Submissions, including without limitation any information contained therein. Any Submissions to the Site
    or made in connection with the use of any Services shall be considered a complete and binding release and waiver by
    you of and to any and all proprietary claims and/or intellectual property rights, including without limitation all
    trademarks, copyrights, and similar rights, with respect to your Submission.

  <h2>VII. Privacy Policy</h2>
  <p class="user-agreement-section">Please review and refer to the BagMask.com “Privacy Policy”.

  <h2>VIII. Governing Law</h2>
  <p class="user-agreement-section">These terms and conditions shall be governed by and construed in accordance with
    the laws of the State of Maryland without regard to any principles of conflicts of law. You agree that any action,
    whether at law or in equity, that arises out of or relates to these terms and conditions will be filed only in the
    state or federal courts located in the State of Maryland.

  <h2>IX. Complete Agreement</h2>
  <p class="user-agreement-section">These terms and conditions, including all policies referred to herein, represent
    the entire understanding relating to the use of the Site and any Services, and will prevail over any prior or
    contemporaneous communications, whether conflicting or additional. BagMask.com reserves the right to revise these
    terms and conditions at any time without notice by updating this posting.

  <h2><a class="user-agreement-link" routerLink="/privacyPolicy">Click here for BagMask.com Privacy Policy</a></h2>


</div>

<div class="vertical-padding"></div>

