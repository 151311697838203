import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from './Services/auth.service';
import {NavigationEnd, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import {fadeAnimation} from './animations';
import {Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import {Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import {MatSidenav} from '@angular/material/sidenav';
import {User} from './Models/user.model';
import {AngularFirestore} from '@angular/fire/firestore';
import {CacheService} from './Services/cache.service';
import {UIService} from './Services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav: MatSidenav;
  private minWidthBreakpoint = 800;
  public appVersion;
  public bagmaskServer;
  opened: boolean;
  isAuth: boolean;
  userInfo: User;
  userPosterType = '';

  authSubscription: Subscription;
  userInfoSubscription: Subscription;

  constructor(
    private cacheService: CacheService,
    private afs: AngularFirestore,
    private authService: AuthService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    Angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    public uiService: UIService,
    private router: Router) {

    this.appVersion = environment.version;

    // Check which server we are hitting so DEVELOPMENT can be labeled for ease of testing
    console.log('Checking if we are using development server: ', environment.firebase.projectId);
    if (environment.firebase.projectId === 'bagmask-dev') {
      // We are in development
      this.bagmaskServer = environment.firebase.projectId;
    } else {
      // Production, leave field blank
      this.bagmaskServer = 'PRODUCTION';
    }

    this.opened = false;
    this.isAuth = false;
    angulartics2GoogleAnalytics.startTracking();
    Angulartics2GoogleTagManager.startTracking();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
        angulartics2GoogleAnalytics.pageTrack(event.urlAfterRedirects);
      }
    });


  }

  ngOnInit () {
    // Load script into index.html instead of manually having it there
    // This allows us to use variables for different environment setup configurations
    this.loadScript();

    this.authService.initAuthListener();

    this.authSubscription = this.authService.authChange.subscribe(authStatus => {
      this.isAuth = authStatus;
      console.log('bagmask-nav, onInit.  isAuth: ' + this.isAuth);

      if (this.isAuth) {
        // console.log('Inside bagmask nav, ngOnInit.  authUser.username: ' + this.authService.authenticatedUser.userName);
        const docRef = this.afs.collection('users').doc(this.authService.authenticatedUser.uid);

        docRef.ref.get().then((doc) => {
          if (doc.exists) {
            // console.log('Document data:', doc.data());
          } else {
            // doc.data() will be undefined in this case
            // console.log('No such document!');
          }
        }).catch(function(error) {
          console.log('Error getting document:', error);
        });
      }
    });

    this.userInfoSubscription = this.authService.fullUserInfoSubject.subscribe(userInfoSub => {
        this.userInfo = userInfoSub;
        this.userPosterType = userInfoSub.userType;
        // console.log('bagmask-nav, checking userInfoSubscription');
        // console.log(JSON.stringify(userInfoSub));
        // console.log(this.userPosterType);

      }
    );
    // console.log('bagmaskNav - finished userInfoSubscription: ' + this.userInfo.userCompanyName);


    // On every user login check for and set hi pris so they are available to others
    this.checkAndSetLocalStorageHiPris();
  }


  /*
    Load the google analytics scripts required so we don't need to include them on the actual index.html file
    Instead they will be added there and we can leverage environment variables for multiple deployments or dev/test/prod
  */
  private loadScript() {
    try {

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + environment.googleAnalyticsKey + `', {'send_page_view': false});
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }

    // const script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.innerHTML = 'window["adrum-start-time"] = new Date().getTime(); (function(config){ config.appKey = ' + environment.key + '; })(window["adrum-config"] || (window["adrum-config"] = {}));';
    // const head = document.getElementsByTagName('head')[0];
    // if (head !== null && head !== undefined) {
    //   document.head.appendChild(script);
    // }
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  testUserInfoButton() {
    console.log(this.authService.fullUserInfo.userCompanyName);
    console.log(this.userPosterType);
  }

  close(reason: string) {
    console.log('Sidenav closed, reason: ' + reason);
    this.sidenav.close();
  }

  onRegister(type: string) {
    console.log('onRegister clicked, type: ' + type);
    this.sidenav.close();
    this.router.navigate(['/login/', { registerType: type}]);
  }

  // Need to check for local storage hi pris and if no value, set them
  checkAndSetLocalStorageHiPris() {
    this.cacheService.fetchAndStoreHighPriorityPostings().then(res => {
      console.log('Hi Pris Populated.  Values: ');
      console.log(this.cacheService.getLocalStorageHighPriorityPostings());
      console.log('Hi Pris Total Count: ' + this.cacheService.getLocalStorageHighPriorityTotalCount());
      console.log('Hi Pris Current Counter: ' + this.cacheService.getLocalStorageHighPriorityCurrentCounter());
    }).catch(e => {
      console.log('Error with getting hi pris: ' + e);
    });
  }

  onLogout() {
    console.log('onLogout clicked.  isAuth status: ' + this.isAuth);
    this.authService.logout();
    console.log('isAuth status: ' + this.isAuth);
  }

  testLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    console.log('inside bagmask-nav ngOnDestroy');
    if (this.authSubscription) {
      console.log('bagmask-nav, authSubscription exists, destroying');
      this.authSubscription.unsubscribe();
    }
    if (this.userInfoSubscription) {
      console.log('bagmask-nav, userInfoSubscription exists, destroying');
      this.userInfoSubscription.unsubscribe();
    }
  }

}
