<div class="bagmask-job-posting-filtering-section">

  <!--<div fxFlex="0 1 30%" class="filter-field mat-typography">-->
    <!--Job Type: {{currentJobType}}-->
  <!--</div>-->

  <mat-toolbar class="bagmask-sub-toolbar toolbar-smaller-font">
    <mat-panel-title fxFlex="65%"
                     class="panel-text-align-center" fxLayoutAlign="start center">
      {{getFilterHeading()}}
      <!--{{currentJobType}}s in {{currentState === 'All' ? 'All States' : currentState}}-->
    </mat-panel-title>
    <mat-form-field fxFlex="0 1 35%" class="filter-field">
      <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter Results">
    </mat-form-field>
  </mat-toolbar>



  <!--<mat-expansion-panel fxFlex="100%" class="bagmask-job-posting-filtering-expansion-panel"-->
                       <!--(opened)="panelOpenState = true"-->
                       <!--(closed)="panelOpenState = false"-->
                       <!--#panel>-->
                       <!--&lt;!&ndash; Code below enables mouseover, works slick but could be annoying &ndash;&gt;-->
                       <!--&lt;!&ndash;(mouseenter)="panel.open()" (mouseleave)="panel.close()">&ndash;&gt;-->

    <!--<mat-expansion-panel-header class="bagmask-job-posting-filtering-expansion-panel-header"-->
                                <!--fxlayout="row" fxLayoutAlign="center center">-->
      <!--<mat-panel-title fxFlex="75%"-->
        <!--class="panel-text-align-center" fxLayoutAlign="start center">-->
        <!--{{currentJobType}}s in {{currentState === 'All' ? 'All States' : currentState}}-->
      <!--</mat-panel-title>-->
      <!--<mat-panel-description fxFlex="25%" fxLayoutAlign="end center"-->
        <!--class="advanced-panel-click-instructions">-->
        <!--&lt;!&ndash;Click Here for Advanced Filtering Options&ndash;&gt;-->
        <!--{{panelOpenState ? 'Minimize Filtering Options' : 'Change State / Advanced Filtering'}}-->
      <!--</mat-panel-description>-->
    <!--</mat-expansion-panel-header>-->
    <!--<div class="bagmask-job-posting-filtering-expansion-panel-content">-->

      <!--<form-->
        <!--[formGroup]="filterForm"-->
        <!--fxLayoutAlign="space-around center" fxLayout="row wrap">-->

        <!--&lt;!&ndash;State Select Filter&ndash;&gt;-->
        <!--<mat-form-field class="add-post-focus-text-color"-->
                        <!--fxFlex="30%" fxFlex.lt-sm="0 1 48%">-->
          <!--<mat-select-->
            <!--formControlName="state"-->
            <!--#filterState-->
            <!--placeholder="State">-->
            <!--<mat-option value="All">All</mat-option>-->
            <!--<mat-option *ngFor="let state of usStates" [value]="state.abbreviation">{{state.stateName}}</mat-option>-->
          <!--</mat-select>-->
        <!--</mat-form-field>-->

        <!--&lt;!&ndash;Min Salary Filter&ndash;&gt;-->
        <!--<mat-form-field class="add-post-focus-text-color"-->
                        <!--fxFlex="30%" fxFlex.lt-sm="0 1 48%"-->
                        <!--hintLabel="Thousands, max 3 digits">-->
          <!--<input-->
            <!--class="post-right-align"-->
            <!--matInput-->
            <!--(keypress)="uiService.numberOnly($event)"-->
            <!--maxlength="3"-->
            <!--#inputMinSalary-->
            <!--autocomplete="off"-->
            <!--formControlName="minSalary"-->
            <!--placeholder="Minimum Salary">-->
          <!--&lt;!&ndash;<span matPrefix>$</span>&ndash;&gt;-->
          <!--<span matSuffix>k</span>-->
          <!--<mat-hint align="end">{{inputMinSalary.value?.length || 0}}/3</mat-hint>-->
          <!--&lt;!&ndash;<mat-error *ngIf="minSalary.invalid">{{getMinSalaryErrorMessage()}}</mat-error>&ndash;&gt;-->
        <!--</mat-form-field>-->

        <!--&lt;!&ndash;Employment Status Filter&ndash;&gt;-->
        <!--<mat-form-field class="add-post-focus-text-color"-->
                        <!--fxFlex="30%" fxFlex.lt-sm="0 1 48%"-->
                        <!--hintLabel="Thousands, max 3 digits">-->
          <!--<mat-select-->
            <!--required-->
            <!--formControlName="employmentStatus"-->
            <!--placeholder="Employment Status">-->
            <!--<mat-option *ngFor="let employmentStatus of employmentStatuses" [value]="employmentStatus">{{employmentStatus}}</mat-option>-->
          <!--</mat-select>-->
        <!--</mat-form-field>-->

        <!--<div fxFlex="100%" class="vertical-space"></div>-->

        <!--<mat-checkbox class="add-post-focus-text-color"-->
                      <!--formControlName="signingBonus"-->
                      <!--color="accent"-->
                      <!--fxFlex="30%" fxFlex.lt-sm="0 1 48%">-->
          <!--Sign-on Bonus-->
        <!--</mat-checkbox>-->

        <!--<mat-checkbox class="add-post-focus-text-color"-->
                      <!--formControlName="tuitionReimbursement"-->
                      <!--color="accent"-->
                      <!--fxFlex="30%" fxFlex.lt-sm="0 1 48%">-->
          <!--Tuition Reimbursement-->
        <!--</mat-checkbox>-->

        <!--<mat-checkbox class="add-post-focus-text-color"-->
                      <!--formControlName="eventualPartnership"-->
                      <!--color="accent"-->
                      <!--fxFlex="30%" fxFlex.lt-sm="0 1 48%">-->
          <!--Eventual Partnership in Practice-->
        <!--</mat-checkbox>-->

      <!--</form>-->
    <!--</div>-->
    <!--<mat-action-row fxLayoutAlign="end center">-->
      <!--<button mat-button mat-raised-button color="warn" class="job-posting-filtering-apply-filter-button" fxFlex="150px"-->
              <!--fxFlexAlign="center center" (click)="clearFilters(); panel.close()">-->
        <!--Clear Filters-->
      <!--</button>-->
      <!--<button mat-button mat-raised-button class="job-posting-filtering-apply-filter-button" fxFlex="150px"-->
              <!--fxFlexAlign="center center" (click)="callNewFetchWithParams(); panel.close()">-->
        <!--Apply Filters-->
      <!--</button>-->
    <!--</mat-action-row>-->
  <!--</mat-expansion-panel>-->
</div>
