<form [formGroup]="resetForm"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="10px">

  <mat-form-field>
    <input
      type="email"
      matInput
      #pwResetEmail
      placeholder="Your email"
      required
      formControlName="email">
    <mat-hint>Please enter a valid email.</mat-hint>
    <mat-error>Invalid or missing email.</mat-error>
  </mat-form-field>


  <div class="small-vertical-space">
    <button type="submit" fxFlex="200px"
            class="rounded-button"
            (click)="passwordResetSendEmail(pwResetEmail.value)"
            mat-raised-button color="warn">Send Password Reset Email</button>
  </div>



</form>
