import { Component, OnInit } from '@angular/core';
import {faCoffee} from '@fortawesome/free-solid-svg-icons';
import {faFacebookF, faInstagram, faInstagramSquare} from '@fortawesome/free-brands-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {faGithub, faGithubAlt} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'app-footer-distributed-contact-form',
  templateUrl: './footer-distributed-contact-form.component.html',
  styleUrls: ['./footer-distributed-contact-form.component.scss']
})
export class FooterDistributedContactFormComponent implements OnInit {
  faCoffee = faCoffee;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faLinkedin = faLinkedin;
  faLinkedinIn = faLinkedinIn;
  faGithub = faGithub;
  faGithubAlt = faGithubAlt;
  faEnvelope = faEnvelope;
  faYoutube = faYoutube;
  faInstagram = faInstagram;
  faInstagramSq = faInstagramSquare;

  constructor() { }

  ngOnInit() {
  }

}
