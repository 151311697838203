import {Component, OnDestroy, OnInit, AfterViewInit, ViewChild, Input, OnChanges, SimpleChanges} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {PostService} from '../../../Services/post.service';
import {JobPosting} from '../../../Models/post.model';
import {UIService} from '../../../Services/ui.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PostQuery} from '../../../Models/postQuery.model';
import {Router} from '@angular/router';
import {AuthService} from '../../../Services/auth.service';
import {PostUpgradeService} from '../../../Services/post-upgrade.service';
import { Title } from '@angular/platform-browser';
import { User } from 'src/app/Models/user.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-posting-table',
  templateUrl: './user-posting-table.component.html',
  styleUrls: ['./user-posting-table.component.scss']
})
export class UserPostingTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() userPostingType: string;

  userDataIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userDataIsLoading: boolean;
  userIsJobPoster$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  userIsJobPoster: boolean;

  userToUpdate: User;

  private unsubscribe: Subscription[] = [];
  fullUserSubscr: Subscription;

  displayedColumns = [
    // 'id',
    'jobType',
    'city',
    'state',
    'shortDescription',
    'companyJobID',
    // 'employmentStatus',
    // 'minSalary',
    // 'maxSalary',
    // 'taxStatus',
    // 'companyType',
    // 'companyName',
    'datePosted',
    'expirationDate',
    // 'postPriority',
    'postStatus',
    'actions'
  ];


  dataSource = new MatTableDataSource<JobPosting>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoading = false;
  private loadingSubs: Subscription;

  jobPostsSubscription: Subscription;
  breakpointSubscription: Subscription;

  selectedRowIndex = -1;
  returnedJobsCount: number;
  returnedNoJobsBoolean: boolean;

  buttonSelected = 'All';

  constructor(public postDialog: MatDialog,
              private postService: PostService,
              private uiService: UIService,
              private router: Router,
              public authService: AuthService,
              private postUpgradeService: PostUpgradeService,
              private titleService: Title,
              ) {
    // Set a subscription to the subject for whether user data is loading
    const userLoadingSubscr = this.userDataIsLoading$
    .asObservable()
    .subscribe((res) => (this.userDataIsLoading = res));
    this.unsubscribe.push(userLoadingSubscr);
    this.userDataIsLoading$.next(true);

    const jobPosterSubscr = this.userIsJobPoster$
    .asObservable()
    .subscribe((res) => (this.userIsJobPoster = res));
    this.unsubscribe.push(jobPosterSubscr);
  }

  ngOnInit() {
    this.titleService.setTitle('User Profile - Your Jobs | BagMask.com');


    this.populateUserFieldsFromExistingData();

    // Set a timer to fire alert if not logged in
    setTimeout(() => {
      if (this.userDataIsLoading) {
        Swal.fire('Job Poster Log-in Required', "<a href='/login'>Click here and log-in</a> to be able to Create a new Job Post!");
      }
    }, 3000);

    console.log('UserPostingTable onInit, userPostingType: ' + this.userPostingType + ' and user UID: ' +
      this.authService.fullUserInfo.uid );
    console.log('UserPostingTable onInit, fullUserInfo: ');
    console.log(this.authService.fullUserInfo);
    this.jobPostsSubscription = this.postService.finishedJobsChanged.subscribe(
      (jobPosts: JobPosting[]) => {
        this.dataSource.data = jobPosts;
        console.log('jobpostingstable ngoninit, count: ' + this.dataSource.data.length);
        this.returnedJobsCount = this.dataSource.data.length;
        if (this.returnedJobsCount > 0) {
          this.returnedNoJobsBoolean = true;
        } else {
          this.returnedNoJobsBoolean = false;
        }
      }
    );

    this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
        this.isLoading = isLoading;
      }
    );


    // Build the PostQuery for this User, adding the fields we want to search by
    // Since we want all results, we want to make both type and state null by default, returning all types and all locations
    const postQuery = new PostQuery(null, null, null);

    postQuery.uid = this.authService.fullUserInfo.uid;
    this.postService.fetchJobPostingsWithMetadataByPostQuery(postQuery);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // This code accurately applies the fix for better sorting, but also accounts for specific number or date fields if need be
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'minSalary':
          return Number(item.minSalary);
        case 'maxSalary':
          return Number(item.maxSalary);
        default:
          return item[property];
      }
    };

    console.log('userPostingTable ngAfterViewInit');
    this.dataSource.data.forEach(value => {
        console.log('minSalary: ' + value.minSalary);
      }
    );
  }

  // Populate the fields from our values in the database to start
  populateUserFieldsFromExistingData() {
    this.fullUserSubscr = this.authService.fullUserInfoBehaviorSubject.subscribe(data => {
      // Make sure it has a value, otherwise we can get it again when it returns value
      if (data) {
        this.userToUpdate = data;
        if (this.userToUpdate.userType === 'Job Poster' ) {
          this.userIsJobPoster$.next(true);
        } else {
          this.userIsJobPoster$.next(false);
        }

        // When data is finished loading update the behavior subject to remove the spinner
        this.userDataIsLoading$.next(false)

        // Test the loading spinner with a timeout function
        // setTimeout(() => {
        //   this.userDataIsLoading$.next(false)
        // }, 1500);
      }
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('Inside UserPostingTable, ngOnChanges');
    // Cancel existing postService subscriptions to make a new one
    this.postService.cancelSubscriptions();
    console.log(JSON.stringify(changes));

    // Build the PostQuery for this User, adding the fields we want to search by
    // Since we want all results, we want to make both type and state null by default, returning all types and all locations
    const postQuery = new PostQuery(null, null, null);

    postQuery.uid = this.authService.fullUserInfo.uid;
    this.postService.fetchJobPostingsWithMetadataByPostQuery(postQuery);
  }

  postStatusSelected(statusSelected: string) {
    console.log('Inside UserPostingTable, postStatusSelected');
    // Cancel existing postService subscriptions to make a new one
    this.postService.cancelSubscriptions();

    this.buttonSelected = statusSelected;
    console.log('statusSelected: ' + statusSelected + ' buttonSelected: ' + this.buttonSelected);

    // TODO: Clean this up so it runs efficiently.
    // Build the PostQuery for this User, adding the fields we want to search by
    // Since we want all results, we want to make both type and state null by default, returning all types and all locations
    const postQuery = new PostQuery(null, null, null);

    postQuery.uid = this.authService.fullUserInfo.uid;
    ;
    if (statusSelected === 'All') {
      postQuery.status = null;
    } else {
      postQuery.status = statusSelected;
    }

    console.log('postStatusSelected, postQuery: ', postQuery);

    this.postService.fetchJobPostingsWithMetadataByPostQuery(postQuery);
  }

  highlight(row) {
    console.log(row);
    this.selectedRowIndex = row.id;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  userEditPostButtonClick(postIdClicked: string, postStatusClicked: string, postJobTypeClicked: string) {
    console.log('userPostButtonClick, value: ' + postIdClicked + ' postStatusClicked: ' + postStatusClicked + ' postJobTypeClicked: ' +
      postJobTypeClicked);
    this.router.navigate(['/add/', {postId: postIdClicked, postStatus: postStatusClicked, postJobType: postJobTypeClicked}]);
  }

  async userUpgradePost(postIdClicked: string, postJobIdClicked: string) {
    console.log('userUpgradePost, postId: ' + postIdClicked, ' postJobIdClicked: ', postJobIdClicked);

    const confirmationReturnValue = await this.postUpgradeService
      .postUpgradeDialog(postIdClicked, postJobIdClicked)
      .catch((res => {
        console.log('userUpgradePost reject result: ' + res);
        console.log(res);
      }));

    console.log('userUpgradePost Confirmation Return Value: ' + confirmationReturnValue);

    if (confirmationReturnValue === true) {
      console.log('userUpgradePost, confirm true');
      // TODO: Do payment here
    }

  }

  userFreePriorityAddButtonClick(postIdClicked: string, postPriority: number) {
    console.log('userFreePriorityAddButtonClick, postId: ' + postIdClicked);
    const numRemaining = this.authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsRemaining;
    if (numRemaining > 0 && postPriority === 1) {
      this.postService.addFreePriorityToPost(
        postIdClicked,
        this.authService.fullUserInfo.uid,
        this.authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsRemaining);
    } else {
      // Do nothing, there are not enough remaining priority posts left to add for free
    }
  }

  userFreePriorityRemoveButtonClick(postIdClicked: string, postPriority: number) {
    console.log('userFreePriorityRemoveButtonClick, postId: ' + postIdClicked);
    const numRemaining = this.authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsRemaining;
    const numMaxFreePosts = this.authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsTotal;
    if (postPriority === 2 && numRemaining < numMaxFreePosts) {
      this.postService.removeFreePriorityFromPost(
        postIdClicked,
        this.authService.fullUserInfo.uid,
        this.authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsRemaining);
    } else {
      this.uiService.showSnackbar('You have reached your free priority posting limit, you can remove a free priority upgrade ' + '' +
        'from another post or upgrade your plan to add more!', null, 8000);
      // Do nothing, the post type that was clicked to remove wasn't priority 2!
    }
  }

  userPostDeactivateClick(postIdClicked: string) {
    console.log('userPostDeactivateClick, value: ' + postIdClicked);
    this.postService.deactivatePost(postIdClicked);
  }

  userPostReactivateClick(postIdClicked: string) {
    console.log('userPostReactivateClick, value: ' + postIdClicked);
    this.postService.reactivatePost(postIdClicked);
  }

  userPostDeleteTemplateOrSavedClick(postIdClicked: string) {
    console.log('userPostDeleteTemplateOrSavedClick, value: ' + postIdClicked);
    this.postService.deletePost(postIdClicked);
  }

  async userPostDeleteInactiveClick(postIdClicked: string, postShortDesc: string) {
    const confirmationReturnValue = await this.uiService
      .confirmationDialog('Are you sure you want delete post: '
        + postShortDesc + '?', 'Post: ' + postShortDesc + ' deleted permanently!')
      .catch((res => {
        console.log('userPostDeleteInactiveClick reject result: ' + res);
        console.log(res);
      }));

    console.log('userPostDeleteInactiveClick Confirmation Return Value: ' + confirmationReturnValue);

    if (confirmationReturnValue === true) {
      console.log('userPostDeleteInactiveClick, value: ' + postIdClicked);
      this.postService.deletePost(postIdClicked);
    }
  }

  // This function will act on the button click to call post editing and add 30 days from today until expiration
  add30DaysUntilExpiration(postIdClicked: string) {
    console.log('add30DaysUntilExpiration for postIdClicked: ', postIdClicked);
    this.postService.addExpirationDaysToPost(postIdClicked, 30);
  }

  checkPriorityClass(postPriority: number, postStatus: string){
    // First check for postStatus
    if (postStatus === 'Expired') {
      return 'expired-post-row';
      // if Status is not Expired, set colors and font otherwise!
    } else if (postPriority === 3) {
      return 'major-highlight';
    } else if (postPriority === 2) {
      return 'highlight';
    } else {
      return 'standard-post-row';
    }
  }

  getPostPriorityName(postPriority: number) {
    if (postPriority === 3) {
      return 'High Priority';
    } else if (postPriority === 2) {
      return 'Priority';
    } else {
      return 'Standard';
    }
  }

  getPostJobTypeName(jobType: string) {
    // console.log('userPostingTable, getPostJobTypeName: ' + jobType);
    if (jobType === 'Anesthesiologist') {
      return 'MD/DO';
    } else {
      return jobType;
    }
  }

  ngOnDestroy() {
    if (this.jobPostsSubscription) {
      this.jobPostsSubscription.unsubscribe();
    }
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
    if (this.loadingSubs) {
      this.loadingSubs.unsubscribe();
    }
  }

}
