import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { BreakpointState} from '@angular/cdk/layout';
import { PostService } from '../../../Services/post.service';
import { JobPosting } from '../../../Models/post.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UIService } from '../../../Services/ui.service';
import { Subscription } from 'rxjs';
import {PostQuery} from '../../../Models/postQuery.model';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-job-postings-table',
  templateUrl: './job-postings-table.component.html',
  styleUrls: ['./job-postings-table.component.scss'],
})

export class JobPostingsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns = [
  ];


  displayedColumnsLargeScreen = [
    'city',
    'state',
    'shortDescription',
    'employmentStatus',
    'minSalary',
    'maxSalary',
    'companyName',
    'datePosted'
  ]

  displayedColumnsWithJobTypeLargeScreen = [

    'city',
    'state',
    'jobType',
    'shortDescription',
    'employmentStatus',
    'minSalary',
    'maxSalary',
    'companyName',
    'datePosted'
  ];

  displayedColumnsSmallScreen = [

    // 'city',
    'state',
    'shortDescription',
    'employmentStatus',
    'minSalary',
    'maxSalary',
    'companyName',
    'datePosted'
  ]

  displayedColumnsWithJobTypeSmallScreen = [

    // 'city',
    'state',
    'jobType',
    'shortDescription',
    'employmentStatus',
    'minSalary',
    'maxSalary',
    'companyName',
    'datePosted'
  ];


  dataSource = new MatTableDataSource<JobPosting>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isLoading = false;
  private loadingSubs: Subscription;
  isSmallScreen = false;
  postDialogHeight: string;
  postDialogMaxWidth: string;
  postDialogWidth: string;
  filterJobType: string;
  filterJobState: string;
  filterJobCompany: string;
  jobPostsSubscription: Subscription;
  breakpointSubscription: Subscription;
  selectedRowIndex = -1;
  returnedJobsCount: number;
  returnedNoJobsBoolean: boolean;
  showJobTypeColumn = false;


  constructor(public postDialog: MatDialog,
              private postService: PostService,
              private uiService: UIService,
              private titleService: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              ) { }

  ngOnInit() {
    // Set default title
    this.titleService.setTitle('Anesthesia Job Postings | BagMask.com');
    this.jobPostsSubscription = this.postService.finishedJobsChanged.subscribe(
      (jobPosts: JobPosting[]) => {
        this.dataSource.data = jobPosts;
        console.log('jobpostingstable ngoninit, count: ' + this.dataSource.data.length);
        this.returnedJobsCount = this.dataSource.data.length;
        if (this.returnedJobsCount > 0) {
          this.returnedNoJobsBoolean = true;
        } else {
          this.returnedNoJobsBoolean = false;
        }
      }
    );

    this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
        this.isLoading = isLoading;
      }
    );

    this.activatedRoute.params.forEach((params: Params) => {
      console.log('activatedRoutes forEach for param: ', params);

      const postQuery = new PostQuery('ActiveExpired', params.type, params.state, params.companyName);

      // Set page title for search based on what job type was selected:
      if (params.type) {
        this.titleService.setTitle(params.type + ' Job Search | BagMask.com');
      }


      // Old working single-status query below
      // const postQuery = new PostQuery('Active', params.type, params.state, params.companyName);
      this.filterJobType = postQuery.type;
      this.filterJobState = postQuery.state;
      this.filterJobCompany = postQuery.companyName;



      // Check if we have a postQuery.type (job type) of "Pain" or "Leadership" and update the columns accordingly
      console.log('Do we have a pain or leadership job type: ' + this.filterJobType);
      if (this.filterJobType === 'Pain' || this.filterJobType === 'Leadership') {
        this.showJobTypeColumn = true;
      }

      // Check if we have a companyName, if so then set the appropriate list of fields we will be displaying
      // The HTML section also will only display the field if companyName exists.
      // BOTH MUST BE TRUE for this to work, there needs to be displayedColumns and an existing html field!
      // Example of companyName link: http://localhost:4200/search;companyName=CompHealth
      console.log('Do we have filterJobCompany: ' + this.filterJobCompany);
      if (this.filterJobCompany) {
        this.showJobTypeColumn = true;
      }

      console.log('current status of displayed columns: ', this.displayedColumns);

      console.log('converted to postQuery, sending to fetchJobPostingsWithMetadataByPostQuery: ', postQuery);
      this.postService.fetchJobPostingsWithMetadataByPostQuery(postQuery);

    });

    this.breakpointSubscription =
    this.uiService.breakpointObserver
      .observe(['(min-width: 500px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          console.log('Viewport is 500px or over!');
          this.isSmallScreen = false;
          if (this.showJobTypeColumn) {
            this.displayedColumns = this.displayedColumnsWithJobTypeLargeScreen
          } else {
            this.displayedColumns = this.displayedColumnsLargeScreen
          }
          this.postDialogHeight = '95%';
          this.postDialogMaxWidth = '95%';
          this.postDialogWidth = '95%';

        } else {
          console.log('Viewport is getting smaller!');
          this.isSmallScreen = true;
          if (this.showJobTypeColumn) {
            this.displayedColumns = this.displayedColumnsWithJobTypeSmallScreen
          } else {
            this.displayedColumns = this.displayedColumnsSmallScreen
          }
          this.postDialogHeight = '100%';
          this.postDialogMaxWidth = '100%';
          this.postDialogWidth = '100%';
        }
      });
  }

  setDisplayedColumnsWithJobType() {
    console.log('setDisplayedColumnsWithJobType');
    this.showJobTypeColumn = true;

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // This code accurately applies the fix for better sorting, but also accounts for specific number or date fields if need be
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'minSalary':
          if (item.minSalary) {
            return Number(item.minSalary);
          } else if (item.minHourly) {
            return Number(item.minHourly)
          }
        case 'maxSalary':
          if (item.maxSalary) {
            return Number(item.maxSalary);
          } else if (item.maxHourly) {
            return Number(item.maxHourly)
          }
        default: return item[property];
      }
    };
  }

  openDialog(row: JobPosting): void {
    console.log('openDialog clicked for row: ', row);

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/post', row.id])
    );
    // this.router.navigate(['/post', row.id]);
    window.open(url, '_blank');

    // const postDialogRef = this.postDialog.open(ViewPostDialogComponent, {
    //   height: this.postDialogHeight,
    //   maxWidth: this.postDialogMaxWidth,
    //   width: this.postDialogWidth,
    //   panelClass: 'bagmask-dialog-container',
    //   closeOnNavigation: true,
    //   data: {job: row}
    // });
    // history.pushState(null, 'Job Posting', '/jobPosting');
    // // history.pushState({ foo: "bar" }, "Image", "/currentpage#");

    // postDialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }

  highlight(row) {
    console.log(row);
    this.selectedRowIndex = row.id;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getMinimumSalaryOrHourlyValue(record: JobPosting){
    let valueToReturn: any;
    if (record.minHourly) {
      valueToReturn = '$' + record.minHourly + '/hr';
    } else if (record.minSalary) {
      valueToReturn = '$' + record.minSalary + 'k';
    } else {
      valueToReturn = 'C.E';
    }
    return valueToReturn;
  }

  getMaximumSalaryOrHourlyValue(record: JobPosting){
    let valueToReturn: any;
    if (record.maxHourly) {
      valueToReturn = '$' + record.maxHourly + '/hr';
    } else if (record.maxSalary) {
      valueToReturn = '$' + record.maxSalary + 'k';
    } else {
      valueToReturn = 'C.E';
    }
    return valueToReturn;
  }

  checkRowTooltip(job) {
    if (job.postStatus === 'Expired') {
      return 'Post Expired';
    }
  }

  checkSalaryTooltip(salaryValue) {
    let tooltipToReturn: any;
    if (salaryValue > 0) {
      tooltipToReturn = null;
    } else {
      tooltipToReturn = 'Contact Employer';
    }
    return tooltipToReturn;
  }



  checkTaxStatus(taxStatusValue) {
    let taxStatusToReturn: any;
    if (taxStatusValue === 'Contact Employer') {
      taxStatusToReturn = 'C.E.';
    } else {
      taxStatusToReturn = taxStatusValue;
    }
    return taxStatusToReturn;
  }

  checkTaxStatusTooltip(taxStatusValue) {
    let tooltipToReturn: any;
    if (taxStatusValue === 'Contact Employer') {
      tooltipToReturn = 'Contact Employer';
    } else {
      tooltipToReturn = null;
    }
    return tooltipToReturn;
  }

  // getJobTypeShortName(jobType: string) {
  //   if (jobType === 'Anesthesiologist') {
  //     return 'Anest.';
  //   } else {
  //     return jobType;
  //   }
  // }

  getPostJobTypeName(jobType: string) {
    if (jobType === 'Anesthesiologist') {
      return 'MD/DO';
    } else {
      return jobType;
    }
  }

  checkPriorityClass(postPriority: number, postStatus: string){
    // First check for postStatus
    if (postStatus === 'Expired') {
      return 'expired-post-row';
    // if Status is not Expired, set colors and font otherwise!
    } else if (postPriority === 3) {
      return 'major-highlight';
    } else if (postPriority === 2) {
      return 'highlight';
    } else {
      return 'standard-post-row';
    }

  }



  ngOnDestroy() {
    this.postService.cancelSubscriptions();
    if (this.jobPostsSubscription) {
      this.jobPostsSubscription.unsubscribe();
    }
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
    if (this.loadingSubs) {
      this.loadingSubs.unsubscribe();
    }
  }
}
