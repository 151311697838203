import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobPostingsTableComponent} from './Post/PostingTable/job-postings-table/job-postings-table.component';
import { HomeComponent } from './Home/home.component';
import { AuthGuard } from './Services/auth.guard';
import { AddPostComponent } from './Post/add-post/add-post.component';
import {UserProfileComponent} from './User/user-profile/user-profile.component';
import {SinglePostComponent} from './Post/single-post/single-post.component';
import {SupportComponent} from './Navigation/support/support.component';
import {UserAgreementComponent} from './Navigation/user-agreement/user-agreement.component';
import {PrivacyPolicyComponent} from './Navigation/privacy-policy/privacy-policy.component';
import {UserJobPostingsComponent} from './User/user-job-postings/user-job-postings.component';
import {CompanyProfileComponent} from './company/company-profile/company-profile.component';
import {StripeSubscriptionComponent} from './payments/stripe-subscription/stripe-subscription.component';
import { Pricing2020Component } from './pricing/pricing2020/pricing2020.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'home', pathMatch: 'full', component: HomeComponent},
  { path: 'home/:sender', pathMatch: 'full', component: HomeComponent},
  { path: 'search', component: JobPostingsTableComponent},
  { path: 'add', component: AddPostComponent},
  { path: 'user/:section', component: UserProfileComponent},
  { path: 'userJobPostings', component: UserJobPostingsComponent},
  { path: 'pricing', component: Pricing2020Component},
  { path: 'support', component: SupportComponent},
  { path: 'support/:supportQuestion', component: SupportComponent},
  { path: 'userAgreement', component: UserAgreementComponent},
  { path: 'privacyPolicy', component: PrivacyPolicyComponent},
  { path: 'post/:postID', component: SinglePostComponent},
  { path: 'expired', redirectTo: '/home', pathMatch: 'full' },

  // Test/Dev Section
  { path: 'company/profile', component: CompanyProfileComponent},
  { path: 'StripeSub', component: StripeSubscriptionComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ],
  providers: [ AuthGuard ],

})
export class AppRoutingModule {}
