<mat-toolbar class="bagmask-sub-toolbar toolbar-smaller-font mat-elevation-z3" fxLayout="column" fxFlexAlign="center center">
  <!--<p class="cursor-pointer" [routerLink]="['/user', 'profile']" fxFlex></p>-->
  <p class="sub-toolbar-header">The Premier Anesthesia Career Site</p>
</mat-toolbar>

<div class="home-container mat-typography" fxLayout="row" fxLayout.lt-md="column">
  <div fxFlex="20"></div>
  <div fxFlex="60" class="bagmask-home-search-type-row safari-fix" fxLayout="column" fxLayout.lt-sm="column" fxLayoutAlign="center center">

    <!-- <div fxLayout='row' fxLayoutAlign='center center' class="job-search-text mat-typography">
      Search a State
    </div> -->

    <div class="state-search-container" fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field>
        <mat-select [(value)]="stateSelected" #state placeholder='State to Search'>
          <mat-option value="All">All</mat-option>
          <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">{{state.stateName}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <a href="#"><mat-icon>search</mat-icon></a> -->
    </div>

    <div fxLayout='row' fxLayoutAlign='center center' class="job-search-text short-vertical-padding mat-typography">
      Click a Job Type to start your Search!
    </div>

    <div class="job-type-button-container" fxLayout="row wrap" fxLayoutAlign="center center">

      <button fxFlex="0 1 187px" class="job-type-button color-primary" mat-button mat-raised-button color="primary"
              (click)="onSearchClick('Anesthesiologist', state.value)">
              Anesthesiologist
      </button>

      <button fxFlex="0 1 187px" class="job-type-button color-primary" mat-button mat-raised-button color="primary"
              (click)="onSearchClick('CRNA', state.value)">
              CRNA
      </button>

      <button fxFlex="0 1 187px" class="job-type-button color-primary" mat-button mat-raised-button color="primary"
              (click)="onSearchClick('AA', state.value)">
              CAA
      </button>
    </div>

    <div class="job-type-button-container" fxLayout="row wrap" fxLayoutAlign="center center">

      <button fxFlex="0 1 187px" class="job-type-button color-primary" mat-button mat-raised-button color="primary"
              (click)="onSearchClick('Leadership', state.value)">
              Leadership Positions
      </button>

      <button fxFlex="0 1 187px" class="job-type-button color-primary" mat-button mat-raised-button color="primary"
              (click)="onSearchClick('Pain', state.value)">
              Pain Management
      </button>
    </div>

    <div class="state-search-container" fxLayout="column" fxLayoutAlign="center center">

      <!--<button mat-button mat-raised-button class="home-search-big-button" color="primary"-->
      <!--        (click)="testLocalStorageSet()">TEST LS SET</button>-->
      <!--<button mat-button mat-raised-button class="home-search-big-button" color="primary"-->
      <!--        (click)="testLocalStorageGet()">TEST LS GET</button>-->
      <!--<button mat-button mat-raised-button class="home-search-big-button" color="primary"-->
              <!--(click)="testLocalStorageFlush()">TEST LS FLUSH</button>-->
      <!--<button mat-button mat-raised-button class="home-search-big-button" color="primary"-->
      <!--        (click)="testLocalStorageHiPris()">TEST LS HiPri</button>-->
    </div>

  </div>
  <app-job-poster-dashboard fxFlex="20" fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center"
                            *ngIf="(userPosterType === 'Job Poster')"></app-job-poster-dashboard>
</div>


<div fxLayoutAlign="center center">
 <mat-divider fxFlex="90%" class="home-divider-top"></mat-divider>
</div>

<div class="home-info-container" fxLayout="row" fxLayoutAlign="space-around center">
  <!--<div class="home-info" fxFlex="40%">-->
    <!-- <div *ngIf="(userPosterType)" class="home-info" fxFlex="40%" fxFlex.lt-md="80%">
      <div (click)="woltersKluwerDiscount()" class="cursor-pointer"
          fxLayoutAlign="center center" fxLayout="row">
        <a href="https://www.linkconnector.com/ta.php?lc=155804000010006879&lc_pid=Promo-35191&url=&lcpf=3&lcpi=35191" target="_blank">
          <img class="wolters-kluwer-ad" src="https://www.linkconnector.com/traffic_record.php?lc=155804101476006879"  border="0"
              alt="25% OFF selected Anesthesiology products">
        </a>
      </div>

      <div class="info-content mat-typography">
        <p class="wolters-kluwer-text">
          We've partnered with Wolters Kluwer to bring our site members fantastic discounts on Anesthesiology Books
          and other Products!
        </p>
      </div>
    </div> -->

    <!-- <div *ngIf="(userPosterType == null)" class="home-info" fxFlex="40%" fxFlex.lt-md="80%">
      <div (click)="woltersKluwerDiscount()" class="cursor-pointer"
          fxLayoutAlign="center center" fxLayout="row">

        <a routerLink="/login">
          <img class="wolters-kluwer-ad" src="https://www.linkconnector.com/traffic_record.php?lc=155804101476006879"  border="0"
              alt="25% OFF selected Anesthesiology products">
        </a>
      </div>

      <div class="info-content mat-typography">
        <p class="wolters-kluwer-text">
          We've partnered with Wolters Kluwer to bring our site members fantastic discounts on Anesthesiology Books
          and other Products! To take advantage of this offer you must be a registered BagMask.com user.
          It's free and quick! <a routerLink="/login">Click here to Register.</a>
        </p>
      </div>
    </div> -->


  <!--</div>-->
</div>

<!--  <div *ngIf="(userPosterType)" class="home-info" fxFlex="40%">-->
<!--    <div (click)="woltersKluwerDiscount()" class="cursor-pointer"-->
<!--         fxLayoutAlign="center center" fxLayout="row">-->
<!--      &lt;!&ndash; Wolters Kluwer Book Ad &ndash;&gt;-->
<!--      <a href="https://www.linkconnector.com/ta.php?lc=155804000010006879&lc_pid=Promo-35191&url=&lcpf=3&lcpi=35191"-->
<!--         target="_blank">-->
<!--        <img class="home-page-ad" src="../../assets/Ads/WoltersKluwer/WoltersKluwerBookAdVerticalOriginal.jpg"-->
<!--             border="0"-->
<!--             alt="25% OFF selected Anesthesiology products">-->
<!--        &lt;!&ndash;<img src="../../assets/Ads/WoltersKluwer/WoltersKluwerBookAdVertical.jpg"&ndash;&gt;-->
<!--        &lt;!&ndash;     border="0" alt="25% OFF selected Anesthesiology products">&ndash;&gt;-->
<!--      </a>-->
<!--    </div>-->

<!--    <div class="info-content mat-typography">-->
<!--      <p class="wolters-kluwer-text">-->
<!--        We've partnered with Wolters Kluwer to bring our site members fantastic discounts on Anesthesiology Books-->
<!--        and other Products!-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->


<!--<div fxLayoutAlign="center center">-->
<!--  <mat-divider fxFlex="90%" class="home-divider-middle"></mat-divider>-->
<!--</div>-->



