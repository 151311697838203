<div class="mat-elevation-z0">
  <app-job-postings-table-filtering class="bagmask-sub-toolbar"
                                    [currentJobType]="filterJobType"
                                    [currentState]="filterJobState"
                                    [currentCompany]="filterJobCompany"
                                    (valueChange)='applyFilter($event)'></app-job-postings-table-filtering>
  <!-- Have to use [hidden] css class here instead of *ngIf, since it won't render the table paginator and filtering
       properly otherwise!!! -->
  <div  [hidden]="isLoading">
    <div class="no-results-section" [hidden]="returnedNoJobsBoolean">
      <mat-card class="bagmask-card-dark-blue"
                fxFlex="100%" fxLayoutAlign="center center"
                fxLayout="row wrap">
        <mat-card-title class="center-mat-card-title" fxFlex="100%">
          Job Search returned no results.  May have too strict filtering or location options
        </mat-card-title>
      </mat-card>
    </div>
  </div>
  <div [hidden]="isLoading">
    <div [hidden]="!returnedNoJobsBoolean">
    <!--<mat-table #table [dataSource]="jobPostsFetched" matSort aria-label="Elements">-->
    <mat-table class="bagmask-posting-table" #table [dataSource]="dataSource" matSort aria-label="Elements">

      <!-- City Column -->
      <ng-container *ngIf="!isSmallScreen" matColumnDef="city">

        <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">
          {{row.city}}
        </mat-cell>
      </ng-container>

      <!-- State Column -->
      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{row.state}}</mat-cell>
      </ng-container>

      <!-- Job Type Column -->
      <ng-container *ngIf="showJobTypeColumn" matColumnDef="jobType">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{getPostJobTypeName(row.jobType)}}</mat-cell>
      </ng-container>

      <!-- Short Description Column -->
      <ng-container matColumnDef="shortDescription">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Short Description</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">
          <span *ngIf="isSmallScreen">
            <a class="plain-link" [routerLink]="['/post', row.id]" target="_blank">
              <span *ngIf='row.city'>{{row.city}} - </span>
              {{row.shortDescription}}
            </a>
          </span>
          <a *ngIf="!isSmallScreen" class="plain-link link-not-clickable" [routerLink]="['/post', row.id]" target="_blank">
            {{row.shortDescription}}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Employment Status Column -->
      <ng-container matColumnDef="employmentStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Employment Status</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{row.employmentStatus}}</mat-cell>
      </ng-container>

      <!-- Minimum Salary Column -->
      <ng-container matColumnDef="minSalary">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Min. $</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{getMinimumSalaryOrHourlyValue(row)}}</mat-cell>
      </ng-container>

      <!-- Maximum Salary Column -->
      <ng-container matColumnDef="maxSalary">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Max. $</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{getMaximumSalaryOrHourlyValue(row)}}</mat-cell>
      </ng-container>

      <!-- OO Name Column -->
      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{row.companyName}}</mat-cell>
      </ng-container>

      <!-- Date Posted Column -->
      <ng-container matColumnDef="datePosted">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Posted</mat-header-cell>
        <mat-cell
          [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
          *matCellDef="let row">{{row.datePosted.toDate() | date: 'shortDate'}}
        </mat-cell>
      </ng-container>

      <!--Functionality below enables highlighting based on priority-->
      <mat-header-row class="jobs-table-header-row ie-mat-row-fix"
                      *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row class="cursor-pointer"
        *matRowDef="let row; columns: displayedColumns;"
        (click)="openDialog(row)"
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)">
      </mat-row>

      <!-- Below is old way of bringing dialog box up for job view -->
      <!-- <mat-row class="cursor-pointer"
        *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row)"
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)">
      </mat-row> -->
      <!--TODO: Test matTooltip fix-->
      <!--<mat-row class="cursor-pointer"-->
      <!--         *matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row)"-->
      <!--         [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"-->
      <!--         matTooltip="{{checkRowTooltip(row)}}"-->
      <!--&gt;-->
      <!--</mat-row>-->

    </mat-table>

    <div class="bagmask-jobs-paginator">

      <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="25"
        [pageSizeOptions]="[25, 50, 100, 250]">
      </mat-paginator>
    </div>

  </div>
  </div>
</div>



