import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('Inside auth.guard, canActivate, isAuth value: ' + this.authService.isAuth());
    if (this.authService.isAuth()) {
      // console.log('Inside auth.guard, canActivate, isAuth is true')
      return true;
    } else  {
      this.router.navigate(['/login']);
    }
    return;

  }
}
