<html lang="en">
<head>
  <meta charset="UTF-8" />
  <title>Document</title>
  <link rel="stylesheet" href="//maxcdn.bootstrapcdn.com/bootstrap/3.2.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.css">
</head>
<body>
<section class="container" *ngIf="stripePortalLoading" fxLayout="column" fxLayoutAlign="center center">
  <div class="mat-typography font-larger">
    Accessing your secure Stripe Payment Portal...
  </div>

  <app-loading-spinner></app-loading-spinner>
</section>

<!-- <app-loading-spinner></app-loading-spinner> -->
<section *ngIf="!stripePortalLoading" class="container">
  <div class="row white">
    <div class="block" fxLayout="center center">

      <div fxFlex class="col-xs-12 col-sm-6 col-md-3">
        <ul class="pricing p-green">
          <li class="pricing-title">
            <h3>Free Account</h3>
          </li>
          <li class="cell-header"><b>Job Postings</b>
            <br><a routerLink="/support/priority" target="_blank">
              <span class="small-link-text">View Priority Information</span>
            </a>
          </li>
          <li>Standard Postings: Free</li>
          <li>Single Priority Upgrade: $199</li>
          <li>Single High Priority Upgrade: $399</li>

          <li>
            <button (click)="goToNewPosting()">Start Posting</button>
          </li>
        </ul>
      </div>

      <div fxFlex class="col-xs-12 col-sm-6 col-md-3">
        <ul class="pricing p-sil">
          <li class="pricing-title">
            <h3>Silver</h3>
          </li>
          <li class="cell-header"><b>Job Postings</b>
            <br><a routerLink="/support/priority" target="_blank">
              <span class="small-link-text">View Priority Information</span>
            </a>
          </li>
          <li>Standard Postings: Free</li>
          <li>Single Priority Upgrade: $199</li>
          <li>Single High Priority Upgrade: $399</li>
          <li class="cell-header"><b>Included Priority Postings</b></li>
          <li>3 Free Priority Postings</li>
          <li class="cell-header"><b>Post Importing Options</b></li>
          <li>Live Data Feed Import</li>
          <li>Regular CSV Imports</li>
          <li>
            <h3>$149</h3>
            <span>per month</span>
          </li>
          <li>
            <!-- <button (click)="this.stripeBillingHandler('price_1HVLo1CfLG9nGFpofBpZhCRM')">Join Now STRIPESUB</button> -->
            <!-- <button (click)="this.stripeBillingHandler(stripeBagmaskService.getBagMaskStripePlanPrices('Silver'))">Join Now</button> -->
            <button (click)="stripeSubscriptionPlan('Silver')">Join Now</button>
            <!--<button (click)="goToUser()">Join Now</button>-->
            <!-- <app-make-payment [paymentAmount]="14900"
                              [paymentPrompt]="'Join Now'"
                              [paymentButtonColor]="'#ad73b7'">
            </app-make-payment> -->
          </li>
        </ul>
      </div>

      <div fxFlex class="col-xs-12 col-sm-6 col-md-3">
        <ul class="pricing p-yel">
          <li class="pricing-title">
            <h3>Gold</h3>
          </li>
          <li class="cell-header"><b>Job Postings</b>
            <br><span class="small-text">25% Discount on additional priority upgrades!</span>
            <br><a routerLink="/support/priority" target="_blank">
              <span class="small-link-text">View Priority Information</span><br>
            </a>
          </li>
          <li>Standard Postings: Free</li>
          <li>Single Priority Upgrade: $149</li>
          <li>Single High Priority Upgrade: $299</li>
          <li class="cell-header"><b>Included Priority Postings</b></li>
          <li>12 Free Priority Postings</li>
          <li class="cell-header"><b>Post Importing Options</b></li>
          <li>Live Data Feed Import</li>
          <li>Regular CSV Imports</li>
          <li>
            <h3>$399</h3>
            <span>per month</span>
          </li>
          <li>
            <!-- <button (click)="this.stripeBillingHandler(stripeBagmaskService.getBagMaskStripePlanPrices('Gold'))">Join Now</button> -->
            <button (click)="stripeSubscriptionPlan('Gold')">Join Now</button>
            <!--<button (click)="goToUser()">Join Now</button>-->
            <!-- <app-make-payment [paymentAmount]="39900"
                              [paymentPrompt]="'Join Now'"
                              [paymentButtonColor]="'#b38430'">
            </app-make-payment> -->
          </li>
        </ul>
      </div>

      <div fxFlex class="col-xs-12 col-sm-6 col-md-3">
        <ul class="pricing p-blue">
          <li class="pricing-title">

            <h3>Platinum</h3>
          </li>
          <li class="cell-header"><b>Job Postings</b>
            <br><span class="small-text">50% Discount on additional priority upgrades!</span>
            <br><a routerLink="/support/priority" target="_blank">
              <span class="small-link-text">View Priority Information</span>
            </a>
          </li>
          <li>Standard Postings: Free</li>
          <li>Single Priority Upgrade: $99</li>
          <li>Single High Priority Upgrade: $199</li>
          <li class="cell-header"><b>Included Priority Postings</b></li>
          <li>24 Free Priority Postings</li>
          <li>1 Free High Priority Posting</li>
          <li class="cell-header"><b>Post Importing Options</b></li>
          <li>Live Data Feed Import</li>
          <li>Regular CSV Imports</li>
          <li class="cell-header"><b>Monthly Post Metrics</b></li>
          <li>Detailed Monthly Metrics Report</li>
          <li>
            <h3>$599</h3>
            <span>per month</span>
          </li>
          <li>
            <!-- <button (click)="this.stripeBillingHandler(stripeBagmaskService.getBagMaskStripePlanPrices('Platinum'))">Join Now</button> -->
            <button (click)="stripeSubscriptionPlan('Platinum')">Join Now</button>
            <!--<button (click)="goToUser()">Join Now</button>-->
            <!-- <app-make-payment [paymentAmount]="59900"
                              [paymentPrompt]="'Join Now'"
                              [paymentButtonColor]="'#3f4bb8'">
            </app-make-payment> -->
          </li>
        </ul>
      </div>

    </div><!-- /block -->
  </div><!-- /row -->
</section>
<script src="//maxcdn.bootstrapcdn.com/bootstrap/3.2.0/js/bootstrap.min.js"></script>
</body>
</html>
