<form
  *ngIf="!editYesNo"
  class="add-post-form"
  [formGroup]="postFormPaymentSubmit"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-sm="space-between center"
  fxLayout="row wrap">

  <mat-card class="bagmask-card-dark-blue ie-container-fix" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <div fxLayout="column" fxFlex="100%" fxLayoutAlign="center center">
      <mat-card *ngIf="!hasPostingPlan"
                class="bagmask-card-payment-plans current-plan vertical-space-above
                cursor-pointer ie-flex-basis-fix" fxFlex="100%">
        <!--<mat-card-header>-->
        <!--<mat-card-title >Current Monthly Plan: {{postingPlan}}</mat-card-title>-->
        <!--</mat-card-header>-->
        <mat-card-content>
          <p class="plan-title">No Posting Plan Active</p>
          <a class="plain-link" [routerLink]="['/pricing']" target="_blank">
            <button class="plan-button" mat-flat-button type="button">
              Click Here to select a Monthly Posting Plan
            </button>
          </a>

          <p>Each plan offers some great features including:</p>
          <ul>
            <li>Unlimited Free Job Postings</li>
            <li>Monthly Free Priority Upgrades</li>
            <li>Excellent Discounts on Priority / High Priority Upgrades</li>
            <!-- <li class="discount-green-text">
              Silver Plan Free and Gold/Platinum 50% off for a Limited Time!
            </li> -->
          </ul>
        </mat-card-content>
      </mat-card>

      <mat-card *ngIf="hasPostingPlan"
                class="bagmask-card-payment-plans current-plan vertical-space-above ie-flex-basis-fix" fxFlex="100%">
        <!--<mat-card-header>-->
        <!--<mat-card-title >Current Monthly Plan: {{postingPlan}}</mat-card-title>-->
        <!--</mat-card-header>-->
        <mat-card-content>
          <p class="plan-title">Current Monthly Plan: {{postingPlan}}</p>

          <p>Free Priority Posts Included: {{postingPlanFreePriorityPostsTotal}}</p>
          <p>Free Priority Posts Remaining: {{postingPlanFreePriorityPostsRemaining}}</p>
          <p>Plan Discount on all further Priority and High Priority Posts: {{postingPlanPriorityDiscount}}%</p>
          <p>Plan Start Date: {{postingStartDate | date: 'shortDate'}}</p>
          <p>Plan Expiration Date: {{postingStartExpirationDate | date: 'shortDate'}}</p>
        </mat-card-content>
      </mat-card>

      <mat-card-title class="space-top">Post Priority Upgrades</mat-card-title>
      <mat-card-subtitle class="white-font">
        <ul>

          <!--<li>Costs are in addition to standard post cost.</li>-->
          <li>All Priority and High Priority Postings remain active until your job opening is filled or for one year
            from post date, whichever comes first.</li>
          <li>For more information about what benefits Priority and High Priority Listings offer for your job post,
            please check out our help section with more information about
            <a routerLink="/support/priority" target="_blank">Post Priority</a> and
            <a routerLink="/support/colors" target="_blank">Job Search Results</a>
          </li>
        </ul>
      </mat-card-subtitle>

      <mat-card fxFlex="80%" (click)="priorityClick('Priority', 2)"
                [ngClass]="{'my-class': highlightedDiv === 2}"
                class="bagmask-card-payment-plans bagmask-card-plans-animated ie-flex-basis-fix">
        <div *ngIf="!hasPostingPlan">
          <mat-card-subtitle class="white-font">
            Priority: ${{postPriCostInDollars}}
          </mat-card-subtitle>
          <!--<mat-card-subtitle class="white-font">-->
          <!--  Post Duration: 365 days, or until you fill the vacancy!-->
          <!--</mat-card-subtitle>-->
        </div>
        <div *ngIf="hasPostingPlan">
          <mat-card-subtitle class="strikediag strike-red-text">
            Priority: ${{postPriCostInDollars}}
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="postPriCostInDollarsAfterDiscount === 'Free'" class="white-font">
            Plan-Included <span class="discount-green-text">Free Priority Post</span>
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="postPriCostInDollarsAfterDiscount != 'Free'" class="white-font">
            Plan Discount Price: {{postPriCostInDollarsAfterDiscount | currency:'USD'}}
          </mat-card-subtitle>
          <!--<mat-card-subtitle class="white-font">-->
          <!--  Post Duration: 365 days, or until you fill the vacancy!-->
          <!--</mat-card-subtitle>-->
        </div>
      </mat-card>

      <mat-card fxFlex="80%" (click)="priorityClick('High Priority', 3)"
                [ngClass]="{'my-class': highlightedDiv === 3}"
                class="bagmask-card-payment-plans bagmask-card-plans-animated ie-flex-basis-fix">
        <div *ngIf="!hasPostingPlan">
          <mat-card-subtitle class="white-font">
            High Priority: ${{postHiPriCostInDollars}}
          </mat-card-subtitle>
        </div>
        <div *ngIf="hasPostingPlan">
          <mat-card-subtitle class="strikediag strike-red-text">
            High Priority: ${{postHiPriCostInDollars}}
          </mat-card-subtitle>
          <mat-card-subtitle class="white-font">
            Plan Discount Price: {{postHiPriCostInDollarsAfterDiscount | currency:'USD'}}
          </mat-card-subtitle>
          <!--<mat-card-subtitle class="white-font">-->
          <!--  365 days, or until you fill the vacancy!-->
          <!--</mat-card-subtitle>-->
        </div>
      </mat-card>

      <mat-card-title class="space-top">Post Payment Summary</mat-card-title>
      <mat-card-subtitle class="white-font">
        Base Post Cost: {{currentPostCostInDollars | currency:'USD'}}
        <!--<span class="strike-red-text strikediag">$200</span>-->
        <!--<span class="discount-green-text">$100</span>-->
      </mat-card-subtitle>
      <mat-card-subtitle class="white-font">
        Priority Additional Cost: {{currentPrioritySelectionCostInDollars | currency:'USD'}}
      </mat-card-subtitle>
      <mat-card-title>
        Total Cost: {{totalCostInDollars | currency:'USD'}}
      </mat-card-title>

      <a class="plain-link cursor-pointer" [routerLink]="['/scholarship2019']" target="_blank">
        <mat-card-subtitle class="white-font space-top">
          A portion of all job posting proceeds go to the Bagmask Scholarship
        </mat-card-subtitle>
      </a>


    </div>

    <!--<div class="space-top" fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="center center">-->
    <!--&lt;!&ndash;<app-monthly-subscription fxFlex="100%"></app-monthly-subscription>&ndash;&gt;-->
    <!--<app-make-payment [disabled]="false" [paymentAmount]="totalCostInCents" [paymentPrompt]="'Pay and Activate Post'">-->

    <!--</app-make-payment>-->
    <!--</div>-->

  </mat-card>

  <div class="add-post-vertical-space" fxFlex="100%"></div>

</form>
