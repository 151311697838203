import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SupportComponent } from './support/support.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    SupportComponent,
    UserAgreementComponent,
    PrivacyPolicyComponent,

  ],
  imports: [
    AppRoutingModule,
    SharedModule
  ],
  exports: [SupportComponent, UserAgreementComponent, PrivacyPolicyComponent],
})

export class NavigationModule {}
