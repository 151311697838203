import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiModule} from '../ui/ui.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from '../material.module';
import {PaymentsModule} from '../payments/payments.module';
import {AgmCoreModule} from '@agm/core';

@NgModule( {
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBp3UZX5Qxv3E3TBxpD2w_0c2GjHLWPMZk'
    }),
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    UiModule,
  ],
  exports: [
    AgmCoreModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    UiModule,
  ],

})

export class SharedModule {}
