import {Subject, Subscription} from 'rxjs';
import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ConfirmationDialogComponent} from '../ui/confirmation-dialog/confirmation-dialog.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {PostUpgradeDialogComponent} from '../payments/post-upgrade-dialog/post-upgrade-dialog.component';

@Injectable()
export class UIService {
  loadingStateChanged = new Subject<boolean>();

  // Breakpoint Subscription that any component can use
  breakpointSubscription: Subscription;
  isSmallScreen = false;
  private minWidthBreakpoint = 800;

  constructor(
    private snackbar: MatSnackBar,
    public breakpointObserver: BreakpointObserver,
    public dialog: MatDialog) {

    this.breakpointSubscription =
      this.breakpointObserver
        .observe(['(min-width: ' + this.minWidthBreakpoint + 'px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            console.log('In app component, ngOnInit. Viewport is ' + this.minWidthBreakpoint + ' or above.');
            this.isSmallScreen = false;
          } else {
            console.log('In app component, ngOnInit. Viewport is ' + this.minWidthBreakpoint + ' or under.');
            this.isSmallScreen = true;
          }
        });

  }

  confirmationDialog(confirmationMessage: string,
                     confirmationYesSnackbarMessage?: string,
                     disableCloseInput?: boolean): Promise<boolean> {
    console.log('confirmationDialog started');
    disableCloseInput = true;

    const promise = new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent, {
          width: '350px',
          data: confirmationMessage,
          disableClose: disableCloseInput,
        }
      );
      dialogRef.afterClosed().subscribe( result => {
        // console.log(result);
        if (result) {
          console.log('confirmationDialog yes clicked');
          // If a confirmationYesSnackbarMessage was passed in, then show it now on confirmation clicked
          if (confirmationYesSnackbarMessage) {
            this.showSnackbar(confirmationYesSnackbarMessage, null, 8000);
          }
          resolve(true);
          // returnValue = true;
        } else {
          reject(false);
          // returnValue = false;
        }
      });
      // return returnValue;
    });

    return promise;
  }

  showSnackbar(message, action, duration) {
    this.snackbar.open(message, action, {
      duration: duration
    });
  }




  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.showSnackbar('Invalid Character - only numerics valid for this field', null, 3000);
      return false;
    }
    return true;
  }

  convertArrayToString(originalArray: string[]) {
    let arrangedString: string;

    // Check for null, return null if so.
    if (originalArray === null) {
      return null;
    }

    // String has at least 1 value, populate base string with first value.
    arrangedString = originalArray[0];

    // Now with first part of string populated, determine if we have more to add, and add them with appropriate commas and spaces.
    // @ts-ignore
    if (originalArray.length > 1) {
      // @ts-ignore
      for ( let i = 1; i < originalArray.length; i++ ) {
        arrangedString = arrangedString + ', ' + originalArray[i];
      }
    }
    // console.log(arrangedString);
    return arrangedString;
  }

  randomOneBillionInt() {
    return this.randomInt(0, 999999999);
  }

  /**
   * generate a random integer between min and max
   * @param {Number} min
   * @param {Number} max
   * @return {Number} random generated integer
   */
  randomInt(min, max) {
    // @ts-ignore
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // isHTML(checkText: string) {
  //
  // };

  isHTML(str) {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  htmlToText(html) {
    // remove code brakes and tabs
    html = html.replace(/\n/g, '');
    html = html.replace(/\t/g, '');

    // keep html brakes and tabs
    html = html.replace(/<\/td>/g, '\t');
    html = html.replace(/<\/table>/g, '\n');
    html = html.replace(/<\/tr>/g, '\n');
    html = html.replace(/<\/p>/g, '\n');
    html = html.replace(/<\/div>/g, '\n');
    html = html.replace(/<\/h>/g, '\n');
    html = html.replace(/<br>/g, '\n'); html = html.replace(/<br( )*\/>/g, '\n');

    // parse html into text
    const dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent;
  }

  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  public compareDate(date1: Date, date2: Date): number
  {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  public compareTimestamps(timestamp1: number, timestamp2: number): number{
    let timestampDiff: number;
    timestampDiff = timestamp2 - timestamp1;
    return timestampDiff;
  }

  public convertMilisecondsToSeconds(miliseconds: number): number {
    return miliseconds / 1000.0;
  }

  public convertMilisecondsToMinutes(miliseconds: number): number {
    return miliseconds / 60000.0;
  }

  public convertMilisecondsToHours(miliseconds: number): number {
    return miliseconds / 3600000.0;
  }

  public convertMilisecondsToDays(miliseconds: number): number {
    return miliseconds / 86400000.0;
  }

  public getShortDateFromDate(d: Date): string {
    // Have to add 1 to month since it is stupidly zero based;
    const month = d.getMonth() + 1;
    const date = d.getDate();
    const year = d.getFullYear();

    return month + '/' + date + '/' + year;
  }

  public getShortDateFromTimestampSeconds(ts: number): string {
    // First convert the timestamp to date, then work with
    // console.log('getShortDateFromTimestampSeconds incoming timestamp');
    // console.log(ts);

    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(ts);

    // console.log(d);
    //
    // console.log('Attempting toDate');
    // console.log(d.getUTCDate());
    // console.log(d.getUTCMonth());
    // console.log(d.getUTCFullYear());

    const month = d.getUTCMonth() + 1;
    const date = d.getUTCDate();
    const year = d.getUTCFullYear();

    return month + '/' + date + '/' + year;
  }

  // Split string into array code:
  // let stringToSplit = "abc def ghi";
  // let x = stringToSplit.split(" ");
  // console.log(x[0]);
}
