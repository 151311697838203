<section class="job-poster-dashboard-loading" *ngIf="stripePortalLoading" fxLayout="column" fxLayoutAlign="center center">
  <div class="mat-typography font-larger">
    Accessing your secure Stripe Payment Portal...
  </div>

  <app-loading-spinner></app-loading-spinner>
</section>


<!-- Prospective Dashboard, vertical buttons -->
<div *ngIf="!stripePortalLoading"
      class="mat-typography job-poster-dashboard-vertical"
      fxLayoutAlign="space-around center"
      fxLayout="column">
<!-- <mat-card *ngIf="!stripePortalLoading"
          class="bagmask-card-dark-blue job-poster-dashboard-vertical" fxLayoutAlign="space-around center"
          fxLayout="column"> -->
  <mat-card-title fxFlex="100%" class="job-poster-dashboard-title">Job Poster Dashboard</mat-card-title>
  <div fxFlex="100%" fxLayoutAlign="space-around center">
    <button class="dashboard-button color-alternate-darker"
            [ngClass]="getExpiredStatus()"
            mat-button mat-raised-button color="primary" (click)="refreshJobPosts()" >
      {{refreshAllPostsMessage}}</button>
  </div>

  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" (click)="postAJob()"
          routerLink="/add">
    Post a Job</button>
  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" [routerLink]="['/user', 'jobs']">
    My Job Postings</button>

  <mat-divider class="job-poster-dashboard-divider" fxFlex="90%"></mat-divider>

  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" routerLink="/pricing">
    Pricing Info</button>
  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" routerLink="/pricing">
    Posting Plan Purchase</button>
  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" (click)="callStripeBillingPortal()">
    Billing Portal</button>

  <mat-divider class="job-poster-dashboard-divider" fxFlex="90%"></mat-divider>

  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" [routerLink]="['/user', 'profile']">
    User Profile</button>
  <button class="dashboard-button color-alternate-darker" mat-button mat-raised-button color="primary" routerLink="/support/priority">
    Priority Posts Info</button>
  <!-- Lighter button color example below -->
  <!-- <button class="dashboard-button color-alternate-darker-lighter" mat-button mat-raised-button color="accent" routerLink="/priorityInfo">
    Priority Posts Info</button> -->


<!-- </mat-card> -->
</div>

<!-- TODO: SweetAlert needs theming and implementation -->
<!--<button [swal]="['Oops!', 'This is not implemented yet :/', 'warning']">-->
<!--  Do it!-->
<!--</button>-->
<!--  <button class="dashboard-button" mat-button mat-raised-button color="accent" routerLink="/advertisingInfo">-->
<!--    Advertising</button>-->


