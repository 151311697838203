import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../Services/auth.service';
import {PostService} from '../../Services/post.service';
import {AnalyticsEventService} from '../../Services/analyticsEvent.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../Services/user.service';
import { StripeBagmaskService } from 'src/app/Services/stripeBagmask.service';

@Component({
  selector: 'app-job-poster-dashboard',
  templateUrl: './job-poster-dashboard.component.html',
  styleUrls: ['./job-poster-dashboard.component.scss']
})
export class JobPosterDashboardComponent implements OnInit {
  uid: string;
  companyName: string;
  refreshAllPostsMessage = 'Refresh All Posts';
  stripePortalLoading: boolean = false;

  constructor(public authService: AuthService,
              public postService: PostService,
              private route: ActivatedRoute,
              public analyticsEventService: AnalyticsEventService,
              private stripeBagmaskService: StripeBagmaskService,
              private userService: UserService,
              ) { }

  ngOnInit() {
    console.log('JobPosterDashboard Init');

    if (this.authService.fullUserInfo) {
      console.log('Home, populating userInfo from fullUserInfo');
      this.uid = this.authService.fullUserInfo.uid;
      this.companyName = this.authService.fullUserInfo.userCompanyName;

      console.log(this.uid);

      console.log('getExpiredStatus: ', this.getExpiredStatus());
    }

  }

  refreshJobPosts() {
    console.log('JobPosterDashboard, refreshJobPosts');
    console.log(this.uid);
    console.log(this.companyName);

    // The following line is an example of refreshing an individual uid for a company in a one-off situation
    // this.postService.refreshAllPostsForUID('YTYpFzuBr2NbvOHwL1MvHLaO2SI3');
    this.postService.refreshAllPostsForUIDandCompanyName(this.uid, this.companyName);

    // When we have a job search we want to fire a Google event to track number of searches
    this.analyticsEventService.jobPosterAction(this.companyName, 'Refresh Job Postings');
  }

  postAJob() {
    console.log('JobPosterDashboard, postAJob');
    console.log(this.uid);

    // When we have a job search we want to fire a Google event to track number of searches
    this.analyticsEventService.jobPosterAction(this.companyName, 'Post A Job');
  }

  getExpiredStatus(): string {
    // Use queryParamMap to see if we have "expiredPosts" as a query paramater.  If we do, check for a uid to determine who came to refresh
    let returnValue: string;
    let expiredPosts = this.route.snapshot.queryParamMap.get('expiredPosts');
    this.route.queryParamMap.subscribe(queryParams => {
      expiredPosts = queryParams.get("expiredPosts");
      if (expiredPosts) {
        console.log('expiredPosts: ', expiredPosts);
        // Now check for uid
        let expiredPostsUid = this.route.snapshot.queryParamMap.get('uid');
        if (expiredPostsUid) {
          console.log('We have both expiredPosts and expiredPostsUid, returning exired posts button class');
          // Return a status to make the button warning color and bigger
          returnValue = 'expired-posts-refresh-button';
        } else {
          console.log('We have an expiredPosts query without an associated uid to attribute it to');
        }
      }


    });
    return returnValue;
  }

  // Function to call the Stripe Billing Portal for User to modify their subscription
  callStripeBillingPortal() {
    this.stripePortalLoading = true;
    this.stripeBagmaskService.stripeBillingPortal();

  }

}
