import {AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {PostService} from '../../Services/post.service';
import {JobPosting} from '../../Models/post.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UIService} from '../../Services/ui.service';
import {Title} from '@angular/platform-browser';
import {AnalyticsEventService} from '../../Services/analyticsEvent.service';

@Component({
  selector: 'app-single-post',
  templateUrl: './single-post.component.html',
  styleUrls: ['./single-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SinglePostComponent implements OnInit, OnDestroy {
  @Input() singlePostID: string;
  isLoading = true;
  loading: boolean;
  hasAddressCityZip = false;
  mapPositionDefined: boolean;

  jobPostSubscription: Subscription;
  dataSource: JobPosting;
  postReturned: boolean;
  postPriorityString: string;
  datePostedConverted: any;
  displayShareToolbar = false;
  companyFirstLetter: string;
  shrinkHeader = false;

  // Section values code
  salSection1HasValues = true;
  salSection2HasValues = true;
  salSection3HasValues = true;
  salSectionsHaveValues = false;

  resSection1HasValues = true;
  resSection2HasValues = true;
  resSection3HasValues = true;
  resSection4HasValues = true;
  resSection5HasValues = true;
  resSection6HasValues = true;
  resSection7HasValues = true;
  resSectionsHaveValues = false;

  facSection1HasValues = true;
  facSection2HasValues = true;
  facSection3HasValues = true;
  facSectionsHaveValues = false;

  emailStringForJob: string;
  emailStringForSharingJob: string;

  eventLeadWithLink = '(LEAD): Apply Click, Sent To Link';
  eventLeadNoLink = '(LEAD): Apply Click, No Link/Email';
  eventLeadViaEmail = '(LEAD): Email Click';

  /*
    Set up a HostListener for firing scrolling events.
    We will use this to shrink the size of the header bar
  */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
      // do tracking
    // console.log('scrolled', event.target.scrollTop);
    // Listen to click events in the component
    let tracker = event.target;
    let limit = tracker.scrollHeight - tracker.clientHeight;

    // Check if we scroll past 100 and shrink bar otherwise keep big
    if (window.scrollY > 101) {
      this.shrinkHeader = true;
    } else {
      this.shrinkHeader = false;
    }
    /* Code below shows how to get current scroll point and client height
    console.log(window.scrollY, document.documentElement.clientHeight);
    */
    // if (event.target.scrollTop === limit) {
    //   alert('end reached');
    // }
  }

  // onScroll(event) {
  //   // do tracking
  //   // console.log('scrolled', event.target.scrollTop);
  //   // Listen to click events in the component
  //   let tracker = event.target;

  //   let limit = tracker.scrollHeight - tracker.clientHeight;
  //   console.log(event.target.scrollTop, limit);
  //   // if (event.target.scrollTop === limit) {
  //   //   alert('end reached');
  //   // }
  // }

  constructor(private analyticsEventService: AnalyticsEventService,
              private postService: PostService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: Title,
              public uiService: UIService) {
    this.route.params.subscribe( params => {
      console.log('Inside single post component, passed params: ' + JSON.stringify(params));
      this.singlePostID = params['postID'];
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Anesthesia Job Posting | BagMask.com');
    console.log('inside single-post, ngOnInit, preparing to get singlejobposting');

    // Code to get:
    // this.router.navigate(['article', articleID]);

    // this.postService.getSingleJobPosting('8IdYQA4msHYMtkxJ75J6');
    this.postService.getSingleJobPosting(this.singlePostID);

    this.jobPostSubscription = this.postService.finishedSinglePostChanged.subscribe(
      (jobPost: JobPosting) => {
        this.dataSource = jobPost;
        this.dataSource.id = this.singlePostID;
        this.analyticsEventService.jobPostAction(this.dataSource, 'View');
        console.log('single post ngoninit, subscription started, returned company name: ' + this.dataSource.companyName);
        if (jobPost) {
          this.postReturned = true;
          // Set isLoading to false and show page
          this.isLoading = false;
        } else {
          this.postReturned = false;
        }

        switch (this.dataSource.postPriority) {
          case 1: {
            console.log('Standard');
            // TODO: Set back to ''
            this.postPriorityString = 'Standard';
            break;
          }
          case 2: {
            console.log('Priority');
            this.postPriorityString = 'Priority';
            break;
          }
          case 3: {
            console.log('High Priority');
            this.postPriorityString = 'High Priority';
            break;
          }
        }

        if (jobPost.datePosted) {
          console.log('has date posted, converting');
          this.datePostedConverted = jobPost.datePosted;
        }

        if (jobPost.position === undefined) {
          this.mapPositionDefined = false;
        } else {
          this.mapPositionDefined = true;
        }

        if (jobPost.address ||
          jobPost.city ||
          jobPost.zipCode > 0 ) {
          console.log('Address, city, zipcode have a value, check for mapPositionDefined next');
          if (this.mapPositionDefined) {
            this.hasAddressCityZip = true;
          }
        }

        this.checkSalValues();
        this.checkResValues();
        this.checkFacValues();

        // First determine if job has company portal for email string.
        console.log('Does job have company portal?: ' + this.dataSource.companyPortal);
        console.log('Does job have company id??: ' + this.dataSource.companyJobID);
        if (this.dataSource.companyPortal) {
          // Company Portal present, standard email
          this.emailStringForJob =
            `mailto:${this.dataSource.contactEmail}?subject=Bagmask Jobs Posting Reply&body=BagMask.com Job Link: https://jobs.BagMask.com/post/${this.singlePostID}`;
        } else {
          // Company portal not present, use "Application" email
          this.emailStringForJob =
            `mailto:${this.dataSource.contactEmail}?subject=Bagmask Jobs Application&body=BagMask.com Job Application for the following Job Posting: https://jobs.BagMask.com/post/${this.singlePostID}`;
        }

        // Then, determine if job has Company Job ID, if it does, we want to show that in email contact
        if (this.dataSource.companyJobID) {
          // Job ID present, note it in the email
          this.emailStringForJob = this.emailStringForJob +
            ' for Job Reference ID: ' + this.dataSource.companyJobID;
        }

        // Set company first letter for image if no image present
        this.companyFirstLetter = this.dataSource.companyName.charAt(0);


        // TODO: 2/20/2020 Set up the email job new feature here
        // this.emailStringForSharingJob =

      }
    );

  }

  displayShare() {
    this.displayShareToolbar = !this.displayShareToolbar;
  }

  applyForJob(data: any, applyType: string) {
    console.log('applyForJob clicked: ' + data.companyPortal);
    window.open(data.companyPortal, '_blank');
    this.applyForJobAnalyticsEvent(data, applyType);
  }

  applyForJobAnalyticsEvent(data: any, applyType: string) {
    this.analyticsEventService.jobPostAction(data, applyType);
  }

  checkSalValues() {
    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.salSigningBonus === 0 &&
      this.dataSource.salSigningBonusCommitmentReimbursement === null &&
      this.dataSource.salTuitionReimbursement === null &&
      this.dataSource.salInterviewExpenses === null &&
      this.dataSource.salRelocationAssistance === null &&
      this.dataSource.salPaidOvertime === null &&
      this.dataSource.salWeeksPaidVacation === null &&
      this.dataSource.salPaidCMETime === null &&
      this.dataSource.salCMEAllowance === null)    {
      console.log('Salary Section 1 values all null, do not display card');
      this.salSection1HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.salSalaryIncomeBoolean === null &&
      this.dataSource.salIncomeFeeForService === null &&
      this.dataSource.salEventualPartnership === null &&
      this.dataSource.salIncomeFullPartnerPractice === null)    {
      console.log('Salary Section 2 values all null, do not display card');
      this.salSection2HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.salMedicalInsurance === null &&
      this.dataSource.salRetirementPlan === null &&
      this.dataSource.salMalpracticeInsurance === null &&
      this.dataSource.salDisabilityInsurance === null)    {
      console.log('Salary Section 3 values all null, do not display card');
      this.salSection3HasValues = false;
    }

    if (this.salSection1HasValues === true ||
      this.salSection2HasValues === true ||
      this.salSection3HasValues === true) {
      this.salSectionsHaveValues = true;
    }
  }

  checkResValues() {
    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.resScheduledCall === null &&
      this.dataSource.resHowOftenFirstCall === null &&
      this.dataSource.resDayOffAfterFirstCall === null &&
      this.dataSource.resHowOftenSecondCall === null &&
      this.dataSource.resCallType === null &&
      this.dataSource.resCallResponseTime === null) {
      console.log('Res/Req Section 1 values all null, do not display card');
      this.resSection1HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.resProviderModel === null &&
      this.dataSource.resDirectionSupervision === null &&
      this.dataSource.resCaseMix === null) {
      console.log('Res/Req Section 2 values all null, do not display card');
      this.resSection2HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.resLaborEpidurals === null &&
      this.dataSource.resSpinalAnesthesia === null &&
      this.dataSource.resRegionalBlocks === null &&
      this.dataSource.resArterialCatheter === null &&
      this.dataSource.resCentralVenousCatheter === null) {
      console.log('Res/Req Section 3 values all null, do not display card');
      this.resSection3HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.reqNewGrads === null &&
      this.dataSource.reqStateLicense === null &&
      this.dataSource.reqNBCRNACertification === null) {
      console.log('Res/Req Section 4 values all null, do not display card');
      this.resSection4HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.reqLifeSupportCard === null &&
      this.dataSource.reqABACertification === null) {
      console.log('Res/Req Section 5 values all null, do not display card');
      this.resSection5HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.reqSubspecialtyFellowship === null &&
      this.dataSource.reqVisaAccepted === null) {
      console.log('Res/Req Section 6 values all null, do not display card');
      this.resSection6HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.resShiftType === null &&
      this.dataSource.resShiftDuration === null) {
      console.log('Res/Req Section 7 values all null, do not display card');
      this.resSection7HasValues = false;
    }

    if (this.resSection1HasValues === true ||
      this.resSection2HasValues === true ||
      this.resSection3HasValues === true ||
      this.resSection4HasValues === true ||
      this.resSection5HasValues === true ||
      this.resSection6HasValues === true ||
      this.resSection7HasValues === true) {
      this.resSectionsHaveValues = true;
    }

  }

  checkFacValues() {
    // Check all values in the section and if all are null, don't display section
    if (this.dataSource.facAverageCredentialingTime === null &&
      this.dataSource.facGroupExclusiveContract === null &&
      this.dataSource.facOtherSites === null &&
      this.dataSource.facTrainingPrograms === null &&
      this.dataSource.facFederalGovJob === null) {
      console.log('Facility Section 1 values all null, do not display card');
      this.facSection1HasValues = false;
    }

    // Check all values in the section and if all are null, don't display section
    // Careful check numbers fields
    let facSection2Numbers = false;
    if (this.dataSource.facNumAnesthesiologists) {
      if (this.dataSource.facNumAnesthesiologists === 0 || this.dataSource.facNumAnesthesiologists.toString.length < 1) {
        console.log('we got here')
        facSection2Numbers = true;
      }
    }
    if (this.dataSource.facNumCRNAs) {
      if (this.dataSource.facNumCRNAs === 0 || this.dataSource.facNumCRNAs.toString.length < 1) {
        console.log('we got here')
        facSection2Numbers = true;
      }
    }
    if (this.dataSource.facNumAAs) {
      if (this.dataSource.facNumAAs === 0 || this.dataSource.facNumAAs.toString.length < 1) {
        console.log('we got here')
        facSection2Numbers = true;
      }
    }

    if (
      facSection2Numbers === false &&
      this.dataSource.facNumLicensedBeds === null &&
      this.dataSource.facNumSurgicalCasesYear === null &&
      this.dataSource.facNumDeliveriesYear === null &&
      this.dataSource.facAvgDailyCensus === null ) {
      console.log('Facility Section 2 values all null, do not display card');
      this.facSection2HasValues = false;
    } else {
      console.log('Facility Section 2 has values:');
      // console.log(this.dataSource.facNumAnesthesiologists);
      // console.log(this.dataSource.facNumCRNAs);
      // console.log(this.dataSource.facNumAAs);
      // console.log(this.dataSource.facNumLicensedBeds);
      // console.log(this.dataSource.facNumSurgicalCasesYear);
      // console.log(this.dataSource.facNumDeliveriesYear);
      // console.log(this.dataSource.facAvgDailyCensus);
    }



    if (this.facSection1HasValues === true ||
        this.facSection2HasValues === true  ){
          this.facSectionsHaveValues = true;
    }
  }

  // TODO 10/5/2021: This below is used to close current tab, sending back to earlier tab
  goToHome() {
    // this.router.navigate(['/home']);
    window.close()
  }

  // 2/17/2022
  // Certain imported companies require css for:
  //    white-space: pre-line;
  // Check if post is imported and then
  // Check if company is on list to remove it otherwise, leave it in
  sectionTextHasWhiteSpacePreLine(): string {
    let returnString = '';
    let noPreLineCompanyList = [
      'NorthStar',
      'Somnia Anesthesia',
      'Envision Physician Services',
      'Cross Country Locums',
      'Odyssey Staffing',
      'Lotus Medical Staffing'
    ]

    // console.log(this.dataSource.postEntryType);
    // Check if post is imported
    if (this.dataSource.postEntryType == 'Import') {
      // Check if post is from the list of companies that need white space removed
      if (noPreLineCompanyList.includes(this.dataSource.companyName)) {
        // If on the list return job-section-text-whitespace-no-preline
        returnString = 'job-section-text-whitespace-no-preline'
      } else {
        returnString = 'job-section-text'
      }
    } else {
      returnString = 'job-section-text'
    }

    return returnString;
  }

  ngOnDestroy() {
    if (this.jobPostSubscription) {
      this.jobPostSubscription.unsubscribe();
    }
  }

}





