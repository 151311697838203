import { JobPosting } from '../Models/post.model';
import {first, map, tap} from 'rxjs/operators';
import {Injectable, isDevMode} from '@angular/core';


import {Observable, Subject, Subscription} from 'rxjs';
import { UIService } from './ui.service';

import {PostQuery} from '../Models/postQuery.model';
import {User} from '../Models/user.model';
import {GeocodeService} from './geocode.service';
import {AuthService} from './auth.service';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, Query} from '@angular/fire/firestore';
import { FrontEndCompanyService } from './frontEndCompany.service';

@Injectable()
export class PostService {
  addPostJobTypeChanged = new Subject<string>();
  finishedJobsChanged = new Subject<JobPosting[]>();
  finishedSinglePostChanged = new Subject<JobPosting>();
  finishedJobsChangedCount = new Subject<number>();
  // Using fbSubs as an array of all Subscriptions so we can cancel them all at once at the end.
  // Remember to add new subscriptions in the service to this!
  private fbSubs: Subscription[] = [];
  private userProfileSubscription: Subscription;
  private postingDoc: AngularFirestoreDocument;
  private userDoc: AngularFirestoreDocument<User>;
  private pQuery: AngularFirestoreCollection<any>;
  jobsToReturn: Observable<any[]>;

  constructor(private afs: AngularFirestore,
              private geocodeService: GeocodeService,
              private frontEndCompanyService: FrontEndCompanyService,
              private uiService: UIService) {

  }

  addPostChangeJobType(jobType: string) {
    console.log('Inside post service, addPostChangeJobType to: ' + jobType);
    this.addPostJobTypeChanged.next(jobType);

    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this.isAuthenticated = true;
    //     this.authChange.next(true);
    //     if (isDevMode()) {
    //       this.router.navigate(['/add']);
    //     } else {
    //       this.router.navigate(['/home']);
    //     }
    //
    //   } else {
    //     this.postService.cancelSubscriptions();
    //     this.authChange.next(false);
    //     this.isAuthenticated = false;
    //   }
    // });
  }

  fetchJobPostingsWithMetadataByPostQuery(postQuery: PostQuery) {
    // For some reason, this version of the fetch does not work with the sorting, possibly look into later.
    console.log('Inside post service, fetchJobPostingsWithMetadataByPostQuery');
    console.log(postQuery);
      // + postQuery.type + ' state ' + postQuery.state);

    // Catch state value of 'All' and convert to a null, so we know not to include it in the query
    if (postQuery.state === 'All') {
      console.log('State was set to All, change to null for query purposes');
      postQuery.state = null;
    }
    // If we have a companyName, set all other fields to null and return all jobs for said company
    if (postQuery.companyName) {
      postQuery.type = null;
      postQuery.state = null;
    }
    this.uiService.loadingStateChanged.next(true);
    this.fbSubs.push(
      this.afs
      // .collection('posts')
        .collection('posts', ref => {
          // ref.orderBy('postPriority','desc');
          let query: CollectionReference | Query = ref;
          // The following command will test for if type exists in the params, and if it does, add the correct query term
          // if (params['type']) { console.log('type exists') };
          if (postQuery.status) {
            console.log('status exists, adding to query:' + postQuery.status);
            if (postQuery.status === 'ActiveExpired') {
              console.log('ActiveExpired status detected, pulling back posts either Active or Expired');
              query = query.where('postStatus', 'in', ['Active', 'Expired']);
            } else {
              console.log('Single postStatus status detected, pulling back posts of type: ', postQuery.status);
              query = query.where('postStatus', '==', postQuery.status);
            }

          }
          if (postQuery.uid) {
            console.log('uid exists, adding to query');
            query = query.where('uid', '==', postQuery.uid);
          }
          if (postQuery.companyName) {
            console.log('companyName exists, adding to query');
            query = query.where('companyName', '==', postQuery.companyName);
          }
          if (postQuery.type) {
            // Catch Leadership or Pain here, return ALL types that have that particular flag set
            console.log('Type exists, adding to query');
            if (postQuery.type === 'Leadership') {
              query = query.where('jobTypeLeadership', '==', true)
            } else if (postQuery.type === 'Pain') {
              query = query.where('jobTypePain', '==', true)
            } else {
              query = query.where('jobType', '==', postQuery.type);
            }

          }
          if (postQuery.state) {
            console.log('State exists, adding to query');
            query = query.where('state', '==', postQuery.state);
          }
          if (postQuery.minSalary) {
            console.log('minSalary exists, adding to query:' + postQuery.minSalary + ' ' + postQuery._minSalary);
            console.log('minSalary typeof:' + typeof postQuery.minSalary + ' ' + typeof postQuery._minSalary);
            query = query.where('minSalary', '>=', Number(postQuery.minSalary));
            // query = query.where('minSalary', '>=', postQuery.minSalary);
          }
          if (postQuery.employmentStatus) {
            console.log('employmentStatus exists, adding to query');
            query = query.where('employmentStatus', '==', postQuery.employmentStatus);
          }
          if (postQuery.signingBonus) {
            console.log('signingBonus exists, adding to query');
            query = query.where('salSigningBonusBoolean', '==', true);
          }
          if (postQuery.tuitionReimbursement) {
            console.log('tuitionReimbursement exists, adding to query');
            query = query.where('salTuitionReimbursementBoolean', '==', true);
          }
          if (postQuery.eventualPartnership) {
            console.log('signingBonus exists, adding to query');
            query = query.where('salEventualPartnershipBoolean', '==', true);
          }

          console.log('Inside post service, fetchJobPostingsWithMetadataByPostQuery, query completed');
          if (postQuery.minSalary) {
            // If a minSalary query exists, it has to come first in order by
            console.log('We have min salary, sorting by that primarily');
            console.log(query);
            return query.orderBy('minSalary', 'desc')
                        .orderBy('datePosted', 'desc')
                        .orderBy('postPriority', 'desc');
          } else {
            // Otherwise, assume regular sorting, keep priority first.
            console.log('No minSalary, regular sorting');
            console.log(query);
            return query.orderBy('postPriority', 'desc')
                        .orderBy('datePosted', 'desc');
          }
        })
        .snapshotChanges()
        .pipe(map(docArray => {
          return docArray.map(doc => {
            // console.log('Getting docArray data');
            // console.log(doc);
            const data = doc.payload.doc.data() as JobPosting;
            // console.log('docArray length: ' + docArray.length);
            const id = doc.payload.doc.id;
            return {
              id,
              ...data
              // id: doc.payload.doc.id,
              // jobType: doc.payload.doc.data()['jobType'],
              // city: doc.payload.doc.data()['city'],
              // state: doc.payload.doc.data()['state'],
              // shortDescription: doc.payload.doc.data()['shortDescription'],
              // employmentStatus: doc.payload.doc.data()['employmentStatus'],
              // minSalary: doc.payload.doc.data()['minSalary'],
              // maxSalary: doc.payload.doc.data()['maxSalary'],
              // taxStatus: doc.payload.doc.data()['taxStatus'],
              // companyType: doc.payload.doc.data()['companyType'],
              // companyName: doc.payload.doc.data()['companyName'],
              // datePosted: doc.payload.doc.data()['datePosted'],
            };
          });
        })).subscribe((jobPosts: JobPosting[]) => {
        // console.log(JSON.stringify(jobPosts));
        this.uiService.loadingStateChanged.next(false);
        console.log('fetchJobPostings... finished, jobPosts length: ' + jobPosts.length);
        console.log('fetchJobPostings... finished, jobPosts results: ', jobPosts);
        console.log('Inside post service, fetchAvailableJobPostingsByPostQuery, subscribe');
        this.finishedJobsChanged.next(jobPosts);
        this.finishedJobsChangedCount.next(jobPosts.length);
      }, error => {
        console.log(error);
        this.uiService.loadingStateChanged.next(false);
        // this.uiService.showSnackbar(error, null, 5000);
        this.uiService.showSnackbar('Fetching Job Postings by Post Query failed, please try again later', null, 3000);
        this.finishedJobsChanged.next(null);
        this.finishedJobsChangedCount.next(null);
      }));
  }

  getSingleJobPosting(postId: string) {
    this.userProfileSubscription = this.afs.doc('posts/' + postId).valueChanges().pipe(
      tap(data => {
        try {
          console.log(data as JobPosting);

        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar('Encountered Issue with getting job posting, Please contact support', null, 3000);
          } else {
            this.uiService.showSnackbar(error.message, null, 3000);
          }

        }
      })
    ).subscribe((jobPost: JobPosting) => {
      // console.log(JSON.stringify(jobPosts));
      this.uiService.loadingStateChanged.next(false);
      console.log('getsinglejobposting... finished');

      this.finishedSinglePostChanged.next(jobPost);
    }, error => {
      console.log(error);
      this.uiService.loadingStateChanged.next(false);
      // this.uiService.showSnackbar(error, null, 5000);
      this.uiService.showSnackbar('Fetching Single Job Post failed, please try again later or ' +
        'contact support if issue persists', null, 5000);
      this.finishedSinglePostChanged.next(null);
    });
  }

  // Retrieve postings based on their priority
  getPostingsByPriority(priority: number): Promise<any> {
    console.log('inside getPostingsByPriority for priority: ' + priority);
    const promise = new Promise<any>((resolve, reject) => {
      this.pQuery = this.afs.collection(
        'posts', ref => ref.where('postPriority', '==', priority));

      // example of valuechanges return id field example right here!!!
      this.jobsToReturn = this.pQuery.valueChanges({idField: 'id'});

      this.jobsToReturn.pipe(first()).subscribe(data => {
        console.log('Getting list of posts for priority: ' + priority);
        console.log(data);
        resolve(data);
      });
    });
    return promise;
  }

  // Refresh Posts owned by a certain uid
  refreshAllPostsForUIDandCompanyName(uid: string, companyName: string) {
    console.log('inside getPostingsByPriority for companyName: ' + companyName );
    const promise = new Promise<any>(async (resolve, reject) => {
      this.pQuery = this.afs.collection(
        'posts', ref => ref.where('companyName', '==', companyName));

      // example of valuechanges return id field example right here!!!
      this.jobsToReturn = this.pQuery.valueChanges({idField: 'id'});

      this.jobsToReturn.pipe(first()).subscribe(data => {
        console.log('Getting list of posts with companyName: ' + companyName);
        console.log(data);
        // resolve(data);

        try {
          for (const job of data) {
            console.log('Results for the individual job to refresh:');
            console.log(job);
            // console.log(job.payload.doc.data());
            const jobFirestoreID = job.id;
            const docToRefresh = this.afs.doc<any>('posts/' + jobFirestoreID);
            // console.log(docToDelete);

            const today = new Date();
            const newExpirationDate = new Date().setDate(today.getDate() + 30);
            const newExpirationDateToSet = new Date(newExpirationDate);

            docToRefresh.update({
              datePosted: today,
              expirationDate: newExpirationDateToSet,
            });
            console.log('Updated Date for JobID: ' + jobFirestoreID);
          }
          this.uiService.showSnackbar('Refresh Job Posts Completed!', null, 5000);
        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar(
              'Encountered Issue Refreshing Records, error: ' + error, null, 9000);
          } else {
            this.uiService.showSnackbar(error.message, null, 9000);
          }
        }
      });

      const companyID = await this.frontEndCompanyService.getCompanyIdForCompanyname(companyName)
      this.frontEndCompanyService.updateCompanyLastRefreshDate(companyID);


    });
  }



  addFreePriorityToPost(postId: string, uid: string, freeRemaining: number) {
    console.log('Inside post service, addFreePriorityToPost.  Setting to priority postId: ' + postId);
    const numFreePriorityRemaining = freeRemaining - 1;
    this.userDoc = this.afs.doc<User>('users/' + uid);
    this.userDoc.update({userCompanyMonthlyPlanFreePriorityPostsRemaining: numFreePriorityRemaining});
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postPriority: 2, postFreePriority: true});
  }

  removeFreePriorityFromPost(postId: string, uid: string, freeRemaining: number) {
    console.log('Inside post service, removeFreePriorityFromPost.  Removing priority from postId: ' + postId);
    const numFreePriorityRemaining = freeRemaining + 1;
    this.userDoc = this.afs.doc<User>('users/' + uid);
    this.userDoc.update({userCompanyMonthlyPlanFreePriorityPostsRemaining: numFreePriorityRemaining});
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postPriority: 1, postFreePriority: false});
  }

  setPostToPriority(postId: string, newPriority: number) {
    console.log('Inside post service, setPostToPriority.  Setting to priority postId: ', postId, ' to priority: ', newPriority);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postPriority: newPriority}).then(res => {
      console.log('setPostToPriority update completed with result of: ', res);
    });
  }

  deactivatePost(postId: string) {
    console.log('Inside post service, deactivatePost.  Setting to inactive postId: ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postStatus: 'Inactive'});
    // this.postingDoc.valueChanges().subscribe(data => {
    //   console.log('Post data after expired');
    //   console.log(data);
    // });

  }

  // This function will bump the post's expiration until 30 days from today
  addExpirationDaysToPost(postId: string, daysUntilExpiration: number) {
    console.log('addExpirationDaysToPost for postId: ', postId, ' and days: ', daysUntilExpiration);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    const today = new Date();
    const newExpirationDate = new Date().setDate(today.getDate() + 30);
    const newExpirationDateToSet = new Date(newExpirationDate);
    console.log('new Expiration Date: ', newExpirationDateToSet);
    this.postingDoc.update({expirationDate: newExpirationDateToSet})
      .catch(e => {
        console.log('Error in addExpirationDaysToPost update: ', e);
      }).then(result => {
        console.log('addExpirationDaysToPost update, result: ', result);
    });
  }

  reactivatePost(postId: string) {
    console.log('Inside post service, reactivatePost.  Setting to active postId: ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postStatus: 'Active'});
  }

  deletePost(postId: string) {
    console.log('Inside post service, deletePost.  Deleting postId: ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.delete();
  }

  addPostViewCount(postId: string, currentPostCount: number) {
    console.log('Inside post service, addPostViewCount.  Adding to ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    const newPostCount = currentPostCount + 1;
    this.postingDoc.update({viewCount: newPostCount});
  }

  addDataToDatabase(jobPost: JobPosting) {

    this.afs.collection('posts').add(jobPost)
      .then((res) => {
        console.log('In Post Service, addDataToDatabased finished. Res: ');
        console.log(res);
        console.log(res.id);


        console.log('Add Database finished for address: ' + jobPost.address + ' city: ' + jobPost.city + ' state: ' + jobPost.state +
                    ' zip: ' + jobPost.zipCode);
        this.addGeocodeLocationToNewPost(jobPost, res.id);
      }).catch(err => {
        console.log(err);
        this.uiService.showSnackbar('Error with Adding Data to Database', null, 3000);
      });
  }

  editPostInDatabase(jobPost: JobPosting, restoredId: string) {
    console.log('editPostInDatabase, restoredId: ' + restoredId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + restoredId);
    this.postingDoc.update(jobPost);
  }

  addGeocodeLocationToNewPost(jobPost: JobPosting, postID: string) {
    // Come up with address to pass to geocoder.
    let addressToGeocode = '';
    if (jobPost.address) {
      console.log('In post service addGeoCodeLocation... address exists: ' + jobPost.address);
      addressToGeocode = addressToGeocode + jobPost.address + ', ';
      console.log('New addressToGeocode: ' + addressToGeocode);
    }
    if (jobPost.city) {
      console.log('In post service addGeoCodeLocation... city exists: ' + jobPost.city);
      addressToGeocode = addressToGeocode + jobPost.city + ', ';
      console.log('New addressToGeocode: ' + addressToGeocode);
    }
    if (jobPost.state) {
      console.log('In post service addGeoCodeLocation... state exists: ' + jobPost.state);
      addressToGeocode = addressToGeocode + jobPost.state + ', ';
      console.log('New addressToGeocode: ' + addressToGeocode);
    }
    if (jobPost.zipCode) {
      console.log('In post service addGeoCodeLocation... city exists: ' + jobPost.zipCode);
      addressToGeocode = addressToGeocode + jobPost.zipCode + ', ';
      console.log('New addressToGeocode: ' + addressToGeocode);
    }

    // this.geocodeService.geocodeAddress(addressToGeocode)
    //   .subscribe(
    //     location => {
    //       console.log('post Service, addGeocodeLocation, passed address of: ' + addressToGeocode);
    //       console.log('addressReturnCoordinatesConsole returned: ' + JSON.stringify(location));
    //     }
    //   );

    this.geocodeService.geocodeAddressAndCreatePointOnPostID(addressToGeocode, postID)
      .subscribe(
        location => {
          console.log('post Service, addGeocodeLocation, passed address of: ' + addressToGeocode);
          console.log('addressReturnCoordinatesConsole returned: ' + JSON.stringify(location));
          console.log('Added location to new post for postID: ' + postID);
        }
      );
  }

  cancelSubscriptions() {
    // Step through each of the subscriptions and unsubscribe if they exist on destroy
    this.fbSubs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  // //  This code is stored on trello, allows updating of all rows in afs to a value
  // updateDatabaseRecordsBatch() {
  //
  // }

}

