import { Injectable } from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Injectable()
export class SeoService {
  constructor(private meta: Meta) { }

  generateTags(config) {
    // default values
    config = {
      title: 'BagMask.com Page',
      description: 'BagMask.com Anesthesia Job Search Page',
      image: 'https://jobs.bagmask.com/assets/bagmask-logo.png',
      slug: '',
      contentType: 'article',
      ...config
    }

    // <meta name="twitter:card" content="summary">
    // <meta name="twitter:site" content="@Bagmaskjobs">
    // <meta name="twitter:title" content="Home Page">
    // <meta name="twitter:description"
    // content="Search and find the top Anesthesiologist, AA and CRNA Jobs on BagMask.com.">
    // <meta name="twitter:image" content="https://www.bagmask.com/assets/bagmask-logo.png">
    //
    // <meta property="og:type" content="article">
    // <meta property="og:site_name" content="BagMask">
    // <meta property="og:title" content="Anesthesia Jobs Search">
    // <meta property="og:description"
    // content="Search and find the top Anesthesiologist, AA and CRNA Jobs on BagMask.com.">
    // <meta property="og:url" content="https://www.bagmask.com/home">

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@Bagmaskjobs' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: config.contentType });
    this.meta.updateTag({ property: 'og:site_name', content: 'BagMask.com' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `https://bagmask.com/${config.slug}` });
  }

  getMetaTags() {
    console.log('SEOService: getMetaTags');
    const metaTest: HTMLMetaElement = this.meta.getTag('name="twitter:card"');
    // console.log(metaTest);

    // const metaEl: HTMLMetaElement = this.meta.getTag('name= "keywords"');
    // console.log(metaEl);
    // console.log(metaEl.name);
    // console.log(metaEl.content);

    let els: HTMLMetaElement[] = this.meta.getTags('name');
    els.forEach(el => {
      console.log(el);
      // console.log(el.name);
      // console.log(el.content);
    });

    els = this.meta.getTags('property');
    els.forEach(el => {
      console.log(el);
    });

  }

}
