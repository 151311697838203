import {Component, isDevMode, OnInit, AfterViewInit, ViewChild, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { JobPosting } from '../../Models/post.model';
import { PostService } from '../../Services/post.service';
import { USStates } from '../../Namespaces/usStates.namespace';
import { BagmaskFieldValues } from '../../Namespaces/bagmaskFieldValues.namespace';
import {MatDialog} from '@angular/material/dialog';
import { AddPostSalaryBenefitsComponent } from './add-post-salary-benefits/add-post-salary-benefits.component';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AddPostFacilityComponent} from './add-post-facility/add-post-facility.component';
// tslint:disable-next-line:max-line-length
import {AddPostResponsibilitiesRequirementsComponent} from './add-post-responsibilities-requirements/add-post-responsibilities-requirements.component';
import {AuthService} from '../../Services/auth.service';
import {UIService} from '../../Services/ui.service';
import {AddPostPaymentSubmitComponent} from './add-post-payment-submit/add-post-payment-submit.component';

import {tap} from 'rxjs/operators';

import {PaymentService} from '../../payments/payment.service';
import {User} from '../../Models/user.model';
import {AngularFirestore} from '@angular/fire/firestore';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AddPostSalaryBenefitsComponent) addPostSalaryBenefits: AddPostSalaryBenefitsComponent;
  @ViewChild(AddPostFacilityComponent) addPostFacility: AddPostFacilityComponent;
  @ViewChild(AddPostResponsibilitiesRequirementsComponent)
    addPostResponsibilitiesRequirements: AddPostResponsibilitiesRequirementsComponent;
  @ViewChild(AddPostPaymentSubmitComponent) addPostPaymentSubmit: AddPostPaymentSubmitComponent;

  userDataIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userDataIsLoading: boolean;
  userIsJobPoster$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  userIsJobPoster: boolean;

  userToUpdate: User;

  private unsubscribe: Subscription[] = [];
  fullUserSubscr: Subscription;

  devModeEnabled = false;
  postForm: FormGroup;
  isEditPost = false;

  usStates = USStates.getStates();
  jobTypes = BagmaskFieldValues.getJobTypes();
  employmentStatuses = BagmaskFieldValues.getEmploymentStatuses();
  taxStatuses = BagmaskFieldValues.getTaxStatuses();
  startDateOptions = BagmaskFieldValues.getStartDateOptions();
  shortDescriptionLength: number;
  formSubmitted = false;
  animal: string;
  name: string;

  introAddDialogResult: string;
  showDatePicker: boolean;
  params: Observable<Params>;
  restoredPostId: string;
  postAction = 'Add';
  restoredPostJobType: string;
  restoredPostData: any;
  addPostStatusTitle = 'Add a Job Posting';
  paymentPrompt = 'Pay and Activate Post';

  addPostTotalCostInCents: number;
  addPostPrioritySelected: number;
  postingPlanFreePriorityPostsRemaining: number;
  postingPlanFreeStandardPostsRemaining: number;
  currentUser: User;

  addPostRestoreSubscription: Subscription;
  userProfileSubscription: Subscription;
  paymentMessageSub: Subscription;
  paymentPriSub: Subscription;
  paymentPriceSub: Subscription;

  // Checkbox Current values
  leadershipCheckboxValue = false;
  painCheckboxValue = false;

  // How to do viewchild posts
  // Link: https://blog.angular-university.io/angular-viewchild/

  constructor(private postService: PostService,
              private router: Router,
              private authService: AuthService,
              public uiService: UIService,
              public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              public introDialog: MatDialog,
              private cdRef: ChangeDetectorRef,
              private afs: AngularFirestore,
              public paymentService: PaymentService,

              ) {
    this.shortDescriptionLength = 140;

    if (isDevMode()) {
      // Enable dev mode if isDevMode is true, showing extra form elements
      this.devModeEnabled = true;
    }

    // Set a subscription to the subject for whether user data is loading
    const userLoadingSubscr = this.userDataIsLoading$
    .asObservable()
    .subscribe((res) => (this.userDataIsLoading = res));
    this.unsubscribe.push(userLoadingSubscr);
    this.userDataIsLoading$.next(true);

    const jobPosterSubscr = this.userIsJobPoster$
    .asObservable()
    .subscribe((res) => (this.userIsJobPoster = res));
    this.unsubscribe.push(jobPosterSubscr);
  }

  async ngOnInit() {
    this.showDatePicker = false;
    this.postForm = new FormGroup({
      jobType: new FormControl(),
      shortDescription: new FormControl(),
      longDescription: new FormControl(),
      minSalary: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      maxSalary: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      minHourly: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      maxHourly: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      employmentStatus: new FormControl(),
      taxStatus: new FormControl(),
      // companyName: new FormControl(),
      companyName: new FormControl ({value: '', disabled: true}, Validators.required),
      companyType: new FormControl ({value: '', disabled: true}, Validators.required),
      startDate: new FormControl(),
      startDatePick: new FormControl(),
      companyPortal: new FormControl(),
      companyJobID: new FormControl(),
      contactEmail: new FormControl('', {validators: [Validators.email]}),
      facilityName: new FormControl(),
      address: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zipCode: new FormControl('', [Validators.pattern('^[0-9]*$')]),
    });

    this.addPostPrioritySelected = 1;

    this.populateUserFieldsFromExistingData();

    // Set a timer to fire alert if not logged in
    setTimeout(() => {
      if (this.userDataIsLoading) {
        Swal.fire('Job Poster Log-in Required', "<a href='/login'>Click here and log-in</a> to be able to Create a new Job Post!");
      }
    }, 3000);

    this.getUserData();

    this.paymentMessageSub = this.paymentService.getMessage().subscribe(message => {
      console.log('Add Post, paymentService returned message: ' + message);
      if (message === 'PaymentFinishedSuccessful') {
        console.log('Add post, payment marked as finished and successful, submitting post');
        // Check if submitted post was priority and free, subtract one from available count in plan.
        this.checkFreePriorityAndSubtract();
        this.checkFreeStandardAndSubtract();
        this.onSubmit('Active');
      } else {

      }
      // TODO: Check for any kind of payment failures here.
    });

    this.paymentPriSub = this.paymentService.getPriSelected().subscribe(pri => {
      // console.log('Add Post, paymentService returned pri selected: ' + pri);
      console.log('add post, paymentPriSub changed: ' + pri);
      this.addPostPrioritySelected = pri;
    });

    this.paymentPriceSub = this.paymentService.getPrice().subscribe(price => {
      // console.log('Add Post, paymentService returned price: ' + price);
      this.addPostTotalCostInCents = price;

      // Check price coming in and update payment prompt accordingly
      if (this.postAction === 'Saved') {
        this.paymentPrompt = 'Save Edits and Re-Post';
      } else if (this.postAction === 'Template') {
        this.paymentPrompt = 'Create a new Post from this Template';
      } else if (this.postAction === 'Active') {
        this.paymentPrompt = 'Save Edits to Post';
      } else if (this.addPostTotalCostInCents > 0) {
        this.paymentPrompt = 'Pay and Activate Post';
      } else {
        this.paymentPrompt = 'Activate Free Post';
      }

    });

    // This works for setting default values in a form.
    this.postForm.get('startDate').setValue('Immediate');
    // Populate email with account email by default.
    this.postForm.get('contactEmail').setValue(this.authService.fullUserInfo.email);

    this.params = this.activatedRoute.params;
    // console.log('inside userPostingTable, params:' + JSON.stringify(this.params));

    this.params.forEach((params: Params) => {
      if (params.postId) {
        console.log('Add Post restored from postId:' + params.postId + ' and postStatus: ' + params.postStatus + ' and postJobType:' +
          params.postJobType);

        this.restoredPostId = params.postId;
        this.postAction = params.postStatus;
        this.restoredPostJobType = params.postJobType;

        // Now we have the restored post status (new/template), we can update the addPostTitle at top left on the sub navbar
        if (this.postAction === 'Template') {
          console.log('Restoring a Template as a posting, setting page title in navbar');
          this.addPostStatusTitle = 'Edit Post Template';
        } else if (this.postAction === 'Saved') {
          console.log('Restoring a Saved post as a posting, setting page title in navbar');
          this.addPostStatusTitle = 'Edit Saved Post';
        } else if (this.postAction === 'Active') {
          console.log('Restoring an Active post, setting page title in navbar');
          this.addPostStatusTitle = 'Edit Post';
        }
        this.preloadData();

        // We have preloaded data and are working with an existing post, set flag so we know to save to an existing post
        this.isEditPost = true;
      }

    });

  }

  // Populate the fields from our values in the database to start
  populateUserFieldsFromExistingData() {
    this.fullUserSubscr = this.authService.fullUserInfoBehaviorSubject.subscribe(data => {
      // Make sure it has a value, otherwise we can get it again when it returns value
      if (data) {
        this.userToUpdate = data;
        if (this.userToUpdate.userType === 'Job Poster' ) {
          this.userIsJobPoster$.next(true);
        } else {
          this.userIsJobPoster$.next(false);
        }

        // When data is finished loading update the behavior subject to remove the spinner
        this.userDataIsLoading$.next(false)

        // Test the loading spinner with a timeout function
        // setTimeout(() => {
        //   this.userDataIsLoading$.next(false)
        // }, 1500);
      }
    })

  }

  ngAfterViewInit() {
    // Testing that we can get information from another sub-form.
    // console.log(this.addPostSalaryBenefits);
    if (this.postForm.value.jobType) {
      console.log('we have a job type');
    }

    // If we have a restored post job type, populate the job type and set it in the post service so all pages know the job type.
    if (this.restoredPostJobType) {

      console.log('calling onJobTypeChange for job type: ' + this.restoredPostJobType);
      this.onJobTypeChange(this.restoredPostJobType);
      this.cdRef.detectChanges();
    }

  }

  preloadData() {
    console.log('Restoring a post for Add, inside preloadData');
    // this.addPostRestoreSubscription = this.afs.doc('posts/' + 'clapTBsrtoFXk9fUeYN3').valueChanges().pipe(
    this.addPostRestoreSubscription = this.afs.doc('posts/' + this.restoredPostId).valueChanges().pipe(
      tap(data => {
        try {
          console.log('Restored Post incoming data: ', data);
          this.postForm.patchValue(data);
          this.addPostSalaryBenefits.postFormSalaryBenefits.patchValue(data);
          this.addPostResponsibilitiesRequirements.postFormResponsibilitiesRequirements.patchValue(data);
          this.addPostFacility.postFormFacility.patchValue(data);
          this.addPostPaymentSubmit.postFormPaymentSubmit.patchValue(data);
          // Set miscellaneous restored post data that can be checked to not re-set when saving post
          this.restoredPostData = {
            // @ts-ignore
            postPriority: data.postPriority,
            // @ts-ignore
            expirationDate: data.expirationDate,
          };

        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar('Encountered Issue with Restoring Post, Please contact support', null, 3000);
          } else {
            this.uiService.showSnackbar(error.message, null, 3000);
          }

        }
      })
    ).subscribe();

  }

  getUserData() {
    this.userProfileSubscription = this.afs.doc('users/' + this.authService.authenticatedUser.uid).valueChanges().pipe(
      tap(data => {
        try {
          this.currentUser = data as User;
          if (this.currentUser.userCompanyMonthlyPlan) {
            this.postingPlanFreePriorityPostsRemaining = this.currentUser.userCompanyMonthlyPlanFreePriorityPostsRemaining;
            this.postingPlanFreeStandardPostsRemaining = this.currentUser.userCompanyMonthlyPlanStandardPostsRemaining;
          }
        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar('Encountered Issue with User Profile, Please contact support', null, 3000);
          } else {
            this.uiService.showSnackbar(error.message, null, 3000);
          }
        }
      })
    ).subscribe();
  }

  onSubmit(postStatusSelected: string) {

    const result: JobPosting = Object.assign({}, this.postForm.value,
                                                        this.addPostSalaryBenefits.postFormSalaryBenefits.value,
                                                        this.addPostResponsibilitiesRequirements.postFormResponsibilitiesRequirements.value,
                                                        this.addPostFacility.postFormFacility.value,
                                                        this.addPostPaymentSubmit.postFormPaymentSubmit.value);

    this.addPostTotalCostInCents = result.postTotalCostInCents;
    console.log('Inside onSubmit, total post cost in cents: ' + this.addPostTotalCostInCents);

    let companyInfoTested: string;

    // Validate that salaries have values, if blank then set to 0.
    if (result.minSalary === null) {
      result.minSalary = 0;
    }
    if (result.maxSalary === null) {
      result.maxSalary = 0;
    }

    // Validate that hospital info has value more than '', else set to null
    if (result.facHospitalInfo === '') {
      result.facHospitalInfo = null;
    }

    // Validate that community info has value more than '', else set to null
    if (result.facCommunityInfo === '') {
      result.facCommunityInfo = null;
    }

    // Checking == null instead of === null gives null/undefined.  === null gives only when 'null'.
    // If it is null (likely because of new user) then set the value we will use to '', else use value in user info.
    if (this.authService.fullUserInfo.userCompanyInfo == null) {
      console.log('userCompanyInfo is null or undefined! new user likely, setting value to blank');
      companyInfoTested = '';
    } else {
      companyInfoTested = this.authService.fullUserInfo.userCompanyInfo;
    }

    // Validate that startDate is either immediate or a date
    if (result.startDate === 'Select Date') {
      console.log('Select Date Picked, evaluating startDatePick value of: ' + this.postForm.value.startDatePick);
      if (this.postForm.value.startDatePick) {
        // Select Date was picked AND we have a value in startDatePick, populate with date
        result.startDate = this.postForm.value.startDatePick;
      } else {
        // Select Date was picked but no date was chosen, keep as 'Immediate'
        result.startDate = 'Immediate';
      }
    }

    // Call evalSigningBonusBoolean to check if yes/negotiable = true, else false.  For Filtering
    result.salSigningBonusBoolean = this.evalSigningBonusBoolean(result.salSigningBonus);
    result.salTuitionReimbursementBoolean = this.evalYesNegotiableNoBoolean(result.salTuitionReimbursement);
    result.salEventualPartnershipBoolean = this.evalYesNegotiableNoBoolean(result.salEventualPartnership);



    // Find date of today and then add 30 to that to set dateToExpire
    const today = new Date();
    // If it is a High Priority Posting, set the dateToExpire to a year out, 365 days
    let daysUntilExpiring: number;
    if (this.addPostPrioritySelected >= 3) {
      daysUntilExpiring = 365;
    } else {
      daysUntilExpiring = 30;
    }

    // Set date based on the days until expiring above
    let dateToExpire = new Date().setDate(today.getDate() + daysUntilExpiring);

    // Check to see if the post is being saved, to not re-update the post expiration
    console.log('Does post have an existing expiration date? ', this.restoredPostData);
    if (this.restoredPostData) {
      if (this.restoredPostData.expirationDate) {
        const incDate = this.restoredPostData.expirationDate.toDate();
        console.log('Post has an existing expiration date, setting to that: ', incDate);
        dateToExpire = incDate;
      }
    }
    console.log('dateToExpire: ' + dateToExpire);

    // Check to see if the post is being saved, if it is then use the existing postPriority instead of setting!
    console.log('Does post have an existing priority? ', this.restoredPostData);
    if (this.restoredPostData) {
      if (this.restoredPostData.postPriority) {
        this.addPostPrioritySelected = this.restoredPostData.postPriority
        console.log('Post had an existing priority, setting addPostPrioritySelected to: ', this.addPostPrioritySelected);
      }
    }

    let finalResult: JobPosting;

    finalResult = {
      // Give everything a Date
      datePosted: new Date(),
      expirationDate: new Date(dateToExpire),
      postStatus: postStatusSelected,
      // postEntryType for anything on the front-end will be "Manual"
      postEntryType: 'Manual',
      // Attach user's uid to post
      uid: this.authService.authenticatedUser.uid,
      // Generate a random integer up to 999,999,999 for random selection
      random: this.uiService.randomOneBillionInt(),
      // Give a view count of zero by default
      viewCount: 0,
      // Populate Main Fields
      jobType: result.jobType,
      shortDescription: result.shortDescription,
      longDescription: result.longDescription,
      minSalary: Number(result.minSalary),
      maxSalary: Number(result.maxSalary),
      employmentStatus: result.employmentStatus,
      taxStatus: result.taxStatus,
      minHourly: result.minHourly,
      maxHourly: result.maxHourly,
      jobTypeLeadership: this.leadershipCheckboxValue,
      jobTypePain: this.painCheckboxValue,


      // this.postForm.get('companyName').setValue(this.authService.fullUserInfo.userCompanyName);
      // this.postForm.get('companyType').setValue(this.authService.fullUserInfo.userCompanyType);

      // Populate companyName and companyType from user info
      companyName: this.authService.fullUserInfo.userCompanyName,
      companyType: this.authService.fullUserInfo.userCompanyType,
      startDate: result.startDate,
      contactEmail: result.contactEmail,
      companyPortal: result.companyPortal,
      companyJobID: result.companyJobID,
      facilityName: result.facilityName,
      address: result.address,
      city: result.city,
      state: result.state,
      zipCode: Number(result.zipCode),
      // Populate Salary and Benefits Fields, if available
      salSigningBonus: Number(result.salSigningBonus),
      salSigningBonusBoolean: result.salSigningBonusBoolean,
      salSigningBonusCommitmentReimbursement: result.salSigningBonusCommitmentReimbursement,
      salTuitionReimbursement: result.salTuitionReimbursement,
      salTuitionReimbursementBoolean: result.salTuitionReimbursementBoolean,
      salPaidOvertime: result.salPaidOvertime,
      salWeeksPaidVacation: result.salWeeksPaidVacation,
      salPaidCMETime: result.salPaidCMETime,
      salCMEAllowance: result.salCMEAllowance,
      salSalaryIncomeBoolean: result.salSalaryIncomeBoolean,
      salEventualPartnership: result.salEventualPartnership,
      salEventualPartnershipBoolean: result.salEventualPartnershipBoolean,
      salIncomeFullPartnerPractice: result.salIncomeFullPartnerPractice,
      salIncomeFeeForService: result.salIncomeFeeForService,
      salMedicalInsurance: result.salMedicalInsurance,
      salRetirementPlan: result.salRetirementPlan,
      salMalpracticeInsurance: result.salMalpracticeInsurance,
      salDisabilityInsurance: result.salDisabilityInsurance,
      salInterviewExpenses: result.salInterviewExpenses,
      salRelocationAssistance: result.salRelocationAssistance,

      // Populate Responsibility Fields, if available
      resShiftType: result.resShiftType,
      resShiftDuration: result.resShiftDuration,
      resScheduledCall: result.resScheduledCall,
      resCallType: result.resCallType,
      resCallResponseTime: result.resCallResponseTime,
      resHowOftenFirstCall: result.resHowOftenFirstCall,
      resDayOffAfterFirstCall: result.resDayOffAfterFirstCall,
      resHowOftenSecondCall: result.resHowOftenSecondCall,
      resProviderModel: result.resProviderModel,
      resDirectionSupervision: result.resDirectionSupervision,
      resCaseMix: result.resCaseMix,
      resAnesthesiologistDirecting: result.resAnesthesiologistDirecting,
      resAnesthesiologistPercentSolo: result.resAnesthesiologistPercentSolo,
      resCRNAPerformLaborEpidurals: result.resCRNAPerformLaborEpidurals,
      resCRNADirectedByAnesthesiologist: result.resCRNADirectedByAnesthesiologist,
      resLaborEpidurals: result.resLaborEpidurals,
      resSpinalAnesthesia: result.resSpinalAnesthesia,
      resRegionalBlocks: result.resRegionalBlocks,
      resArterialCatheter: result.resArterialCatheter,
      resCentralVenousCatheter: result.resCentralVenousCatheter,

      // Populate Requirements Fields, if available
      reqNewGrads: result.reqNewGrads,
      reqStateLicense: result.reqStateLicense,
      reqLifeSupportCard: result.reqLifeSupportCard,
      reqABACertification: result.reqABACertification,
      reqSubspecialtyFellowship: result.reqSubspecialtyFellowship,
      reqVisaAccepted: result.reqVisaAccepted,
      reqNBCRNACertification: result.reqNBCRNACertification,

      // Populate Facility Fields, if available
      facAverageCredentialingTime: result.facAverageCredentialingTime,
      facNumAnesthesiologists: result.facNumAnesthesiologists,
      facNumCRNAs: result.facNumCRNAs,
      facNumAAs: result.facNumAAs,
      facGroupExclusiveContract: result.facGroupExclusiveContract,
      facOtherSites: result.facOtherSites,
      facTrainingPrograms: result.facTrainingPrograms,
      facFederalGovJob: result.facFederalGovJob,
      facAvgDailyCensus: result.facAvgDailyCensus,
      facNumLicensedBeds: result.facNumLicensedBeds,
      facNumSurgicalCasesYear: result.facNumSurgicalCasesYear,
      facNumDeliveriesYear: result.facNumDeliveriesYear,
      facCompanyInfo: companyInfoTested,
      facHospitalInfo: result.facHospitalInfo,
      facCommunityInfo: result.facCommunityInfo,
      // ...result,

      // Populate Payment and Submit fields
      postPriority: this.addPostPrioritySelected,
      postFreePriority : null,
      postTotalCostInCents: result.postTotalCostInCents,

    };

    console.log(finalResult);

    // Save the post here, either as a new post if "Add" or if we are in edit mode, save as the existing post
    try {
      if (this.isEditPost) {
        this.postService.editPostInDatabase(finalResult, this.restoredPostId);
      } else {
        this.postService.addDataToDatabase(finalResult);
      }
    } catch (error) {
      console.log('We hit an error on adding post');
      console.error(error);
      this.uiService.showSnackbar(error.message, null, 30000);
      return false;
    }

    this.formSubmitted = true;


    console.log('Post Submission finished for post type: ' + postStatusSelected);

    // Check for Active post, and if submitted, then show message and send user to appropriate posting page, else send to user profile
    if (postStatusSelected === 'Active') {
      this.uiService.showSnackbar('Job Posting has been successfully created and activated!', null, 4000);

      // Navigate to the search page with the results from posted job immediately so user can see it was created.
      this.router.navigate(['/search/', { type: result.jobType, state: result.state }]);
    } else if (postStatusSelected === 'Saved') {
      this.uiService.showSnackbar('Post has been successfully saved', null, 4000);
      this.router.navigate(['/user', 'jobs']);
    } else if (postStatusSelected === 'Template') {
      this.uiService.showSnackbar('Post Template has been successfully created', null, 4000);
      this.router.navigate(['/user', 'jobs']);
    }
  }

  async checkFreePriorityAndSubtract() {
    // Check if posts remaining and if the selected post was priority 2
    if (this.postingPlanFreePriorityPostsRemaining > 0 && this.addPostPrioritySelected === 2) {
      this.postingPlanFreePriorityPostsRemaining = this.postingPlanFreePriorityPostsRemaining - 1;
      try {
        await this.afs.doc('users/' + this.authService.authenticatedUser.uid)
          .update({userCompanyMonthlyPlanFreePriorityPostsRemaining: this.postingPlanFreePriorityPostsRemaining
          });
        console.log('Successful Removing of One Free Priority for: ' + this.authService.authenticatedUser.uid);
        // this.uiService.showSnackbar('Successful User Subscription Plan', null, 3000);
      } catch (error) {
        console.error(error);
        this.uiService.showSnackbar(error.message, null, 3000);
      }
    }
  }

  async checkFreeStandardAndSubtract() {
    // Check if standard posts remaining and if the selected post was priority 1
    if (this.postingPlanFreeStandardPostsRemaining > 0 && this.addPostPrioritySelected === 1) {
      this.postingPlanFreeStandardPostsRemaining = this.postingPlanFreeStandardPostsRemaining - 1;
      try {
        await this.afs.doc('users/' + this.authService.authenticatedUser.uid)
          .update({userCompanyMonthlyPlanStandardPostsRemaining: this.postingPlanFreeStandardPostsRemaining
          });
        console.log('Successful Removing of One Free Standard for: ' + this.authService.authenticatedUser.uid);
        // this.uiService.showSnackbar('Successful User Subscription Plan', null, 3000);
      } catch (error) {
        console.error(error);
        this.uiService.showSnackbar(error.message, null, 3000);
      }
    }
  }

  evalSigningBonusBoolean(bonus) {
    if (bonus > 0) {
      return true;
    } else {
      return false;
    }
  }

  evalYesNegotiableNoBoolean(value) {
    if (value === 'Yes' || value === 'Negotiable') {
      return true;
    } else {
      return false;
    }
  }

  onTopClick() {
    // When the user clicks forward/back, pop them up to the top of the screen.
    window.scroll( 0, 0);
  }

  onJobTypeChange(jobType: string) {
    this.postService.addPostChangeJobType(jobType);
  }

  invalidMessage() {
    this.uiService.showSnackbar('Please fill out all required fields (if they display an *) to continue ' +
      'creating job posting.', null, 4000);
  }

  onStartDateChange(startDate: string) {
    console.log('Start Date Changed: ' + startDate);
    console.log('Select Date Picked, evaluating startDatePick value of: ' + this.postForm.value.startDatePick);
    if (startDate === 'Select Date') {
      this.showDatePicker = true;
    } else {
      this.showDatePicker = false;
    }

  }

  // updateDatabase() {
  //   this.postService.updateDatabaseRecordsBatch();
  // }

  backToProfile() {
    this.router.navigate(['/user', 'profile']);
  }

  // // Check the value of a checkbox on change
  // checkCheckBoxvalue(event) {
  //   console.log(event.checked)
  // }

  // Check box changing values
  checkBoxValueChanged(event, incomingValue: string) {
    // console.log(`checkBoxValueChanged, event: ${event.checked}`);
    console.log(`checkBoxValueChanged, event: ${event.checked} checkBox: ${incomingValue}`);

    switch (incomingValue) {
      case 'Leadership': {
        console.log('leadership selected');
        if (event.checked === true) {
          this.leadershipCheckboxValue = true;
        } else {
          this.leadershipCheckboxValue = false;
        }
        break;
      }
      case 'Pain': {
        console.log('pain selected');
        if (event.checked === true) {
          this.painCheckboxValue = true;
        } else {
          this.painCheckboxValue = false;
        }
        break;
      }
      default:
        break;
    }

    console.log(this.leadershipCheckboxValue, this.painCheckboxValue);
  }

  ngOnDestroy() {
    if (this.addPostRestoreSubscription) {
      this.addPostRestoreSubscription.unsubscribe();
    }
    if (this.paymentMessageSub) {
      this.paymentMessageSub.unsubscribe();
    }
    if (this.userProfileSubscription) {
      this.userProfileSubscription.unsubscribe();
    }
    if (this.paymentPriSub) {
      this.paymentPriSub.unsubscribe();
    }
    if (this.paymentPriceSub) {
      this.paymentPriceSub.unsubscribe();
    }
    if (this.fullUserSubscr) {
      this.fullUserSubscr.unsubscribe();
    }

  }

}
