import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor() { }

  /*  This function will get a companyName as string and return the filename for the company's logo if it exists, otherwise it will
      return a standard BagMask logo
  */
  getCompanyLogoAsset(companyName: string) {
    console.log('getCompanyLogoAsset for companyName: ', companyName);
    let returnValue: string;

    switch (companyName) {
      case 'Envision':
        returnValue = 'Envision.png';
        break;
      default:
        returnValue = 'BagmaskLogoBlack.png';
        break;
    }

    // ReturnValue is the strict name of the file, now give it the full location to reference
    const imageLocation = '/assets/Companies/';
    console.log('absolute asset location: ', imageLocation);
    // Add location to imageName:
    returnValue = imageLocation + returnValue;
    console.log('getCompanyLogoAsset completed, returning logo with image location: ',  returnValue);
    return returnValue;
  }
}
