<div *ngIf="isLoading">
  <div class="loading-text mat-typography">
    <h2>
      {{isLoadingMessage}}
    </h2>
  </div>
  <app-loading-spinner></app-loading-spinner>
</div>


<div *ngIf="!isLoading">
  <header>
    <a href="/" rel="home">Stripe Subscriptions Firebase Extension Demo - 2</a>
  </header>

  <p>Stripe Plans:</p>
  <div fxLayout="row" fxLayoutAlign="space-around center" >
    <div *ngFor="let plan of stripePlanData">

      <button mat-button mat-raised-button color="primary"
              (click)="stripeBillingHandler(plan['priceId'])">
        {{plan.description}}
        <br>
        {{stripeBagmaskService.getBagMaskStripePlanPriceInDollars(plan['price'])}}
      </button>
      <br>
      <button mat-button mat-raised-button color="primary"
              (click)="userService.updateUserPlan(plan.description)">
        Update User Plan for {{plan.description}}

      </button>
    </div>
  </div>

  
  <div>
    <button mat-button mat-raised-button color="primary"
            (click)="stripeBillingHandler('price_1HVLo1CfLG9nGFpofBpZhCRM')">Stripe Billing Handler</button>
  </div>

  <div>
    <button mat-button mat-raised-button color="primary"
            (click)="stripeBillingPortal()">Stripe Billing Portal</button>
  </div>

  <div>
    <button mat-button mat-raised-button color="primary"
            (click)="stripeDeleteCustomer()">Delete Customer</button>
  </div>

  <section id="firebaseui-auth-container">
    <div id="loader">Loading &hellip;</div>
  </section>
  <main>
    <button type="button" id="signout">
      Sign out
    </button>
    <div id="subscribe">
      <h2>Subscribe</h2>
      <div class="test-card-notice">
        Use any of the
        <a
          href="https://stripe.com/docs/testing#cards"
          target="_blank"
          rel="noopener noreferrer">
          Stripe test cards
        </a>
        for this demo, e.g.
        <div class="card-number">
          4242<span></span>4242<span></span>4242<span></span>4242
        </div>
      </div>
      <section class="products"></section>
    </div>
    <section id="my-subscription">
      <h2>My subscription</h2>
      <p></p>
      <h3>View invoices, update subscription & payment methods</h3>
      <button id="billing-portal-button">Access customer portal</button>
    </section>
  </main>

  <template id="product">
    <div class="product">
      <img src="" alt="" />
      <h2>name</h2>
      <p class="description">description</p>
      <form class="product-form">
        <label for="price">Choose pricing plan</label>
        <select id="price" name="price"></select>
        <button type="submit">Subscribe</button>
      </form>
    </div>
  </template>
</div>
