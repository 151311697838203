<div class="privacy-policy-info-container mat-typography" fxLayout="column" fxLayout.lt-md="column"
     fxLayoutGap.lt-md="10px" fxLayoutAlign="space-around center">
  <h2>If you have any questions about BagMask, get in touch with our friendly support team!</h2>

  <h2><a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a></h2>

  <h2 class="privacy-policy-section">BagMask.com Privacy Policy</h2>

  <h2>1. Acceptance</h2>
  <p class="privacy-policy-section">This Privacy Policy (“Policy”) sets forth the policies of BagMask.com LLC, its
    affiliates and subsidiaries
    (“BagMask.com”) with respect to any information submitted by users of or collected through its website (“Site”)
    and/or any services offered or available on the Site (“Services”). By accessing the Site or utilizing the Services,
    you expressly acknowledge and accept the terms and conditions herein.

  <h2>2. Commitment to Privacy</h2>
  <p class="privacy-policy-section">BagMask.com strives to respect the privacy concerns of the users of its Site and
    Services. The terms and conditions
    of this Policy are intended to describe how we may use and will seek to protect the personal and other information
    provided by users of its Site and Services. We encourage all users of the Site and Services to carefully review the
    entire Policy and to contact BagMask.com for additional information or to ask questions about it. BagMask.com may
    change this Policy from time to time in an effort to address new issues as they arise or as otherwise necessary. Any
    and all changes to this Policy will be posted to the Site as soon as they become available to ensure that users are
    aware of what information BagMask.com collects, the ways in which such information may be utilized, and whether and
    to
    what extent BagMask.com may disclose it to third parties.

  <h2>3. Information Collected</h2>
  <p class="privacy-policy-section-small">BagMask.com may collect the following types of information provided by users
    of the Site and/or Services. However,
    the decision as to what, if any, information is disclosed to BagMask.com is entirely up to each user. BagMask.com
    does not collect any information unless it is provided by users of its Site and/or Services. Users may provide
    information by accessing the login feature of the Site and/or Services, by submitting forms, documents or other
    electronic materials to utilize the Site and/or Services, or by voluntarily disclosing personal information to the
    Site or while using the Services or transacting business with BagMask.com, such as credit card numbers, name,
    address,
    phone number, fax number, email address, demographic, and/or other personal and financial information. Information
    may
    also be disclosed by users through email and other communications with BagMask.com, or by voluntary participation in
    BagMask.com community and forum discussions, blog posts, and the like.

  <p class="privacy-policy-section-small">User information that is disclosed through participation in BagMask.com
    discussion boards or forums, blogs or other
    publicly accessible content may become publicly available to employees of BagMask.com, its partners and, possibly,
    the general public. Therefore, users must be aware that voluntary public disclosure of personal information may
    enable third parties to collect and use such information, which may result in unsolicited messages or other
    communications from other users of the Site and/or Services or from third parties.

  <p class="privacy-policy-section-small">Users acknowledge that anytime an internet website is visited, the users’
    unique internet address, or “IP address”,
    may be recorded. Depending on how a user is connected to or accesses the internet, the amount of personal or other
    information about the user can vary significantly. BagMask.com will not share or release any information collected
    from or related to its users’ IP address to any third parties.

  <p class="privacy-policy-section-small">BagMask.com may from time to time conduct surveys of its users to assist with
    strategic planning, as well as to guide
    the development and enhancement of the Site and the Services users may access. BagMask.com may engage third parties
    to
    conduct such surveys on its behalf. In such cases, responses submitted by users will only be shared with third
    parties
    in aggregate and not on an individual user response basis.

  <p class="privacy-policy-section-small">In the event that a user should contact BagMask.com with a question or
    complaint, BagMask.com may keep a record of any
    phone number or email address used to contact it, as well as any correspondence received or sent by BagMask.com and
    information about the period of time in which we responded to the user communication.

  <p class="privacy-policy-section-small">BagMask.com may also collect, process and/or use information about its users
    for additional purposes not set forth
    above, including without limitation:

  <p class="privacy-policy-section-small">To provide you with the information and Services you have requested, and to
    send you marketing and other materials;

  <p class="privacy-policy-section-small">To authenticate your access to the Site and/or Services;

  <p class="privacy-policy-section-small">To facilitate and enable internal operations such as administration of the
    Site and/or Services, troubleshooting,
    statistical and data analysis, research and other related purposes;

  <p class="privacy-policy-section-small">To identify and differentiate you from other users of the Site and/or
    Services, such as log-in credentials;

  <p class="privacy-policy-section-small">To monitor and track the use of the Site and/or Services by users in order to
    improve user experience and access to
    content from a computer, tablet, smart phone or other devices;

  <p class="privacy-policy-section-small">To notify users of any changes to available Services;

  <p class="privacy-policy-section-small">To ensure the safety and security of the Site and/or Services; and/or

  <p class="privacy-policy-section-small">To comply with all applicable laws and regulations.

  <p class="privacy-policy-section">Finally, BagMask.com may collect and archive basic, anonymous information primarily
    related to statistics regarding
    access and use of the Site and/or Services. Such information may identify the amount of user traffic generated by
    the
    Site and/or Services, requests received for particular files or Services, the locations of servers from which such
    requests are from, including the country of origin, and the domain name of the server or browser used to access the
    Site and/or Services. This aggregated information is used by BagMask.com primarily for internal purposes in order to
    provide the best possible experience to users of the Site and/or Services.

  <h2>4. Processing of Personal Information</h2>
  <p class="privacy-policy-section">BagMask.com will only collect, process and use personal information of its users as
    permitted by relevant laws and
    regulations. Compliance with such laws and regulations will often occur through one or more means including, without
    limitation, user consent, BagMask.com’s legitimate business interests, performance of a contract with a user or to
    take steps prior to entering such a contract, or to comply with a law, regulation or other legal obligation.

  <h2>5. Non-Disclosure</h2>
  <p class="privacy-policy-section">BagMask.com will not share your personal information with any third parties other
    than for one or more of the reasons
    stated herein. Notwithstanding anything to the contrary, BagMask.com may share user personal information when: the
    user has consented to or otherwise requested that information be shared with third parties; BagMask.com or a
    substantial portion of its assets are merged with or otherwise acquired by a third party; BagMask.com provides
    anonymous statistical information about users of its Site and/or Services to reputable third parties; BagMask.com
    utilizes a third party to provide services involving data processing or analytics; or it is otherwise permitted by
    applicable law or regulation, or where BagMask.com is required by law to do so.

  <h2>6. Locations of Stored Personal Information</h2>
  <p class="privacy-policy-section-small">Any personal information collected by or submitted to BagMask.com may be
    transferred to, and stored at, a destination
    outside of a users’ country which may have less strict data protection laws compared to those of the users’ country.

  <p class="privacy-policy-section">If a user is located in the European Economic Area (“EEA”), including the UK, the
    following text shall apply: The
    personal information that we collect may be transferred to, and stored at, a destination outside the EEA. Whenever
    we
    transfer your information outside of the EEA, we will take steps to ensure that appropriate safeguards are in place
    to
    protect your personal information and to treat it in accordance with this Policy. In these cases, we will rely on
    approved data transfer mechanisms (such as the EU “Standard Contractual Clauses” and the EU-US “Privacy Shield”) to
    ensure that user information is subject to adequate safeguards in the recipient country. You may contact us for a
    copy
    of the safeguards which we have put in place to protect your personal information and privacy rights in these
    circumstances.

  <h2>7. Retention of Personal Information</h2>
  <p class="privacy-policy-section">BagMask.com strives to ensure that the personal information provided by its users is
    retained for only as long as it
    is necessary for the purpose(s) for which it was collected. When such information is no longer necessary for such
    purposes, BagMask.com will delete or in same cases make the information anonymous. BagMask.com may keep a record of
    any correspondence with its users for as long as is necessary to protect it from legal claims or potential
    liability.
    Where we have collected personal information based upon the consent of a user and there is no other lawful basis to
    continue the retention, processing or use of such information, BagMask.com will delete the information upon notice
    that the user’s consent has been withdrawn. However, where a user chooses to unsubscribe from marketing or other
    communications to users, BagMask.com will maintain a record of the user’s email address to ensure that such
    communications will not be sent in the future.

  <h2>8. User Rights</h2>
  <p class="privacy-policy-section">Users may exercise certain rights in relation to their personal information,
    including the right to object to and/or
    withdraw consent with respect to the processing, retention and/or use of their information, the right to access
    their
    personal information, and the right to erase, restrict, or receive a machine-readable copy of their personal
    information. BagMask.com will handle any requests to exercise such rights in accordance with applicable law and this
    Policy. In addition, users may have the ability to complain to a proper data protection authority if the user
    believes
    that BagMask.com may have processed, retained or used personal information in a manner that is unlawful or breaches
    the user’s rights.

  <h2>9. Cookies</h2>
  <p class="privacy-policy-section">In order to enable certain features of the Site and/or Services, BagMask.com’s
    server may assign your browser a unique
    identification number that is stored in a file on your computer (“Cookie” or similar technology). Cookies by
    themselves cannot be used to find out the identity of any user. This unique identification number will, however,
    automatically identify a user’s browser to BagMask.com’s system whenever the user accesses or interacts with the
    Site
    and/or Services. Most browsers are initially set up to accept cookies, but you can reset or change browser settings
    to
    refuse or restrict Cookies or to notify a user when a Cookie is sent or requested. However, users acknowledge that
    depending upon their settings, some features of the Site and/or Services may not function properly or at all if
    Cookies are disabled. BagMask.com may collect certain information through Cookies including, without limitation,
    information regarding a user’s device, operating system and IP address; log-in credentials and/or information;
    browser
    type and version; and information about a user’s visit to the Site and/or use of Services, such as URL, clickstream,
    length of visit, and page interaction information.

  <h2>10. Links</h2>
  <p class="privacy-policy-section-small">Users may find links to other sites or services on the Site, both from
    BagMask.com and from third parties. BagMask.com
    does not control the content or additional links that may appear on these third party sites. The fact that
    BagMask.com
    made such links available to you is not to be construed in any way as an endorsement or recommendation by
    BagMask.com.
    BagMask.com may provide these links solely as a convenience to users.

  <p class="privacy-policy-section">It is important to remember that other organizations linked to BagMask.com’s Site
    and/or Services may collect
    information about users when they click these links or view these sites. BagMask.com cannot control third party
    collection of user information. You should contact the providers of these links and sites directly if you have any
    questions about their collection, use and/or retention of information. These sites and third parties may have their
    own privacy policies, or no policy at all. BagMask.com strongly encourages users to review the privacy policies of
    any
    third party sites or services before providing them with any personal information.

  <h2>11. Information Security</h2>
  <p class="privacy-policy-section">BagMask.com takes appropriate and reasonable security measures to protect against
    unauthorized access to or
    alteration, disclosure or destruction of its data, including the personal information of its users. These include
    internal reviews of data collection, storage and processing practices and security measures, including appropriate
    encryption and physical security measures to guard against unauthorized access to BagMask.com’s or third parties’
    systems where users’ personal information may be stored.

  <h2>12. Changes to Privacy Policy</h2>
  <p class="privacy-policy-section">Users acknowledge and agree that BagMask.com may change this Policy from time to
    time. However, in no event shall such
    changes reduce the rights or protections afforded users of the Site and/or Services without first providing notice
    of
    the changes and requesting user consent. BagMask.com will post any and all changes to the Policy on this page and,
    if
    the changes are significant or otherwise require user consent, will provide a more prominent and/or direct notice to
    users.

</div>

<div class="vertical-padding"></div>

