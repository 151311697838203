import {Injectable} from '@angular/core';
import {BagmaskFieldValues} from '../Namespaces/bagmaskFieldValues.namespace';

@Injectable({
  providedIn: 'root'
})
export class PricesService {
  postPriorityCostOptions = BagmaskFieldValues.getPostPriorityCostOptions();
  constructor() { }

  getPostingCostInDollars(priorityName: string): number {
    let returnValue = -1;
    // console.log('getPriorityPostingCostInDollars for priority: ' + priorityName);
    this.postPriorityCostOptions.forEach( val => {
      // console.log(val);
      if (val['priorityName'] === priorityName) {
        // console.log(val);
        // console.log('returning: ' + val['priorityPriceInDollars']);
        returnValue = val['priorityPriceInDollars'];
      }
    });
    return returnValue;
  }
}


