/* tslint:disable:prefer-const */
import {JobPosting} from '../Models/post.model';
import {catchError, first, map, tap, timestamp} from 'rxjs/operators';
import {Injectable, isDevMode, OnDestroy} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, Query} from '@angular/fire/firestore';
import {Observable, Subject, Subscription, throwError} from 'rxjs';
import {UIService} from './ui.service';
import {Company} from '../Models/company.model';
import {PostService} from './post.service';
import {UserService} from './user.service';

@Injectable()
export class FrontEndCompanyService implements OnDestroy {
  finishedSinglePostChanged = new Subject<JobPosting>();

  private companiesResults: AngularFirestoreCollection<any>;
  companiesObservable: Observable<any[]>;
  private companiesSubscription: Subscription;
  finishedCompaniesChanged = new Subject<Company[]>();
  finishedcompaniesChangedCount = new Subject<number>();
  companiesInfo: any;

  // Using fbSubs as an array of all Subscriptions so we can cancel them all at once at the end.
  // Remember to add new subscriptions in the service to this!
  private fbSubs: Subscription[] = [];
  private companyDoc: AngularFirestoreDocument<Company>;

  constructor(private afs: AngularFirestore,) {
    console.log('company service constructor');
    this.getAllCompanyData().then(res => {
      console.log('getAllCompanyData init run completed');
    });
  }

  fetchCompanies(): Promise<Observable<Company>> {
    console.log('inside fetchCompanies');
    const promise = new Promise<any>((resolve, reject) => {
      const companyList = this.afs.collection(
        'companies');

      this.companiesObservable = companyList.valueChanges({idField: 'id'});
      // this.companiesObservable.pipe(first()).subscribe

      this.companiesSubscription = this.companiesObservable.pipe(first()).subscribe(data => {
        console.log('fetchCompanies finished getting companies list');
        console.log(data);
        this.finishedCompaniesChanged.next(data);
        this.finishedcompaniesChangedCount.next(data.length);
      });
    });
    return promise;
  }

  // Get all company Data
  getAllCompanyData(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      // console.log('getAllCompanyData');
      this.companiesResults = this.afs.collection('companies');
      this.companiesObservable = this.companiesResults.valueChanges({idField: 'id'});

      try {
        this.companiesObservable.pipe(first()).subscribe(data => {
          // console.log('Finished getAllCompanyData');
          console.log(data);
          // console.log(data[0]);
          // console.log(data[0]['lastImportDate']);
          // returnValue = data[0]['lastImportDate'];
          resolve(data);
          this.companiesInfo = data;
          console.log('getAllCompanyData completed, companiesInfo: ', this.companiesInfo);
        });
      } catch (e) {
        console.log('Error in getAllCompanyData: ' + e);
      }
    });

    return promise;
  }


  editCompanyInDatabase(company: Company, companyId: string) {
    console.log('editCompanyInDatabase, companyId: ' + companyId);
    this.companyDoc = this.afs.doc<Company>('companies/' + companyId);
    this.companyDoc.update(company);
  }

  // Look for companies who have UIDs set to objects and fix them
  async fixCompanyUIDs() {
    console.log('Started fixCompanyUIDs');

    // Check if companiesInfo exists
    console.log('companiesInfo: ', this.companiesInfo);

    let companyList = this.companiesInfo;
    let companyListCount = 0;

    for (let company of companyList) {
      companyListCount++;
      console.log('companyListCount #' + companyListCount + ': ', company);
      // Check for an object, if so fix to just be a correct string of uid
      if (typeof company.uid === 'object') {
        console.log('we have a uid as object: ', company);

        // Update the company to make one UID the main UID
        const companyDoc = this.afs.doc<Company>('companies/' + company.id);
        await companyDoc.update({
          uid: company.uid.uid

        }).then(result => {
          console.log(result);
        }).catch(err => {
          console.log('ERROR with updating company UID ' + err);
        });

      }
    }

  }

  // Given a company name and full listing of jobPosts, find all of the unique UIDs associated with jobs posted for that company name
  async getUniqueUIDsForCompany(companyName: string, jobPostings: any): Promise<any[]> {
    let uniqueCompanyUIDList: any[] = [];
    let jobCount = 0;
    for (let job of jobPostings) {
      // Check if the job has the companyName we are searching for
      if (job.companyName === companyName) {
        // Add the uid to our list if we haven't already added it
        if (uniqueCompanyUIDList.map(e => e.uid).indexOf(job.uid) < 0) {
          uniqueCompanyUIDList.push(
            {
              uid: job.uid,
            }
          );
        }
      }
    }
    // Completed scan through jobs, return list of unique UIDs for that company name
    return uniqueCompanyUIDList;
  }

  async getCompanyIdForCompanyname(companyName: string) {
    for (let company of this.companiesInfo) {
      if (company.companyName === companyName) {
        return company.id;
      }
    }
  }

  // Given a company name and full listing of jobPosts, find all of the unique Emails associated with jobs posted for that company name
  async getUniqueEmailsForCompany(companyName: string, jobPostings: any): Promise<any[]> {
    let uniqueCompanyEmailList: any[] = [];
    let jobCount = 0;
    for (let job of jobPostings) {
      // Check if the job has the companyName we are searching for
      if (job.companyName === companyName) {
        // Add the Email to our list if we haven't already added it
        if (uniqueCompanyEmailList.map(e => e.contactEmail).indexOf(job.contactEmail) < 0) {
          uniqueCompanyEmailList.push(
            {
              contactEmail: job.contactEmail,
            }
          );
        }
      }
    }
    // Completed scan through jobs, return list of unique Emails for that company name
    return uniqueCompanyEmailList;
  }

  // Given a company name and full listing of jobPosts, count the jobs and job types for the company
  async getJobCountAndTypesForCompany(companyName: string, jobPostings: any): Promise<any> {
    let jobCounts = {
      totalCount: 0,
      AnesthesiologistCount: 0,
      CRNAcount: 0,
      CAACount: 0,
      standardPriorityCount: 0,
      priorityPriorityCount: 0,
      highPriorityCount: 0,
      postingPlanIncludedPriorityCount: 0,
      postingPlanHighIncludedPriorityCount: 0
    }

    for (let job of jobPostings) {
      // Check if the job has the companyName we are searching for
      if (job.companyName === companyName) {
        // Counting logic for all jobs
        jobCounts.totalCount++

        // Counting logic for job types
        switch (job.jobType) {
          case 'Anesthesiologist':
              jobCounts.AnesthesiologistCount++;
              break;
          case 'CRNA':
            jobCounts.CRNAcount++;
            break;
          case 'AA':
            jobCounts.CAACount++;
            break;
        }

        // Counting priorities for jobs
        switch (job.postPriority) {
          case 1:
            jobCounts.standardPriorityCount++;
            break;
          case 2:
            jobCounts.priorityPriorityCount++;
            // Check counts of plan-included or separately purchsed
            if (job.postFreePriority) {
              jobCounts.postingPlanIncludedPriorityCount++
            }
            break;
          case 3:
            jobCounts.highPriorityCount++;
            // Check counts of plan-included or separately purchsed
            if (job.postFreePriority) {
              jobCounts.postingPlanHighIncludedPriorityCount++
            }
            break;
        }

      }
    }
    // Completed scan through jobs, return list of unique Emails for that company name
    return jobCounts;
  }

  // Updates a company's refresh date to today
  updateCompanyLastRefreshDate(companyID: string) {
    console.log('updateCompanyLastImportDate for companyID: ' + companyID);
    const userDoc = this.afs.doc<Company>('companies/' + companyID);
    const today = new Date();
    console.log('Todays Date: ' + today);
    userDoc.update({lastRefreshDate: today}).then(result => {
      console.log('userDoc updated, result: ', result);
    }).catch(err => {
      console.log('ERROR with updateCompanyLastImportDate: ' + err);
    });
  }

  cancelSubscriptions() {
    // Step through each of the subscriptions and unsubscribe if they exist on destroy
    this.fbSubs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.companiesSubscription.unsubscribe;
  }

}
