import {Component, OnInit, HostListener, Input} from '@angular/core';
import { PaymentService } from '../payment.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {
  @Input() paymentAmount: number;
  @Input() paymentPrompt: string;
  @Input() paymentButtonColor: string;
  @Input() disabled: boolean;

  tokenSubmitted: boolean;
  descriptionValue: string;

  handler: any;
  // amount = 25000; // == $5.00

  constructor(private paymentSvc: PaymentService ) { }

  ngOnInit() {
    console.log('makePayment incoming paymentButtonColor: ', this.paymentButtonColor);
    this.tokenSubmitted = false;

    // Ensure that description field will be populated.  If we have a payment prompt use that otherwise default
    if (this.paymentPrompt) {
      this.descriptionValue = this.paymentPrompt;
    } else {
      this.descriptionValue = 'BagMask.com Payment';
    }

    // Check if we have incoming paymentButtonColor, otherwise set it to "warn"
    if (this.paymentButtonColor) {
      // Do nothing, use incoming color
    } else {
      // Set to 'warn' as a default
      this.paymentButtonColor = 'warn';
    }


    console.log('Stripe Payment component activated with amount: ' + this.paymentAmount);
    // Below may be a problem, could potentially need to include @types for stripe
    // @ts-ignore
    this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      image: 'https://jobs.bagmask.com/assets/bagmask-logo.png',
      zipCode: true,
      locale: 'auto',

      token: token => {
        this.paymentSvc.sendMessage('PaymentStarted');
        this.tokenSubmitted = true;
        this.paymentSvc.processPayment(token, this.paymentAmount);
      }
    });
  }

  handlePayment() {
    console.log('inside handlePayment, checking amount, if zero then automatic success');
    if (this.paymentAmount === 0) {
      this.paymentClosedSuccessful();
    } else {
      this.handler.open({
        name: 'BagMask',
        description: this.descriptionValue,
        amount: this.paymentAmount,
        closed: () => {
          // Check if token was submitted, otherwise just a close with no payment
          if (this.tokenSubmitted === true) {
            // Payment closed after successful token, should be successful payment
            this.paymentClosedSuccessful();
          } else {
            this.paymentClosedFailure();
          }

        }
      });
    }
  }


  @HostListener('window:popstate')
  onPopstate() {
    this.handler.close();
  }

  paymentClosedSuccessful() {
    this.paymentSvc.sendMessage('PaymentFinishedSuccessful');
    console.log('Stripe Payment Finished - Successful');
  }

  paymentClosedFailure() {
    this.paymentSvc.sendMessage('PaymentFinishedFailure');
    console.log('Stripe Payment Finished - Failure');
  }

}
