
<div class="add-post-button-container">
  <div fxLayoutAlign="space-around">

    <button *ngIf="paymentButtonColor == 'warn'" [disabled]="disabled" mat-button mat-raised-button color="warn" (click)="handlePayment()">
      {{paymentPrompt}}
    </button>

    <button *ngIf="paymentButtonColor != 'warn'" style="background-color:{{paymentButtonColor}}; color:white" [disabled]="disabled" mat-button mat-raised-button (click)="handlePayment()">
      {{paymentPrompt}}
    </button>
  </div>
</div>

<!--<button (click)="handlePayment()">-->
  <!--Pay and Activate Post.-->
<!--</button>-->
