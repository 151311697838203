import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../Services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  constructor(public authService: AuthService,) { }

  ngOnInit() {
    this.resetForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      })
    });
  }

  passwordResetSendEmail(email: string) {
    console.log('passwordResetSendEmail clicked, value: ' + email);
    this.authService.resetPassword(email);
  }


}
