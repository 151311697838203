import {Component, Inject, Input, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-pinterest-share',
  templateUrl: './pinterest-share.component.html',
  styleUrls: ['../sharing-buttons.scss']
})
export class PinterestShareComponent implements OnInit {
  @Input() summary: string;
  @Input() source: string;

  constructor(
    @Inject(DOCUMENT) public document: any,
    ) {

  }

  ngOnInit() {
  }

}
