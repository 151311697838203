import { Injectable } from '@angular/core';
import * as LSCache from 'lscache';
import {PostService} from './post.service';
import {JobPosting} from '../Models/post.model';

// import {current} from 'codelyzer/util/syntaxKind';
import {first} from 'rxjs/operators';

@Injectable()
export class CacheService {
  constructor(
    private postService: PostService,
    // private snackbar: MatSnackBar
  ) {

  }

  // This will empty the entire LSCache - typically only used for testing
  flushLSCache() {
    console.log('flushLSCache running');
    LSCache.flush();
  }

// This will empty the expired LSCache
  flushLSCacheExpired() {
    LSCache.flushExpired();
  }

  setLSCache(key: string, value: any, time: number) {
    console.log('setLSCache executing for key: ' + key + ' value: ' + value + ' time: ' + time);
    LSCache.set(key, value, time);
  }

  // Get the value in LSCache for a key value, returns null if no value found
  getLSCache(key: string) {
    // console.log('getLSCache running for key: ' + key);
    try {
      console.log(LSCache.get(key));
      return LSCache.get(key);
    } catch (e) {
      console.error('getLSCache failed: ' + e);
      return 'Error in getLSCache: ' + e;
    }
  }

  // Tracks the user's amount of logins based on localstorage using lscache library
  loginCountLocal() {
    console.log('loginCountLocal starting');
    let logins: number;
    try {
      logins = Number(LSCache.get('loginCount'));
    } catch (e) {
      console.log('Error in loginCountLocal: ' + e);
    }
    logins++;
    console.log('loginCountLocal - new visit count for user: ' + logins);
    LSCache.set('loginCount', logins);
  }

  // Grabs the list of current high priority postings and puts the information into the user's local storage
  async fetchAndStoreHighPriorityPostings() {
    // Check to see if we already have a result in local storage, if so then skip, otherwise grab fresh data
    // console.log(this.getLSCache('highPriorities'));
    if (this.getLSCache('highPriorities') === null) {
      console.log('fetchAndStoreHighPriorityPostings found a null, getting high pris');
      let results: JobPosting[];
      results = await this.postService.getPostingsByPriority(3);
      console.log('fetchAndStoreHighPriorityPostings returned results:');
      console.log(results);
      this.setLSCache('highPriorities', results, 1440);
      // Once we get a new cache of high pris, we want to reset counter to 0, to determine which will be viewed next
      this.resetLocalStorageHighPriorityCounter(results.length);
    } else {
      console.log('fetchAndStoreHighPriorityPostings already has value, no need to pull again for now');
    }
  }

  // Grabs the list of current high priority postings from Local storage
  getLocalStorageHighPriorityPostings(): any {
    return this.getLSCache('highPriorities');
  }

  // This sets the counter for the user's view of a high priority listings to round-robin through them and then reset
  // It also sets the count of high priorities so we know when we go over that count to loop back around
  resetLocalStorageHighPriorityCounter(length: number) {
    console.log('resetLocalStorageHighPriorityCounter called with length: ' + length);
    this.setLSCache('highPrioritiesCurrentCounter', 0, 1440 );
    this.setLSCache('highPrioritiesTotalCount', length, 1440);
  }

  getLocalStorageHighPriorityTotalCount() {
    return this.getLSCache('highPrioritiesTotalCount');
  }

  getLocalStorageHighPriorityCurrentCounter() {
    return this.getLSCache('highPrioritiesCurrentCounter');
  }

  setLocalStorageHighPriorityCurrentCounter(counterToSet: number) {
    this.setLSCache('highPrioritiesCurrentCounter', counterToSet, 1440 );
  }

  // Get the next hi pri record and then bump the count afterwards
  // @ts-ignore
  getNextLocalStorageHiPris(countToGet: number): Promise<any> {
    console.log('Inside getNextLocalStorageHiPris');
    const promise = new Promise<any>((resolve, reject) => {

      // TODO: Need to fix currentCounter set to 0 for BOTH records on a first pull from no cache!!
      let currentCounter = this.getLSCache('highPrioritiesCurrentCounter');
      // On first pass this number may not exist yet, if so then set to 0 for this run
      if (currentCounter == null) {
        console.log('getNextLocalStorageHiPris found currentCounter of null, setting to 0!');
        currentCounter = 0;
      }

      let totalCount = this.getLSCache('highPrioritiesTotalCount');
      // On first pass this number may not exist yet, if so then set to 0 for this run
      if (totalCount == null) {
        console.log('getNextLocalStorageHiPris found totalCount of null, setting to 0!');
        totalCount = countToGet;
      }

      this.fetchAndStoreHighPriorityPostings().then(res => {
        const jobPostings = this.getLSCache('highPriorities');
        const jobPostingsToReturn: Array<JobPosting> = [];
        console.log('Inside getNextLocalStorageHiPris, currentCounter: ' + currentCounter + ' jobPostings: ');

        // Need to step through to retrieve the records we want to and then know where to update currentCounter
        // Also must reset counter if it goes over totalCount to start grabbing from beginning again
        for (let i = 1; i <= countToGet; i++) {
          console.log('for loop, i: ' + i + ' countToGet: ' + countToGet + ' currentCounter: '
            + currentCounter + 'totalCount: ' + totalCount);

          console.log('Pushing jobPosting #' + currentCounter);
          // console.log(jobPostings);
          console.log(jobPostings[currentCounter]);
          jobPostingsToReturn.push(jobPostings[currentCounter]);
          // jobPostingsToReturn.push(jobPostings[currentCounter]);

          // Check to see if currentCounter went over totalCount
          // Adding 1 to currentCounter since it it 0 based
          if (currentCounter + 1 >= totalCount) {
            currentCounter = 0;
          } else {
            currentCounter++;
          }
        }

        // After we get current, add one to the number and check for reset to 0
        this.setLocalStorageHighPriorityCurrentCounter(currentCounter);
        console.log('getNextLocalStorageHiPris finished, final result: ');
        console.log(jobPostingsToReturn);
        resolve(jobPostingsToReturn);
      }).catch(e => {
        console.log('Error with getting hi pris: ' + e);
        return 'Failure in getNextLocalStorageHiPris';
      });
    });
    return promise;
  }




  // Example of how to set lscache with a JSON object and timer
  exampleLSCacheSet() {
    console.log('Preparing to loginCountLSCache');
    LSCache.set('data', {'name': 'Dillon', 'age': 37}, 5);
  }

  // Example of retrieving lscache information and creating it if it doesn't already/currently exist or was expired off
  exampleLSCacheGet() {
    console.log('Attemping to check existence of LSCache');
    try {
      console.log(LSCache.get('data'));
      console.log(LSCache.get('data').name);
      console.log(LSCache.get('data').age);
      console.log(LSCache.get('data')['age']); /* This shows how to grab a field with a passable variable name */
    } catch (e) {
      LSCache.set('data', {'name': 'Dillon', 'age': 37}, 5);
      console.log('loginGetLSCache error: ' + e);
    }
  }

}
