<!-- <mat-toolbar class="bagmask-sub-toolbar toolbar-smaller-font mat-elevation-z3"
             fxLayout="column" fxFlexAlign="center center">
</mat-toolbar> -->

<div class="loading-spinner" *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
  <div>Loading your Dream Job...</div>
</div>

<div class="job-container mat-typography" *ngIf="!isLoading" fxLayout="column" fxLayoutAlign="space-around center">
  <!-- Large header-->
  <div fxHide fxShow.gt-sm *ngIf="!shrinkHeader" class="job-post-header" fxLayout="column" fxLayoutAlign="space-around center">
    <div fxLayout="row wrap" class="job-post-header-content" fxLayoutAlign="start center">
      <div fxFlex=25 fxHide.xs class="card">
        <div class="card-body text-center">
            <!-- <span class="category-social text-info pull-right">
                <i class="fa fa-twitter"></i>
            </span> -->
            <div class="clearfix"></div>
            <div class="author">
                <div fxLayoutAlign="center center">
                  <a class="plain-link" [routerLink]="['/search', {companyName: dataSource?.companyName}]" target="_blank">
                    <div class="company-first-letter-logo" fxLayoutAlign="center center">
                      {{companyFirstLetter}}
                    </div>
                  </a>
                  <!-- TODO 10/8/2021 add image code for company logo here -->
                  <!-- <img src="../../../assets/Companies/Envision.png" alt="..." class="avatar-big img-raised border-gray"> -->
                </div>
                <div class="job-post-company-name">{{dataSource?.companyName}}</div>
                <!-- Example below of a direct link to just a company's postings -->
                <a class="text-primary job-post-header-medium" [routerLink]="['/search', {companyName: dataSource?.companyName}]" target="_blank">View all {{dataSource?.companyName}} opportunities</a>
                <!-- <p class="category"><a href="javascript:void(0)" class="text-danger">@kacibaum</a></p> -->
            </div>
            <!-- <div class="job-post-header-small">
              Employer actively reviewed job X days ago
            </div> -->
            <!-- <p class="card-description">
                "Less, but better – because it concentrates on the essential aspects, and the products are not burdened with non-essentials."
            </p> -->
        </div>
      </div>

      <div fxFlex></div>

      <div fxFlex=70 fxFlex.xs=80 class="job-post-header-info-section" fxLayout="column">
        <div class="job-post-header-bold">
          {{dataSource?.jobType}} - {{dataSource?.shortDescription}}
        </div>
        <div fxHide fxShow.xs class="job-post-header-company">
          <a [routerLink]="['/search', {companyName: dataSource?.companyName}]">{{dataSource?.companyName}}</a>
        </div>
        <!-- TODO 10/8/2021: Enable code showing when company last updated here -->
        <!-- <div class="job-post-header-small">
          Employer actively reviewed job X days ago
        </div> -->

        <div class="job-post-top-buttons" fxFlex=100 fxLayout="row wrap" fxLayoutAlign="start center">

          <button fxflex *ngIf="dataSource?.companyPortal"
            class="bagmask-post-toolbar-apply-button" mat-raised-button
            matTooltip="Apply for this position"
            (click)="applyForJob(dataSource, eventLeadWithLink)">
            Apply Now
          </button>

          <!--Apply button without company portal link, instead creates e-mail with "application" in subject-->
          <a  *ngIf="!dataSource?.companyPortal" href={{this.emailStringForJob}}>
            <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
                    matTooltip="Apply for this position"
                    (click)="applyForJobAnalyticsEvent(dataSource, eventLeadNoLink)">
              Apply Now
            </button>
          </a>

          <a href={{this.emailStringForJob}}>
            <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
                    matTooltip="Send an Email Inquiry about Job Posting"
                    (click)="applyForJobAnalyticsEvent(dataSource, eventLeadViaEmail)">
              Email Contact
            </button>
          </a>

          <!-- Hiding "link job" button below -->
          <!--
          <a class="plain-link" [routerLink]="['/post/' + singlePostID]" target="_blank">
            <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
              matTooltip="Display Shareable Link"
              color="accent">
              Link Job
            </button>
          </a> -->

          <!-- <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
            matTooltip="Display Shareable Link"
            (click)="displayShare()"
            color="accent">
            Share Job Link
          </button> -->

          <span fxFlex fxShow fxHide.xs></span>

          <button fxflex class="bagmask-post-toolbar-apply-button" (click)="goToHome()" mat-raised-button matTooltip="Close Posting" color="accent">
            <mat-icon class="back-to-results-arrow" aria-label="label goes here">arrow_back</mat-icon>
            Back to Results
          </button>

        </div>
      </div>

    </div>

    <div class="job-post-horizontal-divider-long"></div>
  </div>

  <!-- Shrink header -->
  <div  fxHide fxShow.gt-sm *ngIf="shrinkHeader" class="job-post-header" fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="row wrap" fxLayoutAlign="start center">

      <div fxFlex=100 fxFlex.xs=80 class="job-post-header-info-section" fxLayout="column">
        <div fxHide fxShow.xs class="job-post-header-company">
          <a [routerLink]="['/search', {companyName: dataSource?.companyName}]">{{dataSource?.companyName}}</a>
        </div>
        <!-- TODO 10/8/2021: Enable code showing when company last updated here -->
        <!-- <div class="job-post-header-small">
          Employer actively reviewed job X days ago
        </div> -->

        <div class="job-post-top-buttons" fxFlex=100 fxLayout="row wrap" fxLayoutAlign="space-around center">

          <button fxflex *ngIf="dataSource?.companyPortal"
            class="bagmask-post-toolbar-apply-button" mat-raised-button
            matTooltip="Apply for this position"
            (click)="applyForJob(dataSource, eventLeadWithLink)">
            Apply Now
          </button>

          <!--Apply button without company portal link, instead creates e-mail with "application" in subject-->
          <a  *ngIf="!dataSource?.companyPortal" href={{this.emailStringForJob}}>
            <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
                    matTooltip="Apply for this position"
                    (click)="applyForJobAnalyticsEvent(dataSource, eventLeadNoLink)">
              Apply Now
            </button>
          </a>

          <a href={{this.emailStringForJob}}>
            <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
                    matTooltip="Send an Email Inquiry about Job Posting"
                    (click)="applyForJobAnalyticsEvent(dataSource, eventLeadViaEmail)">
              Email Contact
            </button>
          </a>

          <!-- Hiding "link job" button below -->
          <!--
          <a class="plain-link" [routerLink]="['/post/' + singlePostID]" target="_blank">
            <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
              matTooltip="Display Shareable Link"
              color="accent">
              Link Job
            </button>
          </a> -->

          <!-- <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
            matTooltip="Display Shareable Link"
            (click)="displayShare()"
            color="accent">
            Share Job Link
          </button> -->

          <span fxFlex fxShow fxHide.xs></span>

          <button fxflex class="bagmask-post-toolbar-apply-button" (click)="goToHome()" mat-raised-button matTooltip="Close Posting" color="accent">
            <mat-icon class="back-to-results-arrow" aria-label="label goes here">arrow_back</mat-icon>
            Back to results
          </button>

        </div>
      </div>

    </div>

    <div class="job-post-horizontal-divider-long"></div>
  </div>

  <!-- Mobile header -->
  <div fxHide fxShow.lt-md class="job-post-header-mobile" fxLayout="column" fxLayoutAlign="start center">
    <div class="job-post-top-buttons-mobile" fxFlex=100 fxLayout="row" fxLayoutAlign="start center">

      <button fxflex *ngIf="dataSource?.companyPortal"
        class="bagmask-post-toolbar-apply-button" mat-raised-button
        matTooltip="Apply for this position"
        (click)="applyForJob(dataSource, eventLeadWithLink)">
        Apply Now
      </button>

      <!--Apply button without company portal link, instead creates e-mail with "application" in subject-->
      <a  *ngIf="!dataSource?.companyPortal" href={{this.emailStringForJob}}>
        <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
                matTooltip="Apply for this position"
                (click)="applyForJobAnalyticsEvent(dataSource, eventLeadNoLink)">
          Apply Now
        </button>
      </a>

      <a href={{this.emailStringForJob}}>
        <button fxflex class="bagmask-post-toolbar-apply-button" mat-raised-button
                matTooltip="Send an Email Inquiry about Job Posting"
                (click)="applyForJobAnalyticsEvent(dataSource, eventLeadViaEmail)">
          Email Contact
        </button>
      </a>

      <button fxflex class="bagmask-post-toolbar-apply-button" (click)="goToHome()" mat-raised-button matTooltip="Close Posting" color="accent">
        <mat-icon class="back-to-results-arrow" aria-label="label goes here">arrow_back</mat-icon>
        Back To Results
      </button>

    </div>
    <div class="job-post-horizontal-divider-long"></div>
  </div>

  <div [ngClass.lt-md]="'job-post-body-mobile'" [ngClass.gt-sm]="'job-post-body'" fxLayoutAlign="start start" fxLayout="row" fxLayout.lt-md="column">

    <!-- <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
      Main Info
    </div> -->

    <div fxHide fxShow.lt-md class="mobile-body-start-spacing"></div>

    <div class="job-post-body-left" fxFlex=65>
      <div class="job-section-header">
        Job Description
      </div>

      <!-- Check if HTML or not, and display accordingly -->
      <!-- *ngIf="!uiService.isHTML(data.job.facHospitalInfo)" -->
      <!-- <div class="job-section-text">{{dataSource?.longDescription}}</div> -->
      <div [ngClass]=sectionTextHasWhiteSpacePreLine() [innerHTML]="dataSource?.longDescription"></div>
      <!-- <div class="job-section-text" [innerHTML]="dataSource?.longDescription"></div> -->
    </div>

    <div fxFlex fxShow fxHide.lt-md></div>

    <div class="job-section-header" fxHide fxShow.lt-md>
      Key Info
    </div>
    <div class="job-post-body-right"  fxFlex=30 fxLayout="column"
                                      fxFlex.lt-md=45 fxLayout.lt-md="row wrap" fxLayoutAlign.lt-md="space-around start">
      <div class="job-section-header" fxHide fxShow.gt-sm>
        Key Info
      </div>

      <div fxFlex=45 fxFlex.lt-md=90 class="job-post-body-right-section">
        <div class="job-info" *ngIf="!hasAddressCityZip">
          <span class="job-info-label">State:</span> {{dataSource?.state}}
        </div>
        <div class="job-info">
          <span class="job-info-label">Job Type:</span> {{dataSource?.jobType}}
        </div>
        <!-- Mobile location, since we aren't showing in other box below -->
        <div fxHide fxShow.lt-md class="job-info">
          <span class="job-info-label">Location:</span> {{dataSource?.state}}
        </div>
        <div class="job-info">
          <span class="job-info-label">Date Posted:</span> {{datePostedConverted?.toDate() | date: 'shortDate'}}
        </div>
        <div class="job-info">
          <span class="job-info-label">Employment Status:</span> {{dataSource?.employmentStatus}}
        </div>
        <div class="job-info">
          <span class="job-info-label">Tax Status:</span> {{dataSource?.taxStatus}}
        </div>
        <div  class="job-info"
              *ngIf="dataSource?.minSalary > 0 || dataSource?.maxSalary >0">
          <span class="job-info-label">Salary Range: </span> ${{dataSource?.minSalary}}k - ${{dataSource?.maxSalary}}k
        </div>
        <div  class="job-info"
              *ngIf="dataSource?.minHourly > 0 || dataSource?.maxHourly >0">
          <span class="job-info-label">Hourly Rate: $</span> ${{dataSource?.minHourly}}k - ${{dataSource?.maxHourly}}k
        </div>
        <div class="job-info" *ngIf="dataSource?.startDate != 'Immediate'">
          <span class="job-info-label">Start Date:</span> {{dataSource?.startDate | date: 'shortDate'}}
        </div>
      </div>

      <div fxHide.lt-md fxFlex=45 class="job-post-body-right-section" *ngIf="hasAddressCityZip" fxLayout="column" fxLayoutAlign="start start">
        <div class="job-info" *ngIf="dataSource?.facilityName">
          <span class="job-info-label">Facility Name:</span> {{dataSource?.facilityName}}
        </div>

        <div class="job-info" *ngIf="dataSource?.address">
          <span class="job-info-label">Address:</span> {{dataSource?.address}}
        </div>

        <div class="job-info" *ngIf="dataSource?.city">
          <span class="job-info-label">City:</span> {{dataSource?.city}}
        </div>

        <div class="job-info" *ngIf="dataSource?.state">
          <span class="job-info-label">State:</span> {{dataSource?.state}}
        </div>

        <div class="job-info" *ngIf="dataSource?.zipCode">
          <span class="job-info-label">Zip:</span> {{dataSource?.zipCode}}
        </div>

        <div class="job-info-map-container" >
          <agm-map
            [latitude]="dataSource?.position.geopoint.latitude"
            [longitude]="dataSource?.position.geopoint.longitude"
            [zoom]="8"
            [disableDefaultUI]="true"
            [zoomControl]="true">
          <agm-marker
            [latitude]="dataSource?.position.geopoint.latitude"
            [longitude]="dataSource?.position.geopoint.longitude">
          </agm-marker>
          </agm-map>
          <p *ngIf="loading">Loading...</p>
        </div>

      </div>

    </div>

  </div>

  <div class="job-post-horizontal-divider"></div>

  <div *ngIf="salSectionsHaveValues || resSectionsHaveValues || facSectionsHaveValues"
        [ngClass.lt-md]="'job-post-body-mobile'" [ngClass.gt-sm]="'job-post-body'" fxLayoutAlign="start start" fxLayout="row wrap">

    <div *ngIf="salSectionsHaveValues" class="job-post-body-left" fxFlex = 32 fxFlex.lt-md = 100 fxLayoutAlign="space-around start" fxLayout="column">
      <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
        Salary and Benefits
      </div>
      <!-- <div fxLayout="row wrap"> -->
      <div fxLayout="column">

        <div class="job-info job-info-minor" *ngIf="dataSource?.minHourly > 0 || dataSource?.maxHourly >0">
            <span class="job-info-label">Hourly Rate: </span> ${{dataSource?.minHourly}}k - ${{dataSource?.maxHourly}}k
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salSigningBonus">
          <span class="job-info-label">Signing Bonus: </span>${{dataSource?.salSigningBonus}}k
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salSigningBonusCommitmentReimbursement">
          <span class="job-info-label">Signing Bonus Commitment: </span>{{dataSource?.salSigningBonusCommitmentReimbursement}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salTuitionReimbursement">
          <span class="job-info-label">Tuition Reimbursement: </span>{{dataSource?.salTuitionReimbursement}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salInterviewExpenses">
          <span class="job-info-label">Interview Expenses: </span>{{dataSource?.salInterviewExpenses}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salRelocationAssistance">
          <span class="job-info-label">Relocation Assistance: </span>{{dataSource?.salRelocationAssistance}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salWeeksPaidVacation">
          <span class="job-info-label">Paid Vacation Weeks: </span>{{dataSource?.salWeeksPaidVacation}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salPaidCMETime">
          <span class="job-info-label">Paid CME Time: </span>{{dataSource?.salPaidCMETime}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salCMEAllowance">
          <span class="job-info-label">CME Allowance: </span>{{dataSource?.salCMEAllowance}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salPaidOvertime">
          <span class="job-info-label">Paid Overtime: </span>{{dataSource?.salPaidOvertime}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salSalaryIncomeBoolean">
          <span class="job-info-label">Income: </span>{{dataSource?.salSalaryIncomeBoolean}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salIncomeFeeForService">
          <span class="job-info-label">Fee-for-service Income: </span>{{dataSource?.salIncomeFeeForService}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salEventualPartnership">
          <span class="job-info-label">Eventual Partnership in Practice: </span>{{dataSource?.salEventualPartnership}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salIncomeFullPartnerPractice">
          <span class="job-info-label">Income as Full Partner: </span>{{dataSource?.salIncomeFullPartnerPractice}}
        </div>

        <div class="job-info job-info-minor" *ngIf="dataSource?.salMedicalInsurance">
          <span class="job-info-label">Medical Insurance: </span>{{dataSource?.salMedicalInsurance}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salRetirementPlan">
          <span class="job-info-label">Retirement Plan: </span>{{dataSource?.salRetirementPlan}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salMalpracticeInsurance">
          <span class="job-info-label">Malpractice Insurance: </span>{{dataSource?.salMalpracticeInsurance}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.salDisabilityInsurance">
          <span class="job-info-label">Disability Insurance: </span>{{dataSource?.salDisabilityInsurance}}
        </div>


      </div>

    </div>

    <div fxFlex></div>

    <div  *ngIf="resSectionsHaveValues" class="job-post-body-left" fxFlex = 32 fxFlex.lt-md = 100 fxLayoutAlign="start start" fxLayout="column">
      <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
        Responsibilities and Requirements
      </div>
      <!-- <div fxLayout="row wrap"> -->
      <div fxLayout="column">
        <div class="job-info job-info-minor" *ngIf="dataSource?.resShiftType">
          <span class="job-info-label">Shift Type: </span>{{this.uiService.convertArrayToString(dataSource?.resShiftType)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resShiftDuration">
          <span class="job-info-label">Shift Duration: </span>{{this.uiService.convertArrayToString(dataSource?.resShiftDuration)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resScheduledCall">
          <span class="job-info-label">Scheduled Call: </span>{{dataSource?.resScheduledCall}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resHowOftenFirstCall">
          <span class="job-info-label">How Often First Call: </span>{{dataSource?.resHowOftenFirstCall}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resDayOffAfterFirstCall">
          <span class="job-info-label">Day Off After First Call: </span>{{dataSource?.resDayOffAfterFirstCall}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resHowOftenSecondCall">
          <span class="job-info-label">How Often Second Call: </span>{{dataSource?.resHowOftenSecondCall}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resCallType">
          <span class="job-info-label">Call Type: </span>{{dataSource?.resCallType}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resCallResponseTime">
          <span class="job-info-label">Call Response Time: </span>{{dataSource?.resCallResponseTime}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resProviderModel">
          <span class="job-info-label">Provider Model: </span>{{dataSource?.resProviderModel}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resDirectionSupervision">
          <span class="job-info-label">Direction or Supervision: </span>{{dataSource?.resDirectionSupervision}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resCaseMix">
          <span class="job-info-label">Case Mix: </span>{{this.uiService.convertArrayToString(dataSource?.resCaseMix)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resSpinalAnesthesia">
          <span class="job-info-label">Perform Spinal Anesthesia: </span>{{dataSource?.resSpinalAnesthesia}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resLaborEpidurals">
          <span class="job-info-label">Perform Epidurals: </span>{{dataSource?.resLaborEpidurals}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resArterialCatheter">
          <span class="job-info-label">Place A-Lines: </span>{{dataSource?.resArterialCatheter}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resCentralVenousCatheter">
          <span class="job-info-label">Place Central Lines: </span>{{dataSource?.resCentralVenousCatheter}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resRegionalBlocks">
          <span class="job-info-label">Perform Regional Blocks: </span>{{dataSource?.resRegionalBlocks}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.reqNewGrads">
          <span class="job-info-label">New Graduates Accepted: </span>{{dataSource?.reqNewGrads}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.reqStateLicense">
          <span class="job-info-label">State License: </span>{{this.uiService.convertArrayToString(dataSource?.reqStateLicense)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.reqNBCRNACertification">
          <span class="job-info-label">NBCRNA Certification Status: </span>{{dataSource?.reqNBCRNACertification}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.reqSubspecialtyFellowship">
          <span class="job-info-label">Subspecialty Fellowship: </span>{{this.uiService.convertArrayToString(dataSource?.reqSubspecialtyFellowship)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.reqVisaAccepted">
          <span class="job-info-label">VISAs Accepted: </span>{{this.uiService.convertArrayToString(dataSource?.reqVisaAccepted)}}
        </div>
      </div>
    </div>

    <div fxFlex></div>

    <div *ngIf="facSectionsHaveValues" class="job-post-body-left" fxFlex = 32 fxFlex.lt-md = 100 fxLayoutAlign="start start" fxLayout="column">
      <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
        Facility Info
      </div>
      <!-- <div fxLayout="row wrap"> -->
      <div fxLayout="column">
        <div class="job-info job-info-minor" *ngIf="dataSource?.resShiftType">
          <span class="job-info-label">Shift Type: </span>{{this.uiService.convertArrayToString(dataSource?.resShiftType)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resShiftDuration">
          <span class="job-info-label">Shift Duration: </span>{{this.uiService.convertArrayToString(dataSource?.resShiftDuration)}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.resScheduledCall">
          <span class="job-info-label">Scheduled Call: </span>{{dataSource?.resScheduledCall}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.facAverageCredentialingTime">
          <span class="job-info-label">Average Credentialing Time: </span>{{dataSource?.facAverageCredentialingTime}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.facGroupExclusiveContract">
          <span class="job-info-label">Anesthesia Group Exclusive Contract: </span>{{dataSource?.facGroupExclusiveContract}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.facOtherSites">
          <span class="job-info-label">Other Anesthesia Sites Covered: </span>{{dataSource?.facOtherSites}}
        </div>
        <div class="job-info job-info-minor" *ngIf="dataSource?.facTrainingPrograms">
          <span class="job-info-label">Training Programs: </span>{{dataSource?.facTrainingPrograms}}
        </div>

      </div>
    </div>

  </div>

  <!-- only show the below horizontal divider if any of the three sections above have values -->
  <div *ngIf="salSectionsHaveValues || resSectionsHaveValues || facSectionsHaveValues" class="job-post-horizontal-divider"></div>

  <div [ngClass.lt-md]="'job-post-body-mobile'" [ngClass.gt-sm]="'job-post-body'" fxLayoutAlign="start start" fxLayout="column">


    <!-- <figure class="newcard newcard--normal" fxFlex=32>
      <div class="newcard__image-container">
        Salary and Benefits
      </div>

      <figcaption class="newcard__caption">


        <table class="newcard__stats">
          <tbody>
          <tr class="job-info-table-row" *ngIf="dataSource?.minSalary > 0 || dataSource?.maxSalary >0">
            <th>Salary Range</th>
            <td>${{dataSource?.minSalary}}k - ${{dataSource?.maxSalary}}k</td>
          </tr>
          <tr class="job-info-table-row" *ngIf="dataSource?.minHourly > 0 || dataSource?.maxHourly >0">
            <th>Hourly Rate</th>
            <td>${{dataSource?.minHourly}}k - ${{dataSource?.maxHourly}}k</td>
          </tr>

          <tr class="job-info-table-row" *ngIf="dataSource?.salSigningBonus">
            <th>Signing Bonus</th>
            <td>${{dataSource?.salSigningBonus}}k</td>
          </tr>


          <tr class="job-info-table-row" *ngIf="dataSource?.salSigningBonusCommitmentReimbursement">
            <th>Signing Bonus Commitment</th>
            <td>{{dataSource?.salSigningBonusCommitmentReimbursement}}</td>
          </tr>


          <tr class="job-info-table-row" *ngIf="dataSource?.salTuitionReimbursement">
            <th>Tuition Reimbursement</th>
            <td>{{dataSource?.salTuitionReimbursement}}</td>
          </tr>

        </tbody></table>

      </figcaption>
    </figure> -->

    <div *ngIf="dataSource?.facCompanyInfo" class="job-post-bottom-info" fxFlex = 45 fxLayoutAlign="start start" fxLayout="column">
      <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
        Company Info
      </div>
      <div fxLayout="row wrap">

         <div class="job-info job-info-minor" *ngIf="dataSource?.facCompanyInfo">
          <!-- <span class="job-info-label">Company Info: </span> -->
          <div [ngClass]=sectionTextHasWhiteSpacePreLine()  [innerHTML]="dataSource?.facCompanyInfo"></div>
        </div>

      </div>
    </div>

    <div fxFlex></div>

    <div *ngIf="dataSource?.facHospitalInfo" class="job-post-bottom-info" fxFlex = 45 fxLayoutAlign="start start" fxLayout="column">
      <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
        Hospital Info
      </div>
      <div fxLayout="row wrap">

        <div class="job-info job-info-minor" *ngIf="dataSource?.facHospitalInfo">
          <!-- <span class="job-info-label">Hospital Info: </span> -->
          <div [ngClass]=sectionTextHasWhiteSpacePreLine()  [innerHTML]="dataSource?.facHospitalInfo"></div>
        </div>

      </div>
    </div>

    <div fxFlex></div>

    <div *ngIf="dataSource?.facCommunityInfo" class="job-post-bottom-info" fxFlex = 45 fxLayoutAlign="start start" fxLayout="column">
      <div fxLayoutAlign="center center" fxFlex="0 1 100%" class="post-dialog-title mat-typography">
        Community Info
      </div>
      <div fxLayout="row wrap">

        <div class="job-info job-info-minor" *ngIf="dataSource?.facCommunityInfo">
          <!-- <span class="job-info-label">Community Info: </span> -->
          <div class="job-section-text" [innerHTML]="dataSource?.facCommunityInfo"></div>
        </div>

      </div>
    </div>
  </div>






