import {Component, Inject, Input, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-twitter-share',
  templateUrl: './twitter-share.component.html',
  styleUrls: ['../sharing-buttons.scss']
})
export class TwitterShareComponent implements OnInit {
  @Input() title: string;
  @Input() summary: string;
  @Input() source: string;

  constructor(@Inject(DOCUMENT) public document: any,) { }

  ngOnInit() {
  }

}
