<div *ngIf="authService.fullUserInfo.userCompanyMonthlyPlan" class="mat-typography" fxLayout="row wrap"
     fxLayoutAlign="center" fxLayoutGap="20px">
  <h3>Monthly Plan Free Priorities Remaining:
  {{authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsRemaining}}</h3>
</div>

<div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="20px">
  <button mat-button mat-raised-button class="user-profile-button" color="primary"
          [ngClass]="{'selected-button': buttonSelected === 'All' }"
          (click)="postStatusSelected('All')">All</button>

  <button mat-button mat-raised-button class="user-profile-button" color="primary"
          [ngClass]="{'selected-button': buttonSelected === 'Active' }"
          (click)="postStatusSelected('Active')">Active</button>

  <button mat-button mat-raised-button class="user-profile-button" color="primary"
          [ngClass]="{'selected-button': buttonSelected === 'Expired' }"
          (click)="postStatusSelected('Expired')">Expired</button>

  <button mat-button mat-raised-button class="user-profile-button" color="primary"
          [ngClass]="{'selected-button': buttonSelected === 'Inactive' }"
          (click)="postStatusSelected('Inactive')">Inactive</button>

  <button mat-button mat-raised-button class="user-profile-button" color="primary"
          [ngClass]="{'selected-button': buttonSelected === 'Template' }"
          (click)="postStatusSelected('Template')">Template</button>

  <button mat-button mat-raised-button class="user-profile-button" color="primary"
          [ngClass]="{'selected-button': buttonSelected === 'Saved' }"
          (click)="postStatusSelected('Saved')">Saved</button>

  <mat-form-field class="filter-field">
    <input matInput (keyup)="applyFilter($any($event.target).value)" placeholder="Filter Results">
  </mat-form-field>
</div>

<div [hidden]="!isLoading" fxLayout="row" fxLayoutAlign="center center">
  <app-loading-spinner class="bagmask-job-posting-spinner"></app-loading-spinner>
</div>
<div [hidden]="isLoading">
  <div class="no-results-section" [hidden]="returnedNoJobsBoolean">
    <mat-card class="bagmask-card-dark-blue"
              fxFlex="100%" fxLayoutAlign="center center"
              fxLayout="row wrap">
      <mat-card-title class="center-mat-card-title" fxFlex="100%">
        No Job Posts of the selected type were found.
      </mat-card-title>
    </mat-card>
  </div>
</div>
<div [hidden]="isLoading">
  <div [hidden]="!returnedNoJobsBoolean">
  <mat-table class="bagmask-posting-table" #table [dataSource]="dataSource" matSort aria-label="Elements">
    <!-- Job Type Column -->
    <ng-container matColumnDef="jobType">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{getPostJobTypeName(row.jobType)}}</mat-cell>
    </ng-container>

    <!-- City Column -->
    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.city}}</mat-cell>
    </ng-container>

    <!-- State Column -->
    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.state}}</mat-cell>
    </ng-container>

    <!-- Short Description Column -->
    <ng-container matColumnDef="shortDescription">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Short Description</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.shortDescription}}</mat-cell>
    </ng-container>

    <!-- Company Job ID Column -->
    <ng-container matColumnDef="companyJobID">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Job ID</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.companyJobID}}</mat-cell>
    </ng-container>

    <!-- Date Posted Column -->
    <ng-container matColumnDef="datePosted">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date Posted</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.datePosted.toDate() | date: 'shortDate'}}</mat-cell>
    </ng-container>

    <!-- Date Posted Column -->
    <ng-container matColumnDef="expirationDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.expirationDate.toDate() | date: 'shortDate'}}</mat-cell>
    </ng-container>

    <!--&lt;!&ndash; Post Priority Column &ndash;&gt;-->
    <!--<ng-container matColumnDef="postPriority">-->
      <!--<mat-header-cell *matHeaderCellDef mat-sort-header>Priority</mat-header-cell>-->
      <!--<mat-cell-->
        <!--[ngClass]="checkPriorityClass(row.postPriority)"-->
        <!--*matCellDef="let row">{{getPostPriorityName(row.postPriority)}}</mat-cell>-->
    <!--</ng-container>-->

    <!-- Post Status Column -->
    <ng-container matColumnDef="postStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell
        [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)"
        *matCellDef="let row">{{row.postStatus}}</mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
      <mat-cell [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)" *matCellDef="let row">
        <!--Active Post Buttons-->
        <button matTooltip="Edit Job Post" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Active'"
                (click)="userEditPostButtonClick(row.id, row.postStatus, row.jobType)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            edit</mat-icon>
        </button>
        <button matTooltip="Upgrade Job Post" class="user-profile-small-button" mat-raised-button
                *ngIf="(row.postStatus === 'Active' &&
                        row.postPriority === 1 || row.postPriority === 2)"
                (click)="userUpgradePost(row.id, row.companyJobID)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            arrow_upward</mat-icon>
        </button>
        <button matTooltip="Add Plan-Included Priority to Job Post" class="user-profile-small-button" mat-raised-button
                *ngIf="(row.postStatus === 'Active' &&
                        row.postPriority === 1 &&
                        authService.fullUserInfo.userCompanyMonthlyPlanFreePriorityPostsRemaining > 0)"
                (click)="userFreePriorityAddButtonClick(row.id, row.postPriority)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            expand_less</mat-icon>
        </button>
        <button matTooltip="Remove Plan-Included Priority to Job Post" class="user-profile-small-button" mat-raised-button
                *ngIf="(row.postStatus === 'Active' &&
                        row.postPriority === 2 &&
                        row.postFreePriority === true)"
                (click)="userFreePriorityRemoveButtonClick(row.id, row.postPriority)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            arrow_downward</mat-icon>
        </button>
        <button matTooltip="Deactivate Post" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Active'"
                (click)="userPostDeactivateClick(row.id)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            archive</mat-icon>
        </button>
        <button matTooltip="Extend Post's Active Time by 30 days" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Active'"
                (click)="add30DaysUntilExpiration(row.id)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            add</mat-icon>
        </button>

        <!--Expired Post Buttons-->
        <button matTooltip="Edit and Re-activate Expired Job Post" class="user-profile-small-button"
                mat-raised-button *ngIf="row.postStatus === 'Expired'"
                (click)="userEditPostButtonClick(row.id, row.postStatus, row.jobType)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            edit</mat-icon>
        </button>
        <button matTooltip="Re-activate Post" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Expired'" (click)="userPostReactivateClick(row.id)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            restore_from_trash</mat-icon>
        </button>
        <button matTooltip="Delete Expired Post" class="user-profile-small-button" mat-raised-button color="warn"
                *ngIf="row.postStatus === 'Expired'" (click)="userPostDeleteInactiveClick(row.id, row.shortDescription)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            delete</mat-icon>
        </button>

        <!--Inactive Post Buttons-->
        <button matTooltip="Edit and Re-activate Expired Job Post" class="user-profile-small-button"
                mat-raised-button *ngIf="row.postStatus === 'Inactive'"
                (click)="userEditPostButtonClick(row.id, row.postStatus, row.jobType)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            edit</mat-icon>
        </button>
        <button matTooltip="Re-activate Post" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Inactive'" (click)="userPostReactivateClick(row.id)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            restore_from_trash</mat-icon>
        </button>
        <button matTooltip="Delete Inactive Post" class="user-profile-small-button" mat-raised-button color="warn"
                *ngIf="row.postStatus === 'Inactive'" (click)="userPostDeleteInactiveClick(row.id, row.shortDescription)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            delete</mat-icon>
        </button>

        <!--Template Post Buttons-->
        <button matTooltip="Edit Post Template" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Template'" (click)="userEditPostButtonClick(row.id, row.postStatus, row.jobType)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            edit</mat-icon>
        </button>
        <button matTooltip="Delete Post Template" class="user-profile-small-button" mat-raised-button color="warn"
                *ngIf="row.postStatus === 'Template'" (click)="userPostDeleteTemplateOrSavedClick(row.id)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            delete</mat-icon>
        </button>

        <!--Saved Post Buttons-->
        <button matTooltip="Edit Saved Post" class="user-profile-small-button" mat-raised-button
                *ngIf="row.postStatus === 'Saved'" (click)="userEditPostButtonClick(row.id, row.postStatus, row.jobType)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            edit</mat-icon>
        </button>
        <button matTooltip="Delete Saved Post" class="user-profile-small-button" mat-raised-button color="warn"
                *ngIf="row.postStatus === 'Saved'" (click)="userPostDeleteTemplateOrSavedClick(row.id)">
          <mat-icon class="bagmask-toolbar-icon user-person-icon" aria-label="label goes here">
            delete</mat-icon>
        </button>

      </mat-cell>
    </ng-container>

    <!--<ng-container matColumnDef="actions">-->
      <!--<mat-header-cell> Actions </mat-header-cell>-->
      <!--<mat-cell *matCellDef="let row" >-->
        <!--<button md-raised-button >Edit</button>-->
      <!--</mat-cell>-->
    <!--</ng-container>-->

    <!--Functionality below enables highlighting based on priority-->
    <mat-header-row class="bagmask-table-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row class="cursor-pointer"
             *matRowDef="let row; columns: displayedColumns;"
             [ngClass]="checkPriorityClass(row.postPriority, row.postStatus)">
             <!--(click)="openDialog(row)"-->
    </mat-row>

  </mat-table>

  <!--Example of how to dynamically click and highlight row, working.-->
  <!--<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
  <!--<mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}" (click)="highlight(row)"-->
  <!--*matRowDef="let row; columns: displayedColumns;" (click)="openDialog(row)"></mat-row>-->
  <!--</mat-table>-->

  <mat-paginator
    #paginator
    [pageIndex]="0"
    [pageSize]="25"
    [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
</div>
