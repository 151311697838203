<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="loginState === 'login'">

  <form *ngIf="!isLoading"
        class="bagmask-login-form"
        [formGroup]="loginForm"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        (ngSubmit)="onSubmitLogin()">

    <mat-form-field>
      <input
        type="email"
        matInput
        placeholder="Your email"
        required
        formControlName="email">
      <mat-hint>Please enter a valid email.</mat-hint>
      <mat-error>Invalid or missing email.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        type="password"
        matInput
        placeholder="Your password"
        required
        formControlName="password">
      <mat-hint>Please enter your password.</mat-hint>
      <mat-error>Missing password.</mat-error>
    </mat-form-field>

    <div class="small-vertical-space"></div>

    <div class="small-vertical-space">
      <button *ngIf="!isLoading" type="submit" fxFlex="235px"
              class="rounded-button"
              mat-raised-button color="warn" [disabled]="loginForm.invalid">Login
      </button>
    </div>

    <div class="small-vertical-space">
      <button *ngIf="!isLoading" fxFlex="235px"
              class="rounded-button"
              (click)="passwordReset()"
              mat-raised-button color="accent">Password Reset
      </button>
    </div>

    <div class="vertical-space"></div>

    <div class="small-vertical-space">
      <button fxFlex="235px"
              class="rounded-button"
              color="primary"
              (click)="onRegister('seeker')"
              mat-raised-button color="primary">Register as Job Seeker
      </button>
    </div>

    <div class="small-vertical-space">
      <button fxFlex="235px"
              class="rounded-button"
              mat-button mat-raised-button
              (click)="onRegister('employer')"
              mat-raised-button color="primary">Register as Employer / Post Jobs
      </button>
    </div>

    <div class="small-vertical-space">
      <button fxFlex="235px"
              class="rounded-button"
              mat-button mat-raised-button
              (click)="help()"
              mat-raised-button color="primary">Help with Registration
      </button>
    </div>

  </form>
</div>

<div *ngIf="loginState === 'signup'">

  <form *ngIf="!isLoading && registrationType === 'seeker'"
        fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
        [formGroup]="signupFormSeeker"
        #f="ngForm" (ngSubmit)="onSubmitSignup(f, registrationType)">

    <span class="mat-typography">
      Registration as {{registrationType}}
    </span>

    <div class="small-vertical-space"></div>

    <mat-form-field>
      <input
        type="email"
        matInput
        placeholder="Register your email"
        email
        required
        #emailInput
        formControlName="email">
      <mat-hint>Please enter a valid email.</mat-hint>
      <mat-error>Invalid or missing email.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        type="password"
        matInput
        placeholder="Select your password"
        required
        minlength="6"
        #passwordInput
        formControlName="password">
      <mat-hint align="end">{{ passwordInput.value?.length }} / 6</mat-hint>
      <mat-error>Has to be at least 6 characters long.</mat-error>
    </mat-form-field>

    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="userType"
        required
        #userType
        placeholder="User Type">
        <mat-option *ngFor="let userType of userTypes" [value]="userType">{{userType}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--<mat-form-field class="add-post-focus-text-color"-->
    <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
    <!--<input-->
    <!--matInput-->
    <!--required-->
    <!--maxlength="20"-->
    <!--formControlName="userName"-->
    <!--placeholder="User Name">-->
    <!--</mat-form-field>-->

    <!-- Mailing List Acceptance -->
    <mat-checkbox [checked]="mailingListChecked" class="vertical-space registration-mailing-list"
                  formControlName="mailingList" color="primary">
      Sign up for the BagMask.com Mailing List
    </mat-checkbox>

    <!--    Terms and Conditions Below-->
    <!--    <mat-checkbox class="vertical-space" formControlName="agree" required color="primary">-->
    <!--      Agree to Terms and Conditions.-->
    <!--    </mat-checkbox>-->

    <button *ngIf="!isLoading" type="submit" mat-raised-button class="rounded-button"
            color="warn" [disabled]="f.invalid">Submit
    </button>

    <div class="small-vertical-space"></div>

    <button *ngIf="!isLoading" type="button" mat-raised-button class="rounded-button"
            (click)="returnToLogin()" color="primary">Return to Login
    </button>
    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  </form>

  <form *ngIf="!isLoading && registrationType === 'employer'"
        fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
        [formGroup]="signupFormEmployer"
        #f="ngForm" (ngSubmit)="onSubmitSignup(f, registrationType)">

    <span class="mat-typography">
      Registration as {{registrationType}}
    </span>

    <div class="small-vertical-space"></div>

    <mat-form-field>
      <input
        type="email"
        matInput
        placeholder="Register your email"
        email
        required
        #emailInput
        formControlName="email">
      <mat-hint>Please enter a valid email.</mat-hint>
      <mat-error>Invalid or missing email.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        type="password"
        matInput
        placeholder="Select your password"
        required
        minlength="6"
        #passwordInput
        formControlName="password">
      <mat-hint align="end">{{ passwordInput.value?.length }} / 6</mat-hint>
      <mat-error>Has to be at least 6 characters long.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        placeholder="Company Name"
        required
        formControlName="userCompanyName">
      <mat-hint>Please enter a Company Name to display in Postings</mat-hint>
      <mat-error>Missing Company Name</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="registrationType === 'employer'" class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="userCompanyType"
        placeholder="Company Type">
        <mat-option *ngFor="let companyType of companyTypes" [value]="companyType">{{companyType}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Mailing List Acceptance -->
    <mat-checkbox [checked]="mailingListChecked" class="vertical-space registration-mailing-list"
                  formControlName="mailingList" color="primary">
      Sign up for the BagMask.com Mailing List
    </mat-checkbox>

    <!--<mat-form-field class="add-post-focus-text-color"-->
    <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
    <!--<input-->
    <!--matInput-->
    <!--required-->
    <!--maxlength="20"-->
    <!--formControlName="userName"-->
    <!--placeholder="User Name">-->
    <!--</mat-form-field>-->

    <!--    Terms and Conditions Below-->
    <!--    <mat-checkbox class="vertical-space" formControlName="agree" required color="primary">-->
    <!--      Agree to Terms and Conditions.-->
    <!--    </mat-checkbox>-->

    <button *ngIf="!isLoading" type="submit" mat-raised-button class="rounded-button"
            color="warn" [disabled]="f.invalid">Submit
    </button>

    <div class="small-vertical-space"></div>

    <button *ngIf="!isLoading" type="button" mat-raised-button class="rounded-button"
            (click)="returnToLogin()" color="primary">Return to Login
    </button>

    <div class="login-info-container"
         fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap.lt-md="10px" fxLayoutAlign="space-around center">

      <div class="login-info" fxFlex="100%">
        <div class="info-title mat-typography cursor-pointer" fxLayoutAlign="center center">
          Here is why companies are posting jobs with BagMask.com:
        </div>
        <div class="info-subtitle mat-typography cursor-pointer" fxLayoutAlign="center center">
          Standard Job Postings are Free!
        </div>
      </div>

      <div class="login-info" fxFlex="45%">
        <div class="info-icon fade-in-2 cursor-pointer"
             fxLayoutAlign="center center" fxLayout="row">
          <mat-icon fxLayoutAlign="center center" class="login-info-icon">
            explore
          </mat-icon>
        </div>
        <div class="info-title mat-typography cursor-pointer" fxLayoutAlign="center center">
          Career Navigation
        </div>
        <div class="info-content mat-typography">
          <p>
            Our goal is to be the first all-inclusive Anesthesia Career Resource Website. By being the first to offer
            career advancement articles, interviews with leaders and recruiters in the industry, and our annual
            scholarship we are constantly engaging new and current job seekers, leading to more opportunities for your
            openings to be viewed.
          </p>
        </div>
      </div>

      <div class="login-info" fxFlex="45%">
        <div class="info-icon fade-in-3 cursor-pointer"
             fxLayoutAlign="center center" fxLayout="row">
          <mat-icon fxLayoutAlign="center center" class="login-info-icon">
            whatshot
          </mat-icon>
        </div>
        <div class="info-title mat-typography cursor-pointer" fxLayoutAlign="center center">Cutting Edge Design</div>
        <div class="info-content mat-typography">
          <p>
            You've seen the competition and we have too. We've leveraged modern web technologies and user interaction
            patterns to create a website that feels like it was built in {{currentYear}} - because it was! The Providers
            we've
            spoken to have overwhelmingly responded that BagMask.com's interface is slick and easy to use.
            We won't let your postings get left behind with a dated interface or aging technologies!
          </p>
        </div>
      </div>

      <div class="login-info" fxFlex="45%">
        <div class="info-icon fade-in" fxLayoutAlign="center center" fxLayout="row">
          <mat-icon fxLayoutAlign="center center" class="login-info-icon">
            search
          </mat-icon>
        </div>
        <div class="info-title mat-typography" fxLayoutAlign="center center">Posting Flexibility</div>
        <div class="info-content mat-typography">
          <p>
            We did our homework on market research in the area of job postings based on recruiter and job seeker input.
            Do you have a large quantity of posts focused on the main descriptions? We can do that! Are your job posts
            filled with details and specifics to include? We designed our Post Editor with you in mind too! This
            flexibility gives you the best opportunity to connect with providers.
          </p>
        </div>
      </div>

      <div class="login-info" fxFlex="45%">
        <div class="info-icon fade-in-3 cursor-pointer"
             fxLayoutAlign="center center" fxLayout="row">
          <mat-icon fxLayoutAlign="center center" class="login-info-icon">
            assignment_returned
          </mat-icon>
        </div>
        <div class="info-title mat-typography cursor-pointer" fxLayoutAlign="center center">Custom Job Importing</div>
        <div class="info-content mat-typography">
          <p>
            We've made adding new jobs and managing existing ones as easy as possible. However, if you're a high-volume
            customer with an online jobs feed or even recurring exports, we can help set up a custom import to take all
            the work out of getting your jobs posted! Contact us at info@bagmask.com to speak with an engineer to get a
            custom importer set up so you can have more time to focus on your primary job: Talking to job seekers!
          </p>
        </div>
      </div>
    </div>

    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  </form>
</div>

<div *ngIf="loginState === 'signupSubmitted'" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <mat-card class="bagmask-card-dark-blue"
            fxFlex="100%" fxLayoutAlign="center center"
            fxLayout="row wrap">
    <mat-card-title class="center-mat-card-title" fxFlex="70%">
      Registration Submitted.  Please check your e-mail for verification.  If it doesn't show up soon, don't forget to
      check your Spam mail folder, or reach out to our support team.

      <p></p>
      <a href="https://jobs.bagmask.com">Return to BagMask.com Home Page.</a> <br>

      If you have any questions, reach out to our support team:
      <a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a>
    </mat-card-title>
  </mat-card>
</div>

<!--<div>-->
<!--<pre>auth.authenticatedUser: {{ authService.authenticatedUser | json }}</pre>-->
<!--{{authService.authenticatedUser}}-->
<!--</div>-->

<!-- <ol>
  <li>Add an email and password field (with Material Components of course)</li>
  <li>Add validation (email => required and email, password => required)</li>
  <li>Show errors and hints</li>
  <li>Make form submittable and disable button if invalid</li>
  <li>BONUS: Do all of that by using the reactive approach!</li>
</ol> -->
