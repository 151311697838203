// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { version } from './version';

// Development Environment
// export const environment = {
//   version: version,
//   production: false,
//   googleAnalyticsKey:'G-VQSPNGNKGC',
//   stripeKey: 'pk_test_uFjwuZ9bJd8JqwCB3EiZYI7G',
//   firebase: {
//     apiKey: 'AIzaSyBDokOO-hNHCBq2n6Z1iZKUHMvYVVxPoww',
//     authDomain: 'bagmask-dev.firebaseapp.com',
//     databaseURL: 'https://bagmask-dev.firebaseio.com',
//     projectId: 'bagmask-dev',
//     storageBucket: 'bagmask-dev.appspot.com',
//     messagingSenderId: '735152065640',
//     appId: '1:735152065640:web:2c36b1d4a5de30acc13fdd'
//   }
// };

//// Older Version works on main database prior to 8/25/2020
/* Production Environment */
export const environment = {

  version: version,
  production: false,
  googleAnalyticsKey:'G-1ZS923ZM64',
  stripeKey: 'pk_test_uFjwuZ9bJd8JqwCB3EiZYI7G',
  firebase: {
    apiKey: 'AIzaSyArufZsTbOEuaGdFWXn-GrGEHowlIAmSBc',
    authDomain: 'bagmask-d6f1f.firebaseapp.com',
    databaseURL: 'https://bagmask-d6f1f.firebaseio.com',
    projectId: 'bagmask-d6f1f',
    storageBucket: 'bagmask-d6f1f.appspot.com',
    messagingSenderId: '1006789295887'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
