import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faGithub, faGithubAlt } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {CompaniesService} from '../../Services/companies.service';
import {PaymentService} from '../../payments/payment.service';
import {UIService} from '../../Services/ui.service';
import {PostUpgradeService} from '../../Services/post-upgrade.service';
import {UserService} from '../../Services/user.service';
import { AuthProvider } from 'ngx-auth-firebaseui';
import Swal from 'sweetalert2';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from 'src/app/Services/auth.service';



@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']

})
export class CompanyProfileComponent implements OnInit {
  @ViewChild('myAlert',{static: false})
  myAlert: ElementRef;
  faCoffee = faCoffee;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faLinkedin = faLinkedin;
  faLinkedinIn = faLinkedinIn;
  faGithub = faGithub;
  faGithubAlt = faGithubAlt;
  faEnvelope = faEnvelope;
  providers = AuthProvider;

  companyLogo: string;
  constructor(
    private companiesService: CompaniesService,
    private postUpgradeService: PostUpgradeService,
    private userService: UserService,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    // this.companyLogo = this.companiesService.getCompanyLogoAsset('none');
    this.companyLogo = this.companiesService.getCompanyLogoAsset('Envision');
  }


  testGetCompanyLogo() {
    const companyImageResultReturned = this.companiesService.getCompanyLogoAsset('none');
    console.log('testGetCompanyLogo, companyImageResultReturned: ', companyImageResultReturned);
  }


  testGetUserDiscount() {
    console.log('testGetUserDiscount');
    console.log(this.userService.getUserPlanDiscount());
  }

  testAuthDialog() {
    console.log('testAuthDialog clicked');
    // Swal.fire({
    //   imageUrl: 'https://placeholder.pics/svg/300x1500',
    //   imageHeight: 1500,
    //   imageAlt: 'A tall image'
    // })
    Swal.fire({
      html: this.myAlert.nativeElement,
      width: '700px',
      showConfirmButton: false,
    });
  }


  testAfAuthSigninWithPopup() {
    this.authService.googleLogin()
  }
  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider);
  }

  // async testPostUpgradeDialog() {
  //   const confirmationReturnValue = await this.postUpgradeService
  //     .postUpgradeDialog('111')
  //     .catch((res => {
  //       console.log('testPostUpgradeDialog reject result: ' + res);
  //       console.log(res);
  //     }));
  //
  //   console.log('testPostUpgradeDialog Confirmation Return Value: ' + confirmationReturnValue);
  //
  //   if (confirmationReturnValue === true) {
  //     console.log('testPostUpgradeDialog, confirm true');
  //     // TODO: Do payment here
  //   }
  // }

}
