<form
  #addPostFormFacility="ngForm"
  class="add-post-form"
  [formGroup]="postFormFacility"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-sm="space-between center"
  fxLayout="row wrap">

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <!--Average Credentialing -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="Average Credentialing Time"
             formControlName="facAverageCredentialingTime"
             matInput [matAutocomplete]="averageCredentialingTime">
      <mat-autocomplete #averageCredentialingTime="matAutocomplete">
        <mat-option *ngFor="let option of averageCredentialingTimeOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- Anesthesia Group Exclusive Contract -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="facGroupExclusiveContract"
        placeholder="Anesthesia Group Exclusive Contract?">
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Other Anesthesia Sites Covered -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="facOtherSites"
        multiple
        placeholder="Other Anesthesia Sites Covered">
        <mat-option *ngFor="let option of otherFacilitySitesOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!-- Training Programs -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="facTrainingPrograms"
        placeholder="Training Programs"
        multiple>
        <mat-option *ngFor="let option of trainingProgramOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-card>

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <!-- Hospital Information -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="0 1 100%" fxFlex.lt-sm="0 1 calc(100% - 15px)">
            <textarea
              class="facility-info-textarea"
              matInput
              formControlName="facHospitalInfo"
              #inputHospitalInfo
              placeholder="Hospital Information">
            </textarea>
    </mat-form-field>

    <!-- Community Information -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="0 1 100%" fxFlex.lt-sm="0 1 calc(100% - 15px)">
            <textarea
              class="facility-info-textarea"
              matInput
              formControlName="facCommunityInfo"
              #inputCommunityInfo
              placeholder="Community Information">
            </textarea>
    </mat-form-field>

  </mat-card>

  <div class="debug-information" *ngIf="devModeEnabled" fxFlexAlign="row" fxLayoutAlign="center center">
    <!--<pre>{{ addPostFormFacility.value | json }}</pre>-->
  </div>

</form>

