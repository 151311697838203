import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  getBagMaskProjectID(): string {
    return environment.firebase.projectId;
  }

  getBagMaskStripeKey(): string {
    // const stripeKeyString: string = environment.stripeKey;
    return environment.stripeKey;
  }
}
