import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scholarship-mailing-list-signup',
  templateUrl: './scholarship-mailing-list-signup.component.html',
  styleUrls: ['./scholarship-mailing-list-signup.component.scss']
})
export class ScholarshipMailingListSignupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
