import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from '../ui/loading-spinner/loading-spinner.component';
import {NgxMdModule} from 'ngx-md';
import { FacebookShareComponent } from './Sharing-Buttons/facebook-share/facebook-share.component';
import { TwitterShareComponent } from './Sharing-Buttons/twitter-share/twitter-share.component';
import { EmailShareComponent } from './Sharing-Buttons/email-share/email-share.component';
import { PinterestShareComponent } from './Sharing-Buttons/pinterest-share/pinterest-share.component';
import { LinkedInShareComponent } from './Sharing-Buttons/linked-in-share/linked-in-share.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FooterDistributedContactFormComponent } from './footer-distributed-contact-form/footer-distributed-contact-form.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MaterialModule} from '../material.module';
// import { HighPriorityPostBlockComponent } from './high-priority-post-block/high-priority-post-block.component';
import { ScholarshipMailingListSignupComponent } from './scholarship-mailing-list-signup/scholarship-mailing-list-signup.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    EmailShareComponent,
    FacebookShareComponent,
    FooterDistributedContactFormComponent,
    // HighPriorityPostBlockComponent,
    LinkedInShareComponent,
    LoadingSpinnerComponent,
    PinterestShareComponent,
    ScholarshipMailingListSignupComponent,
    TwitterShareComponent,
  ],
  imports: [
    NgxMdModule,
    FontAwesomeModule,
    MaterialModule,
  ],
  exports: [
    ConfirmationDialogComponent,
    EmailShareComponent,
    FacebookShareComponent,
    FooterDistributedContactFormComponent,
    // HighPriorityPostBlockComponent,
    LinkedInShareComponent,
    LoadingSpinnerComponent,
    NgxMdModule,
    PinterestShareComponent,
    ScholarshipMailingListSignupComponent,
    TwitterShareComponent
  ],
})

export class UiModule {}
