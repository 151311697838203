import { Injectable } from '@angular/core';

@Injectable()
export class JobPostContentService {

  constructor() {
  }

  // TODO: Check this entire service if it works the same for a single posting!
  
  checkSalaryValues(data: any) {

    if (
      data.job.salSigningBonus === 0 &&
      data.job.salSigningBonusCommitmentReimbursement === null &&
      data.job.salTuitionReimbursement === null &&
      data.job.salInterviewExpenses === null &&
      data.job.salRelocationAssistance === null &&
      data.job.salPaidOvertime === null &&
      data.job.salWeeksPaidVacation === null &&
      data.job.salPaidCMETime === null &&
      data.job.salCMEAllowance === null &&
      data.job.salSalaryIncomeBoolean === null &&
      data.job.salIncomeFeeForService === null &&
      data.job.salEventualPartnership === null &&
      data.job.salIncomeFullPartnerPractice === null &&
      data.job.salMedicalInsurance === null &&
      data.job.salRetirementPlan === null &&
      data.job.salMalpracticeInsurance === null &&
      data.job.salDisabilityInsurance === null
    ) {
      console.log('Inside jobPostContentService, checkSalaryValues returned false, no values in salary');
      return false;
    } else {
      console.log('Inside jobPostContentService, checkSalaryValues returned true, has values in salary');
      return true;
    }
  }

  checkResponsibilitiesRequirementsValues(data: any) {
    if (
        data.job.resScheduledCall === null &&
        data.job.resHowOftenFirstCall === null &&
        data.job.resDayOffAfterFirstCall === null &&
        data.job.resHowOftenSecondCall === null &&
        data.job.resCallType === null &&
        data.job.resCallResponseTime === null &&
        data.job.resProviderModel === null &&
        data.job.resDirectionSupervision === null &&
        data.job.resCaseMix === null &&
        data.job.resLaborEpidurals === null &&
        data.job.resSpinalAnesthesia === null &&
        data.job.resRegionalBlocks === null &&
        data.job.resArterialCatheter === null &&
        data.job.resCentralVenousCatheter === null &&
        data.job.reqNewGrads === null &&
        data.job.reqStateLicense === null &&
        data.job.reqNBCRNACertification === null &&
        data.job.reqLifeSupportCard === null &&
        data.job.reqABACertification === null &&
        data.job.reqSubspecialtyFellowship === null &&
        data.job.reqVisaAccepted === null &&
        data.job.resShiftType === null &&
        data.job.resShiftDuration === null
    ) {
      console.log('Inside jobPostContentService, checkResponsibilitiesRequirementsValues returned false, no values in R/R');
      return false;
    } else {
      console.log('Inside jobPostContentService, checkResponsibilitiesRequirementsValues returned true, has values in R/R');
      return true;
    }
  }

  checkFacilityValues(data: any) {
    if (
      data.job.facAverageCredentialingTime === null &&
      data.job.facGroupExclusiveContract === null &&
      data.job.facOtherSites === null &&
      data.job.facTrainingPrograms === null &&
      data.job.facFederalGovJob === null &&
      (data.job.facNumAnesthesiologists === '' || data.job.facNumAnesthesiologists === null) &&
      (data.job.facNumCRNAs === '' || data.job.facNumCRNAs === null) &&
      (data.job.facNumAAs === '' || data.job.facNumAAs === null) &&
      data.job.facNumLicensedBeds === null &&
      data.job.facNumSurgicalCasesYear === null &&
      data.job.facNumDeliveriesYear === null &&
      data.job.facAvgDailyCensus === null &&
      (data.job.facCompanyInfo === '' || data.job.facCompanyInfo === null) &&
      data.job.facHospitalInfo === null &&
      data.job.facCommunityInfo === null
    ) {
      console.log('Inside jobPostContentService, checkFacilityValues returned false, no values in facility');
      return false;
    } else {
      console.log('Inside jobPostContentService, checkFacilityValues returned true, has values in facility');
      return true;
    }
  }

    
}
