<div class="support-info-container mat-typography" fxLayout="column" fxLayout.lt-md="column"
     fxLayoutGap.lt-md="10px" fxLayoutAlign="space-around center">
  <h2>If you encounter any issues or have questions about BagMask, get in touch with our friendly support team!</h2>

  <h2><a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a></h2>

  <h2>BagMask Frequently Asked Questions:</h2>

  <mat-accordion class="support-accordion">
    <mat-expansion-panel class="support-question-panel" expanded="{{supportQuestion === 'registration'}}">
      <mat-expansion-panel-header class="support-question-header">
        <mat-panel-title class="support-question-title">
          <b>Bagmask Registration Walkthrough</b>
        </mat-panel-title>
        <!--<mat-panel-description>-->
        <!--Type your name and age-->
        <!--</mat-panel-description>-->
      </mat-expansion-panel-header>

      <img class="support-image-small" src="../../../assets/Example Bagmask Registration Step 1 v503 - Annotated.png"
           height="625" width="1458"/>

      <p>There are two main ways of getting to the registration/signup screen, so you can create your BagMask
        Account</p>

      <p>1. The <b>Post a Job</b> button will take you directly to the registration screen.  It will be visible
        on the top Navigation Bar as long as you aren't already logged into the site.</p>

      <br>

      <p>2. Also, once you're on the login page you can click the <b>Register as Employer / Post Jobs</b>
        button to get to the Registration screen.</p>

      <br>


      <img class="support-image-small" src="../../../assets/Example Bagmask Registration Step 2 v503 - Annotated.png"
           height="629" width="583"/>

      <br>

      <p>3. Enter your e-mail and a password here (at least 6 characters for the password!)</p>

      <br>

      <p>4. Every employer needs a <b>Company Name</b> that will help identify your job postings to the potential
        job seekers</p>

      <br>

      <p>5. Below you will have to select a <b>Company Type</b>, which is helpful for the job seekers to
        quickly understand what type of group you are.</p>

      <br>

      <p>6. You're almost done! Once the three fields are filled in, click <b>Submit</b></p>

      <br>

      <img class="support-image-small" src="../../../assets/Example%20Bagmask%20Registration%20Step%203%20v503%20-%20Annotated.png"
           height="292" width="2047"/>

      <br>

      <p>7. You'll see a message on the site informing you that we've sent a registration e-mail to you, for
        confirmation that you own the actual e-mail address!</p>

      <br>

      <img class="support-image-small" src="../../../assets/Example%20Bagmask%20Registration%20Step%204%20v503%20-%20Annotated.png"
           height="408" width="1665"/>

      <br>

      <p>8. Check your e-mail inbox after a minute or two and you should see an e-mail like this.  Just simply
        click and follow the link and your account should be properly registered!</p>

      <br>
    </mat-expansion-panel>

    <mat-expansion-panel class="support-question-panel" expanded="{{supportQuestion === 'colors'}}">
      <mat-expansion-panel-header class="support-question-header">
        <mat-panel-title class="support-question-title">
          <b>What do the colors on the Job Posting Grid Mean?</b>
        </mat-panel-title>
        <!--<mat-panel-description>-->
          <!--Type your name and age-->
        <!--</mat-panel-description>-->
      </mat-expansion-panel-header>

      <img class="support-image"
           src="../../../assets/Example Bagmask Search Page v501 - Annotated.png" height="658" width="1468"/>

      <p>1. The color variations of the job post's background and text signify the post's <b>priority</b>.
        BagMask offers three different variations of priority:</p>
      <ul>
        <li><b>High Priority</b></li>
          <ul>
            <li>These posts are always listed first in any job search that would return the post as a result</li>
          </ul>
        <li><b>Priority</b></li>
          <ul>
            <li>Priority posts show up above all standard free job postings, and will typically be quite prominent
              in many statewide job searches.</li>
          </ul>
        <li>Standard</li>
      </ul>
      <p>The post's priority determines the ordering of job posts that are returned.</p>

      <br>

      <p>2. Each column can be <b>clicked to change the sorting</b> of the job results that were returned!
      Use this feature to sort ascending/descending based on the columns that interest you the most.</p>

      <br>

      <p>3. The Filtering Bar drops down and allows you to <b>refine your search based on a few key criteria</b>
        This is a great way to find jobs that match your ideal type of position!</p>

      <br>

      <p>4. The pagination bar lets you <b>switch between pages of postings</b> if the search returned a lot of
        records.  You can also change the dropdown value to show more posts per page.</p>
    </mat-expansion-panel>

    <mat-expansion-panel class="support-question-panel" expanded="{{supportQuestion === 'priority'}}">
      <mat-expansion-panel-header class="support-question-header">
        <mat-panel-title class="support-question-title">
          <b>How does BagMask's Post Priority system work?</b>
        </mat-panel-title>
        <!--<mat-panel-description>-->
        <!--Type your name and age-->
        <!--</mat-panel-description>-->
      </mat-expansion-panel-header>

      <p>BagMask offers three different variations of priority:</p>
      <ul>
        <li><b>High Priority</b></li>
          <ul>
            <li>These posts are always listed first in any job search that would return the post as a result</li>
            <li>Each High Priority listing will be darkened and shown with slightly larger font to stand apart from
              the other postings</li>
            <li>Every High Priority listing also gets included in a rotation of High Priority Advertising that shows
              up on the side of nearly every article on BagMask.com!</li>
            <li>All High Priority Postings remain active until your job opening is filled or for one year
              from post date, whichever comes first.</li>
          </ul>
        <li><b>Priority</b></li>
          <ul>
            <li>Priority posts show up above all standard free job postings, and will typically be quite prominent
              in many statewide job searches.</li>
            <li>These posts have a darker color and slightly larger font size to stand out more than standard
              postings</li>
            <li>If your company has a large number of postings that your would want to use Priority status on,
              <a routerLink="/pricing" target="_blank"> one of our monthly posting plans</a> could
              provide outstanding value!</li>
            <li>All Priority Postings remain active until your job opening is filled or for one year
              from post date, whichever comes first.</li>
          </ul>
        <li><b>Standard</b></li>
          <ul>
            <li>Standard posts are free to create and can be refreshed at any time!</li>
          </ul>
      </ul>
      <p>For more information about pricing for adding Priority or High Priority, or to check out our posting
        plans that can offer a multitude of cost-saving options if you have a large number of job postings,
        please check out our <a routerLink="/pricing" target="_blank"> Pricing Page!</a></p>

      <p>BagMask also offers multiple options for your High Priority posting advertisements, which show up in multiple locations on our website!</p>
      <ul>
        <li><b>Standard Template</b></li>
        <ul>
          <li>Upload your company logo to us at: <a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a>
            (If you don’t have one we will type in your group or hospital name)
          </li>
          <li>We will adjust border colors to match your Brand</li>
          <li>The job’s short description will be used to fill in Ad details</li>
          <div fxLayout="row wrap" fxLayoutAlign="start center">

            <img src="../../../assets/Ads/Examples/Support%20Hi%20Pri%20Ads%20Picture%201.jpg"
                 height="240" width="360"/>
          </div>
        </ul>
      </ul>

      <ul>
        <li><b>Customized Ad</b></li>
        <ul>
          <li>Contact our marketing team at <a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a> to help design a
            custom ad for you.
          </li>
          <li>Additional design fees for the customized ad start at $50</li>
          <li>Example:</li>
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <img src="../../../assets/Ads/Examples/Support%20Hi%20Pri%20Ads%20Picture%205.jpg"
                 height="240" width="360"/>
            <!--<img src="../../../assets/Ads/Examples/Support%20Hi%20Pri%20Ads%20Picture%203.jpg"-->
            <!--     height="240" width="360"/>-->
          </div>
        </ul>
      </ul>

      <ul>
        <li><b>Upload your own AD</b></li>
        <ul>
          <li>Upload existing ad to us at: <a href="mailto:Info@Bagmask.com">Info@Bagmask.com</a>
          </li>
          <li>We have found the best-looking ads at the 640(w) x 430(h)</li>
        </ul>
      </ul>




    </mat-expansion-panel>


  </mat-accordion>
</div>

<div class="vertical-padding"></div>

