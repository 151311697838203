import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PaymentService} from '../payment.service';
import {PostService} from '../../Services/post.service';

@Component({
  selector: 'app-post-upgrade-dialog',
  templateUrl: './post-upgrade-dialog.component.html',
  styleUrls: ['./post-upgrade-dialog.component.scss']
})
export class PostUpgradeDialogComponent implements OnInit {
  currentlySelectedUpgrade: string;
  selectPostConfirmButtonText: string;
  confirmButtonDisabled = true;
  dialogReturnData: any;
  currentPaymentAmount: number;
  paymentButtonColor: string;
  constructor(
    public dialogRef: MatDialogRef<PostUpgradeDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public postId: string,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentService,
    private postService: PostService,
  ) {
  }


  ngOnInit() {
    console.log('postUpgradeDialog started with incoming data: ', this.data);
    this.currentlySelectedUpgrade = 'none';
    this.selectPostConfirmButtonText = 'No Upgrade Selected';
    this.dialogReturnData = 'success';

    this.paymentButtonColor = '#B9B9B9';

    this.paymentService.getMessage().subscribe(message => {
      if (message === 'PaymentFinishedSuccessful') {
        console.log('Post Upgrade Dialog, payment marked as finished received! Closing Dialog Box ' +
          'and setting postId: ', this.data.postId, ' to new priority for: ', this.currentlySelectedUpgrade);
        if (this.currentlySelectedUpgrade.includes('High Priority')) {
          console.log('currentlySelectedUpgrade matched High Priority: ', this.currentlySelectedUpgrade);
          this.postService.setPostToPriority(this.data.postId, 3);
        } else if (this.currentlySelectedUpgrade.includes('Priority')) {
          console.log('currentlySelectedUpgrade matched Priority: ', this.currentlySelectedUpgrade);
          this.postService.setPostToPriority(this.data.postId, 2);
        } else {
          console.log('currentlySelectedUpgrade did not match any type: ', this.currentlySelectedUpgrade);
        }
        this.dialogRef.close('success');
      } else {
        // Payment returned unsuccessful
        this.dialogRef.close('failure');
      }
      // TODO: Check for any kind of payment failures here.
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // Function for changing the priority selected
  priorityClickToggle(prioritySelected: string) {
    console.log('priorityClickToggle for prioritySelected: ', prioritySelected);
    if (prioritySelected === 'priority') {
      console.log('priority selected');
      this.currentlySelectedUpgrade = 'Priority - $199.00';
      this.currentPaymentAmount = 19900;
    } else {
      console.log('high priority selected');
      this.currentlySelectedUpgrade = 'High Priority - $399.00';
      this.currentPaymentAmount = 39900;
    }
    this.selectPostConfirmButtonText = 'Purchase Upgrade: ' + this.currentlySelectedUpgrade;
    console.log('new value for selectPostConfirmButtonText: ', this.selectPostConfirmButtonText);
    this.confirmButtonDisabled = false;
    this.paymentButtonColor = '#1565c0';
  }

}
