import {Component, OnInit, isDevMode} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BagmaskFieldValues} from '../../../Namespaces/bagmaskFieldValues.namespace';

@Component({
  selector: 'app-add-post-facility',
  templateUrl: './add-post-facility.component.html',
  styleUrls: ['./add-post-facility.component.scss']
})
export class AddPostFacilityComponent implements OnInit {
  devModeEnabled = false;
  postFormFacility: FormGroup;
  yesNoOptions = BagmaskFieldValues.getYesNoOptions();
  trainingProgramOptions = BagmaskFieldValues.getTrainingProgramOptions();
  federalGovJobOptions = BagmaskFieldValues.getFederalGovJobOptions();
  otherFacilitySitesOptions = BagmaskFieldValues.getOtherFacilitySitesOptions();
  bedsCensusOptions = BagmaskFieldValues.getBedsCensusOptions();
  surgicalCasesOBDeliveriesOptions = BagmaskFieldValues.getSurgicalCasesOBDeliveriesOptions();
  averageCredentialingTimeOptions = BagmaskFieldValues.getAverageCredentialingTimeOptions()

  constructor() {
    if (isDevMode()) {
      // Enable dev mode if isDevMode is true, showing extra form elements
      this.devModeEnabled = true;
    }
  }

  ngOnInit() {
    this.postFormFacility = new FormGroup({
      facAverageCredentialingTime: new FormControl(),
      facNumAnesthesiologists: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      facNumCRNAs: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      facNumAAs: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      facGroupExclusiveContract: new FormControl(),
      facOtherSites: new FormControl(),
      facTrainingPrograms: new FormControl(),
      facFederalGovJob: new FormControl(),
      facAvgDailyCensus: new FormControl(),
      facNumLicensedBeds: new FormControl(),
      facNumSurgicalCasesYear: new FormControl(),
      facNumDeliveriesYear: new FormControl(),
      facHospitalInfo: new FormControl(),
      facCommunityInfo: new FormControl(),
    });
  }


}
