import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Observable ,  Subject ,  of } from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import { filter, catchError, tap, map, switchMap } from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from "firebase/app"
// firebase.firestore // instead

declare var google: any;
import * as geofire from "geofire-common";
// const geofire = require('geofire');

// TODO: Migrate go geofire instead of geofirex
// Link https://firebase.google.com/docs/firestore/solutions/geoqueries


@Injectable()
export class GeocodeService {
  private geocoder: any;
  // private geoFireInstance = new geofire.GeoFire(firebase);

  constructor(private mapLoader: MapsAPILoader,
              private afs: AngularFirestore) {}

  private initGeocoder() {
    console.log('Init geocoder!');
    this.geocoder = new google.maps.Geocoder();
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if (!this.geocoder) {
      return fromPromise(this.mapLoader.load())
        .pipe(
          tap(() => this.initGeocoder()),
          map(() => true)
        );
    }
    return of(true);
  }

  // 4/21/2020, this no longer works since we migrated from geofirex to geofire
  // TODO: Rewrite in geofire
  testSearchGeoQuery() {
    // const center = geo.point(39.299, -76.609);
    // const radius = 2;
    // const field = 'position';

    // const query = geo.query('posts').within(center, radius, field);

    // query.subscribe(console.log);
  }

    // 4/21/2020, this no longer works since we migrated from geofirex to geofire
  // TODO: Rewrite in geofire
  testSearchGeolocation() {
    // console.log('Inside testSearchGeolocation');

    // const center = this.geo.point(39.1, -76.7);
    // const radius = 7500;
    // const field = 'position';

  }

  geocodeAddress(location: string): Observable<any> {
    console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              console.log('Geocoding complete!');
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
              console.log('Geocoding lat: ' + results[0].geometry.location.lat() + ' ,lng: ' + results[0].geometry.location.lng());
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next({});
            }
            observer.complete();
          });
        });
      })
    );
  }

  geocodeAddressAndCreatePointOnPostID(location: string, postID: string): Observable<any> {
    console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              console.log('Geocoding complete!');
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
              console.log('Geocoding lat: ' + results[0].geometry.location.lat() + ' ,lng: ' + results[0].geometry.location.lng());
              this.createPointForPostID(postID, results[0].geometry.location.lat(), results[0].geometry.location.lng());
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next({});
            }
            observer.complete();
          });
        });
      })
    );
  }

  combineCityAndStateAndGeocode(post: any) {
    console.log('Start combineCityAndOrStateToLocationForGeocode for post: ', post);
    let locationToGeocode: string;
    if (post.city) {
      locationToGeocode = post.city + ', ' + post.state;
      console.log('Post has city, combining with state for result: ', location);
    } else  {
      locationToGeocode = post.state;
      console.log('Post has no city, only state: ', location);
    }

    // Now that we have an appropriate location for sure, geocode this address
    // this.geocodeAddressAndCreatePointOnPostID(locationToGeocode, post.id);

    this.geocodeAddressAndCreatePointOnPostID(locationToGeocode, post.id)
      .subscribe(
        location => {
          console.log('Geocode Service, combineCityAndStateAndGeocode, passed address of: ' + locationToGeocode);
          console.log('addressReturnCoordinatesConsole returned: ' + JSON.stringify(location));
          console.log('Added location to new post for postID: ' + post.id);
        }
      );
  }

  createPoint(lat, lng) {
    // // const collection = firestore().collection('posts');
    // const collection = this.geo.collection('places');
    // // const doc = this.geo.collection('users').doc(this.userId).collection('payments').doc(token.id).set(payment);
    // // const doc = this.geo.collection('users').setDoc(this.userId).collection('payments').doc(token.id).set(payment);
    //
    // // Convenience method
    // // collection.setPoint('my-place', 'place1', lat, lng);
    //
    // // Explicit
    // const point = this.geo.point(lat, lng);
    // collection.setDoc('my-place', {position: point.data});
    //
    // const cities = this.geo.collection('cities');
    // const point2 = this.geo.point(40, -119);
    // cities.add({name: 'Phoenix', position: point2.data});
  }


  // Create position data for a postID given a lat and lng for that location
  createPointForPostID(postID, lat, lng) {
    const posts = this.afs.collection('posts');

    /* Test:
      lat:39.4667034
      lng:-87.41390919999998
      expected output: dp1bdbenh (from old geofirex library)
    */

    const hash = geofire.geohashForLocation([lat, lng]);

    /* Example of current data in firebase (4/21/2021) for a position data:

      position
        geohash: "dp1bdbenh"
        geopoint: [39.4667034° N, 87.41390919999998° W] (geopoint)
    */

    // Below appears to accurately create position data looking just like the old data!
    const positionToUpdate = posts.doc(postID);
    positionToUpdate.update({
      position: {
        geohash: hash,
        geopoint: new firebase.firestore.GeoPoint(lat, lng)
      }
    });
  }



}



