import { JobPosting } from '../Models/post.model';
import {first, map, tap} from 'rxjs/operators';
import {Injectable, isDevMode} from '@angular/core';


import {Observable, Subject, Subscription} from 'rxjs';
import { UIService } from './ui.service';

import {PostQuery} from '../Models/postQuery.model';
import {User} from '../Models/user.model';
import {GeocodeService} from './geocode.service';
import {AuthService} from './auth.service';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, Query} from '@angular/fire/firestore';
import {PostMetricsModel} from '../Models/postMetrics.model';
// import * as firebase from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable()
export class PostMetricsService {
  private fbSubs: Subscription[] = [];
  private userProfileSubscription: Subscription;
  private postingDoc: AngularFirestoreDocument;
  private userDoc: AngularFirestoreDocument<User>;
  private postMetricsDoc: AngularFirestoreDocument<PostMetricsModel>;
  private pQuery: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore,
              private uiService: UIService) {
  }

  postMetricsAction() {
    let metricsAction: PostMetricsModel;
    const serverTimestampDate = firebase.firestore.FieldValue.serverTimestamp();

    metricsAction = {
      // Required fields
      jobBoard: 'BagMask.com',
      jobSite: 'BagMask.com',
      // dateOfAction: serverTimestampDate,
      // TODO: Finish these
      bagmaskJobID: '',
      companyJobID: '',
      speciality: '',
      positionType: '',
      jobState: '',
      clickAction: '',
      // Optional Fields
      bagmaskUserID: '',
      bagmaskUserCity: '',
      bagmaskUserState: '',
      jobCity: '',
      actionDetails: '',

      // Optional fields

      /*
        jobBoard: string;
        jobSite: string;
        dateOfAction: Date;
        bagmaskJobID: string;
        companyJobID: string;
        speciality: string; // Anesthesiology
        positionType: string // MD/CRNA/CAA
        jobState: string;
        clickAction: string;
        bagmaskUserID?: string;
        bagmaskUserCity?: string;
        bagmaskUserState?: string;
        jobCity?: string;
        actionDetails?: string;
       */


      // Give everything a Date
      // datePosted: new Date(),
      // expirationDate: new Date(dateToExpire),
      // postStatus: postStatusSelected,
      // // Attach user's uid to post
      // uid: this.authService.authenticatedUser.uid,
      // // Generate a random integer up to 999,999,999 for random selection
      // random: this.uiService.randomOneBillionInt(),
      // // Give a view count of zero by default
      // viewCount: 0,
      // // Populate Main Fields
      // jobType: result.jobType,
      // shortDescription: result.shortDescription,

    };
  }

  // addDataToDatabase(jobPost: JobPosting) {
  //
  //   this.afs.collection('posts').add(jobPost)
  //     .then((res) => {
  //       console.log('In Post Service, addDataToDatabased finished. Res: ');
  //       console.log(res);
  //       console.log(res.id);
  //
  //
  //       console.log('Add Database finished for address: ' + jobPost.address + ' city: ' + jobPost.city + ' state: ' + jobPost.state +
  //         ' zip: ' + jobPost.zipCode);
  //       this.addGeocodeLocationToNewPost(jobPost, res.id);
  //     }).catch(err => {
  //     console.log(err);
  //     this.uiService.showSnackbar('Error with Adding Data to Database', null, 3000);
  //   });
  // }

  addFreePriorityToPost(postId: string, uid: string, freeRemaining: number) {
    console.log('Inside post service, addFreePriorityToPost.  Setting to priority postId: ' + postId);
    const numFreePriorityRemaining = freeRemaining - 1;
    this.userDoc = this.afs.doc<User>('users/' + uid);
    this.userDoc.update({userCompanyMonthlyPlanFreePriorityPostsRemaining: numFreePriorityRemaining});
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postPriority: 2, postFreePriority: true});
  }

  setPostToPriority(postId: string, newPriority: number) {
    console.log('Inside post service, setPostToPriority.  Setting to priority postId: ', postId, ' to priority: ', newPriority);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postPriority: newPriority}).then(res => {
      console.log('setPostToPriority update completed with result of: ', res);
    });
  }

  deactivatePost(postId: string) {
    console.log('Inside post service, deactivatePost.  Setting to inactive postId: ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.update({postStatus: 'Inactive'});
    // this.postingDoc.valueChanges().subscribe(data => {
    //   console.log('Post data after expired');
    //   console.log(data);
    // });
  }

  // This function will bump the post's expiration until 30 days from today
  addExpirationDaysToPost(postId: string, daysUntilExpiration: number) {
    console.log('addExpirationDaysToPost for postId: ', postId, ' and days: ', daysUntilExpiration);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    const today = new Date();
    const newExpirationDate = new Date().setDate(today.getDate() + 30);
    const newExpirationDateToSet = new Date(newExpirationDate);
    console.log('new Expiration Date: ', newExpirationDateToSet);
    this.postingDoc.update({expirationDate: newExpirationDateToSet})
      .catch(e => {
        console.log('Error in addExpirationDaysToPost update: ', e);
      }).then(result => {
        console.log('addExpirationDaysToPost update, result: ', result);
    });
  }

  deletePost(postId: string) {
    console.log('Inside post service, deletePost.  Deleting postId: ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    this.postingDoc.delete();
  }

  addPostViewCount(postId: string, currentPostCount: number) {
    console.log('Inside post service, addPostViewCount.  Adding to ' + postId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + postId);
    const newPostCount = currentPostCount + 1;
    this.postingDoc.update({viewCount: newPostCount});
  }

  editPostInDatabase(jobPost: JobPosting, restoredId: string) {
    console.log('editPostInDatabase, restoredId: ' + restoredId);
    this.postingDoc = this.afs.doc<JobPosting>('posts/' + restoredId);
    this.postingDoc.update(jobPost);
  }

  cancelSubscriptions() {
    // Step through each of the subscriptions and unsubscribe if they exist on destroy
    this.fbSubs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  // //  This code is stored on trello, allows updating of all rows in afs to a value
  // updateDatabaseRecordsBatch() {
  //
  // }

}

