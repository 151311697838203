import {Injectable} from '@angular/core';
import {first} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { EnvironmentService } from './environment.service';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class StripeBagmaskService {
  stripePlanData = [];
  stripe: any;
  currentUserUID: string;
  isLoading: boolean;

  constructor(private afs: AngularFirestore,
              private fns: AngularFireFunctions,
              private environmentService: EnvironmentService,
              ) {

  }

  // We want to retrieve all the currently active subscription plans available in our database
  async getBagMaskStripeSubPlans(): Promise<any> {
    console.log('Starting getBagMaskStripeSubPlans');

    const promise = new Promise<any>((resolve, reject) => {
      const postQuery = this.afs.collection(
        'products', ref => ref.where('active', '==', true));
      // .where('companyName', '==', companyName));

      const stripePlanDataSub = postQuery.valueChanges({idField: 'id'});
      // const stripePlanDataSub = postQuery.snapshotChanges();

      stripePlanDataSub.pipe(first()).subscribe( async data => {
        console.log('Stripe active plans returned: ', data);
        this.stripePlanData = data;

        for (const product of this.stripePlanData) {
          console.log('Product: ', product);
          const planPrice = await this.getBagMaskStripeSubPlanPrice(product.id);
          console.log('Price retrieved for product');
          product.priceId = planPrice.priceId;
          product.price = planPrice.price;
        }

        resolve(this.stripePlanData);

        // resolve(data);
      });
    });

    return promise;
  }

  // Get an individual plan's price
  async getBagMaskStripeSubPlanPrice(productId: string): Promise<any> {
    console.log('Starting getBagMaskStripeSubPlanPrice');

    const promise = new Promise<any>((resolve, reject) => {

      const prices = this.afs.firestore.collection('products').doc(productId).collection('prices');
      prices.get().then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          console.log('Price ID: ', doc.id);
          console.log('Price full data: ', doc.data());
          console.log('Price Amount: ', doc.data().unit_amount);

          const returnValues: any = {
            priceId: doc.id,
            price: doc.data().unit_amount,
          };

          resolve(returnValues);
        });
      });
    });

    return promise;
  }

  async stripeBillingPortal() {
    console.log('Starting stripeBillingPortal');
    // Call billing portal function
    const functionRef = this.fns
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')

    // @ts-ignore
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    const data = await functionRef({ returnUrl: window.location.origin });
    data.subscribe(val => {
      console.log(val);
      window.location.assign(val.url);
    })
    // window.location.assign(data.url);

    // Call billing portal function
    // const functionRef = firebase
    //   .app()
    //   .functions(functionLocation)
    //   .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    // window.location.assign(data.url);

    // window.location.assign(data.url);
    // let session = await this.stripe.billingPortal.sessions.create({
    //   customer: 'cus_I5Y00VkVZt5GUe',
    //   return_url: 'https://jobs.bagmask.com',
    // });
  }

  // Return the plan price in $ format
  getBagMaskStripePlanPriceInDollars(price: number) {

    price.toString();
    let stringPrice = price.toString();

    // Get total character count

    // First part (pre-decimal)
    const dollarsPart = stringPrice.substr(0, stringPrice.length - 2);

    // Concat all strings together
    stringPrice = '$'.concat(dollarsPart, '.', stringPrice.slice(-2));

    return stringPrice;
  }

  // Function to check if server is development or production, and based on that return the current price record IDs from the database for a given plan name
  // TODO: Update this to pull dynamically so we don't need to have things hardcoded!!!
  getBagMaskStripePlanPrices(planName: string) {
    // Check which server we are hitting so DEVELOPMENT can be labeled for ease of testing
    let projectId = environment.firebase.projectId;
    console.log('Checking if we are using development server: ', projectId);
    if (projectId === 'bagmask-dev') {
      // We are in development
      if (planName === 'Silver') {
        return 'price_1HVLo1CfLG9nGFpofBpZhCRM';
      }
      if (planName === 'Gold') {
        return 'price_1HVN5ZCfLG9nGFpoABGCJ9vQ';
      }
      if (planName === 'Platinum') {
        return 'price_1HVN5rCfLG9nGFpowKBBEq8y';
      }
    } else {
      // We are in production
      if (planName === 'Silver') {
        return 'price_1IMg1nCfLG9nGFpoQGF3FbZv';
      }
      if (planName === 'Gold') {
        return 'price_1IMg1GCfLG9nGFponPTDP4MS';
      }
      if (planName === 'Platinum') {
        return 'price_1IMg1ZCfLG9nGFponPJwYoGF';
      }
    }

  }

}
