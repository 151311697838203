import {Component, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-facebook-share',
  templateUrl: './facebook-share.component.html',
  styleUrls: ['../sharing-buttons.scss']
})
export class FacebookShareComponent implements OnInit {

  constructor(@Inject(DOCUMENT) public document: any,) { }

  ngOnInit() {
  }

}
