<div mat-dialog-content class="confirmation-content mat-typography">
  <h2 class="post-upgrade-title">BagMask.com Upgrade for Post: {{data.postJobId}}</h2>
  <div class="mat-typography upgrade-info">
    <p>BagMask offers three different variations of priority:</p>
    <ul>
      <li><b>High Priority</b></li>
      <ul>
        <li>These posts are always listed first in any job search that would return the post as a result</li>
        <li>Each High Priority listing will be darkened and shown with slightly larger font to stand apart from
          the other postings</li>
        <li>Every High Priority listing also gets included in a rotation of High Priority Advertising that shows
          up on the side of nearly every article on BagMask.com!</li>
        <li>All High Priority Postings remain active until your job opening is filled or for one year
          from post date, whichever comes first.</li>
      </ul>
      <li><b>Priority</b></li>
      <ul>
        <li>Priority posts show up above all standard free job postings, and will typically be quite prominent
          in many statewide job searches.</li>
        <li>These posts have a darker color and slightly larger font size to stand out more than standard
          postings</li>
        <li>If your company has a large number of postings that your would want to use Priority status on,
          <a routerLink="/pricing" target="_blank"> one of our monthly posting plans</a> could
          provide outstanding value!</li>
        <li>All Priority Postings remain active until your job opening is filled or for one year
          from post date, whichever comes first.</li>
      </ul>
      <li><b>Standard</b></li>
      <ul>
        <li>Standard posts are free to create and can be refreshed at any time!</li>
      </ul>
    </ul>
    <p>For more information about pricing for adding Priority or High Priority, or to check out our posting
      plans that can offer a multitude of cost-saving options if you have a large number of job postings,
      please check out our <a routerLink="/pricing" target="_blank"> Pricing Page!</a></p>
  </div>
</div>

<div fxLayoutAlign="center center">
  <mat-button-toggle-group #group="matButtonToggleGroup" vertical="true">
    <mat-button-toggle class='post-upgrade-priority-button' (click)="priorityClickToggle('hi pri')"
                       value="highPriority" aria-label="Text align left">
      Select High Priority Upgrade
    </mat-button-toggle>
    <mat-button-toggle class='post-upgrade-priority-button' (click)="priorityClickToggle('priority')"
                       value="Priority" aria-label="Text align center">
      Select Priority Upgrade
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<!--<div class="example-selected-value">Selected value: {{group.value}}</div>-->
<!--<div class="example-selected-value">confirmButtonDisabled: {{confirmButtonDisabled}}</div>-->

<div mat-dialog-actions fxLayoutAlign="space-between">
  <button mat-raised-button color="warn" mat-button (click)="onNoClick()">Cancel Upgrade</button>
  <!--<button mat-raised-button [disabled]="confirmButtonDisabled" color="primary"-->
  <!--        mat-button [mat-dialog-close]="dialogReturnData" >-->
  <!--  {{selectPostConfirmButtonText}}-->
  <!--</button>-->
  <app-make-payment [disabled]="confirmButtonDisabled"
                    [paymentAmount]="currentPaymentAmount"
                    [paymentPrompt]=selectPostConfirmButtonText
                    [paymentButtonColor]="paymentButtonColor">
  </app-make-payment>
</div>
