import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Angulartics2Module} from 'angulartics2';
import {MaterialModule} from '../material.module';
import {SharedModule} from '../shared/shared.module';
import {PostModule} from '../Post/post.module';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PaymentsModule} from '../payments/payments.module';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

@NgModule({
  imports: [
    CommonModule,
    PaymentsModule,
    Angulartics2Module,
    MaterialModule,
    SharedModule,
    PostModule,
    FontAwesomeModule,
    NgxAuthFirebaseUIModule,
  ],
  exports: [CompanyProfileComponent],
  declarations: [CompanyProfileComponent],
})
export class CompanyModule { }
