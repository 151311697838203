import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { PaymentService } from './payment.service';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../material.module';
import {RouterModule} from '@angular/router';
import { PostUpgradeDialogComponent } from './post-upgrade-dialog/post-upgrade-dialog.component';
import { StripeSubscriptionComponent } from './stripe-subscription/stripe-subscription.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    MakePaymentComponent,
    PostUpgradeDialogComponent,
    StripeSubscriptionComponent
  ],
  providers: [
    PaymentService
  ],
  exports: [
    MakePaymentComponent,
    StripeSubscriptionComponent
  ]
})
export class PaymentsModule { }
