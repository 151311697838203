<form
  #addPostFormResponsibilitiesRequirements="ngForm"
  class="add-post-form"
  [formGroup]="postFormResponsibilitiesRequirements"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-sm="space-between center"
  fxLayout="row wrap">

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center"
            *ngIf="currentJobType == 'CRNA' || currentJobType == 'AA'">
    <!--Shift-->
    <mat-form-field
      class="add-post-focus-text-color"
      fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select formControlName="resShiftType"
                  placeholder="Shift Type"
                  multiple>
        <mat-option *ngFor="let option of shiftTypeOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Schedule-->
    <mat-form-field
      class="add-post-focus-text-color"
      fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select formControlName="resShiftDuration"
                  placeholder="Shift Duration Options"
                  multiple>
        <mat-option *ngFor="let option of shiftDurationOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-card>

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <!--Scheduled Call-->
    <mat-form-field fxFlex="48%" fxFlex.lt-sm="0 1 48%" class="scheduled-call-field add-post-focus-text-color">
      <mat-select
        formControlName="resScheduledCall"
        #scheduledCall
        placeholder="Scheduled Call">
        <mat-option *ngFor="let option of yesNoOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--How Often First Call-->
    <mat-form-field class="add-post-focus-text-color"
                    *ngIf="scheduledCall.value === 'Yes'" fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="How Often First Call"
             formControlName="resHowOftenFirstCall"
             matInput [matAutocomplete]="howOftenFirstCallAuto">
      <mat-autocomplete #howOftenFirstCallAuto ="matAutocomplete">
        <mat-option *ngFor="let option of callOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div *ngIf="scheduledCall.value === 'Yes'" class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Day Off After First Call-->
    <mat-form-field class="add-post-focus-text-color"
                    *ngIf="scheduledCall.value === 'Yes'" fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="resDayOffAfterFirstCall"
        placeholder="Day Off After First Call">
        <mat-option *ngFor="let option of alwaysNeverNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--How Often Second Call-->
    <mat-form-field class="add-post-focus-text-color"
                    *ngIf="scheduledCall.value === 'Yes'" fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <input type="text"
             placeholder="How Often Second Call"
             formControlName="resHowOftenSecondCall"
             matInput [matAutocomplete]="howOftenSecondCallAuto">
      <mat-autocomplete #howOftenSecondCallAuto="matAutocomplete">
        <mat-option *ngFor="let option of callOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!--Call Type-->
    <mat-form-field fxFlex="48%" fxFlex.lt-sm="0 1 48%" class="scheduled-call-field add-post-focus-text-color"
                    *ngIf="scheduledCall.value === 'Yes'">
      <mat-select
        formControlName="resCallType"
        #callType
        (selectionChange)="onCallTypeChange(callType.value)"
        placeholder="Call Type">
        <mat-option *ngFor="let option of callTypeOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Call Response Time-->
    <mat-form-field fxFlex="48%" fxFlex.lt-sm="0 1 48%" class="scheduled-call-field add-post-focus-text-color"
                    *ngIf="callType === 'Call From Home'">
      <mat-select
        formControlName="resCallResponseTime"
        placeholder="Call Response Time">
        <mat-option *ngFor="let option of callResponseTimeOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-card
            class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
    <!--Provider Model-->
    <mat-form-field *ngIf="currentJobType === 'Anesthesiologist' || currentJobType === 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="100%" fxFlex.lt-sm="0 1 100%">
      <mat-select
        #providerModel
        (selectionChange)="providerModelChange(providerModel.value)"
        formControlName="resProviderModel"
        placeholder="Provider Model">
        <mat-option *ngFor="let option of providerModelOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Direction or Supervision-->
    <mat-form-field *ngIf="providerModel === 'Care Team Model'"
                    class="add-post-focus-text-color"
                    fxFlex="100%" fxFlex.lt-sm="0 1 100%">
      <mat-select
        formControlName="resDirectionSupervision"
        placeholder="Direction or Supervision">
        <mat-option *ngFor="let option of directionSupervisionOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Case Mix-->
    <mat-form-field
      class="add-post-focus-text-color"
      fxFlex="100%" fxFlex.lt-sm="0 1 100%">
      <mat-select formControlName="resCaseMix"
                  placeholder="Case Mix"
                  multiple>
        <mat-option *ngFor="let option of caseMixOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--&lt;!&ndash;Anesthesiologist Medically directing CRNA or AAs&ndash;&gt;-->
    <!--<mat-form-field class="add-post-focus-text-color"-->
                    <!--fxFlex="100%" fxFlex.lt-sm="0 1 100%">-->
      <!--<mat-select-->
        <!--formControlName="resAnesthesiologistDirecting"-->
        <!--placeholder="Anesthesiologist Medically directing CRNA or AAs">-->
        <!--<mat-option *ngFor="let option of yesNoPercentageOfTimeOptions" [value]="option">{{option}}</mat-option>-->
      <!--</mat-select>-->
    <!--</mat-form-field>-->

    <!--&lt;!&ndash;Anesthesiologist Practicing Solo&ndash;&gt;-->
    <!--<mat-form-field class="add-post-focus-text-color"-->
                    <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
      <!--<mat-select-->
        <!--formControlName="resAnesthesiologistPercentSolo"-->
        <!--placeholder="Anesthesiologist Practicing Solo">-->
        <!--<mat-option *ngFor="let option of yesNoPercentageOfTimeOptions" [value]="option">{{option}}</mat-option>-->
      <!--</mat-select>-->
    <!--</mat-form-field>-->
  </mat-card>

  <!--<mat-card *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"-->
            <!--class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"-->
            <!--fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">-->

    <!--&lt;!&ndash;CRNA Medically Directed by Anesthesiologist&ndash;&gt;-->
    <!--<mat-form-field *ngIf="currentJobType == 'CRNA'"-->
                    <!--class="add-post-focus-text-color"-->
                    <!--fxFlex="100%" fxFlex.lt-sm="0 1 100%">-->
      <!--<mat-select-->
        <!--formControlName="resCRNADirectedByAnesthesiologist"-->
        <!--placeholder="CRNA Medically Directed by Anesthesiologist">-->
        <!--<mat-option *ngFor="let option of yesNoPercentageOfTimeOptions" [value]="option">{{option}}</mat-option>-->
      <!--</mat-select>-->
    <!--</mat-form-field>-->

    <!--&lt;!&ndash;CRNAs Perform Labor Epidurals&ndash;&gt;-->
    <!--<mat-form-field *ngIf="currentJobType == 'Anesthesiologist' || currentJobType == 'CRNA'"-->
                    <!--class="add-post-focus-text-color"-->
                    <!--fxFlex="100%" fxFlex.lt-sm="0 1 100%">-->
      <!--<mat-select-->
        <!--formControlName="resCRNAPerformLaborEpidurals"-->
        <!--placeholder="CRNAs Perform Labor Epidurals Under Medical Direction">-->
        <!--<mat-option *ngFor="let option of yesNoPercentageOfTimeOptions" [value]="option">{{option}}</mat-option>-->
      <!--</mat-select>-->
    <!--</mat-form-field>-->


  <!--</mat-card>-->

  <mat-card *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
            class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

    <!--Performs Spinal Anesthesia-->
    <mat-form-field *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="resSpinalAnesthesia"
        placeholder="Perform Spinal Anesthesia">
        <mat-option *ngFor="let option of yesNoSometimesOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!--Performs Labor Epidurals-->
    <mat-form-field *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="resLaborEpidurals"
        placeholder="Perform Epidurals">
        <mat-option *ngFor="let option of yesNoSometimesOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>



    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Arterial Catheter-->
    <mat-form-field *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="resArterialCatheter"
        placeholder="Place A-Lines">
        <mat-option *ngFor="let option of yesNoSometimesOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>


    <!--CV Cath-->
    <mat-form-field *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="resCentralVenousCatheter"
        placeholder="Place Central Lines">
        <mat-option *ngFor="let option of yesNoSometimesOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="add-post-salary-vertical-space" fxFlex="100%"></div>

    <!--Perform Regional Blocks-->
    <mat-form-field *ngIf="currentJobType == 'AA' || currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="resRegionalBlocks"
        placeholder="Perform Regional Blocks">
        <mat-option *ngFor="let option of yesNoSometimesOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

  </mat-card>

  <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
    <!--New Graduates Acceptable-->
    <mat-form-field class="add-post-focus-text-color scheduled-call-field"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="reqNewGrads"
        placeholder="New Graduates Acceptable">
        <mat-option *ngFor="let option of yesNoNegotiableOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- State License Required -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select #state
                  formControlName="reqStateLicense"
                  placeholder="State License"
                  multiple>
        <mat-option value="All">All</mat-option>
        <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">{{state.stateName}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- NBCRNA Certification Status -->
    <mat-form-field *ngIf="currentJobType == 'CRNA'"
                    class="add-post-focus-text-color"
                    fxFlex="100%" fxFlex.lt-sm="0 1 100%">
      <mat-select
        formControlName="reqNBCRNACertification"
        placeholder="NBCRNA Certification Status">
        <mat-option *ngFor="let option of nbcrnaCertificationStatusOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-card *ngIf="currentJobType == 'Anesthesiologist'"
            class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
            fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
    <!-- Subspecialty Fellowship -->
    <mat-form-field
                    class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select formControlName="reqSubspecialtyFellowship"
                  placeholder="Subspecialty Fellowship"
                  multiple>
        <mat-option *ngFor="let option of subspecialtyFellowship" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- VISAS Accepted -->
    <mat-form-field class="add-post-focus-text-color"
                    fxFlex="48%" fxFlex.lt-sm="0 1 48%">
      <mat-select
        formControlName="reqVisaAccepted"
        placeholder="VISAS Accepted"
        multiple>
        <mat-option *ngFor="let option of visasAccepted" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <!--<mat-card *ngIf="currentJobType == 'CRNA'"-->
            <!--class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"-->
            <!--fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">-->



  <!--</mat-card>-->

  <!--<div class="debug-information" *ngIf="devModeEnabled" fxFlexAlign="row" fxLayoutAlign="center center">-->
    <!--&lt;!&ndash;<pre>{{ addPostFormResponsibilitiesRequirements.value | json }}</pre>&ndash;&gt;-->
  <!--</div>-->

</form>
