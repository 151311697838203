import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../Services/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';

// const stripe = require('stripe')('pk_test_uFjwuZ9bJd8JqwCB3EiZYI7G');
import {loadStripe} from '@stripe/stripe-js';
import {fn} from '@angular/compiler/src/output/output_ast';
import {first} from 'rxjs/operators';
import {StripeBagmaskService} from '../../Services/stripeBagmask.service';
import {UserService} from '../../Services/user.service';

@Component({
  selector: 'app-stripe-subscription',
  templateUrl: './stripe-subscription.component.html',
  styleUrls: ['./stripe-subscription.component.scss']
})
export class StripeSubscriptionComponent implements OnInit {
  isLoading: boolean;
  isLoadingMessage = 'Fetching User Info...';
  hasCurrentSubscription = false;
  currentUserUID: string;
  STRIPE_PUBLISHABLE_KEY = 'pk_test_uFjwuZ9bJd8JqwCB3EiZYI7G';
  stripe: any;

  stripePlanData = [];

  constructor(private afs: AngularFirestore,
              private authService: AuthService,
              private fns: AngularFireFunctions,
              public stripeBagmaskService: StripeBagmaskService,
              public userService: UserService) { }

  async ngOnInit() {
    this.stripe = await loadStripe('pk_test_uFjwuZ9bJd8JqwCB3EiZYI7G');
    // Set isLoading to true, until we are sure we have the authenticatedUser field
    this.isLoading = true;
    // Step 1: get 'currentUser' or current firebase user's uid

    // Check to see if authenticatedUser exists yet, if not wait and try again
    // It should be created very quickly after user signs into site, but if user immediately/directly goes to this page, it may not
    // eist yet.
    if (this.authService.authenticatedUser) {
      // Exists, set the local variable
      this.currentUserUID = this.authService.authenticatedUser.uid;
      console.log('Current User UID: ', this.currentUserUID);
      // TODO: Now that we have UID, check to see if they have a value in customers/UID/subscriptions
      this.isLoading = false;
    } else {
      console.log('authenticatedUser does not exist yet, waiting then fetching');
      setTimeout(() => {
        this.currentUserUID = this.authService.authenticatedUser.uid;
        console.log('Current User UID: ', this.currentUserUID);
        this.isLoading = false;
      } , 2000)
    }

    // Step 2: Get current Stripe subscription plans
    this.stripePlanData = await this.stripeBagmaskService.getBagMaskStripeSubPlans();
    console.log('Final StripePlanData: ', this.stripePlanData);
    // await this.getBagMaskStripeSubPlans();
  }

  async stripeBillingHandler(planPriceId: string) {
    console.log('Starting stripeBillingHandler for planPriceId: ', planPriceId);
    console.log('currentUserUID: ', this.currentUserUID);

    const docRef = await this.afs
      .collection('customers')
      .doc(this.currentUserUID)
      .collection('checkout_sessions')
      .add({
        // price: formData.get('price'),
        // price: 14900,
        price: planPriceId,
        // price: 'price_1HVLo1CfLG9nGFpofBpZhCRM',
        allow_promotion_codes: true,
        // tax_rates: taxRates,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          tax_rate: 'N/A',
        },
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`);
        document.querySelectorAll('button').forEach((b) => (b.disabled = false));
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        console.log(sessionId);
        // const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
        this.stripe.redirectToCheckout({ sessionId });
      }
    });

  }

  async stripeBillingPortal() {
    console.log('Starting stripeBillingPortal');
    // Call billing portal function
    const functionRef = this.fns
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')

    // @ts-ignore
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    const data = await functionRef({ returnUrl: window.location.origin });
    data.subscribe(val => {
      console.log(val);
      window.location.assign(val.url);
    })
    // window.location.assign(data.url);

    // Call billing portal function
    // const functionRef = firebase
    //   .app()
    //   .functions(functionLocation)
    //   .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    // window.location.assign(data.url);

    // window.location.assign(data.url);
    // let session = await this.stripe.billingPortal.sessions.create({
    //   customer: 'cus_I5Y00VkVZt5GUe',
    //   return_url: 'https://jobs.bagmask.com',
    // });
  }

  async stripeDeleteCustomer() {
    console.log('Starting stripeDeleteCustomer');
    // Call delete function

    const functionRef = this.fns
      .httpsCallable('ext-firestore-stripe-subscriptions-onUserDeleted')

    // @ts-ignore
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    const data = await functionRef({ returnUrl: window.location.origin });
    data.subscribe(val => {
      console.log(val);
      window.location.assign(val.url);
    })

    // const deleted = await this.stripe.customer.del(
    //   'cus_I5Y00VkVZt5GUe'
    // );




  }

}
