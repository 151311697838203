import { Injectable, isDevMode } from '@angular/core';
import {AuthService} from './auth.service';
import {Angulartics2} from 'angulartics2';
import { SnotifyService } from 'ng-snotify';

@Injectable()
export class AnalyticsEventService {
  googleAnalyticsEventPriorityName: string;

  constructor(
    private authService: AuthService,
    private angulartics2: Angulartics2,
    private snotifyService: SnotifyService,
  ) {

  }


  // When a job search happens, send a google analytics event
  jobSearchAction(type: any, state, action: string) {
    console.log('jobSearchAction action: ' + action + ' type:' + type + ' state: ' + state);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'Job Search, Type: ' + type,
          label: 'Job Search - Type: ' + type + ' State: ' + state,
        },
      });
      console.log('Event for ' + action + ' sent to Google Analytics!');
    }
  }

  jobPostAction(data: any, action: string) {
    // Now that we've opened a post we want to send an event to Google Analytics so we can track metrics for individual post views!
    console.log('jobPostAction action: ' + action + ' - data:');
    console.log(data);

    this.setGAPriorityName(data);
    console.log('googleAnalyticsEventPriorityName: ' + this.googleAnalyticsEventPriorityName);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      let labelToSet: string;

      // We have a view action, simply put the link to job inside with shortDesc
      if (action === 'View') {
        labelToSet = 'https://jobs.bagmask.com/post/' + data.id + ' - Sh Desc: ' + data.shortDescription;
      // Else we have an apply, so include the above prefaced by an apply type
      } else {
        labelToSet = action + ': https://jobs.bagmask.com/post/' + data.id + ' - Sh Desc: ' + data.shortDescription;
      }

      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'Job Posting: ' + data.companyName + ' - ' + this.googleAnalyticsEventPriorityName,
          label: labelToSet,
        },
      });
      console.log('Event for ' + action + ' sent to Google Analytics!');
    }
  }

  // We want to record actions and activities related to high priority advertisements!
  highPriorityAdAction(data: any, action: string) {

    console.log('highPriorityAdAction action: ' + action + ' - data:');
    console.log(data);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'HiPri Ad: ' + data.companyName,
          label: 'Job ID: ' + data.id + ' Short Description: ' + data.shortDescription,
        },
      });
      console.log('Event for ' + action + ' sent to Google Analytics!');
    }
  }

  // We want to record actions and activities related to Company Posts View action
  companyPostsViewAction(companyName: string, articleTitle: string, action: string) {

    console.log('companyPostsViewAction action: ' + action + ' - data:');
    console.log(companyName);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'Article View Jobs For: ' + companyName,
          label: 'View Jobs from Article: ' + articleTitle,
        },
      });
      console.log('Event for ' + action + ' sent to Google Analytics!');
    }
  }

  // Event for a Job Poster Dashboard Action
  jobPosterAction(companyName: string, description: string) {
    //    this.analyticsEventService.jobPosterAction(this.companyName,'Refresh Job Postings');

    console.log('jobPosterAction companyName: ' + companyName + ' description: ' + description);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: 'Job Poster Action',
        properties: {
          category: companyName + ' - ' + description,
          label: companyName + ': ' + description,
        },
      });
      console.log('Event for jobPosterAction sent to Google Analytics!');
    }
  }

  // Want to be able to track ad impressions, may need to complete this function later
  adImpressionAction(data: any, action: string) {
    // Track when a user clicks on a paid advertisement
    console.log('adImpressionAction action: ' + action + ' - data:');
    console.log(data);

    // this.setGAPriorityName(data);
    // console.log('googleAnalyticsEventPriorityName: ' + this.googleAnalyticsEventPriorityName);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'Ad Click for Company: ' + data.companyName,
          label: 'Ad Click, Ad Size: ' + data.adSize,
        },
      });
      console.log('adClickAction Event for ' + action + ' sent to Google Analytics!');
    }
  }

  adClickAction(data: any, action: string) {
    // Track when a user clicks on a paid advertisement
    console.log('adClickAction action: ' + action + ' - data:');
    console.log(data);

    // this.setGAPriorityName(data);
    // console.log('googleAnalyticsEventPriorityName: ' + this.googleAnalyticsEventPriorityName);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'Ad Click for Company: ' + data.companyName,
          label: 'Ad Click, Company: ' + data.companyName,
        },
      });
      console.log('adClickAction Event for ' + action + ' sent to Google Analytics!');
    }
  }

  // We want to record actions and activities related to CV Template Views!
  cvTemplateViewAction(data: any, action: string) {

    console.log('cvTemplateViewAction action: ', action, ' - data:', data);

    // Check to make sure not a site admin, for more accurate reporting
    if (this.isSiteAdmin()) {
      // Do nothing
      console.log('Not logging to google analytics');
    } else {
      this.angulartics2.eventTrack.next({
        action: action,
        properties: {
          category: 'CVTemplateView user: ' + data.userEmail + ' Template: ' + data.cvName,
          label: 'CVTemplateView user: ' + data.userEmail + ' Template: ' + data.cvName,
        },
      });
      console.log('Event for ' + action + ' sent to Google Analytics!');
    }
  }


  // Get post priority for event
  setGAPriorityName(data: any) {
    // Check post priority
    console.log('Post priority selected: ');
    switch (data.postPriority) {
      case 1: {
        console.log('Standard');
        this.googleAnalyticsEventPriorityName = 'Standard';
        break;
      }
      case 2: {
        console.log('Priority');
        this.googleAnalyticsEventPriorityName = 'Priority';
        break;
      }
      case 3: {
        console.log('High Priority');
        this.googleAnalyticsEventPriorityName = 'High Priority';
        break;
      }
    }
  }

  // Check if a current site admin is logged in, or dev mode, and don't log event if so
  isSiteAdmin(): boolean {
    let returnValue: boolean;

    if (isDevMode()) {
      this.snotifyService.warning('Dev Mode Enabled, no analytics event activated', {
        timeout: 5000,
      });
      returnValue = true;
    }

    if (this.authService.fullUserInfo) {
      if (this.authService.fullUserInfo.email === 'c6dillon@gmail.com' || this.authService.fullUserInfo.email === 'flahertyjpat@gmail.com')
      {
        console.log('is Dillon or Pat, dont log event to google!');
        returnValue = true;
      }
    } else {
      console.log('log event to google!');
      returnValue = false;
    }

    return returnValue;
  }

}
