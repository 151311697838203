<div>
  <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">

  <mat-card class="scholarship-info-card">
    <div class="mat-typography" id="mc_embed_signup">
      <form ngNoForm
            action="https://bagmask.us19.list-manage.com/subscribe/post?u=6bd735bcbbbdbd8f463ee81cb&amp;id=104c72f257"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
            target="_blank" novalidate>
        <div class="white-font" id="mc_embed_signup_scroll">
          <h2 class="white-font mailing-list-font">Sign up for our BagMask.com Monthly Newsletter, and stay up to
            date with upcoming Scholarship Opportunities</h2>
          <div class="mc-field-group">
            <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
            </label>
            <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
          </div>
          <div class="mc-field-group">
            <label for="mce-PROVIDERT">Provider Type <span class="asterisk">*</span>
            </label>
            <select name="PROVIDERT" class="required" id="mce-PROVIDERT">
              <option value=""></option>
              <option value="Anesthesiologist">Anesthesiologist</option>
              <option value="Anesthesia Resident">Anesthesia Resident</option>
              <option value="CRNA">CRNA</option>
              <option value="SRNA">SRNA</option>
              <option value="CAA">CAA</option>
              <option value="SAA">SAA</option>
              <option value="Medical Student">Medical Student</option>
              <option value="Program Administrator">Program Administrator</option>
              <option value="Other">Other</option>

            </select>
          </div>
          <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                                                                                    name="b_6bd735bcbbbdbd8f463ee81cb_104c72f257"
                                                                                    tabindex="-1" value=""></div>
          <div class="clear center-button"><input type="submit" value="Stay Informed" name="subscribe"
                                                  id="mc-embedded-subscribe"
                                                  class="button button-blue"></div>
        </div>
      </form>
    </div>
  </mat-card>
  <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
  <script type='text/javascript'>(function ($) {
      window.fnames = new Array();
      window.ftypes = new Array();
      fnames[0] = 'EMAIL';
      ftypes[0] = 'email';
      fnames[3] = 'MMERGE3';
      ftypes[3] = 'dropdown';
  }(jQuery));
  var $mcj = jQuery.noConflict(true);</script>

</div>
