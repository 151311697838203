<div *ngIf="!userDataIsLoading" class="add-post-container">
  <mat-toolbar class="bagmask-sub-toolbar toolbar-smaller-font mat-elevation-z3" fxLayout="row">
    <p fxFlex>{{addPostStatusTitle}} - {{postAction}}</p>
    <!--<p fxFlex>Add a Job Posting</p>-->
    <div fxFlex class="add-post-toolbar-middle" fxLayoutGap="15px">

      <button *ngIf="isEditPost" mat-button mat-raised-button
              matTooltip="Back to Profile"
              class="add-post-toolbar-button"
              (click)="backToProfile()">
        Back to Profile
      </button>
      <!-- If the user is adding new post or editing a saved post, allow them to
      create a new template from their current post -->
      <!-- TODO: Re-enable Templates when they are working correctly  -->
<!--      <button *ngIf="postAction === 'Add' || postAction === 'Saved'"-->
<!--              mat-button mat-raised-button-->
<!--              matTooltip="Create Template from Post"-->
<!--              class="add-post-toolbar-button"-->
<!--              (click)="onSubmit('Template')">-->
<!--        Create Template from Posting-->
<!--      </button>-->
      <!-- If the user is working with a template, allow them to save the current template -->
      <button *ngIf="postAction === 'Template'"
              mat-button mat-raised-button
              matTooltip="Save this Template"
              class="add-post-toolbar-button"
              (click)="onSubmit('Template')">
        Save this Template
      </button>
      <button *ngIf="postAction === 'Add'"
              mat-button mat-raised-button
              matTooltip="Save Post"
              class="add-post-toolbar-button"
              (click)="onSubmit('Saved')">
        Save Posting
      </button>

    </div>
    <div fxFlex></div>
  </mat-toolbar>

  <!-- Debug mode below lets clicking next without all fields populated -->
  <mat-horizontal-stepper linear="true" #stepper>
    <!-- Setting linear='true' below will make each form required before taking further steps -->
    <!--<mat-horizontal-stepper linear="true" #stepper>-->

    <!-- Primary Fields Step -->
    <mat-step [stepControl]="postForm">
      <ng-template matStepLabel>Primary Fields</ng-template>
      <form
        #addPostFormMain="ngForm"
        class="add-post-form"
        [formGroup]="postForm"
        (ngSubmit)="onSubmit('Active')"
        fxLayoutAlign="space-between center"
        fxLayoutAlign.lt-sm="space-between center"
        fxLayout="row wrap">

        <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
                  fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
          <!--Job Type-->
          <mat-form-field class="add-post-focus-text-color" fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <mat-select
              required
              #inputJobType
              (selectionChange)="onJobTypeChange(inputJobType.value)"
              formControlName="jobType"
              placeholder="Job Type">
              <mat-option *ngFor="let jobType of jobTypes" [value]="jobType">{{jobType}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- hidden div to take up space after job type -->
          <div fxFlex="50%">

          </div>

          <!-- Leadership Position Checkbox -->
          <div fxFlex="50%" class='add-post-checkbox add-post-focus-text-color'>
            <mat-checkbox color="primary" (change)="checkBoxValueChanged($event, 'Leadership')">Leadership Position</mat-checkbox>
          </div>

          <!-- Pain Management Position Checkbox -->
          <div fxFlex="50%" class='add-post-checkbox add-post-focus-text-color'>
            <mat-checkbox color="primary" (change)="checkBoxValueChanged($event, 'Pain')">Pain Management Position</mat-checkbox>
          </div>

          <!--Short Description-->
          <mat-form-field class="add-post-description add-post-focus-text-color"
                          fxFlex="0 1 100%" fxFlex.lt-sm="0 1 calc(100% - 15px)"
                          hintLabel="Maximum {{shortDescriptionLength}} characters">
            <textarea
              matInput
              maxlength={{shortDescriptionLength}}
              formControlName="shortDescription"
              required
              #inputShortDescription
              placeholder="Short Description">
            </textarea>
            <mat-hint align="end">{{inputShortDescription.value?.length || 0}}/{{shortDescriptionLength}}</mat-hint>
          </mat-form-field>
        </mat-card>

        <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
                  fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">
          <!--Long Description-->
          <mat-form-field class="add-post-long-description add-post-focus-text-color"
                          fxFlex="0 1 100%" fxFlex.lt-sm="0 1 calc(100% - 15px)">
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="longDescription"
              required
              #inputLongDescription
              placeholder="Long Description">
            </textarea>
            <mat-hint>This field will expand to fit the Long Description of the Position.</mat-hint>
          </mat-form-field>
        </mat-card>

        <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
                  fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

          <!-- Employment Status -->
          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <mat-select
              required
              formControlName="employmentStatus"
              placeholder="Employment Status">
              <mat-option *ngFor="let employmentStatus of employmentStatuses"
                          [value]="employmentStatus">{{employmentStatus}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Tax Status -->
          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <mat-select
              required
              formControlName="taxStatus"
              placeholder="Tax Status">
              <mat-option *ngFor="let taxStatus of taxStatuses" [value]="taxStatus">{{taxStatus}}</mat-option>
            </mat-select>
          </mat-form-field>

          <!--Salary Fields-->

          <!-- Min Salary -->
          <mat-form-field class="add-post-focus-text-color small-vertical-space"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%" hintLabel="Thousands, max 3 digits">
            <input
              class="post-right-align add-post-input"
              matInput
              maxlength="3"
              #inputMinSalary
              (keypress)="uiService.numberOnly($event)"
              autocomplete="off"
              formControlName="minSalary"
              placeholder="Minimum Salary">
            <!--<span matPrefix>$</span>-->
            <span matSuffix>k</span>
            <mat-hint align="end">{{inputMinSalary.value?.length || 0}}/3</mat-hint>
            <!--<mat-error *ngIf="minSalary.invalid">{{getMinSalaryErrorMessage()}}</mat-error>-->
          </mat-form-field>

          <!-- Max Salary -->
          <mat-form-field class="add-post-focus-text-color small-vertical-space"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%" hintLabel="Thousands, max 3 digits">
            <input
              class="post-right-align"
              matInput
              maxlength="3"
              autocomplete="off"
              #inputMaxSalary
              (keypress)="uiService.numberOnly($event)"
              formControlName="maxSalary"
              placeholder=" Maximum Salary">
            <!--<span matPrefix>$</span>-->
            <span matSuffix>k</span>
            <mat-hint align="end">{{inputMaxSalary.value?.length || 0}}/3</mat-hint>
            <!--<mat-error *ngIf="maxSalary.invalid">{{getMaxSalaryErrorMessage()}}</mat-error>-->
          </mat-form-field>

          <!-- Hourly Fields -->

          <!-- Min Hourly -->
          <mat-form-field class="add-post-focus-text-color small-vertical-space"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%" hintLabel="Max 3 digits">
            <input
              class="post-right-align add-post-input"
              matInput
              maxlength="3"
              #inputMinHourly
              (keypress)="uiService.numberOnly($event)"
              autocomplete="off"
              formControlName="minHourly"
              placeholder="Minimum Hourly Rate">
            <mat-hint align="end">{{inputMinHourly.value?.length || 0}}/3</mat-hint>
            <!--<mat-error *ngIf="minSalary.invalid">{{getMinSalaryErrorMessage()}}</mat-error>-->
          </mat-form-field>

          <!-- Max Hourly -->
          <mat-form-field class="add-post-focus-text-color small-vertical-space"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%" hintLabel="Max 3 digits">
            <input
              class="post-right-align"
              matInput
              maxlength="3"
              autocomplete="off"
              #inputMaxHourly
              (keypress)="uiService.numberOnly($event)"
              formControlName="maxHourly"
              placeholder=" Maximum Hourly Rate">
            <mat-hint align="end">{{inputMaxHourly.value?.length || 0}}/3</mat-hint>
            <!--<mat-error *ngIf="maxSalary.invalid">{{getMaxSalaryErrorMessage()}}</mat-error>-->
          </mat-form-field>



          <!--<mat-form-field class="add-post-focus-text-color"-->
          <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->
          <!--<input-->
          <!--matInput-->
          <!--formControlName="companyName"-->
          <!--required-->
          <!--placeholder="Company">-->
          <!--</mat-form-field>-->

          <!--&lt;!&ndash;Company Type&ndash;&gt;-->
          <!--<mat-form-field class="add-post-focus-text-color"-->
          <!--fxFlex="48%" fxFlex.lt-sm="0 1 48%">-->

          <!--<input-->
          <!--matInput-->
          <!--formControlName="companyType"-->
          <!--required-->
          <!--placeholder="Company Type">-->

          <!--&lt;!&ndash;<mat-select&ndash;&gt;-->
          <!--&lt;!&ndash;required&ndash;&gt;-->
          <!--&lt;!&ndash;formControlName="companyType"&ndash;&gt;-->
          <!--&lt;!&ndash;placeholder="User Type">&ndash;&gt;-->
          <!--&lt;!&ndash;<mat-option *ngFor="let companyType of companyTypes" [value]="companyType">{{companyType}}</mat-option>&ndash;&gt;-->
          <!--&lt;!&ndash;</mat-select>&ndash;&gt;-->
          <!--</mat-form-field>-->

          <!-- Start Date -->
          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <mat-select
              formControlName="startDate"
              #startDate
              (selectionChange)="onStartDateChange(startDate.value)"
              placeholder="Start Date">
              <mat-option *ngFor="let companyType of startDateOptions"
                          [value]="companyType">{{companyType}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div [hidden]="showDatePicker" class="add-post-focus-text-color" fxFlex="48%" fxFlex.lt-sm="0 1 48%"></div>

          <!-- Date Picker for Start Date -->
          <mat-form-field [hidden]="!showDatePicker"
                          class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <input matInput
                   placeholder="Start Date"
                   [matDatepicker]="picker"
                   autocomplete="off"

                   formControlName="startDatePick">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <!--<div class="add-post-short-vertical-space"></div>-->

          <!-- Contact Email -->
          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <input
              matInput
              formControlName="contactEmail"
              matTooltip="Enter the Email for the person responsible for managing this specific job posting."
              placeholder="Contact Email">
            <mat-hint>Can be different from Account Email.</mat-hint>
            <mat-error>Invalid or missing email.</mat-error>
          </mat-form-field>

          <!-- Company Application Portal -->
          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <input
              matInput
              formControlName="companyPortal"
              matTooltip="Enter the specific website link that we can direct job seekers to for this specific job
                          posting, otherwise all applications will be sent to the e-mail listed in your User Profile."
              placeholder="Company Application Portal For Job">
          </mat-form-field>

          <!-- Company Internal Job ID -->
          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <input
              matInput
              formControlName="companyJobID"
              matTooltip="This is to track a company's internal Job ID"
              placeholder="Your Company's Job ID">
          </mat-form-field>

        </mat-card>

        <mat-card class="bagmask-card-dark-blue" fxFlex="100%" fxLayoutAlign="space-between center"
                  fxLayout="row wrap" fxLayoutAlign.lt-sm="space-between center">

          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <input
              matInput
              formControlName="facilityName"
              placeholder="Facility Name">
          </mat-form-field>

          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="100%" fxFlex.lt-sm="100%">
            <input
              matInput
              formControlName="address"
              placeholder="Address">
          </mat-form-field>

          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="48%" fxFlex.lt-sm="0 1 48%">
            <input
              matInput

              formControlName="city"
              placeholder="City">
          </mat-form-field>

          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="24%" fxFlex.lt-sm="0 1 24%">
            <mat-select
              formControlName="state"
              required
              placeholder="State">
              <mat-option *ngFor="let state of usStates" [value]="state.abbreviation">{{state.stateName}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="add-post-focus-text-color"
                          fxFlex="24%" fxFlex.lt-sm="0 1 24%" hintLabel="5 digit zipcode">
            <input
              class="post-right-align"
              matInput
              maxlength="5"
              #inputZipCode
              formControlName="zipCode"
              placeholder="Zip Code">
            <mat-hint align="end">{{inputZipCode.value?.length || 0}}/5</mat-hint>
          </mat-form-field>
        </mat-card>

        <div fxLayoutAlign="space-around">
          <!--<button mat-raised-button color="primary" type="submit" [disabled]="postForm.invalid">Add Post</button>-->
          <!--<button mat-raised-button color="primary" type="submit">(Debug) Add Post</button>-->
          <!--<button mat-raised-button color="primary">Cancel</button>-->
        </div>
      </form>

      <div class="add-post-vertical-space"></div>

      <div class="add-post-button-container">
        <div fxLayoutAlign="space-around">
          <!-- Wrapper around disabled button so we can have a mouseover event fire when moving into it
               without the wrapper, events will not fire on a disabled control! -->
          <div *ngIf="postForm.invalid" (mouseover)="invalidMessage()">
            <button *ngIf="postForm.invalid" disabled mat-button mat-raised-button color="primary"
                    (mouseover)="invalidMessage()"
                    (mouseenter)="invalidMessage()"
                    matStepperNext>Next
            </button>
          </div>
          <div *ngIf="!postForm.invalid">
            <button *ngIf="!postForm.invalid" mat-button mat-raised-button color="primary"
                    (click)="onTopClick()"
                    matStepperNext>Next
            </button>
          </div>
        </div>
      </div>
      <!--<div class="debug-information" *ngIf="devModeEnabled" fxFlexAlign="row" fxLayoutAlign="center center">-->
      <!--<pre>{{ addPostFormMain.value | json }}</pre>-->
      <!--<button mat-raised-button color="primary" (click)="onSubmit()"-->
      <!--type="submit">(Debug) Add Post</button>-->
      <!--<button mat-raised-button color="primary" (click)="updateDatabase()">(Debug) Update Database</button>-->
      <!--</div>-->

    </mat-step>

    <!-- Salary and Benefits Step -->
    <mat-step [optional]="true">
      <ng-template matStepLabel>Salary and Benefits</ng-template>
      <app-add-post-salary-benefits></app-add-post-salary-benefits>
      <div class="add-post-button-container">
        <div fxLayoutAlign="space-around">
          <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperPrevious>Back</button>
          <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperNext>Next</button>
        </div>
      </div>
    </mat-step>

    <!-- Responsibilities and Requirements Step -->
    <mat-step [optional]="true">
      <ng-template matStepLabel>Responsibilities and Requirements</ng-template>
      <app-add-post-responsibilities-requirements></app-add-post-responsibilities-requirements>
      <!--<app-add-post-responsibilities></app-add-post-responsibilities>-->
      <div class="add-post-button-container">
        <div fxLayoutAlign="space-around">
          <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperPrevious>Back</button>
          <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperNext>Next</button>
        </div>
      </div>
    </mat-step>

    <!--&lt;!&ndash; Requirements Step &ndash;&gt;-->
    <!--<mat-step [optional]="true">-->
    <!--<ng-template matStepLabel>Requirements</ng-template>-->
    <!--<app-add-post-requirements></app-add-post-requirements>-->
    <!--<div class="add-post-button-container">-->
    <!--<div fxLayoutAlign="space-around">-->
    <!--<button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperPrevious>Back</button>-->
    <!--<button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperNext>Next</button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</mat-step>-->

    <!--Facility and Area Details Step -->
    <mat-step [optional]="true">
      <ng-template matStepLabel>Facility and Area Details</ng-template>
      <app-add-post-facility></app-add-post-facility>
      <div class="add-post-button-container">
        <div fxLayoutAlign="space-around">
          <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperPrevious>Back</button>
          <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperNext>Next</button>
        </div>
      </div>
    </mat-step>

    <!-- Pay and Submit Job Posting Step -->
    <mat-step>
      <ng-template matStepLabel>Submit Posting</ng-template>
      <div class="add-post-form">
        <app-add-post-payment-submit [editYesNo]="isEditPost"></app-add-post-payment-submit>
        <div class="add-post-button-container" fxLayoutAlign="space-around" fxLayout="row wrap">
          <!-- <button mat-button mat-raised-button color="primary" (click)="onTopClick()" matStepperPrevious>Back</button> -->
          <!--<div class="add-post-vertical-space" fxFlex="100%"></div>-->
          <!--<button class="add-post-submit-button" mat-raised-button color="warn" (click)="onSubmit('Active');-->
          <!--stepper.reset()" [disabled]="postForm.invalid || addPostPaymentSubmit.postFormPaymentSubmit.invalid">-->
          <!--Pay and Activate Post-->
          <!--</button>-->
          <app-make-payment [disabled]="postForm.invalid || addPostPaymentSubmit.postFormPaymentSubmit.invalid"
                            [paymentAmount]="addPostTotalCostInCents" [paymentPrompt]="paymentPrompt">

          </app-make-payment>
        </div>
      </div>

    </mat-step>
  </mat-horizontal-stepper>


</div>

<div *ngIf="userDataIsLoading">
  Loading...
</div>

