import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {USStates} from '../Namespaces/usStates.namespace';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import {AuthService} from '../Services/auth.service';
import {Title} from '@angular/platform-browser';
import {CacheService} from '../Services/cache.service';
import {User} from '../Models/user.model';
import {AnalyticsEventService} from '../Services/analyticsEvent.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UIService} from '../Services/ui.service';
import { SnotifyService } from 'ng-snotify';

// @ts-ignore
const Parallax = require('parallax-js');

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('typeGroup') typeGroup: ElementRef;

  usStates = USStates.getStates();
  value = 'Clear me!';
  searchButtonEnabled = false;
  typeGroupValue = null;
  stateValue = null;
  stateSelected = 'All';
  userType: any;
  isAuth = false;
  userPosterType: string;

  userInfo: User;
  userInfoSubscription: Subscription;

  constructor(
              private cacheService: CacheService,
              private analyticsEventService: AnalyticsEventService,
              private router: Router,
              private route: ActivatedRoute,
              private snotifyService: SnotifyService,
              private titleService: Title,
              public uiService: UIService,
              private httpClient: HttpClient,
              public authService: AuthService) {
  }

  ngOnInit() {
    // HttpParams getting paramaters
    // https://blog.angular-university.io/angular-http/

    // This will display all of the incoming query params
    // ?paramType=name&paramType2=name2
    this.route.queryParamMap.subscribe(params =>{
      console.log('queryParamMap params: ', params);
    });

    // Example of looking for a specific paramater and do something with it
    // let name = this.route.snapshot.queryParamMap.get("paramName");
    // this.route.queryParamMap.subscribe(queryParams => {
    //   name = queryParams.get("paramName");
    //   console.log('paramName: ', name);
    // });

    // Use queryParamMap to see if we have "expiredPosts" as a query paramater.  If we do, check for a uid to determine who came to refresh
    // let expiredPosts = this.route.snapshot.queryParamMap.get('expiredPosts');
    // this.route.queryParamMap.subscribe(queryParams => {
    //   expiredPosts = queryParams.get("expiredPosts");
    //   console.log('expiredPosts: ', expiredPosts);
    //   // Now check for uid
    //   let expiredPostsUid = this.route.snapshot.queryParamMap.get('uid');
    //   if (expiredPostsUid) {
    //     console.log('We have both expiredPosts and expiredPostsUid');
    //   } else {
    //     console.error('We have an expiredPosts query without an associated uid to attribute it to');
    //   }
    // });


    // Youtube player script addition
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    this.titleService.setTitle('Anesthesia Job Search | BagMask.com');

    console.log('does auth fullUserInfo exist?');
    console.log(this.authService.fullUserInfo);

    // Check to see if we're already active in app, fullUserInfo exists, otherwise subscribe to the first run to get info
    if (this.authService.fullUserInfo) {
      console.log('Home, populating userInfo from fullUserInfo');
      this.userInfo = this.authService.fullUserInfo;
      this.userPosterType = this.userInfo.userType;

      console.log(this.userInfo);
      console.log(this.userPosterType);
    } else {
      console.log('Home onInit getting userInfoSubscription');
      this.userInfoSubscription = this.authService.fullUserInfoSubject.subscribe(userInfoSub => {
          this.userInfo = userInfoSub;
          this.userPosterType = userInfoSub.userType;
          console.log('Home, checking userInfoSubscription');
          console.log(JSON.stringify(userInfoSub));
          console.log(this.userPosterType);
        }
      );
    }

  }

  ngAfterContentInit() {
    // Parallax Library Code Example Below and on HTML.
    // const scene = document.getElementById('scene');
    // const parallaxInstance = new Parallax(scene);
    // const scene2 = document.getElementById('scene2');
    // const parallaxInstance2 = new Parallax(scene2);
    // const parallaxInstance = new Parallax(scene, {
    //   relativeInput: true,
    //   hoverOnly: true
    // });
  }

  async signInWithGoogle() {
    await this.authService.googleLogin();
    return await this.afterSignIn();
  }

  private afterSignIn() {
    // Do after login stuff here, such router redirects, toast messages, etc.
    // return this.router.navigate(['/']);
  }

  onTypeGroupValChange(value: string) {
    console.log('onTypeGroupValChange activated');
    console.log(value);
    this.typeGroupValue = value;
    this.searchButtonEnabled = true;
    // if (this.stateValue && this.typeGroupValue) {
    //   console.log('both have values, enabling search');
    //   this.searchButtonDisabled = false;
    // } else {
    //   console.log('both don\'t have values yet, search remains disabled');
    // }
  }

  // // Old code used to check and make sure state and job type were selected when state was required
  // onStateValChange(value: string) {
  //   console.log('stateSelectGroup activated');
  //   console.log(value);
  //   this.stateValue = value;
  //   if (this.stateValue && this.typeGroupValue) {
  //     console.log('both have values, enabling search');
  //     this.searchButtonDisabled = false;
  //   } else {
  //     console.log('both don\'t have values yet, search remains disabled');
  //   }
  // }

  onSearchClick(type: string, state?: string) {
    // When we have a job search we want to fire a Google event to track number of searches
    this.analyticsEventService.jobSearchAction(type, state, 'Search');

    console.log('In Home, onSearchClick, type: ' + type + ' and state (optional): ' + state);
    if (state === 'All') {
      console.log('We have a type value, and state set to all, search with just type paramater');
      this.router.navigate(['/search/', { type: type}]);
    } else if (state) {
      console.log('Searching for type and state');
      this.router.navigate(['/search/', { type: type, state: state }]);
      // this.router.navigate(['/search/' + type]);
    } else {
      console.log('Searching for type only');
      this.router.navigate(['/search/', { type: type}]);
    }


  }

  navigateSite(destination: String) {
    this.router.navigate(['/' + destination + '/']);
  }

  // Function that will take users to the WoltersKluwerDiscount page if they are logged in, else navigate to registration
  woltersKluwerDiscount() {
    console.log('woltersKluwerDiscount started');
  }

  youtubeVideoClick() {
    console.log('youtubeVideoClick started');
  }

  testLocalStorageSet() {
    // this.cacheService.exampleLSCacheSet();
    console.log('testLocalStorageSet');
    this.cacheService.setLSCache('data', {'name': 'Dillon', 'age': 37}, 1);
  }

  testLocalStorageGet() {
    // this.cacheService.exampleLSCacheGet();
    console.log('testLocalStorageGet');
    this.cacheService.getLSCache('data');
  }

  testLocalStorageFlush() {
    console.log('testLocalStorageFlush');
    this.cacheService.flushLSCache();
  }

  testLocalStorageHiPris() {
    this.cacheService.fetchAndStoreHighPriorityPostings().then(res => {
      console.log('Hi Pris Populated');
    }).catch(e => {
      console.log('Error with getting hi pris: ' + e);
    });
  }

  // Snotify examples
  testSnotify() {
    this.snotifyService.success('Example body content');
  }


  ngOnDestroy() {
    console.log('inside home ngOnDestroy');
    if (this.userInfoSubscription) {
      console.log('bagmask-nav, userInfoSubscription exists, destroying');
      this.userInfoSubscription.unsubscribe();
    }
  }

}
