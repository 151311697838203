import {Component, Inject, Input, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-email-share',
  templateUrl: './email-share.component.html',
  styleUrls: ['../sharing-buttons.scss']
})
export class EmailShareComponent implements OnInit {
  @Input() emailTitle: string;

  constructor(@Inject(DOCUMENT) public document: any,) { }

  ngOnInit() {
  }

}
