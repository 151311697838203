import {Component, Inject, Input, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-linked-in-share',
  templateUrl: './linked-in-share.component.html',
  styleUrls: ['../sharing-buttons.scss']
})
export class LinkedInShareComponent implements OnInit {
  @Input() title: string;
  @Input() summary: string;
  @Input() source: string;

  constructor(@Inject(DOCUMENT) public document: any,) { }

  ngOnInit() {
  }

}
