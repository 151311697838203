import { Injectable } from '@angular/core';
import {PostUpgradeDialogComponent} from '../payments/post-upgrade-dialog/post-upgrade-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UIService} from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class PostUpgradeService {

  constructor(public dialog: MatDialog,
              private uiService: UIService) { }

  postUpgradeDialog(postId: string,
                    postJobId: string,
                    confirmationYesSnackbarMessage?: string,
                    disableCloseInput?: boolean): Promise<boolean> {
    console.log('postUpgradeDialog started');
    disableCloseInput = true;

    const promise = new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialog.open(
        PostUpgradeDialogComponent, {
          width: '650px',
          data: {
            postId: postId,
            postJobId: postJobId,
          },
          disableClose: disableCloseInput,
        }
      );
      dialogRef.afterClosed().subscribe( result => {
        // console.log(result);
        if (result) {
          console.log('postUpgradeDialog value returned: ', result);
          this.uiService.showSnackbar('Post Successfully Upgraded', null, 8000);

          resolve(true);
          // returnValue = true;
        } else {
          reject(false);
          // returnValue = false;
        }
      });
      // return returnValue;
    });

    return promise;
  }
}
