import {Component, OnInit, Input, isDevMode, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {PostService} from '../../../Services/post.service';
import {PaymentService} from '../../../payments/payment.service';
import {User} from '../../../Models/user.model';
import {tap} from 'rxjs/operators';
import {UIService} from '../../../Services/ui.service';
import {AuthService} from '../../../Services/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {BagmaskFieldValues} from '../../../Namespaces/bagmaskFieldValues.namespace';
import getPostPriorityCostOptions = BagmaskFieldValues.getPostPriorityCostOptions;
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../Services/user.service';

@Component({
  selector: 'app-add-post-payment-submit',
  templateUrl: './add-post-payment-submit.component.html',
  styleUrls: ['./add-post-payment-submit.component.scss']
})
export class AddPostPaymentSubmitComponent implements OnInit, OnDestroy {
  @Input() editYesNo: boolean;
  postPriorityCostOptions = BagmaskFieldValues.getPostPriorityCostOptions();
  devModeEnabled = false;
  highlightedDiv: number;
  postFormPaymentSubmit: FormGroup;
  currentPrioritySelected: number;
  currentUser: User;
  isEditPost = false;

  postSubscription: Subscription;
  userProfileSubscription: Subscription;

  hasPostingPlan = false;
  postingPlan: string;
  postingPlanFreePriorityPostsTotal: number;
  postingPlanFreePriorityPostsRemaining: number;
  postingPlanPriorityDiscount: number;
  postingStartDate: Date;
  postingStartExpirationDate: Date;

  postCostInCents: number;
  postCostInDollars: number;

  postPriCostInCents: number;
  postPriCostInDollars: number;
  postPriCostInDollarsAfterDiscount: any;

  postHiPriCostInCents: number;
  postHiPriCostInDollars: number;
  postHiPriCostInDollarsAfterDiscount: any;

  currentPostCostInCents = 0;
  currentPostCostInDollars = 0;
  currentPrioritySelectionCostInCents = 0;
  currentPrioritySelectionCostInDollars = 0;
  totalCostInCents = 0;
  totalCostInDollars = 0;

  postDialogMaxWidth = '90%';
  postDialogWidth = '90%';

  // TODO: After free plan period is over, TURN THIS OFF
  isFreePeriod: boolean;

  constructor(private postService: PostService,
              public auth: AuthService,
              private paymentSvc: PaymentService,
              private afs: AngularFirestore,
              public subscriptionDialog: MatDialog,
              private uiService: UIService,
              private userService: UserService,) {
    if (isDevMode()) {
      // Enable dev mode if isDevMode is true, showing extra form elements
      this.devModeEnabled = true;
    }
  }

  ngOnInit() {
    // Check Free Period
    this.isFreePeriod = this.paymentSvc.getBagmaskFreePeriodStatus();
    if (this.isFreePeriod) {
      console.log('monthly subscription - inside Bagmask free period');
    } else {
      console.log('monthly subscription - paid period');
    }

    this.preloadUserData();
    this.setInitialCosts();

    this.postFormPaymentSubmit = new FormGroup({
      postTotalCostInCents: new FormControl(),
      // salSigningBonus: new FormControl('', [Validators.pattern('^[0-9]*$')]),
      // salSigningBonusCommitmentReimbursement: new FormControl(),
    });



    console.log('post submit, checking hasPostingPlan for initial cost');
    if (this.hasPostingPlan) {
      console.log('post submit, checking hasPostingPlan for initial cost, has posting plan');
      this.currentPostCostInCents = 0;
      this.currentPostCostInDollars = 0;
    } else if (this.isFreePeriod) {
      console.log('Inside add post payment submit, free posting period active, setting base cost to 0');
      this.currentPostCostInCents = 0;
      this.currentPostCostInDollars = 0;
    } else {
      console.log('post submit, checking hasPostingPlan for initial cost, does not have posting plan');
      this.currentPostCostInCents = this.postCostInCents;
      this.currentPostCostInDollars = this.postCostInDollars;
    }

    // Initial calculation of totalCostinDollars
    this.totalCostInCents = this.currentPostCostInCents;
    this.totalCostInDollars = this.currentPostCostInDollars;

    // Now send initial calculation to message of current post cost
    //
    this.paymentSvc.sendPriSelected(1);
    this.paymentSvc.sendMessage('postTotalCostInCents Updated: ' + this.totalCostInCents);
    this.paymentSvc.sendPrice(this.totalCostInCents);

  }

  setInitialCosts() {
    // Basic Costs Assigned
    console.log('getPostPriorityCostOptions');

    for (const plan of getPostPriorityCostOptions()) {
      console.log(plan);
      if (plan.priorityName === 'Single') {
        this.postCostInCents = plan.priorityPriceInCents;
        this.postCostInDollars = plan.priorityPriceInDollars;
      } else if (plan.priorityName === 'Priority') {
        this.postPriCostInCents = plan.priorityPriceInCents;
        this.postPriCostInDollars = plan.priorityPriceInDollars;
      } else if (plan.priorityName === 'High Priority') {
        this.postHiPriCostInCents = plan.priorityPriceInCents;
        this.postHiPriCostInDollars = plan.priorityPriceInDollars;
      }
    }
  }

  preloadUserData() {
    console.log('Inside add post submit, preloadUserData');
    this.userProfileSubscription = this.afs.doc('users/' + this.auth.authenticatedUser.uid).valueChanges().pipe(
      tap(data => {
        try {
          this.currentUser = data as User;
          console.log('Checking Monthlyplan: ' + this.currentUser.userCompanyMonthlyPlan);
          if (this.currentUser.userCompanyMonthlyPlan) {
            // Set hasPostingPlan to true, and show the card with posting plan, otherwise show "buy subscription plan"
            this.hasPostingPlan = true;

            // this.hasPostingPlan = true;
            // console.log('preloadData finished, value: ' + JSON.stringify(data));
            console.log('preloadData start time: ' + this.currentUser.userCompanyMonthlyPlanStartDate.toDate() +
              ' expiration: ' + this.currentUser.userCompanyMonthlyPlanExpirationDate.toDate());

            this.postingPlan = this.currentUser.userCompanyMonthlyPlan;

            this.postingPlanFreePriorityPostsTotal = this.currentUser.userCompanyMonthlyPlanFreePriorityPostsTotal;
            this.postingPlanFreePriorityPostsRemaining = this.currentUser.userCompanyMonthlyPlanFreePriorityPostsRemaining;
            this.postingPlanPriorityDiscount = this.currentUser.userCompanyMonthlyPlanPriorityDiscount;
            this.postingStartDate = this.currentUser.userCompanyMonthlyPlanStartDate.toDate();
            this.postingStartExpirationDate = this.currentUser.userCompanyMonthlyPlanExpirationDate.toDate();

            // Check for monthly plan set post cost to 0
            if (this.hasPostingPlan) {
              console.log('add post - hasPostingPlan is true in preloadUserData');
              this.currentPostCostInCents = 0;
              this.currentPostCostInDollars = 0;
              this.totalCostInCents = 0;
              this.totalCostInDollars = 0;

              this.paymentSvc.sendPriSelected(1);
              this.paymentSvc.sendMessage('postTotalCostInCents in Preload Updated: ' + this.totalCostInCents);
              this.paymentSvc.sendPrice(this.totalCostInCents);
            }

            this.setDisplayCostsAfterDiscount();
          } else {
            // Do nothing
          }


        } catch (error) {
          console.error(error);
          if (error instanceof TypeError) {
            this.uiService.showSnackbar('Encountered Issue with User Profile, Please contact support', null, 3000);
          } else {
            this.uiService.showSnackbar(error.message, null, 3000);
          }

        }
      })
    ).subscribe();

    console.log('Preloading Data, display name: ' + this.auth.authenticatedUser.displayName + ' ' + this.auth.authenticatedUser.uid);
  }

  setDisplayCostsAfterDiscount() {
    console.log('setDisplayCostAfterDiscount, discount: ' + this.postingPlanPriorityDiscount);

    console.log('postPriCostInCents: ' + this.postPriCostInCents);
    console.log('postHiPriCostInCents: ' + this.postHiPriCostInCents);
    console.log('postingPlanPriorityDiscount: ' + this.postingPlanPriorityDiscount);

    // Determine post-discount prices for display on form only
    if (this.hasPostingPlan) {
      // Populate HiPri cost with plan discount
      this.postHiPriCostInDollarsAfterDiscount =
        this.postHiPriCostInCents -
        (this.postHiPriCostInCents * (this.postingPlanPriorityDiscount / 100));
      this.postHiPriCostInDollarsAfterDiscount = this.postHiPriCostInDollarsAfterDiscount / 100;

      // Check for free priorities remaining
      if (this.postingPlanFreePriorityPostsRemaining > 0) {
        this.postPriCostInDollarsAfterDiscount = 'Free';

      } else {
        this.postPriCostInDollarsAfterDiscount =
          this.postPriCostInCents -
          (this.postPriCostInCents * (this.postingPlanPriorityDiscount / 100));
        this.postPriCostInDollarsAfterDiscount = this.postPriCostInDollarsAfterDiscount / 100;
      }

    }
    console.log('setDisplayCostAfterDiscount, after function: ' + this.postPriCostInDollarsAfterDiscount + ' ' +
      this.postHiPriCostInDollarsAfterDiscount);
  }

  priorityClick(priorityLevelClicked: string, prioritySelected: number) {
    console.log('priorityClick: ' + priorityLevelClicked + ' prioritySelected: ' + prioritySelected);
    // Check if currently selected priority is same, if it is, then cancel all priorities selected
    if (this.currentPrioritySelected === prioritySelected) {
      // Existing selection, cancel it
      this.highlightedDiv = null;
      this.currentPrioritySelected = 1;
    } else {
      // New selection, update the priority selected
      this.highlightedDiv = prioritySelected;
      this.currentPrioritySelected = prioritySelected;
    }

    console.log('currentPrioritySelected: ' + this.currentPrioritySelected);

    if (this.currentPrioritySelected === 2) {
      console.log('Apply pricing for priority');
      this.paymentSvc.sendPriSelected(2);
      this.currentPrioritySelectionCostInCents = this.postPriCostInCents;
      this.currentPrioritySelectionCostInDollars = this.postPriCostInDollars;
    } else if (this.currentPrioritySelected === 3) {
      this.paymentSvc.sendPriSelected(3);
      this.currentPrioritySelectionCostInCents = this.postHiPriCostInCents;
      this.currentPrioritySelectionCostInDollars = this.postHiPriCostInDollars;
      console.log('Apply pricing for high priority');
    } else {
      this.paymentSvc.sendPriSelected(1);
      this.currentPrioritySelectionCostInCents = 0;
      this.currentPrioritySelectionCostInDollars = 0;
      console.log('Apply base pricing without priority');
    }

    this.applyDiscount();

    this.applyFreePriorities();

    // Sum up cost and priority cost
    this.totalCostInCents = this.currentPostCostInCents + this.currentPrioritySelectionCostInCents;
    this.totalCostInDollars = this.currentPostCostInDollars + this.currentPrioritySelectionCostInDollars;

    console.log('Summing up total cost: ' + this.totalCostInDollars + ' from current costs: ' + this.currentPostCostInDollars + ', ' +
    this.currentPrioritySelectionCostInDollars);

    // Apply total cost in cents to formcontrol that large add form can access

    this.postFormPaymentSubmit.get('postTotalCostInCents').setValue(this.totalCostInCents);

    this.paymentSvc.sendMessage('postTotalCostInCents Updated: ' + this.totalCostInCents);
    this.paymentSvc.sendPrice(this.totalCostInCents);
  }

  applyDiscount() {
    // Check the discount here to apply to current priority cost
    if (this.currentPrioritySelectionCostInCents > 0 && this.hasPostingPlan === true) {

      this.currentPrioritySelectionCostInCents =
        this.currentPrioritySelectionCostInCents -
        (this.currentPrioritySelectionCostInCents * (this.postingPlanPriorityDiscount / 100));

      this.currentPrioritySelectionCostInDollars =
        this.currentPrioritySelectionCostInDollars -
        (this.currentPrioritySelectionCostInDollars * (this.postingPlanPriorityDiscount / 100));

      console.log('We have a priority cost, now applying monthly discount to it and we have a posting plan.' +
        'cents: ' + this.currentPrioritySelectionCostInCents + ' dollars: ' + this.currentPrioritySelectionCostInDollars);
    }
  }

  applyFreePriorities() {
    // Check if priority is selected
    if (this.currentPrioritySelected === 2) {
      // Check if remianing priorities exist
      if (this.postingPlanFreePriorityPostsRemaining > 0) {
        this.currentPrioritySelectionCostInCents = 0;
        this.currentPrioritySelectionCostInDollars = 0;
      }
    }

  }

  ngOnDestroy() {
    if (this.postSubscription) {
      this.postSubscription.unsubscribe();
    }

    if (this.userProfileSubscription) {
      this.userProfileSubscription.unsubscribe();
    }

  }

}
