import {Component, OnDestroy, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../Services/auth.service';
import { UIService} from '../../Services/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  // maxDate;
  isLoading = false;
  private loadingSubs: Subscription;

  constructor(private authService: AuthService,
              private uiService: UIService) { }

  ngOnInit() {
    this.loadingSubs = this.uiService.loadingStateChanged.subscribe(isLoading => {
        this.isLoading = isLoading;
      }
    );
    // this.maxDate = new Date();
    // this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }

  onSubmit(form: NgForm) {
    this.authService.registerUser({
      email: form.value.email,
      password: form.value.password
    });
  }

  ngOnDestroy() {
    console.log('inside signup ngOnDestroy');
    if (this.loadingSubs) {
      console.log('signup, loadingSubs exists, destroying');
      this.loadingSubs.unsubscribe();
    }
  }

}
