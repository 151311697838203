import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {PricesService} from '../../Services/prices.service';
import {loadStripe} from '@stripe/stripe-js';
import { StripeBagmaskService } from 'src/app/Services/stripeBagmask.service';
import { AuthService } from 'src/app/Services/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { EnvironmentService } from 'src/app/Services/environment.service';

@Component({
  selector: 'app-pricing2020',
  templateUrl: './pricing2020.component.html',
  styleUrls: ['./pricing2020.component.scss']
})
export class Pricing2020Component implements OnInit {
  stripe: any;
  isLoading: boolean;
  stripePortalLoading: boolean = false;
  currentUserUID: string;
  stripePlanData = [];

  constructor(private router: Router,
              private titleService: Title,
              private authService: AuthService,
              private afs: AngularFirestore,
              private environmentService: EnvironmentService,
              private fns: AngularFireFunctions,
              public stripeBagmaskService: StripeBagmaskService,
              public pricesService: PricesService,) { }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Anesthesia Job Posting Plans & Pricing | BagMask.com');
    console.log('Starting stripeBagmask service on Init')

    // Manual testing of test below
    // this.stripe = await loadStripe('pk_test_uFjwuZ9bJd8JqwCB3EiZYI7G');
    // Get the current stripe key
    this.stripe = await loadStripe(this.environmentService.getBagMaskStripeKey());
    // Set isLoading to true, until we are sure we have the authenticatedUser field
    this.isLoading = true;
    // Step 1: get 'currentUser' or current firebase user's uid

    // Check to see if authenticatedUser exists yet, if not wait and try again
    // It should be created very quickly after user signs into site, but if user immediately/directly goes to this page, it may not
    // eist yet.
    if (this.authService.authenticatedUser) {
      // Exists, set the local variable
      this.currentUserUID = this.authService.authenticatedUser.uid;
      console.log('Current User UID: ', this.currentUserUID);
      // TODO: Now that we have UID, check to see if they have a value in customers/UID/subscriptions
      this.isLoading = false;
    } else {
      console.log('authenticatedUser does not exist yet, waiting then fetching');
      setTimeout(() => {
        this.currentUserUID = this.authService.authenticatedUser.uid;
        console.log('Current User UID: ', this.currentUserUID);
        this.isLoading = false;
      } , 2000)
    }

    // Step 2: Get current Stripe subscription plans
    this.stripePlanData = await this.stripeBagmaskService.getBagMaskStripeSubPlans();
    console.log('Final StripePlanData: ', this.stripePlanData);
    // await this.getBagMaskStripeSubPlans();
  }

  goToUser() {
    this.router.navigate(['/user', 'profile']).then(r => {
      // console.log('router navigate: ', r);
    });
  }

  goToNewPosting() {
    this.router.navigate(['/add']).then(r => {
      // console.log('router navigate: ', r);
    });
  }

  // Function to be called from the html that will in turn set variables and call the billing handler
  stripeSubscriptionPlan(planName: string) {
    // Set isLoading to true so the user knows a process is happening while they wait for stripe billing handler to start up
    this.stripePortalLoading = true;
    this.stripeBillingHandler(this.stripeBagmaskService.getBagMaskStripePlanPrices(planName));
  }


  async stripeBillingHandler(planPriceId: string) {
    console.log('Starting stripeBillingHandler for planPriceId: ', planPriceId);
    console.log('currentUserUID: ', this.currentUserUID);

    const docRef = await this.afs
      .collection('customers')
      .doc(this.currentUserUID)
      .collection('checkout_sessions')
      .add({
        // price: formData.get('price'),
        // price: 14900,
        price: planPriceId,
        // price: 'price_1HVLo1CfLG9nGFpofBpZhCRM',
        allow_promotion_codes: true,
        // tax_rates: taxRates,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          tax_rate: 'N/A',
        },
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data();
      if (error) {
        // Show an error to your customer and then inspect your function logs.
        alert(`An error occured: ${error.message}`);
        document.querySelectorAll('button').forEach((b) => (b.disabled = false));
      }
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        console.log(sessionId);
        // const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
        this.stripe.redirectToCheckout({ sessionId });
      }
    });

  }

  async stripeBillingPortal() {
    console.log('Starting stripeBillingPortal');
    // Call billing portal function
    const functionRef = this.fns
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')

    // @ts-ignore
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    const data = await functionRef({ returnUrl: window.location.origin });
    data.subscribe(val => {
      console.log(val);
      window.location.assign(val.url);
    })
    // window.location.assign(data.url);

    // Call billing portal function
    // const functionRef = firebase
    //   .app()
    //   .functions(functionLocation)
    //   .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    // window.location.assign(data.url);

    // window.location.assign(data.url);
    // let session = await this.stripe.billingPortal.sessions.create({
    //   customer: 'cus_I5Y00VkVZt5GUe',
    //   return_url: 'https://jobs.bagmask.com',
    // });
  }


}
